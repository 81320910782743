import { ReactElement, JSXElementConstructor } from 'react'
import { IonButton } from '@ionic/react'

export type WithOptionalKeys<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
export type ID = number
export type Identified = { id: ID }
export type Unidentified<T extends Identified> = Omit<T, 'id'>
export type OptionallyIdentified<T extends Identified> = WithOptionalKeys<T, 'id'>

export interface FileWithUrl extends File {
  url?: string
  thumbnail_url?: string
}

export interface SelectOption {
  label: string
  value: string | number
  disabled?: boolean
}

export interface LabeledValue<ValueType> {
  label: string | ReactElement
  value: ValueType
  disabled: boolean
  extraContent?: string | ReactElement
}

export interface User {
  first_name: string
  last_name: string
  email: string
  phone: string
  company_name: string
  accounting_emails: string[]
  password: string
  market_id: string
  supplier_id?: string
  invite_token?: string
}

export interface UserProfile extends User {
  id: ID
  name: string
  role: string
  market: Market
  team: Team
  feature_flags: { [key: string]: boolean }
  subscription?: Subscription
}

// Stripe Payment method
export interface PaymentMethod {
  id: string
  card: {
    last4: string
    brand: string
    exp_year: number
    exp_month: number
  }
}

export enum VehicleType {
  Car = 'Car',
  Truck = 'Truck',
  Supplier = 'Supplier',
  RackTruck = 'RackTruck',
  SprinterVan = 'SprinterVan',
}

export enum DeliveryBy {
  SupplyHound = 'SupplyHound',
  Supplier = 'Supplier',
}

export enum DeliveryType {
  None = '',
  TwoHours = 'two_hours',
  FourHours = 'four_hours',
  SameDay = 'same_day',
  Tomorrow9am = 'tomorrow_9am',
  Tomorrow2pm = 'tomorrow_2pm',
  Custom = 'custom',

  // Curri deliery options
  Rush = 'rush',
  Tomorrow9amCurri = 'tomorrow_9am_curri',
  Tomorrow3pmCurri = 'tomorrow_3pm_curri',
  Monday9amCurri = 'monday_9am_curri',
  Monday3pmCurri = 'monday_3pm_curri',
  Scheduled = 'scheduled',
}

export enum SupplierDeliveryDate {
  Tomorrow = 'tomorrow',
  TwoDays = 'two_days',
  ThreeDays = 'three_days',
  FourDays = 'four_days',
  FiveDays = 'five_days',
  Custom = 'custom',
}

export enum SupplierDeliveryType {
  Anytime = 'anytime',
  Morning = '9am_to_12pm',
  Afternoon = 'afternoon',
}

export enum PickupType {
  None = '',
  TwoHours = 'two_hours',
  FourHours = 'four_hours',
  TodayAfter2pm = 'today_after_2pm',
  TomorrowBy7am = 'tomorrow_by_7am',
  TomorrowAfter2pm = 'tomorrow_after_2pm',
}

export type AbstractItem = {
  id: ID
  description: string
  quantity: number | ''
  image?: FileWithUrl
  source_task_item_id?: ID
}

export type UnidentifiedAbstractItem = Unidentified<AbstractItem>

export interface Market {
  id: ID
  name: string
  timezone: string
  timezone_offset: number
}

export interface DeliveryTypeConfig {
  value: DeliveryType
  label: string
  isEnabled?(tz?: string): boolean
  computeDatetimes(tz?: string, deliveryDatetime?: string): [string, string]
}

export interface SupplierDeliveryDateConfig {
  value: SupplierDeliveryDate
  label: string
  isEnabled?(): boolean
  computeDatetimes(deliveryDate?: string): string
}

export interface SupplierDeliveryTypeConfig {
  value: SupplierDeliveryType
  label: string
}

export interface SupplierDeliveryDateConfig {
  value: SupplierDeliveryDate
  label: string
  isEnabled?(): boolean
  computeDatetimes(deliveryDate?: string): string
}

export interface SupplierDeliveryTypeConfig {
  value: SupplierDeliveryType
  label: string
}

export interface PickupTypeConfig {
  value: PickupType
  label: string
  isEnabled?(tz?: string): boolean
  computePickupDatetime(tz?: string): string
}

export interface Supplier {
  id: ID
  store_type: string
  store_name: string
  address: string
  phone: string
  supplier_order_email: string
  sh_order_email: string
  logo: string
  website: string
  location: Location
}

export interface Promotion {
  id: ID
  discount: number
  supplier: Supplier
}

export interface Location {
  id: ID
  country: string
  state: string
  city: string
  street: string
  street2?: string
  zipcode: string
  place_id?: string
  full_address: string
}

export type LocationAttributes = Omit<Location, 'id'>

export interface MenuItem {
  title: string
  icon: string
  routeProps: {
    routerLink?: string
    href?: string
    onClick?(): void
  }
  conditionalRender?: (userProfile?: UserProfile) => boolean
}

export type ClientApplication = {
  name: string
  version: string
}

export type TeamMember = {
  id: ID
  phone: string
  first_name: string
  last_name: string
  confirmed_at?: string
  should_receive_daily_reminder: boolean
  team_id: ID
  confirmation_url?: string
  reminder_accept_url?: string
  reminder_decline_url?: string
}

export type UnidentifiedTeamMember = WithOptionalKeys<TeamMember, 'id' | 'team_id'>

export type TeamMemberAuthenticationValues = Pick<TeamMember, 'phone'>

export type TeamMemberAuthQuery = TeamMemberAuthenticationValues
export type TeamMemberConfirmationQuery = TeamMemberAuthQuery & {
  token: string
}

export type Team = {
  id: ID
  schedule_days: string[]
  schedule_time: string
}

export type AuthToken = string

export type DecodedUserToken = {
  sub: UserProfile['id']
  email: User['email']
  role: UserProfile['role']
  exp: number
}

export type ModalView = {
  modal_type: string
  view_count: number
}

type RouteID = string

export type AwsSignedID = string

export type toEnumType<EnumType> = EnumType[keyof EnumType]

export type ForwardProps<T extends JSXElementConstructor<any>> = React.ComponentProps<T>

export type ForwardPartialProps<T extends JSXElementConstructor<any>> = Partial<React.ComponentProps<T>>

export type ButtonProps = ForwardProps<typeof IonButton>
export type ButtonType = React.FC<ButtonProps>

export type ForwardPropsToChildren<Throughout, Inner> = Throughout & {
  children?: (props: Throughout & Inner) => JSX.Element
}

export interface AbstractList {
  id: ID
  job_site_id: ID
  active_items_count: number
}

export interface OrdererList extends AbstractList {
  orderer_id: ID
  active_items: OrdererListItem[]
}

export interface TeamMemberList extends AbstractList {
  team_member_id: ID
  items: TeamMemberListItem[]
  job_site: JobSite
}

export interface TeamMemberListItem extends AbstractItem {
  team_member_list_id: ID
  status?: 'active' | 'submitted' | 'archived' | 'accepted'
}

export type UnidentifiedTeamMemberListItem = WithOptionalKeys<Unidentified<TeamMemberListItem>, 'team_member_list_id'>

export type OptionallyIdentifiedTeamMemberListItem = WithOptionalKeys<TeamMemberListItem, 'id' | 'team_member_list_id'>

export interface OrdererListItem extends AbstractItem {
  orderer_list_id: ID
  orderer_list_item_id?: ID
}

export type UnidentifiedOrdererListItem = WithOptionalKeys<Unidentified<OrdererListItem>, 'orderer_list_id'>

export type OptionallyIdentifiedOrdererListItem = WithOptionalKeys<OrdererListItem, 'id' | 'orderer_list_id'>

export type JobSiteDetailRouteParams = {
  id: RouteID
}

export type TeamMemberProfile = TeamMember & {
  team_id: ID
  reminder_declined: boolean
}

export type OrdererTeamMemberList = {
  id: ID
  job_site_id: ID
  items: SelectableTeamMemberListItem[]
  team_member: TeamMemberInfo
}

export const JobSiteStatuses = {
  current: 'active',
  archived: 'archived',
} as const
export type JobSiteStatus = toEnumType<typeof JobSiteStatuses>

export type JobSite = {
  id: ID
  contact_name: string
  phone: string
  note?: string
  location: Location
  // Fields derived from address
  formatted_address: string
  name: string
  orderer_list: OrdererList
  team_lists_count?: number
  team_requests_count?: number
  status: JobSiteStatus
  created_date?: string
  archived_date?: string
  nickname: string
}

export type JobSiteAttributes = Pick<JobSite, 'contact_name' | 'phone' | 'note' | 'status' | 'nickname'> & {
  location_attributes: LocationAttributes
}

export type UnidentifiedJobSite = Omit<Unidentified<JobSite>, 'location' | 'orderer_list'> & {
  location: LocationAttributes
}
export type OptionallyIdentifiedTeamMember = OptionallyIdentified<TeamMember>
export type TeamMemberInfo = Omit<TeamMember, 'team_id' | 'should_receive_daily_reminder'>

export const TaskTypes = {
  Delivery: 'DeliveryTask',
  Pickup: 'PickupTask',
} as const

export type TaskType = toEnumType<typeof TaskTypes>

export interface UnidentifiedTaskItem extends UnidentifiedAbstractItem {}

export type SupplierSearchResult = {
  name: string
  displayText?: string
  address: string
  place_ids?: Location['place_id'][]
  place_id?: Location['place_id']
  supplier_id?: ID
}

export interface UnidentifiedAbstractTask {
  supplier_id?: ID
  job_site_id?: ID
  job_site_name: string // This is a computed value dependent on job_site_id

  pickup_address: string
  pickup_datetime: string
  pickup_note: string

  itemIds: ID[]

  supplier?: SupplierSearchResult
  ordered_with_subscription?: boolean
}

export interface UnidentifiedDeliveryTask extends UnidentifiedAbstractTask {
  type: typeof TaskTypes.Delivery
  vehicle_type: VehicleType
  ordered_directly: boolean
  delivery_address: string
  delivery_by: DeliveryBy
  delivery_type: DeliveryType | SupplierDeliveryType
  delivery_datetime: string
  delivery_username: string
  delivery_phone: string
  delivery_note?: string
  job_distance: number
  promotion?: Promotion
  promo_code?: string
}

export interface QuoteTask extends UnidentifiedDeliveryTask {
  extra_fee: number
  promo_discount: number
}

export interface DeliveryTask extends UnidentifiedDeliveryTask {
  id: ID
}

export type DeliveryTaskBillingDetails = {
  subtotal: number
  extraFee: number
  handlingFee: number
  discount: number
  subtotalWithFees: number
  total: number
  quote_id?: string
  quote_code?: string
}

export interface UnidentifiedPickupTask extends UnidentifiedAbstractTask {
  type: typeof TaskTypes.Pickup
  pickup_type: PickupType
}

export interface PickupTask extends UnidentifiedPickupTask {
  id: ID
}

export type UnidentifiedGenericTask = UnidentifiedDeliveryTask | UnidentifiedPickupTask

export interface HistoryTask extends UnidentifiedAbstractTask {
  id: ID
  delivery_job_status?: string
  pickup_job_status?: string
  created_at: string
  store_name?: string
  type: TaskType
  status: string
  task_complete: boolean
  delivery_address?: string
  stripe_payment_method_id: string
  items: OrdererListItem[]
  job_site_id: ID
  ordered_directly: boolean
  billingDetails: {
    subtotal: number
    extraFee: number
    handlingFee: number
    discount: number
    total: number
    subtotalWithFees: number
  }
  supplier: {
    name: string
    address: string
  }
}

export const LAST_ORDER_OMIT_FIELDS = ['stripe_payment_method_id', 'items', 'billingDetails']

export interface LastOrderExtraProps {
  stripe_payment_method_id: string
  items: OrdererListItem[]
  billingDetails?: DeliveryTaskBillingDetails
}

export type Selectable = { selected: boolean }
export type SelectableOrdererListItem = OrdererListItem & Selectable
export type SelectableTeamMemberListItem = TeamMemberListItem & Selectable
export type SelectableOrdererList = Omit<OrdererList, 'active_items'> & {
  active_items: SelectableOrdererListItem[]
}

export const Modes = {
  Add: 'Add',
  Edit: 'Edit',
}

export const BuildList = {
  MyList: 'My List',
  Requests: 'Requests',
}

export const MaterialListHeader = {
  JobSiteDropdown: 'jobsite_dropdown',
  TeamMember: 'team_member',
}

export type PageMode = toEnumType<typeof Modes>

export const USER_EDITABLE_ATTRS = [
  'first_name',
  'last_name',
  'email',
  'phone',
  'company_name',
  'market_id',
  'name',
] as const
type BasicUserTuple = typeof USER_EDITABLE_ATTRS
type BasicUserAttrs = BasicUserTuple[number]

export type BasicUser = Pick<UserProfile, BasicUserAttrs>

export type SubscriptionType = 'legacy' | 'free' | 'pro' | 'power'
export type BillingPlanType = 'monthly' | 'annually'

export type Subscription = {
  id: ID
  ended_at?: string
  billing_plan_type: BillingPlanType
  stripe_payment_method_id: string
  next_billing_at: string
  subscription_type: SubscriptionType
  administrator: boolean
  free_booking_fee_count: number
}

export type UnidentifiedContact = {
  first_name: string
  last_name: string
  email: string
  phone: string
  mobile: string
  place_ids: string[]
  supplier_name: string
  supplier_id: string | null
}

export interface Contact extends UnidentifiedContact {
  id: ID

  is_archived: boolean
}

export interface ContactWithCommunicationPreference extends Contact {
  contact_preference_email: boolean
  contact_preference_sms: boolean
}

export interface UnidentifiedContactWithCommunicationPreference extends UnidentifiedContact {
  contact_preference_email: boolean
  contact_preference_sms: boolean
}
