export interface ConnectorAndHangerFormValues {
  category: string
  subcategory1: string
  subcategory2: string
  subcategory3: string
  flangeWidthW1: string
  flangeWidthW2: string
  length: string
  width: string
  nominalColumnSize: string
  nominalPostSize: string
  beamWidth: string
  columnWidth: string
  nominalPostWidth: string
  nominalJoistSize: string
  headerWidth: string
  rafterSize: string
  skewDirection: string
  gauge: string
  coating: string
  application: string
  item: string
}

export const initialFormValues: ConnectorAndHangerFormValues = {
  category: '',
  subcategory1: '',
  subcategory2: '',
  subcategory3: '',
  flangeWidthW1: '',
  flangeWidthW2: '',
  length: '',
  width: '',
  nominalColumnSize: '',
  nominalPostSize: '',
  beamWidth: '',
  columnWidth: '',
  nominalPostWidth: '',
  nominalJoistSize: '',
  headerWidth: '',
  rafterSize: '',
  skewDirection: '',
  gauge: '',
  coating: '',
  application: '',
  item: '',
}

export const connectorAndHangerByDimensionFields = {
  flangeWidthW1: 'Flange Width - W1',
  flangeWidthW2: 'Flange Width - W2',
  length: 'Length - L',
  width: 'Width - W',
  nominalColumnSize: 'Nominal Column Size (W1 x W2)',
  nominalPostSize: 'Nominal Post Size (W1 x W2)',
  beamWidth: 'Beam Width (W1)',
  columnWidth: 'Column Width (W2)',
  nominalPostWidth: 'Nominal Post Width (W1)',
  nominalJoistSize: 'Nominal Joist Size',
  headerWidth: 'Header Width',
  rafterSize: 'Rafter Size',
  skewDirection: 'Skew Direction',
  gauge: 'Gauge (Length in ft)',
  coating: 'Coating',
  application: 'Application',
} as const

export type ConnectorAndHangerByDimensionFieldType = keyof typeof connectorAndHangerByDimensionFields

const stripNonNumericCharacters = (s: string) => {
  return s.replace(/[^0-9]/g, '')
}

const actualToNominalConverter = {
  '3"': '4',
  '3 1/2"': '4',
  '4 1/2"': '5',
  '5 1/8"': '5',
  '5 1/2"': '6',
  '7 1/2"': '8',
}

export const getCategoryText = (currentCategoryText: string) => {
  if (currentCategoryText === 'Bases' || currentCategoryText === 'Caps') {
    return `${currentCategoryText} (Column & Post)`
  }

  return currentCategoryText
}

export const getHeaderText = (
  currentHeaderValue: string,
  {
    category,
    subcategory1,
  }: {
    category: string
    subcategory1: string
  }
) => {
  if (category === 'Simpson Model Number') {
    if (subcategory1 === 'Angles') {
      switch (currentHeaderValue) {
        case 'A':
          return `A - ${subcategory1}`
        case 'L':
          return `L - ${subcategory1}`
        case 'HL':
          return `Heavy L - ${subcategory1}`
        case 'LS':
          return `Skewable L - ${subcategory1}`
        case 'GA':
          return `Gusset ${subcategory1}`
        case 'HGA':
          return `Hurricane Gusset ${subcategory1}`
        default:
          return currentHeaderValue
      }
    }
  }

  return currentHeaderValue
}

export const formatValues = ({
  category,
  subcategory1,
  subcategory2,
  subcategory3,
  flangeWidthW1,
  flangeWidthW2,
  length,
  width,
  nominalColumnSize,
  nominalPostSize,
  beamWidth,
  columnWidth,
  nominalPostWidth,
  nominalJoistSize,
  headerWidth,
  rafterSize,
  skewDirection,
  gauge,
  coating,
  application,
  item,
}: ConnectorAndHangerFormValues) => {
  if (category === 'Simpson Model Number') {
    return connectorAndHangersDisplayText[item]
  }

  const ZMAX = coating === 'ZMAX' ? 'Z' : ''
  let code = ''
  let type = ''
  let nominalStats = ''
  let nominalStatsPostfix = ''
  let description = ''
  if (subcategory1 === 'Angles') {
    return `${application} ${subcategory1} - ${length} long, flange depth ${flangeWidthW1} x ${flangeWidthW2} - ${coating}`
  } else if (subcategory1 === 'Hangers') {
    description = `for ${nominalJoistSize}`
    nominalStats = stripNonNumericCharacters(nominalJoistSize)
    nominalStatsPostfix = ZMAX
    if (subcategory2 === 'Light Joist (LUS)') {
      code = 'LUS'
      type = 'Light Joist Hanger'
      nominalStatsPostfix = nominalJoistSize.includes('double') ? `-2${ZMAX}` : ZMAX
    } else if (subcategory2 === 'Light Concealed Joist (LUC)') {
      code = 'LUC'
      type = 'Light Concealed Joist Hanger'
    } else if (subcategory2 === 'Heavy Joist (HUS)') {
      code = 'HUS'
      type = 'Heavy Joist Hanger'
    } else if (subcategory2 === 'Heavy Concealed Joist (HUC)') {
      code = 'HUC'
      type = 'Heavy Concealed Joist Hanger'
    } else if (subcategory2 === 'Header (HH)') {
      code = 'HH'
      type = 'Header Hanger'
      if (headerWidth === '3 1/2" (HH4)') {
        description = 'for 3 1/2" wide header'
        nominalStats = '4'
      } else if (headerWidth === '5 1/2" (HH6)') {
        description = 'for 5 1/2" wide header'
        nominalStats = '6'
      }
    } else if (subcategory2 === 'Rafter (LRUZ)') {
      code = 'LRU'
      type = 'Face Mount Rafter Hanger'
      description = `for ${rafterSize} (ZMAX)`
      nominalStats = stripNonNumericCharacters(rafterSize)
      nominalStatsPostfix = 'Z'
    } else if (subcategory2 === 'Light Adjustable Jack (LSSJ)') {
      code = 'LSSJ'
      type = 'Face Mount Adjustable Jack Hanger'
      nominalStatsPostfix = skewDirection === 'Left' ? `LZ` : `RZ`
      description = `${description} (ZMAX, ${skewDirection})`
    } else if (subcategory2 === 'Adjustable Rafter (LSSR)') {
      code = 'LSSR'
      type = 'Slopeable Rafter Hanger'
      nominalStatsPostfix = 'Z'
    } else if (subcategory2 === 'Skewed (SUL / SUR)') {
      if (skewDirection === 'Left') {
        code = 'SUL'
        type = 'Left-Skewed Hanger'
      } else {
        code = 'SUR'
        type = 'Right-Skewed Hanger'
      }
      description = `${description} (ZMAX, ${skewDirection})`
      nominalStatsPostfix = nominalJoistSize.includes('double') ? `-2` : ''
    } else if (subcategory2 === 'Stair (TA)') {
      code = 'TA'
      type = 'Stair Hanger'
      nominalStatsPostfix = 'Z'
      if (length === '8 1/2"') {
        nominalStats = '9'
      } else if (length === '10 1/4"') {
        nominalStats = '10'
      }
      description = `- ${length} length (ZMAX)`
    }
    type = `${type} ${description}${coating ? ` (${coating})` : ''}`
  }

  if (subcategory2 === 'Column Bases') {
    const HDG = coating === 'HDG' ? 'HDG' : ''
    if (subcategory3 === 'Column Base (CB)') {
      code = 'CB'
      type = `Column Base for ${nominalColumnSize} ${coating}`
    } else if (subcategory3 === 'Column Base w/ Standoff (CBS)') {
      code = 'CBS'
      type = `Column Base w/ Standoff for ${nominalColumnSize} ${coating}`
    } else if (subcategory3 === 'Column Base w/ Standoff - Quick Install (CBSQ)') {
      code = 'CBSQ'
      type = `Column Base w/ Standoff for ${nominalColumnSize} (${coating}, quick install)`
    }
    nominalStats = stripNonNumericCharacters(nominalColumnSize)
    nominalStatsPostfix = HDG
  } else if (subcategory2 === 'Post Bases') {
    nominalStats = stripNonNumericCharacters(nominalPostSize)
    nominalStatsPostfix = ZMAX
    description = nominalPostSize
    if (subcategory3 === 'Adjustable Post Base w/ Uplift (ABU)') {
      code = 'ABU'
      type = 'Adjustable Post Base with Uplift'
    } else if (subcategory3 === 'Adjustable Post Base w/ Wind Uplift (ABW)') {
      code = 'ABW'
      type = 'Adjustable Post Base with Wind Uplift'
    } else if (subcategory3 === 'Post Base (BC)') {
      code = 'BC'
      nominalStats = `${stripNonNumericCharacters(nominalPostWidth)}0`
      description = `${nominalPostWidth} x ${nominalPostWidth}`
      type = 'Post Base'
    } else if (subcategory3 === 'Concealed Post Tie (CPTZ)') {
      code = 'CPTZ'
      type = 'Concealed Post Tie'
    } else if (subcategory3 === 'Post Base (PB)') {
      code = 'PB'
      type = 'Post Base'
    } else if (subcategory3 === 'Post Base w/ Standoff (PBS)') {
      code = 'PBS'
      type = 'Post Base w/ Standoff'
    }
    type = `${type} for ${description} ${coating ? `(${coating})` : ''}`
  } else if (subcategory2 === 'Column Caps') {
    code = subcategory3 === 'Column Caps - Quick Install (CCQ)' ? 'CCQ' : 'ECCQ'
    type = subcategory3 === 'Column Caps - Quick Install (CCQ)' ? 'Column Cap' : 'End Column Cap'
    // @ts-ignore
    let beamWidthLabel = actualToNominalConverter[beamWidth] || ''
    // @ts-ignore
    let columnWidthLabel = actualToNominalConverter[columnWidth] || ''
    nominalStats = `${beamWidthLabel}${columnWidthLabel}`
    if (beamWidth === '5 1/8"') {
      nominalStats = `${beamWidthLabel}-${columnWidthLabel}`
      nominalStatsPostfix = 'SDS2.5'
    }
    type = `${type} for ${beamWidth} x ${columnWidth} column (quick install)`
  } else if (subcategory2 === 'Post Caps') {
    if (subcategory3 === 'Adjustable Post Caps (AC)') {
      code = 'AC'
      nominalStats = stripNonNumericCharacters(nominalPostWidth)
      nominalStatsPostfix = coating === 'ZMAX' ? 'Z' : 'SS'
      type = `Adjustable Post Cap for ${nominalPostWidth} x ${nominalPostWidth} (${coating})`
    } else if (subcategory3 === 'Post Caps (BC)') {
      code = 'BC'
      nominalStats = stripNonNumericCharacters(nominalPostSize)
      nominalStatsPostfix = 'Z'
      type = `Post Cap for ${nominalPostSize} (${coating})`
    } else if (subcategory3 === 'End Post Caps (EPCZ)') {
      code = 'EPC'
      nominalStats = stripNonNumericCharacters(nominalPostWidth)
      nominalStatsPostfix = ZMAX
      type = `End Post Cap ${nominalPostWidth} x ${nominalPostWidth} (${coating})`
    } else if (subcategory3 === 'Light Post End Cap (LCE)') {
      code = 'LCE'
      nominalStats = '4'
      nominalStatsPostfix = coating === 'ZMAX' ? 'Z' : 'SS'
      type = `Light Post End Cap (${coating})`
    } else if (subcategory3 === 'One-Piece Post Caps (PCZ)') {
      code = 'PC'
      nominalStats = stripNonNumericCharacters(nominalPostWidth)
      nominalStatsPostfix = ZMAX
      type = `Post Cap ${nominalPostWidth} x ${nominalPostWidth} (${coating})`
    }
  } else if (subcategory2 === 'Lateral Tie Plate (LTP)') {
    code = 'LTP'
    // @ts-ignore
    nominalStats = actualToNominalConverter[width]
    type = `Lateral Tie Plate - ${width} length`
  } else if (subcategory2 === 'Coiled Strap (CS)') {
    code = 'CS'
    const tokens = gauge.split(' ')
    nominalStats = tokens[0]
    const coil = !!tokens[1] ? tokens[1].replace(/[\(\)]/g, '') : ''
    type = `Coil Strap - ${nominalStats} gauge, ${coil} coil`
  } else if (subcategory2 === 'Coiled Medium Strap Tie (CMST)') {
    code = 'CMST'
    const tokens = gauge.split(' ')
    nominalStats = tokens[0]
    const coil = !!tokens[1] ? tokens[1].replace(/[\(\)]/g, '') : ''
    type = `Coiled Medium Strap Tie - ${nominalStats} gauge, ${coil} coil`
  }

  return `${code}${nominalStats}${nominalStatsPostfix}${type ? ` - ${type}` : ''}`
}

const connectorAndHangersDisplayText: { [key: string]: string } = {
  A23: 'A23 Angle (2" x 1 1/2")',
  A24: 'A24 Angle (2" x 3 7/8")',
  A33: 'A33 Angle (3" x 3")',
  A34: 'A34 Framing Angle (1 7/16" x 2 1/2")',
  A34Z: 'A34Z Framing Angle (1 7/16" x 2 1/2") - ZMAX',
  A35: 'A35 Framing Angle (1 7/16" x 4 1/2")',
  A35Z: 'A35Z Framing Angle (1 7/16" x 4 1/2") - ZMAX',
  A44: 'A44 Angle (4 9/16" x 4 3/8")',
  L30: 'L30 Reinforcing Angle (3", 16 gauge)',
  L50: 'L50 Reinforcing Angle (5", 16 gauge)',
  L50Z: 'L50Z Reinforcing Angle (5", 16 gauge) - ZMAX',
  L70: 'L70 Reinforcing Angle (7", 16 gauge)',
  L70Z: 'L70Z Reinforcing Angle (7", 16 gauge) - ZMAX',
  L90: 'L90 Reinforcing Angle (9", 16 gauge)',
  L90Z: 'L90Z Reinforcing Angle (9", 16 gauge) - ZMAX',
  HL35: 'HL35 Heavy Angle (3 1/4" x 5")',
  HL37: 'HL37 Heavy Angle (3 1/4" x 7 1/2")',
  HL46: 'HL46 Heavy Angle (4 1/4" x 6")',
  HL53: 'HL53 Heavy Angle (5 3/4" x 2 1/2")',
  HL55: 'HL55 Heavy Angle (5 3/4" x 5")',
  HL73: 'HL73 Heavy Angle (7 1/4" x 3")',
  LS30Z: 'LS30Z Skewable Angle (3 3/8", 18 gauge) - ZMAX',
  LS50: 'LS50 Skewable Angle (4 7/8", 18 gauge)',
  LS50Z: 'LS50Z Skewable Angle (4 7/8", 18 gauge) - ZMAX',
  LS70: 'LS70 Skewable Angle (6 3/8", 18 gauge)',
  LS70Z: 'LS70Z Skewable Angle (6 3/8", 18 gauge) - ZMAX',
  LS90: 'LS90 Skewable Angle (7 7/8", 18 gauge)',
  LS90Z: 'LS90 Skewable Angle (7 7/8", 18 gauge) - ZMAX',
  GA1: 'GA1 Gusset Angle (1" x 1")',
  GA2: 'GA2 Gusset Angle (1 1/4" x 1 1/4")',
  HGA10KT: 'HGA10KT Hurricane Gusset Angle Kit',
  CB44: 'CB44 - Column Base for 4"x 4" (Galvanized)',
  CB44HDG: 'CB44HDG - Column Base for 4"x 4" (HDG)',
  CB66: 'CB66 - Column Base for 6"x 6" (Galvanized)',
  CB66HDG: 'CB66HDG - Column Base for 6"x 6" (HDG)',
  CBS44: 'CBS44 - Column Base w/ Standoff for 4"x 4" (Galvanized)',
  CBS46: 'CBS46 - Column Base w/ Standoff for 4"x 6" (Galvanized)',
  CBS66: 'CBS66 - Column Base w/ Standoff for 6"x 6" (Galvanized)',
  'CBSQ44-SDS2': 'CBSQ44-SDS2 - Column Base w/ Standoff for 4"x 4" (Galvanized, quick install)',
  'CBSQ46-SDS2': 'CBSQ46-SDS2 - Column Base w/ Standoff for 4"x 6" (Galvanized, quick install)',
  'CBSQ66-SDS2': 'CBSQ66-SDS2 Column Base w/ Standoff for 6"x 6" (Galvanized, quick install)',
  ABU44Z: 'ABU44Z - Adjustable Post Base with Uplift for 4"x 4" (ZMAX)',
  ABU46Z: 'ABU46Z - Adjustable Post Base with Uplift for 4"x 6" (ZMAX)',
  ABU66Z: 'ABU66Z - Adjustable Post Base with Uplift for 6"x 6" (ZMAX)',
  ABW44Z: 'ABW44Z - Adjustable Post Base with Wind Uplift for 4"x 4" (ZMAX)',
  ABW46Z: 'ABW46Z - Adjustable Post Base with Wind Uplift for 4"x 6" (ZMAX)',
  ABW66Z: 'ABW66Z - Adjustable Post Base with Wind Uplift for 6"x 6" (ZMAX)',
  BC40Z: 'BC40Z - Post Base for 4"x 4" (ZMAX)',
  BC60Z: 'BC60Z - Post Base for 6"x 6" (ZMAX)',
  PB44Z: 'PB44Z - Post Base for 4"x 4" (ZMAX)',
  PB46Z: 'PB46Z - Post Base for 4"x 6" (ZMAX)',
  PB66Z: 'PB66Z Post Base',
  PBS44AZ: 'PBS44AZ - Post Base w/ Standoff for 4"x 4"',
  PBS46: 'PBS46 - Post Base w/ Standoff for 4"x 6"',
  'PBS66-HDG': 'PBS66HDG - Post Base w/ Standoff for 6"x 6"',
  CPT44Z: 'CPT44Z - Concealed Post Tie for 4"x 4" (ZMAX)',
  CPT66Z: 'CPT66Z - Concealed Post Tie for 6"x 6" (ZMAX)',
  CPT88Z: 'CPT88Z - Concealed Post Tie for 8"x 8" (ZMAX)',
  CCQ44: 'CCQ44 - Column Cap for 3 1/2"x 3 1/2" column (quick install)',
  CCQ46: 'CCQ46 - Column Cap for 3 1/2"x 5 1/2" column (quick install)',
  CCQ48: 'CCQ48 - Column Cap for 3 1/2"x 7 1/2" column (quick install)',
  'CCQ5-4SDS2.5': 'CCQ5-4SDS2.5 - Column Cap for 5 1/8"x 3 1/2" column (quick install)',
  'CCQ5-6SDS2.5': 'CCQ5-6SDS2.5 - Column Cap for 5 1/8"x 5 1/2" column (quick install)',
  'CCQ5-8SDS2.5': 'CCQ5-8SDS2.5 - Column Cap for 5 1/8"x 7 1/2" column (quick install)',
  CCQ64: 'CCQ64 - Column Cap for 5 1/2"x 3 1/2" column (quick install)',
  CCQ66: 'CCQ66 - Column Cap for 5 1/2"x 5 1/2" column (quick install)',
  CCQ68: 'CCQ68 - Column Cap for 5 1/2"x 7 1/2" column (quick install)',
  'CCQ7.1-6SDS2.5': 'CCQ7.1-6SDS2.5 Column Cap for 7" x 6" column (quick install)',
  ECCLLQ444: 'ECCLLQ444 L-Shaped End Column Cap for 4" x 4" column (Left)',
  ECCLLQ444HDG: 'ECCLLQ444HDG L-Shaped End Column Cap for 4" x 4" column (Left) - HDG',
  ECCLLQ666: 'ECCLLQ666 L-Shaped End Column Cap for 6" x 6" column (Left)',
  ECCLLQ666HDG: 'ECCLLQ666HDG L-Shaped End Column Cap for 6" x 6" column (Left) - HDG',
  ECCLRQ444: 'ECCLRQ444 L-Shaped End Column Cap for 4" x 4" column (Right)',
  ECCLRQ444HDG: 'ECCLRQ444HDG L-Shaped End Column Cap for 4" x 4" column (Right) - HDG',
  ECCLRQ666: 'ECCLRQ666 L-Shaped End Column Cap for 6" x 6" column (Right)',
  ECCLRQ666HDG: 'ECCLRQ666HDG L-Shaped End Column Cap for 6" x 6" column (Right) - HDG',
  ECCQ44: 'ECCQ44 - End Column Cap for 3 1/2"x 3 1/2" column (quick install)',
  ECCQ46: 'ECCQ46 - End Column Cap for 3 1/2"x 5 1/2" column (quick install)',
  ECCQ48: 'ECCQ48 - End Column Cap for 3 1/2"x 7 1/2" column (quick install)',
  'ECCQ5-4SDS2.5': 'ECCQ5-4SDS2.5 - End Column Cap for 5 1/8"x 3 1/2" column (quick install)',
  'ECCQ5-6SDS2.5': 'ECCQ5-6SDS2.5 - End Column Cap for 5 1/8"x 5 1/2" column (quick install)',
  ECCQ64: 'ECCQ64 - End Column Cap for 5 1/2"x 3 1/2" column (quick install)',
  ECCQ66: 'ECCQ66 - End Column Cap for 5 1/2"x 5 1/2" column (quick install)',
  ECCQ68: 'ECCQ68 - End Column Cap for 5 1/2"x 7 1/2" column (quick install)',
  'ECCQ7.1-4SDS2.5': 'ECCQ7.1-4SDS2.5 End Column Cap for 7" beam, 4x column (quick install)',
  'ECCQ7.1-6SDS2.5': 'ECCQ7.1-4SDS2.5 End Column Cap for 7" beam, 6x column (quick install)',
  AC4Z: 'AC4Z - Adjustable Post Cap for 4"x 4" (ZMAX)',
  AC4SS: 'AC4SS - Adjustable Post Cap for 4"x 4" (Stainless)',
  AC6Z: 'AC6Z - Adjustable Post Cap for 6"x 6" (ZMAX)',
  AC6SS: 'AC6SS - Adjustable Post Cap for 6"x 6" (Stainless)',
  BC4Z: 'BC4Z - Post Cap for 4"x 4" (ZMAX)',
  BC46Z: 'BC46Z - Post Cap for 4"x 6" (ZMAX)',
  BC6Z: 'BC6Z - Post Cap for 6"x 6" (ZMAX)',
  PC4Z: 'PC4Z - Post Cap for 4"x 4" (ZMAX)',
  PC6Z: 'PC6Z - Post Cap for 6"x 6" (ZMAX)',
  EPC4Z: 'EPC4Z - End Post Cap for 4"x 4" (ZMAX)',
  EPC6Z: 'EPC6Z - End Post Cap for 6"x 6" (ZMAX)',
  LCE4Z: 'LCE4Z - Light Post End Cap (ZMAX)',
  LUS24: 'LUS24 - Light Joist Hanger for 2"x 4" (Galvanized)',
  LUS24Z: 'LUS24Z - Light Joist Hanger for 2"x 4" (ZMAX)',
  'LUS24-2': 'LUS24-2 - Light Joist Hanger for double 2"x 4" (Galvanized)',
  'LUS24-2Z': 'LUS24-2Z - Light Joist Hanger for double 2"x 4" (ZMAX)',
  LUS26: 'LUS26 - Light Joist Hanger for 2"x 6" (Galvanized)',
  LUS26Z: 'LUS26Z - Light Joist Hanger for 2"x 6" (ZMAX)',
  'LUS26-2': 'LUS26-2 - Light Joist Hanger for double 2"x 6" (Galvanized)',
  'LUS26-2Z': 'LUS26-2Z - Light Joist Hanger for double 2"x 6" (ZMAX)',
  LUS28: 'LUS28 - Light Joist Hanger for 2"x 8" (Galvanized)',
  LUS28Z: 'LUS28Z - Light Joist Hanger for 2"x 8" (ZMAX)',
  'LUS28-2': 'LUS28-2 - Light Joist Hanger for double 2"x 8" (Galvanized)',
  'LUS28-2Z': 'LUS28-2Z - Light Joist Hanger for double 2"x 8" (ZMAX)',
  LUS210: 'LUS210 - Light Joist Hanger for 2"x 10" (Galvanized)',
  'LUS210-2': 'LUS210-2 - Light Joist Hanger for double 2"x 10" (Galvanized)',
  LUS210Z: 'LUS210Z - Light Joist Hanger for 2"x 10" (ZMAX)',
  'LUS210-2Z': 'LUS210-2Z - Light Joist Hanger for double 2"x 10" (ZMAX)',
  LUS36: 'LUS36 - Light Joist Hanger for 3"x 6" (Galvanized)',
  LUS36Z: 'LUS36Z - Light Joist Hanger for 3" x 6" (ZMAX)',
  LUS44: 'LUS44 - Light Joist Hanger for 4"x 4" (Galvanized)',
  LUS44Z: 'LUS44Z - Light Joist Hanger for 4"x 4" (ZMAX)',
  LUS46: 'LUS46 - Light Joist Hanger for 4"x 6" (Galvanized)',
  LUS46Z: 'LUS46Z - Light Joist Hanger for 4"x 6" (ZMAX)',
  LUS48: 'LUS48 - Light Joist Hanger for 4"x 8" (Galvanized)',
  LUS48Z: 'LUS48Z - Light Joist Hanger for 4"x 8" (ZMAX)',
  LUS410: 'LUS410 - Light Joist Hanger for 4"x 10" (Galvanized)',
  LUS410Z: 'LUS410Z - Light Joist Hanger for 4"x 10" (ZMAX)',
  HU11: 'HU11 Heavy Face-Mount Hanger (1 13/16" W x 11 1/16" H)',
  HU14: 'HU14 Heavy Face-Mount Hanger (1 3/16" W x 11 1/16" H)',
  HU26: 'HU26 Heavy Face-Mount Hanger for 2" x 6" joist',
  HU28: 'HU28 Heavy Face-Mount Hanger for 2" x 8" joist',
  HU210: 'HU210 Heavy Face-Mount Hanger for 2" x 10" joist',
  'HU210-2': 'HU210 Heavy Face-Mount Hanger for double 2" x 10" joist',
  'HU210-3': 'HU210 Heavy Face-Mount Hanger for triple 2" x 10" joist',
  'HU210-3Z': 'HU210 Heavy Face-Mount Hanger for triple 2" x 10" joist - ZMAX',
  'HU210-4': 'HU210 Heavy Face-Mount Hanger for quad 2" x 10" joist',
  HU212: 'HU210 Heavy Face-Mount Hanger for 2" x 12" joist',
  'HU212-2': 'HU210 Heavy Face-Mount Hanger for double 2" x 12" joist',
  'HU212-3': 'HU210 Heavy Face-Mount Hanger for triple 2" x 12" joist',
  HU310: 'HU310 Heavy Face-Mount Hanger for 3" x 10" joist',
  HU46: 'HU46 Heavy Face-Mount Hanger for 4" x 6" joist',
  HU46Z: 'HU46Z Heavy Face-Mount Hanger for 4" x 6" joist - ZMAX',
  HU48: 'HU48 Heavy Face-Mount Hanger for 4" x 8" joist',
  HU48Z: 'HU48 Heavy Face-Mount Hanger for 4" x 8" joist - ZMAX',
  HU410: 'HU410 Heavy Face-Mount Hanger for 4" x 10" joist',
  HU410Z: 'HU410 Heavy Face-Mount Hanger for 4" x 10" joist - ZMAX',
  HU412: 'HU412 Heavy Face-Mount Hanger for 4" x 12" joist',
  HU412Z: 'HU412 Heavy Face-Mount Hanger for 4" x 12" joist - ZMAX',
  HU414: 'HU414 Heavy Face-Mount Hanger for 4" x 14" joist',
  HU66: 'HU66 Heavy Face-Mount Hanger for 6" x 6" joist',
  HU68: 'HU68 Heavy Face-Mount Hanger for 6" x 8" joist',
  HU68Z: 'HU68Z Heavy Face-Mount Hanger for 6" x 8" joist - ZMAX',
  HU610: 'HU610 Heavy Face-Mount Hanger for 6" x 10" joist',
  HU610Z: 'HU610 Heavy Face-Mount Hanger for 6" x 10" joist - ZMAX',
  HU612: 'HU612 Heavy Face-Mount Hanger for 6" x 12" joist',
  HU612Z: 'HU612 Heavy Face-Mount Hanger for 6" x 12" joist - ZMAX',
  HU7: 'HU7 Heavy Face-Mount Hanger (1 13/16" W x 6 11/16" H)',
  HU9: 'HU9 Heavy Face-Mount Hanger (1 13/16" W x 9 5/16" H)',
  HUS26: 'HUS26 - Heavy Joist Hanger for 2"x 6" (Galvanized)',
  HUS26Z: 'HUS26Z - Heavy Joist Hanger for 2"x 6" (ZMAX)',
  HUS28Z: 'HUS28Z - Heavy Joist Hanger for 2"x 8" (ZMAX)',
  HUS210: 'HUS210 - Heavy Joist Hanger for 2"x 10" (Galvanized)',
  HUS28: 'HUS210 - Heavy Joist Hanger for 2"x 10" (Galvanized)',
  HUS210Z: 'HUS210Z - Heavy Joist Hanger for 2"x 10" (ZMAX)',
  LUC26Z: 'LUC26Z - Light Concealed Joist Hanger for 2"x 6" (ZMAX)',
  LUC28Z: 'LUC28Z - Light Concealed Joist Hanger for 2"x 8" (ZMAX)',
  LUC210Z: 'LUC210Z - Light Concealed Joist Hanger for 2"x 10" (ZMAX)',
  LUC212Z: 'LUC212Z - Light Concealed Joist Hanger for 2"x 12" (ZMAX)',
  HUC44Z: 'HUC44Z - Heavy Concealed Joist Hanger for 4"x 4" (ZMAX)',
  HUC46: 'HUC46 - Heavy Concealed Joist Hanger for 4"x 6" (Galvanized)',
  HUC46Z: 'HUC46Z - Heavy Concealed Joist Hanger for 4"x 6" (ZMAX)',
  HUC48: 'HUC48 - Heavy Concealed Joist Hanger for 4"x 8" (Galvanized)',
  HUC48Z: 'HUC48Z - Heavy Concealed Joist Hanger for 4"x 8" (ZMAX)',
  HUC410: 'HUC410 - Heavy Concealed Joist Hanger for 4"x 10" (Galvanized)',
  HUC410Z: 'HUC410Z - Heavy Concealed Joist Hanger for 4"x 10" (ZMAX)',
  HUC412: 'HUC412 - Heavy Concealed Joist Hanger for 4"x 12" (Galvanized)',
  HUC412Z: 'HUC412Z - Heavy Concealed Joist Hanger for 4"x 12" (ZMAX)',
  HUC66: 'HUC66 - Heavy Concealed Joist Hanger for 6"x 6" (Galvanized)',
  HUC68: 'HUC68 - Heavy Concealed Joist Hanger for 6"x 8" (Galvanized)',
  HUC68Z: 'HUC68Z - Heavy Concealed Joist Hanger for 6"x 8" (ZMAX)',
  HUC610: 'HUC610 - Heavy Concealed Joist Hanger for 6"x 10" (Galvanized)',
  HUC610Z: 'HUC610Z - Heavy Concealed Joist Hanger for 6"x 10" (ZMAX)',
  HUC612: 'HUC612 - Heavy Concealed Joist Hanger for 6"x 12" (Galvanized)',
  HUC612Z: 'HUC612Z - Heavy Concealed Joist Hanger for 6"x 12" (ZMAX)',
  HGUS410: 'HGUS410 Joist Hanger for 4" x 10" joist',
  HGUS412: 'HGUS412 Joist Hanger for 4" x 12" joist',
  'HGUS5.5/10': 'HGUS5.5/10 Joist Hanger for 5 1/2" x 10" joist',
  'HGUS5.5/12': 'HGUS5.5/12 Joist Hanger for 5 1/2" x 12" joist',
  'HGUS5.5/14': 'HGUS5.5/14 Joist Hanger for 5 1/2" x 14" joist',
  'HGUS7.25/10': 'HGUS7.25/10 Joist Hanger for 7 1/4" x 10" joist',
  'HGUS7.25/12': 'HGUS7.25/12 Joist Hanger for 7 1/4" x 12" joist',
  U24: 'U24 Joist Hanger for 2" x 4" joist',
  U24R: 'U24R Joist Hanger for 2" x 4" rough joist',
  U26: 'U26 Joist Hanger for 2" x 6" joist',
  U26R: 'U26R Joist Hanger for 2" x 6" rough joist',
  U210: 'U210 Joist Hanger for 2" x 10" joist',
  U210R: 'U210R Joist Hanger for 2" x 10" rough joist',
  U410: 'U410 Joist Hanger for 4" x 10" joist',
  U410R: 'U410R Joist Hanger for 4" x 10" rough joist',
  'ITS1.81/11.88': 'ITS1.81/11.88 Top Flange Hanger fpr 1 3/4" x 11 7/8" joist',
  'ITS1.81/14': 'ITS1.81/14 Top Flange Hanger for 1 3/4" x 14" joist',
  'ITS1.81/16': 'ITS1.81/16 Top Flange Hanger for 1 3/4" x 16" joist',
  'ITS2.06/11.88': 'ITS2.06/11.88 Top Flange Hanger for 2" x 11 7/8" joist',
  'ITS2.06/14': 'ITS2.06/14 Top Flange Hanger for 2" x 14" joist',
  'ITS2.06/16': 'ITS2.06/16 Top Flange Hanger for 2" x 16" joist',
  'ITS2.37/9.5': 'ITS2.37/9.5 Top Flange Hanger for 2 5/16" x 9 1/2" joist',
  'ITS2.37/11.88': 'ITS2.37/11.88 Top Flange Hanger for 2 5/16" x 11 7/8" joist',
  'ITS2.37/14': 'ITS2.37/14 Top Flange Hanger for 2 5/16" x 14" joist',
  'ITS2.37/16': 'ITS2.37/16 Top Flange Hanger for 2 5/16" x 16" joist',
  HH4: 'HH4 Header Hanger for 4" x 4"',
  HH6: 'HH6 Header Hanger for 6" x 6"',
  'IUS1.81/11.88': 'IUS1.81/11.88 I-Joist Hanger for 1 3/4" x 11 7/8" joist',
  'IUS1.81/14': 'IUS1.81/11.88 I-Joist Hanger for 1 3/4" x 14" joist',
  'IUS2.06/11.88': 'IUS2.06/11.88 I-Joist Hanger for 2" x 11 7/8" joist',
  'IUS2.06/14': 'IUS2.06/14 I-Joist Hanger for 2" x 14" joist',
  'IUS2.37/9.5': 'IUS2.37/9.5 I-Joist Hanger for 2 5/16" x 9 1/2" joist',
  'IUS2.37/11.88': 'IUS2.37/11.88 I-Joist Hanger for 2 5/16" x 11 7/8" joist',
  'IUS3.56/11.88': 'IUS3.56/11.88 I-Joist Hanger for 3 1/2" x 11 7/8" joist',
  LRU26Z: 'LRU26Z Rafter Hanger for 2" x 6" rafter - ZMAX',
  LRU28Z: 'LRU28Z Rafter Hanger for 2" x 8" rafter - ZMAX',
  LRU210Z: 'LRU210Z Rafter Hanger for 2" x 10" rafter - ZMAX',
  SUL24: 'SUL24 Skew Hanger for 2" x 4" (Left)',
  SUL26: 'SUL26 Skew Hanger for 2" x 6" (Left)',
  SUL26Z: 'SUL26-Z Skew Hanger for 2" x 6" (Left)',
  SUL210: 'SUL210 Skew Hanger for 2" x 10" (Left)',
  SUL210Z: 'SUL210Z Skew Hanger for 2" x 10" - ZMAX (Left)',
  SUR24: 'SUR24 Skew Hanger for 2" x 4" (Right)',
  SUR26: 'SUR26 Skew Hanger for 2" x 6" (Right)',
  SUR26Z: 'SUR26Z Skew Hanger for 2" x 6" (Right) - ZMAX',
  SUR210: 'SUR210 Skew Hanger for 2" x 10" (Right)',
  SUR210Z: 'SUR210Z Skew Hanger for 2" x 10" (Right) - ZMAX',
  LSSJ26LZ: 'LSSJ26LZ - Face Mount Adjustable Jack Hanger for 2"x 6" (ZMAX, Left)',
  LSSJ26RZ: 'LSSJ26RZ - Face Mount Adjustable Jack Hanger for 2"x 6" (ZMAX, Right)',
  LSSJ28LZ: 'LSSJ28LZ - Face Mount Adjustable Jack Hanger for 2"x 8" (ZMAX, Left)',
  LSSJ28RZ: 'LSSJ28RZ - Face Mount Adjustable Jack Hanger for 2"x 8" (ZMAX, Right)',
  LSSJ210LZ: 'LSSJ210LZ - Face Mount Adjustable Jack Hanger for 2"x 10" (ZMAX, Left)',
  LSSJ210RZ: 'LSSJ210RZ - Face Mount Adjustable Jack Hanger for 2"x 10" (ZMAX, Right)',
  LSSR26Z: 'LSSR26Z - Slopeable Rafter Hanger for 2"x 6"',
  LSSR28Z: 'LSSR28Z - Slopeable Rafter Hanger for 2"x 8"',
  LSSR210Z: 'LSSR210Z - Slopeable Rafter Hanger for 2"x 10"',
  LSSR410Z: 'LSSR410Z - Slopeable Rafter Hanger for 4"x 10"',
  TA9Z: 'TA9Z - Stair Hanger - 8 1/2" length (ZMAX)',
  TA10Z: 'TA10Z - Stair Hanger - 10 1/2" length (ZMAX)',
  LTP4: 'LTP4 - Lateral Tie Plate - 3" length',
  LTP4Z: 'LTP4Z - Lateral Tie Plate - 3" length (ZMAX)',
  LTP5: 'LTP5 - Lateral Tie Plate - 4 1/2" length',
  LTP5Z: 'LTP5Z - Lateral Tie Plate - 4 1/2" length (ZMAX)',
  '1212HT': '1212HT Heavy T-Shaped Strap Tie 12" x 12"',
  '1212T': '1212T Heavy T-Shaped Strap Tie 12" x 12"',
  '128T': '128T Heavy T-Shaped Strap Tie 12" x 8"',
  '1616HL': '1616HL Heavy L-Shaped Strap Tie 16" x 16"',
  '66T': '66T Heavy T-Shaped Strap Tie 6" x 6"',
  '88L': '88T Heavy T-Shaped Strap Tie 8" x 8"',
  HST2: 'HST2 Heavy Strap Tie 2 1/2" x 21 1/4"',
  MST27: 'MST27 Medium Strap Tie 2 1/16" x 27"',
  MST27HDG: 'MST27HDG Strap Tie 2 1/16" x 27" - HDG',
  MST37: 'MST37 Medium Strap Tie 2 1/16" x 37"',
  MST37HDG: 'MST37HDG Medium Strap Tie 2 1/16" x 37" - HDG',
  MST48: 'MST48 Medium Strap Tie 2 1/16" x 48"',
  MST48HDG: 'MST48HDG Medium Strap Tie 2 1/16" x 48" - HDG',
  MST60: 'MST60 Medium Strap Tie 2 1/16" x 60"',
  MSTA12: 'MSTA12 Medium Strap Tie for Uplift Application 1 1/4" x 12"',
  MSTA12Z: 'MSTA12Z Medium Strap Tie for Uplift Application 1 1/4" x 12" - ZMAX',
  MSTA24: 'MSTA24 Medium Strap Tie for Uplift Application 1 1/4" x 24"',
  MSTA24Z: 'MSTA24Z Medium Strap Tie for Uplift Application 1 1/4" x 24" - ZMAX',
  MSTA30: 'MSTA30 Medium Strap Tie for Uplift Application 1 1/4" x 30"',
  MSTA30Z: 'MSTA30 Medium Strap Tie for Uplift Application 1 1/4" x 30" - ZMAX',
  MSTA36: 'MSTA36 Medium Strap Tie for Uplift Application 1 1/4" x 36"',
  MSTA36Z: 'MSTA36Z Medium Strap Tie for Uplift Application 1 1/4" x 36" - ZMAX',
  MSTC28: 'MSTC28 Medium Strap Tie with Countersunk Nail Slots 3" x 28 1/4"',
  MSTC40: 'MSTC40 Medium Strap Tie with Countersunk Nail Slots 3" x 40 1/4"',
  MSTC52: 'MSTC52 Medium Strap Tie with Countersunk Nail Slots 3" x 52 1/4"',
  MSTC66: 'MSTC66 Medium Strap Tie with Countersunk Nail Slots 3" x 65 3/4"',
  MSTC78: 'MSTC78 Medium Strap Tie with Countersunk Nail Slots 3" x 77 3/4"',
  MSTI26: 'MSTI26 Medium Strap Tie for I-Joist 2 1/16" x 26"',
  MSTI36: 'MSTI36 Medium Strap Tie for I-Joist 2 1/16" x 36"',
  MSTI48: 'MSTI48 Medium Strap Tie for I-Joist 2 1/16" x 48"',
  MSTI60: 'MSTI60 Medium Strap Tie for I-Joist 2 1/16" x 60"',
  MSTI72: 'MSTI72 Medium Strap Tie for I-Joist 2 1/16" x 72"',
  SMSTC28: 'SMSTC28 Strap Tie',
  ST12: 'ST12 Strap Tie 1 1/4" x 11 5/8"',
  ST18: 'ST18 Strap Tie 1 1/4" x 17 3/4"',
  ST6215: 'ST6215 Strap Tie 2 1/16" x 16 5/16"',
  ST6224: 'ST6224 Strap Tie 2 1/16" x 25 5/16"',
  ST6236: 'ST6236 Strap Tie 2 1/16" x 33 13/16"',
  HTT4: 'HTT4 Heavy Tension Tie (12 3/8" length)',
  HTT5: 'HTT5 Tension Tie (16" length)',
  H1: 'H1 Hurricane Tie',
  H8: 'H8 Hurricane Tie',
  H1Z: 'H1Z Hurricane Tie - ZMAX',
  'H2.5AZ': 'H2.5AZ Hurricane Tie - ZMAX',
  WT10: 'WT10 Wedge Tie for 10" thick wall',
  WT12: 'WT12 Wedge Tie for 12" thick wall',
  WT6: 'WT6 Wedge Tie for 6" thick wall',
  WT8: 'WT8 Wedge Tie for 8" thick wall',
  "CMST12 (40')": "CMST12 - Coiled Medium Strap Tie - 12 gauge, 40' coil",
  "CMST14 (52.5')": "CMST14 - Coiled Medium Strap Tie - 14 gauge, 52.5' coil",
  "CMST16 (54')": "CMST16 - Coiled Medium Strap Tie - 16 gauge, 54' coil",
  "CS14 (100')": "CS14 - Coil Strap - 14 gauge, 100' coil",
  "CS16 (150')": "CS16 - Coil Strap - 16 gauge, 150' coil",
  "CS16Z (150')": "CS16Z - Coil Strap - 16 gauge, 150' coil (ZMAX)",
}

type ConnectorsAndHangersConfigType = {
  [category: string]: {
    [subcategory1: string]: {
      [subcategory2OrField: string]:
        | string[]
        | {
            [fieldOrSubcategory: string]: string[] | { [field: string]: string[] }
          }
    }
  }
}

const ConnectorsAndHangersConfig: ConnectorsAndHangersConfigType = {
  'Simpson Model Number': {
    Angles: {
      A: ['A23', 'A24', 'A33', 'A34', 'A34Z', 'A35', 'A35Z', 'A44'],
      L: ['L30', 'L50', 'L50Z', 'L70', 'L70Z', 'L90', 'L90Z'],
      HL: ['HL35', 'HL37', 'HL46', 'HL53', 'HL55', 'HL73'],
      LS: ['LS30Z', 'LS50', 'LS50Z', 'LS70', 'LS70Z', 'LS90', 'LS90Z'],
      GA: ['GA1', 'GA2'],
      HGA: ['HGA10KT'],
    },
    Bases: {
      'Column Bases': [
        'CB44',
        'CB44HDG',
        'CB66',
        'CB66HDG',
        'CBS44',
        'CBS46',
        'CBS66',
        'CBSQ44-SDS2',
        'CBSQ46-SDS2',
        'CBSQ66-SDS2',
      ],
      'Post Bases': [
        'ABU44Z',
        'ABU46Z',
        'ABU66Z',
        'ABW44Z',
        'ABW46Z',
        'ABW66Z',
        'BC40Z',
        'BC60Z',
        'PB44Z',
        'PB46Z',
        'PB66Z',
        'PBS44AZ',
        'PBS46',
        'PBS66-HDG',
      ],
      'Concealed Post Bases': ['CPT44Z', 'CPT66Z', 'CPT88Z'],
    },
    Caps: {
      'Column Caps': [
        'CCQ44',
        'CCQ46',
        'CCQ48',
        'CCQ5-4SDS2.5',
        'CCQ5-6SDS2.5',
        'CCQ5-8SDS2.5',
        'CCQ64',
        'CCQ66',
        'CCQ68',
        'CCQ7.1-6SDS2.5',
      ],
      'End Column Caps': [
        'ECCLLQ444',
        'ECCLLQ444HDG',
        'ECCLLQ666',
        'ECCLLQ666HDG',
        'ECCLRQ444',
        'ECCLRQ444HDG',
        'ECCLRQ666',
        'ECCLRQ666HDG',
        'ECCQ44',
        'ECCQ46',
        'ECCQ48',
        'ECCQ5-4SDS2.5',
        'ECCQ5-6SDS2.5',
        'ECCQ64',
        'ECCQ66',
        'ECCQ68',
        'ECCQ7.1-4SDS2.5',
        'ECCQ7.1-6SDS2.5',
      ],
      'Post Caps': ['AC4Z', 'AC4SS', 'AC6Z', 'AC6SS', 'BC4Z', 'BC46Z', 'BC6Z', 'PC4Z', 'PC6Z'],
      'End Post Caps': ['EPC4Z', 'EPC6Z', 'LCE4Z'],
    },
    Hangers: {
      Joist: {
        'LU - LUS': [
          'LUS24',
          'LUS24Z',
          'LUS24-2',
          'LUS24-2Z',
          'LUS26',
          'LUS26Z',
          'LUS26-2',
          'LUS26-2Z',
          'LUS28',
          'LUS28Z',
          'LUS28-2',
          'LUS28-2Z',
          'LUS210',
          'LUS210-2',
          'LUS210Z',
          'LUS210-2Z',
          'LUS36',
          'LUS36Z',
          'LUS44',
          'LUS44Z',
          'LUS46',
          'LUS46Z',
          'LUS48',
          'LUS48Z',
          'LUS410',
          'LUS410Z',
        ],
        'HU - HUS': [
          'HU11',
          'HU14',
          'HU26',
          'HU28',
          'HU210',
          'HU210-2',
          'HU210-3',
          'HU210-3Z',
          'HU210-4',
          'HU212',
          'HU212-2',
          'HU212-3',
          'HU310',
          'HU46',
          'HU46Z',
          'HU48',
          'HU48Z',
          'HU410',
          'HU410Z',
          'HU412',
          'HU412Z',
          'HU414',
          'HU66',
          'HU68',
          'HU68Z',
          'HU610',
          'HU610Z',
          'HU612',
          'HU612Z',
          'HU7',
          'HU9',
          'HUS26',
          'HUS26Z',
          'HUS28Z',
          'HUS210',
          'HUS28',
          'HUS210Z',
        ],
        LUC: ['LUC26Z', 'LUC28Z', 'LUC210Z', 'LUC212Z'],
        HUC: [
          'HUC44Z',
          'HUC46',
          'HUC46Z',
          'HUC48',
          'HUC48Z',
          'HUC410',
          'HUC410Z',
          'HUC412',
          'HUC412Z',
          'HUC66',
          'HUC68',
          'HUC68Z',
          'HUC610',
          'HUC610Z',
          'HUC612',
          'HUC612Z',
        ],
        HGUS: ['HGUS410', 'HGUS412', 'HGUS5.5/10', 'HGUS5.5/12', 'HGUS5.5/14', 'HGUS7.25/10', 'HGUS7.25/12'],
        U: ['U24', 'U24R', 'U26', 'U26R', 'U210', 'U210R', 'U410', 'U410R'],
      },
      'Top Flange': [
        'ITS1.81/11.88',
        'ITS1.81/14',
        'ITS1.81/16',
        'ITS2.06/11.88',
        'ITS2.06/14',
        'ITS2.06/16',
        'ITS2.37/9.5',
        'ITS2.37/11.88',
        'ITS2.37/14',
        'ITS2.37/16',
      ],
      Header: ['HH4', 'HH6'],
      'I-Joist': [
        'IUS1.81/11.88',
        'IUS1.81/14',
        'IUS2.06/11.88',
        'IUS2.06/14',
        'IUS2.37/9.5',
        'IUS2.37/11.88',
        'IUS3.56/11.88',
      ],
      Rafter: ['LRU26Z', 'LRU28Z', 'LRU210Z'],
      Skewed: ['SUL24', 'SUL26', 'SUL26Z', 'SUL210', 'SUL210Z', 'SUR24', 'SUR26', 'SUR26Z', 'SUR210', 'SUR210Z'],
      'Skewable / Slopeable': [
        'LSSJ26LZ',
        'LSSJ26RZ',
        'LSSJ28LZ',
        'LSSJ28RZ',
        'LSSJ210LZ',
        'LSSJ210RZ',
        'LSSR26Z',
        'LSSR28Z',
        'LSSR210Z',
        'LSSR410Z',
      ],
      Stair: ['TA9Z', 'TA10Z'],
    },
    Plates: { 'Framing Plates': ['LTP4', 'LTP4Z', 'LTP5', 'LTP5Z'] },
    'Straps & Ties': {
      'Strap Ties': [
        '1212HT',
        '1212T',
        '128T',
        '1616HL',
        '66T',
        '88L',
        'HST2',
        'MST27',
        'MST27HDG',
        'MST37',
        'MST37HDG',
        'MST48',
        'MST48HDG',
        'MST60',
        'MSTA12',
        'MSTA12Z',
        'MSTA24',
        'MSTA24Z',
        'MSTA30',
        'MSTA30Z',
        'MSTA36',
        'MSTA36Z',
        'MSTC28',
        'MSTC40',
        'MSTC52',
        'MSTC66',
        'MSTC78',
        'MSTI26',
        'MSTI36',
        'MSTI48',
        'MSTI60',
        'MSTI72',
        'SMSTC28',
        'ST12',
        'ST18',
        'ST6215',
        'ST6224',
        'ST6236',
      ],
      'Tension Ties': ['HTT4', 'HTT5'],
      'Hurricane Ties': ['H1', 'H8', 'H1Z', 'H2.5AZ'],
      'Wedge Ties': ['WT10', 'WT12', 'WT6', 'WT8'],
      'Coiled Strap': ["CMST12 (40')", "CMST14 (52.5')", "CMST16 (54')", "CS14 (100')", "CS16 (150')", "CS16Z (150')"],
    },
  },
  'Type and Dimension': {
    Angles: {
      '90° Angles': {
        flangeWidthW1: ['1"', '1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '5"', '6"', '7"', '8"'],
        flangeWidthW2: ['1"', '1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '5"', '6"', '7"', '8"'],
        length: ['1/2"', '3/4"', '1"', '1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '5"', '6"', '7"', '8"', '9"'],
        coating: ['Galvanized', 'Stainless'],
        application: ['Light', 'Heavy'],
      },
      'Gusset Angles': {
        flangeWidthW1: ['1"', '1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '5"', '6"', '7"', '8"'],
        flangeWidthW2: ['1"', '1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '5"', '6"', '7"', '8"'],
        length: ['1/2"', '3/4"', '1"', '1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '5"', '6"', '7"', '8"', '9"'],
        coating: ['Galvanized', 'Stainless'],
        application: ['Light', 'Heavy'],
      },
      'Skewable Angles': {
        flangeWidthW1: ['1"', '1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '5"', '6"', '7"', '8"'],
        flangeWidthW2: ['1"', '1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '5"', '6"', '7"', '8"'],
        length: ['1/2"', '3/4"', '1"', '1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '5"', '6"', '7"', '8"', '9"'],
        coating: ['Galvanized', 'Stainless'],
        application: ['Light', 'Heavy'],
      },
    },
    Bases: {
      'Column Bases': {
        'Column Base (CB)': {
          nominalColumnSize: ['4"x 4"', '4"x 6"', '6"x 6"'],
          coating: ['Galvanized', 'HDG'],
        },
        'Column Base w/ Standoff (CBS)': {
          nominalColumnSize: ['4"x 4"', '4"x 6"', '6"x 6"'],
          coating: ['Galvanized', 'HDG'],
        },
        'Column Base w/ Standoff - Quick Install (CBSQ)': {
          nominalColumnSize: ['4"x 4"', '4"x 6"', '6"x 6"'],
          coating: ['Galvanized', 'HDG'],
        },
      },
      'Post Bases': {
        'Adjustable Post Base w/ Uplift (ABU)': {
          nominalPostSize: ['4"x 4"', '4"x 6"', '6"x 6"'],
          coating: ['ZMAX'],
        },
        'Adjustable Post Base w/ Wind Uplift (ABW)': {
          nominalPostSize: ['4"x 4"', '4"x 6"', '6"x 6"'],
          coating: ['ZMAX'],
        },
        'Post Base (BC)': { nominalPostWidth: ['4"', '6"'], coating: ['ZMAX'] },
        'Concealed Post Tie (CPTZ)': { nominalPostSize: ['4"x 4"', '6"x 6"', '8"x 8"'], coating: ['ZMAX'] },
        'Post Base (PB)': { nominalPostSize: ['4"x 4"', '4"x 6"', '6"x 6"'], coating: ['ZMAX'] },
        'Post Base w/ Standoff (PBS)': { nominalPostSize: ['4"x 4"', '4"x 6"', '6"x 6"'] },
      },
    },
    Caps: {
      'Column Caps': {
        'Column Caps - Quick Install (CCQ)': {
          beamWidth: ['3 1/2"', '5 1/8"', '5 1/2"'],
          columnWidth: ['3 1/2"', '5 1/2"', '7 1/2"'],
        },
        'End Column Caps - Quick Install (ECCQ)': {
          beamWidth: ['3 1/2"', '5 1/8"', '5 1/2"'],
          columnWidth: ['3 1/2"', '5 1/2"', '7 1/2"'],
        },
      },
      'Post Caps': {
        'Adjustable Post Caps (AC)': { nominalPostWidth: ['4"', '6"'], coating: ['ZMAX', 'Stainless'] },
        'Post Caps (BC)': { nominalPostSize: ['4"x 4"', '4"x 6"', '6"x 6"'] },
        'End Post Caps (EPCZ)': { nominalPostWidth: ['4"', '6"'], coating: ['ZMAX'] },
        'Light Post End Cap (LCE)': { coating: ['ZMAX', 'Stainless'] },
        'One-Piece Post Caps (PCZ)': { nominalPostWidth: ['4"', '6"'], coating: ['ZMAX'] },
      },
    },
    Hangers: {
      'Light Joist (LUS)': {
        nominalJoistSize: [
          '2"x 4"',
          '2"x 6"',
          '2"x 8"',
          '2"x 10"',
          'double 2"x 4"',
          'double 2"x 6"',
          'double 2"x 8"',
          'double 2"x 10"',
          '3"x 6"',
          '4"x 4"',
          '4"x 6"',
          '4"x 8"',
          '4"x 10"',
        ],
        coating: ['Galvanized', 'ZMAX'],
      },
      'Light Concealed Joist (LUC)': {
        nominalJoistSize: ['2"x 6"', '2"x 8"', '2"x 10"', '2"x 12"'],
        coating: ['ZMAX'],
      },
      'Heavy Joist (HUS)': {
        nominalJoistSize: ['2"x 6"', '2"x 8"', '2"x 10"'],
        coating: ['Galvanized', 'ZMAX'],
      },
      'Heavy Concealed Joist (HUC)': {
        nominalJoistSize: ['4"x 6"', '4"x 8"', '4"x 10"', '4"x 12"', '6"x 8"', '6"x 10", 6"x 12"'],
        coating: ['Galvanized', 'ZMAX'],
      },
      'Header (HH)': { headerWidth: ['3 1/2" (HH4)', '5 1/2" (HH6)'] },
      'Rafter (LRUZ)': { rafterSize: ['2"x 6"', '2"x 8"', '2"x 10"', '2"x 12"'] },
      'Light Adjustable Jack (LSSJ)': {
        nominalJoistSize: ['2"x 6"', '2"x 8"', '2"x 10"'],
        skewDirection: ['Left', 'Right'],
      },
      'Adjustable Rafter (LSSR)': { nominalJoistSize: ['2"x 6"', '2"x 8"', '2"x 10"', '4"x 10"'] },
      'Skewed (SUL / SUR)': {
        nominalJoistSize: [
          '2"x 4"',
          '2"x 6"',
          '2"x 8"',
          '2"x 10"',
          'double 2"x 6"',
          'double 2"x 8"',
          'double 2"x 10"',
          '4"x 6"',
          '4"x 8"',
          '4"x 10"',
        ],
        skewDirection: ['Left', 'Right'],
      },
      'Stair (TA)': { length: ['8 1/2"', '10 1/4"'] },
    },
    Plates: { 'Lateral Tie Plate (LTP)': { width: ['3"', '4 1/2"'] } },
    'Straps & Ties': {
      'Coiled Strap (CS)': { gauge: ["14 (100')", "16 (150')", "20 (250')"] },
      'Coiled Medium Strap Tie (CMST)': { gauge: ["12 (40')", "14 (52.5')", "16 (54')"] },
    },
  },
}

export default ConnectorsAndHangersConfig
