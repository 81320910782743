import React from 'react'
import { ForwardProps } from '@supplyhound/types'
import styled from 'styled-components'
import BaseButton from '@supplyhound/components/buttons/BaseButton'

type Props = ForwardProps<typeof BaseButton>

const StyledBaseButton = styled(BaseButton)`
  --background-hover: none;
  --background-focused: none;
`

const SubmitButton: React.FC<Props> = ({ children, ...props }) => (
  <StyledBaseButton {...props}>{children}</StyledBaseButton>
)

export default SubmitButton
