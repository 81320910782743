import React, { useEffect, useState } from 'react'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { observer } from 'mobx-react-lite'
import { IonLoading } from '@ionic/react'

const GlobalLoading: React.FC = () => {
  const loadingState = useLoadingState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(loadingState.isLoading)
  }, [loadingState.isLoading])

  return <IonLoading isOpen={isLoading} />
}

export default observer(GlobalLoading)
