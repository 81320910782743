import React from 'react'
import styled from 'styled-components'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'

const Content = styled.div`
  font-size: ${({ theme }) => theme.fontSizeLarge};
  padding: 20px;
  border: none;
  text-align: center;
`

const Phone = styled.div`
  margin: 0.25rem 0;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeightBold};
`

const ContactUsModal = ({ dismiss }: { dismiss: () => void }) => (
  <BaseModal onDidDismiss={dismiss} label="Contact Us">
    <Content>
      <div>Give us a call</div>
      <Phone>415-349-5085</Phone>
      <div>Monday-Friday between the hours of 9AM - 5PM (PST)</div>
    </Content>
  </BaseModal>
)

export default ContactUsModal
