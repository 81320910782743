import { makeAutoObservable } from 'mobx'

import {
  updateTeamMemberProfile as updateTeamMemberProfileApi,
  fetchTeamMemberProfile as fetchTeamMemberProfileApi,
  confirmTeamMember as confirmTeamMemberApi,
  loginTeamMember as loginTeamMemberApi,
} from '@supplyhound/api'

import { TeamMemberProfile } from '@supplyhound/types'
import { ProfileStoreProps } from './ProfileStore'
import { TeamMemberListStore } from '@supplyhound/stores'
import { AuthStore } from './AuthStore'
import * as DirectUpload from '@supplyhound/utils/directUpload'

type TeamMemberStoreProps = ProfileStoreProps<TeamMemberProfile>

export class TeamMemberProfileStore {
  listStore: TeamMemberListStore
  authStore: AuthStore<TeamMemberProfile>

  constructor({ authStore }: TeamMemberStoreProps) {
    this.authStore = authStore
    this.listStore = new TeamMemberListStore({ axiosConfig: this.axiosConfig })

    makeAutoObservable(this, {
      listStore: false,
      authStore: false,
    })
  }

  get axiosConfig() {
    return this.authStore.axiosConfig
  }

  get profile() {
    return this.authStore.profile
  }

  dispatchUpdateTeamMember = (teamMemberProfile: TeamMemberProfile) => {
    this.authStore.updateProfile(teamMemberProfile)

    return updateTeamMemberProfileApi(this.profile!, this.axiosConfig).then(teamMember =>
      this.authStore.setProfile(teamMember)
    )
  }

  dispatchLoadProfile = () => {
    return fetchTeamMemberProfileApi(this.axiosConfig).then(teamMember => {
      this.authStore.setProfile(teamMember)
      DirectUpload.initialize(this.authStore.getToken())
    })
  }

  dispatchConfirmTeamMember = (phone: string, token: string) => {
    return confirmTeamMemberApi({ phone, token }, this.axiosConfig).then(token => this.prepareStores(token))
  }

  dispatchLoginTeamMember = (phone: string) => {
    return loginTeamMemberApi(phone, this.axiosConfig).then(token => this.prepareStores(token))
  }

  prepareStores = (token: string) => {
    this.authStore.setToken(token)
    this.listStore.setAxiosConfig(this.axiosConfig)
  }

  reset = () => {
    this.authStore.reset()
    this.listStore.reset()
  }
}
