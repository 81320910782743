import { formatReadyByDatetime } from 'helpers/datetimes'

import React from 'react'
import { Task } from 'types/tasks'

const ReadyByCol = ({ task }: { task: Task }) => {
  const text = formatReadyByDatetime(task)
  return <>{text}</>
}

export default ReadyByCol
