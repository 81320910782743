import { DirectUpload, Blob } from '@rails/activestorage'
import { FileWithUrl, AwsSignedID } from '@supplyhound/types'

type Token = string

class DirectUploadWrapper {
  directUpload
  token

  constructor(file: File, token?: Token) {
    this.directUpload = new DirectUpload(file, `${process.env.REACT_APP_API_URL}/v1/direct_uploads`, this)
    this.token = token
  }

  create(callback: (error: Error, blob: Blob) => void) {
    this.directUpload.create(callback)
  }

  directUploadWillCreateBlobWithXHR(xhr: XMLHttpRequest) {
    if (!this.token) {
      throw new Error('missing authorization bearer token')
    }
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`)
  }
}

let bearerToken: Token | undefined
export const initialize = (token?: Token) => {
  bearerToken = token
}

export const uploadImageToS3 = async (image: FileWithUrl): Promise<AwsSignedID> => {
  const directUploadWrapper = new DirectUploadWrapper(image, bearerToken)
  return new Promise((resolve, reject) => {
    directUploadWrapper.create((error, blob) => {
      if (error) {
        reject(error)
      }
      resolve(blob.signed_id)
    })
  })
}

export default DirectUploadWrapper
