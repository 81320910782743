import React from 'react'
import { observer } from 'mobx-react-lite'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import useStores from '@supplyhound/hooks/useStores'
import JobSiteForm from '@supplyhound/forms/JobSiteForm'
import { JobSite, UnidentifiedJobSite } from '@supplyhound/types'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'

const AddEditJobSiteModal: React.FC<{
  dismiss: () => void
  onSubmit: (arg0: JobSite) => Promise<void>
  jobSite?: JobSite
}> = ({ dismiss, onSubmit, jobSite }) => {
  const { jobSitesStore } = useStores()
  const { loadWhile } = useLoadingState()

  const handleSubmit = (values: UnidentifiedJobSite | JobSite) => {
    loadWhile(async () => {
      let jobSiteResponse
      if (jobSite) {
        jobSiteResponse = await jobSitesStore.dispatchUpdateJobSite(values as JobSite)
      } else {
        jobSiteResponse = await jobSitesStore.dispatchCreateJobSite(values as UnidentifiedJobSite)
      }

      if (jobSiteResponse) {
        await onSubmit(jobSiteResponse)
      }
      dismiss()
    })
  }

  return (
    <BaseModal label={jobSite ? 'Edit jobsite' : 'Add jobsite'} onDidDismiss={dismiss}>
      <JobSiteForm jobSite={jobSite} onSubmit={handleSubmit} />
    </BaseModal>
  )
}

export default observer(AddEditJobSiteModal)
