import { RefObject, useEffect, useRef } from 'react'

export type GooglePlacesServiceHelpers = {
  getPlaceDetails: (address: string) => Promise<google.maps.places.PlaceResult | void>
  hostRef: RefObject<HTMLDivElement>
}

export const useGooglePlacesService = (): GooglePlacesServiceHelpers => {
  const hostRef = useRef<HTMLDivElement | null>(null)
  const serviceRef = useRef<google.maps.places.PlacesService | null>(null)

  const getPlaceDetails = async (placeId: string) => {
    return new Promise<google.maps.places.PlaceResult | void>((resolve, reject) => {
      if (!serviceRef.current) resolve()

      serviceRef.current!.getDetails({ placeId }, (place, status) => {
        if (place) resolve(place)
        else if (
          [
            google.maps.places.PlacesServiceStatus.INVALID_REQUEST,
            google.maps.places.PlacesServiceStatus.REQUEST_DENIED,
            google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR,
          ].includes(status)
        ) {
          reject()
        }
      })
    })
  }

  useEffect(() => {
    if (!serviceRef.current && hostRef.current)
      serviceRef.current = new google.maps.places.PlacesService(hostRef.current)
  }, [hostRef.current])

  return { getPlaceDetails, hostRef }
}

export default useGooglePlacesService
