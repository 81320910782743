import React from 'react'
import styled from 'styled-components'
import { TaskItemImage, DeliveryTask } from 'types/tasks'
import Spacer from '@supplyhound/components/common/Spacer'
import Card from '@supplyhound/components/common/Card'
import ImagePreview from '@supplyhound/components/ImagePreview'
import { TaskStatuses } from 'config/taskStatuses'
import { formatPhoneNumber } from 'helpers/phone'

const TaskDetailRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: right;
`

const TaskDetailRowLabel = styled.span`
  display: flex;
  margin-right: 10px;
`

const TaskImagePreview = styled(ImagePreview)`
  margin: 7px 0 7px 10px;
`

const Layout = styled.div`
  display: flex;
`

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 240px;
`

const OrderDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.span`
  color: var(--greyscale-6);
`

const OrderDetail = ({ label, value }: { label: string; value: string | number | null }) => {
  return (
    <OrderDetailContainer>
      <Label>{label}</Label>
      <span>{value}</span>
      <Spacer height={15} />
    </OrderDetailContainer>
  )
}

const RightSide = styled.div`
  flex: 1 0 500px;
`

const InformationGroup = styled(Card)`
  background-color: var(--ion-background-color);
  padding: 20px;
`

const TaskDetailRow = ({
  label,
  image,
  value,
}: {
  label: string
  image?: TaskItemImage
  value: string | number | null
}) => {
  return (
    <TaskDetailRowContainer>
      <TaskDetailRowLabel>
        <Label>{label}</Label>
        {image && <TaskImagePreview height={100} width={100} thumbnailSrc={image.thumbnail_url} src={image.url} />}
      </TaskDetailRowLabel>
      <span>{value}</span>
    </TaskDetailRowContainer>
  )
}

const HistoryDetails = ({ task }: { task: DeliveryTask }) => {
  return (
    <Layout>
      <LeftSide>
        <h2>Task #: {task.id}</h2>
        <Spacer height={25} />
        <OrderDetail label="Status" value={TaskStatuses[task.status]} />

        <OrderDetail label="Job Site" value={task.job_site_name} />
        <OrderDetail label="Order/PO #" value={task.order_no} />
      </LeftSide>
      <Spacer width={50} />
      <RightSide>
        <InformationGroup>
          <h3>Delivery Contact</h3>
          <TaskDetailRow label="Name" value={task.delivery_username} />
          <TaskDetailRow label="Phone" value={formatPhoneNumber(task.delivery_phone || '')} />
        </InformationGroup>
        <InformationGroup>
          <h3>Address Information</h3>
          <TaskDetailRow label="Pickup" value={task.pickup_address} />
          <TaskDetailRow label="Delivery" value={task.delivery_address} />
        </InformationGroup>
      </RightSide>
    </Layout>
  )
}

export default HistoryDetails
