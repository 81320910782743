import React, { useEffect } from 'react'
import { IonImg } from '@ionic/react'
import styled from 'styled-components'
import { AppUpdate } from '@robingenz/capacitor-app-update'
import Spacer from '@supplyhound/components/common/Spacer'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'
import BaseButton from '@supplyhound/components/buttons/BaseButton'
import { HeaderModes } from '@supplyhound/layout'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Top = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
`
const Main = styled.div`
  flex: 2 0;
  text-align: center;
  padding: 20px;
`
const Bottom = styled.div`
  flex: 1 0;
`

const Logo = styled(IonImg)`
  height: 100px;
  width: 100px;
`

const UpdateAppModal = ({ logoSrc }: { logoSrc: string }) => {
  return (
    <BaseModal mode={HeaderModes.None}>
      <Content>
        <Top>
          <Logo src={logoSrc} />
        </Top>
        <Main>
          <h3>
            The latest version of this app is available to download from the app store. Please update to the latest
            version to continue using this app.
          </h3>
          <Spacer height={20} />
          <BaseButton onClick={() => AppUpdate.openAppStore()}>Update</BaseButton>
        </Main>
        <Bottom />
      </Content>
    </BaseModal>
  )
}

export default UpdateAppModal
