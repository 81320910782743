import { axiosInstance } from './axios'
import { ID, JobSite, JobSiteAttributes } from '@supplyhound/types'

export const fetchJobSites = (): Promise<JobSite[]> =>
  axiosInstance.get('/v1/job_sites').then(({ data: { job_sites } }) => job_sites)

export const createJobSite = (payload: JobSiteAttributes): Promise<JobSite> =>
  axiosInstance
    .post('/v1/job_sites', {
      job_site: payload,
    })
    .then(({ data: { job_site } }) => job_site)

export const updateJobSite = (id: ID, payload: Partial<JobSiteAttributes>): Promise<JobSite> =>
  axiosInstance.put(`/v1/job_sites/${id}`, { job_site: payload }).then(({ data: { job_site } }) => job_site)

export const deleteJobSite = (id: ID) => axiosInstance.delete(`/v1/job_sites/${id}`)
