import React from 'react'
import { IonModal } from '@ionic/react'
import BaseModal, { BaseModalProps } from '@supplyhound/components/common/Modals/BaseModal'

const InlineModal = (props: BaseModalProps & { isOpen: boolean; onDidDismiss: () => void }) => {
  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.onDidDismiss}>
      <BaseModal {...props} />
    </IonModal>
  )
}

export default InlineModal
