import React from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import { useIonModal } from '@ionic/react'
import { chevronForwardOutline, addOutline } from 'ionicons/icons'
import ListItem from '@supplyhound/components/common/ListItem'
import { AddEditJobSiteModal } from '@supplyhound/modals'
import { JobSite } from '@supplyhound/types'
import Ellipsis from './common/text/Ellipsis'

const StyledItem = styled(ListItem)`
  --background: var(--ion-color-light);

  &.add-job-site-item ion-label,
  &.add-job-site-item::part(native) {
    padding: 10px 0px;
  }
`

const RowLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledLabel = styled.span`
  font-size: 1.125rem;
  font-weight: 500;
`

const StyledSubText = styled.span`
  color: var(--ion-color-medium);
  font-size: 1rem;
`

type JobSiteItemProps = {
  label?: string
  labelSubText?: string
  onClick?: () => void
  icon?: string
  addJobSiteItem?: boolean
  onJobSiteCreate?: (arg0: JobSite) => Promise<void>
}

const JobSiteItem = ({
  label,
  labelSubText,
  onClick: handleOnClick,
  icon,
  addJobSiteItem = false,
  onJobSiteCreate,
}: JobSiteItemProps) => {
  const [present, dismiss] = useIonModal(AddEditJobSiteModal, {
    dismiss: () => dismiss(),
    onSubmit: onJobSiteCreate,
  })

  if (addJobSiteItem) {
    return (
      <StyledItem
        className={clsx({
          'add-job-site-item': addJobSiteItem,
        })}
        onClick={handleOnClick ? handleOnClick : () => present()}
        detail
        button
        detailIcon={icon ? icon : addOutline}
      >
        <RowLabel>{label ? label : 'Add a new jobsite'}</RowLabel>
      </StyledItem>
    )
  }
  return (
    <StyledItem onClick={handleOnClick} detail button detailIcon={icon ? icon : chevronForwardOutline}>
      <RowLabel>
        <StyledLabel>
          <Ellipsis text={label!} />
        </StyledLabel>
        {labelSubText && <StyledSubText>{labelSubText}</StyledSubText>}
      </RowLabel>
    </StyledItem>
  )
}

export default JobSiteItem
