import clsx from 'clsx'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { IonLabel, IonRow } from '@ionic/react'
import { ForwardPartialProps } from '@supplyhound/types'

type Props = ForwardPartialProps<typeof Wrapper> & {
  label: ReactNode
}

const Wrapper = styled.div`
  padding-bottom: var(--space-unit);
`

const Row = styled(IonRow)`
  display: flex;
  align-items: flex-end;
`

const SecondaryLabel = styled(IonLabel)`
  position: absolute;
  right: 15px;
`

const FieldHeader: React.FC<Props> = ({ label, extraLabel, className, ...props }) => (
  <Wrapper className={clsx('field-header', { className })} {...props}>
    <Row>
      <h3 className="ion-text-wrap">{label}</h3>
      <SecondaryLabel>{extraLabel}</SecondaryLabel>
    </Row>
  </Wrapper>
)

export default FieldHeader
