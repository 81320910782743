import { IonCheckbox } from '@ionic/react'
import React from 'react'
import styled from 'styled-components'

type Props = React.ComponentProps<typeof IonCheckbox>

const StyledIonCheckbox = styled(IonCheckbox)`
  width: 25px;
  height: 25px;
  --border-width: 0.5px;
  --border-style: solid;
  --border-color: var(--ion-color-medium);
  --border-color-checked: var(--ion-color-medium);
  --border-radius: 6px;
  --checkmark-width: 1.5px;

  -webkit-filter: drop-shadow(0px 3px 4px rgba(var(--ion-color-dark-rgb), 0.15));
  filter: drop-shadow(0px 3px 4px rgba(var(--ion-color-dark-rgb), 0.15));

  ::part(mark) {
    transform-origin: 10px 10px;
    transform: scale(1.3);
  }
`

const Checkbox: React.FC<Props> = props => <StyledIonCheckbox color="dark" {...props} />

export default Checkbox
