import { axiosInstance } from './axios'
import { ID, TeamMember, UnidentifiedTeamMember, Team } from '@supplyhound/types'

export const updateTeamMember = (team_member: TeamMember): Promise<TeamMember> =>
  axiosInstance
    .patch(`/v1/team_members/${team_member.phone}`, { team_member })
    .then(({ data: { team_member } }) => team_member)

export const createTeamMember = (team_member: UnidentifiedTeamMember): Promise<TeamMember> =>
  axiosInstance.post(`/v1/team_members`, { team_member }).then(({ data: { team_member } }) => team_member)

export const deleteTeamMember = (team_member: TeamMember): Promise<TeamMember> =>
  axiosInstance.delete(`/v1/team_members/${team_member.phone}`).then(({ data: { team_member } }) => team_member)

export const fetchTeamMembers = (teamId: ID): Promise<TeamMember[]> =>
  axiosInstance.get(`/v1/teams/${teamId}/team_members`).then(({ data: { team_members } }) => team_members)

export const updateTeamReminderSchedule = (teamId: ID, reminderTime: string, reminderDays: string[]): Promise<Team> =>
  axiosInstance
    .patch(`v1/teams/${teamId}`, {
      schedule_days: reminderDays,
      schedule_time: reminderTime,
    })
    .then(({ data: { team } }) => team)
