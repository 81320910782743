import React from 'react'
import NavigationButton from './NavigationButton'
import { ButtonType } from '@supplyhound/types'
import { closeOutline } from 'ionicons/icons'
import styled from 'styled-components'
import Icon from '@supplyhound/components/common/Icon'

const StyledNavigationButton = styled(NavigationButton)`
  height: 50px;
  width: 50px;
`

const CancelButton: ButtonType = props => (
  <StyledNavigationButton {...props} color="secondary">
    <div slot="icon-only">
      <Icon icon={closeOutline} width={30} />
    </div>
  </StyledNavigationButton>
)

export default CancelButton
