import React from 'react'

import styled from 'styled-components'
import Spacer from '@supplyhound/components/common/Spacer'
import { IonButton } from '@ionic/react'

import Icon from '@supplyhound/components/common/Icon'

import { VisaCard, DiscoverCard, MasterCard, AmexCard } from '@supplyhound/images'
import { cardOutline, chevronForwardOutline } from 'ionicons/icons'
import capitalize from 'lodash/capitalize'

const Container = styled.div<Editable>`
  display: flex;
  ${props => (props.editable ? 'cursor: pointer;' : '')}
  padding: calc(1.5 * var(--space-unit));
`
const CardLogo = styled.div`
  width: 30px;
`
const CardBrand = styled.div`
  font-size: 1.125rem;
`
const CardNumber = styled.div`
  font-size: 0.8rem;
  color: var(--ion-color-medium-shade);
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`
const StyledIcon = styled(Icon)`
  font-size: 1.5rem;
`
const CenteredFlex = styled(CardBrand)`
  display: flex;
  align-items: center;
`

const StyledIonButton = styled(IonButton)`
  --border-radius: 100px;
`

interface Card {
  brand: string
  last4: string
}

type Editable = { editable?: boolean }

interface CreditCardDisplayProps {
  card: Card | null
  onClick?: () => void
  pillText?: string
}

const CreditCardDisplay: React.FC<CreditCardDisplayProps & Editable> = ({
  card,
  onClick,
  editable = true,
  pillText,
}) => {
  const { brand, last4 } = card || {}
  let img

  switch (brand) {
    case 'visa':
      img = VisaCard
      break
    case 'mastercard':
      img = MasterCard
      break
    case 'discover':
      img = DiscoverCard
      break
    case 'amex':
      img = AmexCard
      break
    default:
      img = ''
  }

  return (
    <Container
      editable={editable}
      onClick={() => {
        if (editable) {
          onClick()
        }
      }}
    >
      <CardLogo>{card ? <img src={img} alt={'Credit card logo'} /> : <StyledIcon icon={cardOutline} />}</CardLogo>
      <Spacer width={20} />
      {card ? (
        <div>
          <CardBrand>{capitalize(brand)}</CardBrand>
          <CardNumber>•••• •••• •••• {last4}</CardNumber>
        </div>
      ) : (
        <CenteredFlex> Add a credit card</CenteredFlex>
      )}

      {editable && (
        <IconContainer>
          {pillText ? (
            <StyledIonButton size="small" color="tertiary">
              {pillText}
            </StyledIonButton>
          ) : (
            <Icon icon={chevronForwardOutline} />
          )}
        </IconContainer>
      )}
    </Container>
  )
}

export default CreditCardDisplay
