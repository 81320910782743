import axios, { AxiosRequestConfig } from 'axios'

import { getItem } from 'helpers/storage'

export const API_URL = process.env.REACT_APP_API_URL

export const apiAxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})

apiAxiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getItem('token')
  const method = config.method!.toUpperCase()

  if (token && method !== 'OPTIONS') {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})
