import React from 'react'
import styled from 'styled-components'
import {
  IonContent,
  IonMenu,
  IonMenuToggle,
  IonLabel,
  IonList,
  IonListHeader,
  IonText,
  IonMenuButton,
} from '@ionic/react'
import { createOutline, logOutOutline } from 'ionicons/icons'
import { observer } from 'mobx-react-lite'
import { TEAM_MEMBER_HOME_ROUTE, TEAM_MEMBER_LOGIN_ROUTE } from '@supplyhound/utils/config'
import useStores from '@supplyhound/hooks/useStores'
import { MenuItem } from '@supplyhound/types'
import Item from '@supplyhound/components/common/Item'
import Icon from '@supplyhound/components/common/Icon'
import { useLocation } from 'react-router-dom'

const StyledMenuButton = styled(IonMenuButton)`
  background: var(--ion-color-dark);
  color: var(--ion-color-secondary);
  border: 1.5px solid;
  border-radius: var(--ion-border-radius);
  width: 50px;
  height: 50px;
`

const StyledNameText = styled.div`
  font-size: 1.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledList = styled(IonList)`
  padding: 0;
`

const StyledListHeader = styled(IonListHeader)`
  padding: calc(var(--space-unit) * 3) calc(var(--space-unit) * 2);
`

const HeaderInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Label = styled(IonLabel)`
  &&& {
    font-size: 1.33rem;
  }
`

const Sidebar: React.FC = () => {
  const { teamMemberProfileStore, resetStores } = useStores()
  const userName = teamMemberProfileStore.profile?.first_name || 'User'

  const menuItems: MenuItem[] = [
    {
      title: 'List',
      icon: createOutline,
      routeProps: {
        routerLink: TEAM_MEMBER_HOME_ROUTE,
      },
    },
    {
      title: 'Logout',
      icon: logOutOutline,
      routeProps: {
        onClick: () => {
          resetStores()
        },
        routerLink: TEAM_MEMBER_LOGIN_ROUTE,
      },
    },
  ]
  const location = useLocation()

  return (
    <IonMenu contentId="main" menuId="menu" type="overlay">
      <IonContent>
        <StyledList>
          <StyledListHeader lines="full">
            <HeaderInnerWrapper>
              <StyledMenuButton />
              <StyledNameText>
                <IonText>{userName}</IonText>
              </StyledNameText>
            </HeaderInnerWrapper>
          </StyledListHeader>

          {menuItems.map(({ title, routeProps, icon }, index) => (
            <IonMenuToggle key={index}>
              <Item lines="full" detail={true} {...routeProps}>
                <Icon
                  slot="start"
                  icon={icon}
                  border
                  width={40}
                  containerPadding={8}
                  invertColors={!!routeProps.routerLink && location.pathname.includes(routeProps.routerLink)}
                />
                <Label>{title}</Label>
              </Item>
            </IonMenuToggle>
          ))}
        </StyledList>
      </IonContent>
    </IonMenu>
  )
}

export default observer(Sidebar)
