import { UnidentifiedTeamMemberListItem, TeamMemberListItem, AwsSignedID, ID } from '@supplyhound/types'
import { axiosInstance, transformJsonToFormData } from './axios'
import type { AxiosRequestConfig } from 'axios'
import { uploadImageToS3 } from '@supplyhound/utils/directUpload'
import * as Sentry from '@sentry/react'

const mutatingRequestConfigDefaults = {
  transformRequest: [
    transformJsonToFormData, // transform image blobs
  ],
}

export const createTeamMemberListItem = async (
  team_member_list_item: UnidentifiedTeamMemberListItem,
  config: AxiosRequestConfig = {}
): Promise<TeamMemberListItem> => {
  let signedUrl: AwsSignedID | undefined
  if (team_member_list_item.image) {
    try {
      signedUrl = await uploadImageToS3(team_member_list_item.image)
    } catch (e) {
      Sentry.captureException(e)
    }
  }
  return axiosInstance
    .post(
      '/v1/team_member_list_items',
      {
        team_member_list_item: signedUrl ? { ...team_member_list_item, image: signedUrl } : team_member_list_item,
      },
      { ...(signedUrl ? {} : mutatingRequestConfigDefaults), ...config }
    )
    .then(({ data: { team_member_list_item } }) => team_member_list_item)
}

export const updateTeamMemberListItem = async (
  team_member_list_item: TeamMemberListItem,
  config: AxiosRequestConfig = {}
): Promise<TeamMemberListItem> => {
  let signedUrl: AwsSignedID | undefined
  if (team_member_list_item.image && team_member_list_item.image instanceof File) {
    try {
      signedUrl = await uploadImageToS3(team_member_list_item.image)
    } catch (e) {
      Sentry.captureException(e)
    }
  }
  return axiosInstance
    .patch(
      `/v1/team_member_list_items/${team_member_list_item.id}`,
      {
        team_member_list_item: signedUrl ? { ...team_member_list_item, image: signedUrl } : team_member_list_item,
      },
      { ...(signedUrl ? {} : mutatingRequestConfigDefaults), ...config }
    )
    .then(({ data: { team_member_list_item } }) => team_member_list_item)
}

export const deleteTeamMemberListItem = (teamMemberListItemId: ID, config: AxiosRequestConfig = {}) =>
  axiosInstance
    .delete(`/v1/team_member_list_items/${teamMemberListItemId}`, config)
    .then(({ data: { status } }) => status)

export const archiveTeamMemberListItems = (teamId: ID, itemIds: number[]) => {
  axiosInstance
    .patch(`/v1/teams/${teamId}/team_member_lists/archive`, {
      item_ids: itemIds,
    })
    .then(({ data: { status } }) => status)
}

export const acceptTeamMemberListItems = (teamId: ID, jobSiteId: ID, itemIds: number[]) => {
  axiosInstance
    .patch(`v1/teams/${teamId}/team_member_lists/accept`, {
      item_ids: itemIds,
      job_site_id: jobSiteId,
    })
    .then(({ data: team_member_lists }) => team_member_lists)
}
export const submitTeamMemberListItem = (
  listId: ID,
  itemIdsToSubmit: ID[],
  config: AxiosRequestConfig = {}
): Promise<TeamMemberListItem> =>
  axiosInstance.patch(`/v1/team_member_lists/${listId}/submit`, { item_ids: itemIdsToSubmit }, { ...config })
