import React from 'react'
import { IonText } from '@ionic/react'
import styled from 'styled-components'

const WrapperDiv = styled.div`
  margin: 10px 0;
  font-size: 0.875rem;
  font-weight: 500;
`

const ErrorLabel = ({ fieldName, children }: { fieldName?: string; children?: string }) => {
  return (
    <WrapperDiv>
      <IonText color="danger">{children}</IonText>
    </WrapperDiv>
  )
}

export default ErrorLabel
