import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

const OverlaySpinner = () => (
  <Overlay>
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </Overlay>
)

export default OverlaySpinner
