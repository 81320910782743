import { useRef, useEffect, useCallback } from 'react'
import { useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react'

export const useHandlePressEnter = (handler: Function, isIonView: boolean) => {
  const savedHandler = useRef<Function>()

  const handleKeypress = useCallback((e: KeyboardEvent) => {
    const target = e.target as HTMLElement
    const targetTagName = target.tagName?.toLowerCase()

    // Do not catch keypress event on textarea
    if (e.key !== 'Enter' || targetTagName === 'textarea') {
      return
    }

    // Do not trigger default on button
    if (targetTagName === 'ion-button') {
      e.preventDefault()
    }

    if (savedHandler.current) {
      savedHandler.current()
    }
  }, [])

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  // If the current page is ion-view, use hooks from @ionic/react
  useIonViewDidEnter(() => {
    if (isIonView) {
      document.addEventListener('keypress', handleKeypress)
    }
  })

  useIonViewDidLeave(() => {
    if (isIonView) {
      document.removeEventListener('keypress', handleKeypress)
    }
  })

  // Otherwise, use useEffect
  useEffect(() => {
    if (!isIonView) {
      document.addEventListener('keypress', handleKeypress)

      return () => {
        document.removeEventListener('keypress', handleKeypress)
      }
    }
  }, [isIonView])
}

export default useHandlePressEnter
