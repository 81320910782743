import format from 'date-fns/format'
import isToday from 'date-fns/isToday'
import isTomorrow from 'date-fns/isTomorrow'
import { TIME_DATE_FORMAT as DEFAULT_DATE_FORMAT, DATE_FORMAT } from 'config/date'
import { Task, SupplierDeliveryTypes, SupplierDeliveryTypesType, TaskTypes } from 'types/tasks'

export const formatDateTime = (date: Date | string, dateFormat: string = DEFAULT_DATE_FORMAT): string => {
  return format(new Date(date), dateFormat)
}

export const formatReadyBy = (readyBy: string) => {
  if (readyBy === 'ASAP') return readyBy

  const readyByDateTime = new Date(readyBy)
  const dayWiseFormat = (dayOfWeek: string) => `h:mm a '${dayOfWeek}'`

  let readyByFormat

  if (isToday(readyByDateTime)) {
    readyByFormat = dayWiseFormat('Today')
  } else if (isTomorrow(readyByDateTime)) {
    readyByFormat = dayWiseFormat('Tomorrow')
  } else {
    readyByFormat = DEFAULT_DATE_FORMAT
  }

  return format(readyByDateTime, readyByFormat)
}

export const formatSupplierDeliveryReadyBy = (readyBy: string, deliveryType: SupplierDeliveryTypesType): string => {
  const date = format(new Date(readyBy), DATE_FORMAT)
  const deliveryTypeLabel = SupplierDeliveryTypes[deliveryType]

  return `${deliveryTypeLabel} ${date}`
}

export const formatReadyByDatetime = (task: Task) => {
  const datetime = task.type === TaskTypes.Delivery ? task.ready_by : task.pickup_datetime

  if (
    task.type === TaskTypes.Delivery &&
    task.vehicle_type === 'Supplier' &&
    Object.keys(SupplierDeliveryTypes).indexOf(task.delivery_type) > -1
  ) {
    return formatSupplierDeliveryReadyBy(task.delivery_datetime, task.delivery_type as SupplierDeliveryTypesType)
  }

  return formatReadyBy(datetime)
}
