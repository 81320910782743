import { makeAutoObservable } from 'mobx'

import { PaymentMethod } from '@supplyhound/types'
import {
  fetchPaymentMethods as fetchPaymentMethodsApi,
  deletePaymentMethod as deletePaymentMethodApi,
} from '@supplyhound/api'

export class PaymentMethodStore {
  paymentMethods: PaymentMethod[] = []
  selectedPaymentMethodId?: string = undefined
  error: string | undefined = undefined
  isFetching: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  async dispatchFetchPaymentMethods() {
    this.isFetching = true
    return fetchPaymentMethodsApi()
      .then(response => {
        this.updatePaymentMethods(response.data)
        this.error = undefined
        this.isFetching = false
      })
      .catch(() => {
        this.isFetching = false
        this.error = 'Error in fetching payment methods'
      })
  }

  async dispatchDeletePaymentMethod(paymentId: string) {
    this.isFetching = true
    return deletePaymentMethodApi(paymentId)
      .then(() => {
        const filteredPaymentMethods = this.paymentMethods.filter(paymentMethod => paymentMethod.id !== paymentId)
        this.updatePaymentMethods(filteredPaymentMethods)
        this.error = undefined
        this.isFetching = false
      })
      .catch(e => {
        this.error = `${e}`
        this.isFetching = false
      })
  }

  updatePaymentMethods(paymentMethods: PaymentMethod[]) {
    this.paymentMethods = paymentMethods
  }
  updateSelectedPaymentMethod(paymentId: string | undefined) {
    this.selectedPaymentMethodId = paymentId
  }
  selectDefaultPaymentMethod() {
    if (!this.selectedPaymentMethodId && this.paymentMethods.length) {
      this.updateSelectedPaymentMethod(this.paymentMethods[0].id)
    }
  }

  reset() {
    this.updatePaymentMethods([])
    this.updateSelectedPaymentMethod(undefined)
    this.error = undefined
    this.isFetching = false
  }
}
