import React, { useState } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import useGooglePlacesService from '@supplyhound/hooks/useGoogleGeocoder'
import { GoogleMapStyles } from '@supplyhound/styles'

interface MapPinProps {
  address: string
}

type Coords = {
  lat: number
  lng: number
}

const MapPin: React.FC<MapPinProps> = ({ address }) => {
  const [coords, setCoords] = useState<Coords | null>(null)
  const geocoder = useGooglePlacesService()
  geocoder.getGeocode(address).then(results => {
    const firstResult = results && results[0]
    if (!firstResult) {
      return
    }
    const coords = {
      lat: firstResult.geometry?.location?.lat() || 0,
      lng: firstResult.geometry?.location?.lng() || 0,
    }
    setCoords(coords)
  })

  return coords ? (
    <GoogleMap
      center={{
        lat: coords.lat,
        lng: coords.lng,
      }}
      mapContainerStyle={GoogleMapStyles}
      zoom={15}
      options={{ disableDefaultUI: true, draggable: false }}
    >
      <Marker
        position={{
          lat: coords.lat,
          lng: coords.lng,
        }}
      />
    </GoogleMap>
  ) : null
}

export default MapPin
