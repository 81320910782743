import { configurePersistable } from 'mobx-persist-store'

configurePersistable({
  storage: window.localStorage,
})

export { UserProfileStore } from './UserProfileStore'
export { AuthStore } from './AuthStore'
export { SuppliersStore } from './SuppliersStore'
export { TeamStore } from './TeamStore'
export { LoadingStore } from './LoadingStore'
export { OrdererListStore } from './OrdererListStore'
export { TeamMemberListStore } from './TeamMemberListStore'
export { TeamMemberProfileStore } from './TeamMemberProfileStore'
export { JobSitesStore } from './JobSitesStore'
export { TaskStore } from './TaskStore'
export { PaymentMethodStore } from './PaymentMethodStore'
export { HistoryStore } from './HistoryStore'
export { OrdererTeamRequestsStore } from './OrdererTeamRequestsStore'
export { SessionStore } from './SessionStore'
export { SubscriptionStore } from './SubscriptionStore'
