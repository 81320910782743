import React from 'react'
import styled from 'styled-components'
import Spacer from '@supplyhound/components/common/Spacer'
import { SupplierSearchResult } from '@supplyhound/types'
import { parseAddress, removeNumberAndZipcode } from '@supplyhound/utils/address'

interface Props {
  supplier: SupplierSearchResult
  onSupplierClicked: () => void
}
const Container = styled.div`
  cursor: pointer;
`

const Text = styled.p`
  margin: 0px;
`

const SupplierRow: React.FC<Props> = ({ supplier, onSupplierClicked }) => {
  return (
    <>
      <Container onClick={onSupplierClicked}>
        <h3>{supplier.name}</h3>
        <Text>{supplier.supplier_id ? removeNumberAndZipcode(supplier.address) : parseAddress(supplier.address)}</Text>
      </Container>
      <Spacer height={20} />
    </>
  )
}

export default SupplierRow
