import React, { useState } from 'react'
import Datetime from 'react-datetime'
import moment, { Moment } from 'moment'
import { TIME_FORMAT } from 'config/date'
import styled from 'styled-components'

export interface DatetimePickerRef {
  isValid: boolean
}

interface Props {
  initialValue?: string
  onChange: (datetime: Moment) => void
}

const DATE_FORMAT = `M/d/yyyy ${TIME_FORMAT}`

const InvalidMessage = styled.div`
  margin-top: 1rem;
  color: red;
  text-align: left;
`

const DatetimePicker: React.FC<Props> = ({ initialValue, onChange }) => {
  const [isValid, setIsValid] = useState<boolean>(true)
  const validate = (datetime: string | Moment) => moment(datetime, DATE_FORMAT).isValid()
  const handleChange = (datetime: string | Moment) => {
    setIsValid(validate(datetime))
    onChange(moment(datetime))
  }

  return (
    <>
      <Datetime
        timeFormat={TIME_FORMAT}
        isValidDate={validate}
        initialValue={moment(initialValue)}
        onChange={handleChange}
        inputProps={{ readOnly: true }}
      />
      <InvalidMessage style={{ display: isValid ? 'none' : 'block' }}>Invalid time!</InvalidMessage>
    </>
  )
}

export default DatetimePicker
