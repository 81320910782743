import { makeAutoObservable, toJS, runInAction } from 'mobx'
import jsonwebtoken from 'jsonwebtoken'
import { setItem, getItem } from 'helpers/storage'
import { fetchProfile, login } from 'api/users'
import streamChatAdapter from 'helpers/streamChatAdapter'

import { User } from 'types/users'

import { configureScope, setUser } from '@sentry/browser'

const setUserInSentry = (email: string): void => {
  if (typeof email === 'string') {
    setUser({ email })
  } else {
    configureScope(scope => scope.clear())
  }
}

export class AuthStore {
  token?: string
  user?: User
  userIsConnectedToStreamChat: boolean

  constructor() {
    this.token = getItem('token')
    this.user = getItem('user')
    this.userIsConnectedToStreamChat = false
    makeAutoObservable(this)
  }

  setProfile(user: User) {
    this.user = user
    setItem('user', user)
  }

  setToken(token: string) {
    this.token = token
    setItem('token', token)
  }

  clearToken() {
    setItem('token', undefined)
    this.token = undefined
  }

  clearUser() {
    setItem('user', undefined)
    this.user = undefined
  }

  reset() {
    this.logout()
  }

  get getUser() {
    return toJS(this.user)
  }

  get getIsAdmin() {
    return this.user?.role === 'admin'
  }

  get getIsLoggedIn() {
    return !!this.token
  }

  featureFlagValue(flag: string) {
    return this.user?.feature_flags[flag]
  }

  dispatchFetchProfile = async () => {
    try {
      const { user } = await fetchProfile()

      runInAction(() => {
        this.user = user
      })
      setItem('user', user)

      if (user.stream_chat_token) {
        await streamChatAdapter.connectUser(user)
        runInAction(() => {
          this.userIsConnectedToStreamChat = true
        })
      }

      if (user?.email) {
        setUserInSentry(user.email)
      }
    } catch (err) {
      this.logout()
      throw err
    }
  }

  dispatchLogin = async (email: string, password: string) => {
    try {
      const { jwt } = await login(email, password)

      const payload = jsonwebtoken.decode(jwt)

      if (typeof payload === 'object' && payload?.role === 'orderer') {
        throw new Error('Invalid role')
      }

      runInAction(() => {
        this.token = jwt
      })
      setItem('token', jwt)

      await this.dispatchFetchProfile()
    } catch (err: any) {
      let error: string

      if (err?.response?.status === 404) {
        error = 'Invalid email or password'
      } else {
        error = 'Unexpected error. Please contact SupplyHound'
      }

      throw new Error(error)
    }
  }

  logout = async () => {
    runInAction(() => {
      this.token = undefined
      this.user = undefined
    })
    setItem('token', undefined)
    setItem('user', undefined)

    await streamChatAdapter.disconnectUser()
    runInAction(() => {
      this.userIsConnectedToStreamChat = false
    })
  }
}
