import { makeAutoObservable } from 'mobx'
import { readdOrderListItem } from '@supplyhound/api'
import { ID, UnidentifiedOrdererListItem } from '@supplyhound/types'

export class HistoryStore {
  itemCount: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  setItemCount(count: number) {
    this.itemCount = count
  }

  reset() {
    this.itemCount = 0
  }

  async dispatchOrderListItem(ordererListId: ID, item: UnidentifiedOrdererListItem, sourceTaskItemId: ID) {
    return readdOrderListItem({
      orderer_list_id: ordererListId,
      overwrite_params: item,
      source_task_item_id: sourceTaskItemId,
    })
  }
}
