import { UnidentifiedJobSite, JobSiteAttributes } from '@supplyhound/types'

export const convertJobSiteToAttributes = (jobSite: UnidentifiedJobSite): JobSiteAttributes => ({
  contact_name: jobSite.contact_name,
  phone: jobSite.phone,
  note: jobSite.note,
  location_attributes: jobSite.location,
  nickname: jobSite.nickname,
  status: jobSite.status,
})
