import React, { useEffect, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import useLoadingState from '@supplyhound/hooks/useLoadingState'

type TeamMemberAppProps = {
  children: ReactNode
}

const TeamMemberApp: React.FC<TeamMemberAppProps> = ({ children }) => {
  const {
    teamMemberProfileStore,
    teamMemberProfileStore: { authStore },
  } = useStores()

  const { loadWhile } = useLoadingState()

  useEffect(() => {
    if (authStore.isAuthenticated) {
      loadWhile(teamMemberProfileStore.dispatchLoadProfile)
    }
  }, [authStore.isAuthenticated])

  return <div>{children}</div>
}

export default observer(TeamMemberApp)
