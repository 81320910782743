import React, { useState } from 'react'
import styled from 'styled-components'
import { FieldProps, getIn } from 'formik'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow-y: scroll;
`

const Option = styled.span`
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 24px;
  padding: 5px;

  &.active {
    background-color: var(--ion-color-dark);
    color: var(--ion-color-dark-contrast);
    border-radius: var(--ion-border-radius);
  }
`

const ListField: React.FC<
  FieldProps & {
    options: string[]
    onSelect?: (option: string) => void
  }
> = ({ field: { name }, form, options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState('')
  const error = getIn(form.errors, name)
  const touched = getIn(form.touched, name)

  return (
    <div>
      <OptionsContainer>
        {options.map((option, idx) => {
          return (
            <Option
              key={`${option}${idx}`}
              className={selectedOption === option ? 'active' : ''}
              onClick={() => {
                form.setFieldValue(name, option)
                setSelectedOption(option)
                if (onSelect) {
                  onSelect(option)
                }
              }}
            >
              {option}
            </Option>
          )
        })}
      </OptionsContainer>
      {error && touched ? <ErrorLabel>{error}</ErrorLabel> : null}
    </div>
  )
}

export default ListField
