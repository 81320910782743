import React, { useState, ChangeEvent } from 'react'
import { Field, FormikBag, FormikProps, withFormik, FieldProps, getIn } from 'formik'
import * as Yup from 'yup'
import { IonItemGroup } from '@ionic/react'

import {
  ContactWithCommunicationPreference,
  UnidentifiedContactWithCommunicationPreference,
  SupplierSearchResult,
} from '@supplyhound/types'

import { catchAndSetErrors } from '@supplyhound/utils/forms'
import TextInputField from './fields/TextInputField'
import Spacer from '@supplyhound/components/common/Spacer'
import styled from 'styled-components'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import useGoogleAutocompleteService from '@supplyhound/hooks/useGoogleAutocompleteService'
import { InputChangeEventDetail, IonInputCustomEvent } from '@ionic/core'
import SupplierOptions from '@supplyhound/components/SupplierOptions'
import { parseNameWithCity } from '@supplyhound/utils/address'
import { withInputBorder } from '@supplyhound/forms/fields/InputBorder'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'
import { IonInput } from '@ionic/react'
import FieldHeader from './fields/FieldHeader'
import Caption from '@supplyhound/components/common/text/Caption'
import CheckboxField from '@supplyhound/forms/fields/CheckboxField'

const ContentWrapper = styled.div`
  height: 100%;
`
const SupplierContainer = styled.div`
  position: relative;
  width: 100%;
`
const ButtonContainer = styled.div`
  flex: auto 1 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 0 20px 0;
`
const StyledInput = styled(withInputBorder(IonInput))<{ error: boolean }>`
  background-color: var(--greyscale-1);
  border-radius: var(--ion-border-radius);
  --padding-start: 10px;
  height: 50px;
  ${({ error }) => {
    return error ? 'border: 2px solid var(--ion-color-danger);' : ''
  }}
`
const StyledIonButton = styled(SubmitButton)`
  --background: var(--greyscale-4);
`

const StyledCheckboxField = styled(CheckboxField)`
  display: flex;
  align-items: center;
`

const DEFAULT_CONTACT = {
  supplier: undefined,
  first_name: undefined,
  last_name: undefined,
  phone: undefined,
  mobile: undefined,
  email: undefined,
  contact_preference_email: false,
  contact_preference_sms: false,
}

export type ContactFormValues = {
  supplier?: {
    place_ids: string[]
    displayText: string
  }
  first_name?: string
  last_name?: string
  phone?: string
  mobile?: string
  email?: string
  contact_preference_email?: boolean
  contact_preference_sms?: boolean
}

interface Props {
  onSubmit: (contact: UnidentifiedContactWithCommunicationPreference | ContactWithCommunicationPreference) => void
  contact?: ContactWithCommunicationPreference
}

const ContactForm: React.FC<Props & FormikProps<ContactFormValues>> = ({
  values,
  setFieldValue,
  submitForm,
  contact,
}) => {
  const { search: googleSearch } = useGoogleAutocompleteService()
  const [controlText, setControlText] = useState(values?.supplier?.displayText || undefined)
  const [searchResults, setSearchResults] = useState<SupplierSearchResult[]>([])
  const [showSupplierOptions, setShowSupplierOptions] = useState(true)

  const handleControlChange = (event: CustomEvent<InputChangeEventDetail>) => {
    const text = event.detail.value || ''
    if (text) {
      search(text)
    }
  }

  const handleOnInput = (event: IonInputCustomEvent<InputEvent>) => {
    const text = event.target.value || ''

    if (text) {
      setControlText(text.toString())
    } else {
      setControlText('')
      setSearchResults([])
    }
  }

  const search = (input: string) => {
    googleSearch(input).then(predictions => {
      const suppliersArray: SupplierSearchResult[] = predictions.map(prediction => {
        return {
          name: prediction.name,
          address: prediction.address,
          place_ids: prediction.place_id ? [prediction.place_id] : [],
          displayText: parseNameWithCity(prediction.address),
        }
      })
      setSearchResults(suppliersArray)
    })
  }

  const controlTextDisplay = (supplier: SupplierSearchResult) => {
    if (supplier.supplier_id && supplier.displayText) {
      return supplier.displayText
    } else if (supplier.supplier_id) {
      return supplier.name
    } else {
      return parseNameWithCity(supplier.address)
    }
  }

  return (
    <ContentWrapper>
      <SupplierContainer
        onBlur={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            // If loses focus and new focus is not child of supplier container
            setShowSupplierOptions(false)
          }
        }}
      >
        <Field
          name="location"
          label="Supplier"
          children={(props: FieldProps<Partial<SupplierSearchResult>>) => {
            const form = props.form
            const error = getIn(form.errors, `supplier.displayText`)
            return (
              <>
                <FieldHeader label="Supplier" />
                <StyledInput
                  placeholder="Enter and select supplier name or address"
                  onIonChange={handleControlChange}
                  onIonInput={handleOnInput}
                  debounce={400}
                  value={controlText}
                  onClick={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target) {
                      event.target.select()
                    }
                    setShowSupplierOptions(true)
                  }}
                  readonly={!!contact}
                  disabled={!!contact}
                  error={!!error}
                />
                {error && <ErrorLabel>{error}</ErrorLabel>}
                {contact && <Caption>{'Cannot change supplier'}</Caption>}

                {showSupplierOptions && (
                  <div tabIndex={1}>
                    <SupplierOptions
                      supplierOptions={searchResults}
                      sectionLabel={'Suggested'}
                      onSupplierSelected={supplier => {
                        setFieldValue('supplier', supplier)
                        setControlText(controlTextDisplay(supplier))
                        setShowSupplierOptions(false)
                      }}
                      dropDown
                    />
                  </div>
                )}
                <Spacer height={5} />
              </>
            )
          }}
        />
      </SupplierContainer>
      <Field name="first_name" placeholder="First Name" component={TextInputField} label="First Name" type="text" />
      <Field name="last_name" placeholder="Last Name" component={TextInputField} label="Last Name" type="text" />
      <Field
        name="phone"
        placeholder="(999)-999-9999"
        component={TextInputField}
        label="Phone"
        type="tel"
        mask="(999) 999-9999"
      />
      <Field
        name="mobile"
        placeholder="(999)-999-9999"
        component={TextInputField}
        label="Mobile"
        type="tel"
        mask="(999) 999-9999"
      />
      <Field name="email" placeholder="email@email.com" component={TextInputField} label="Email" type="email" />
      <IonItemGroup>
        <h3>Communication Preferences</h3>
        <Field
          type="checkbox"
          component={StyledCheckboxField}
          name="contact_preference_email"
          label="Email my sales contact"
        />
        <Spacer height={10} />
        <Field
          type="checkbox"
          component={StyledCheckboxField}
          name="contact_preference_sms"
          label="Text my sales contact"
        />
        <Spacer height={10} />
        <Field type="checkbox" component={StyledCheckboxField} checked disabled label="SupplyHound portal" />
      </IonItemGroup>
      <ButtonContainer>
        <SubmitButton onClick={submitForm}>{contact ? 'Save changes' : 'Add Contact'}</SubmitButton>
        <Spacer height={8} />
        {contact && (
          <StyledIonButton
            onClick={() => {
              setFieldValue('is_archived', !contact.is_archived)
              submitForm()
            }}
          >
            {contact.is_archived ? 'Unarchive' : 'Archive'}
          </StyledIonButton>
        )}
      </ButtonContainer>
    </ContentWrapper>
  )
}

export default withFormik<Props, ContactFormValues>({
  displayName: 'ContactForm',
  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    supplier: Yup.object().shape({
      displayText: Yup.string().required('Please select an option'),
      place_ids: Yup.array().of(Yup.string()).required(),
    }),
    first_name: Yup.string().required("Enter the contact's first name"),
    last_name: Yup.string().required("Enter the contact's last name"),
    phone: Yup.string().required("Enter the contact's phone"),
    mobile: Yup.string(),
    email: Yup.string().required("Enter the contact's email"),
    is_archived: Yup.boolean(),
    contact_preference_email: Yup.boolean(),
    contact_preference_sms: Yup.boolean(),
  }),

  mapPropsToValues({ contact }: Props): ContactFormValues {
    if (contact) {
      return {
        ...DEFAULT_CONTACT,
        ...contact,
        supplier: {
          place_ids: contact.place_ids,
          displayText: contact.supplier_name,
        },
      }
    }
    return DEFAULT_CONTACT
  },

  handleSubmit(values: ContactFormValues, { props: { onSubmit }, setErrors }: FormikBag<Props, ContactFormValues>) {
    const formattedValues = {
      ...values,
      supplier_name: values.supplier?.displayText,
      place_ids: values.supplier?.place_ids,
    }
    delete formattedValues.supplier
    console.log({ formattedValues })
    catchAndSetErrors(async () => {
      await onSubmit(formattedValues)
    }, setErrors)
  },
})(ContactForm)
