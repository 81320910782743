import React from 'react'
import { IonButton, IonItemGroup } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import { chevronUpOutline } from 'ionicons/icons'
import styled from 'styled-components'
import { ForwardProps } from '@supplyhound/types'
import ExpandableList from './ExpandableList'
import useDynamicHeight, { DynamicHeightProps, HeightStates } from '@supplyhound/hooks/useDynamicHeight'

type Props = ForwardProps<typeof IonItemGroup> & {
  items: React.ReactNode
  control?: React.ReactNode
  dropDownMenuHeight?: number
} & Pick<DynamicHeightProps, 'onStateChange'> &
  Disabled

type Disabled = { disabled?: boolean }

const StyledIonItemGroup = styled(IonItemGroup)<Disabled>`
  ${props => (props.disabled ? '' : 'cursor: pointer;')}
  position: relative;
`

const CollapseButton = styled(IonButton)`
  height: calc(5 * var(--space-unit));
`

const StyledExpandableList = styled(ExpandableList)`
  .expandable-list-content {
    border-radius: 0 0 8px 8px;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: var(--ion-border-color);
  }

  &:not(.${HeightStates.Collapsed}) .expandable-list-content {
    border-bottom: 1px solid var(--ion-border-color);
  }
`

const Dropdown: React.FC<Props> = ({
  onStateChange,
  items,
  control,
  className,
  disabled = false,
  dropDownMenuHeight,
  ...props
}) => {
  const { hostRef, toggle, collapse, heightState } = useDynamicHeight<HTMLIonItemGroupElement>({
    onStateChange,
  })

  return (
    <StyledIonItemGroup
      ref={hostRef}
      onClick={disabled ? undefined : toggle}
      className={`${className} ${heightState} dropdown`}
      disabled={disabled}
      {...props}
    >
      {control}
      <StyledExpandableList lines="full" state={heightState} maxHeight={dropDownMenuHeight}>
        {items}
        <CollapseButton expand="full" fill="clear" onClick={collapse}>
          <Icon color="medium" icon={chevronUpOutline} />
        </CollapseButton>
      </StyledExpandableList>
    </StyledIonItemGroup>
  )
}

export default Dropdown
