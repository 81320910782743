import _ from 'lodash'

type SheetConfigType = {
  [key: string]: { size: Array<string>; thickness: Array<string>; grade: Array<string> }
}

export interface SheetFormValues {
  type: string
  size: string
  thickness: string
  grade: string
}

const SheetConfig: SheetConfigType = {
  Plywood: {
    thickness: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1 1/8"'],
    size: ["4' x 8'", "4' x 9'", "4' x 10'", "4' x 12'"],
    grade: [
      'CDX',
      'CDX - Struct1',
      'CDX - T/G',
      'PT',
      'PT + T/G',
      'Exterior',
      'OSB - FlameBlock',
      'OSB - Struct1',
      'OSB - T/G (Advantech)',
      'OSB - T/G (Edge Gold)',
      'OSB - Radiant',
      'Fire Treated - AC',
      'Fire Treated - Struct1',
      'Sturdifloor',
      'Birch',
      'Maple',
      'Maple - Clear',
      'Marine',
      'Concrete Form',
      '7-Ply',
    ],
  },
  'Thermo Ply': { thickness: ['1/8"'], size: ["4' x 8'", "4' x 10'"], grade: [] },
  MDF: { thickness: ['3/4"'], size: ['49" x 97"'], grade: ['Ultra Lite'] },
  Fiberwood: { thickness: ['1/4"', '1/2"', '5/8"', '3/4"', '1"'], size: ['49" x 97"'], grade: ['MDF'] },
  Hardboard: { thickness: ['1/8"', '1/4"'], size: ["4' x 8'"], grade: [] },
  'Redwood Lattice': {
    thickness: [],
    size: ["4' x 8'"],
    grade: ['Diagonal', 'Heavy Diagonal', 'Heavy Square', 'Privacy'],
  },
}

const gradeDisplayConfig = {
  Fiberwood: {
    '1/4"': { MDF: 'Medium Density Fiberwood (MDF)' },
    '1/2"': { MDF: 'Medium Density Fiberwood (MDF)' },
    '5/8"': { MDF: 'Medium Density Fiberwood (MDF)' },
    '3/4"': { MDF: 'Medium Density Fiberwood (MDF)' },
    '1"': { MDF: 'Medium Density Fiberwood (MDF)' },
  },
  MDF: { '3/4"': { 'Ultra Lite': 'Ultra Lite MDF' } },
  Hardboard: { '1/8"': { Hardboard: 'Hardboard Tempered 1 Side' }, '1/4"': { Hardboard: 'Hardboard Tempered 1 Side' } },
  'Redwood Lattice': {
    ' ': {
      Diagonal: 'Redwood Lattice Diagonal',
      'Heavy Diagonal': 'Redwood Lattice Heavy Diagonal',
      'Heavy Square': 'Redwood Lattice Heavy Square',
      Privacy: 'Redwood Lattice Privacy',
    },
  },
  Plywood: {
    '5/8"': {
      CDX: 'Plywood CDX',
      'CDX - T/G': 'Plywood CDX - T/G',
      'OSB - Radiant': 'Plywood OSB - Radiant Barrier',
      Exterior: 'Plywood Exterior - AC',
    },
    '3/4"': {
      CDX: 'Plywood CDX',
      'CDX - T/G': 'Plywood CDX - T/G',
      PT: 'Plywood CDX Pressure Treated',
      'PT + T/G': 'Plywood CDX Pressure Treated T/G',
      Sturdifloor: 'Plywood Sturdifloor T/G',
      'OSB - T/G (Edge Gold)': 'Plywood OSB - T/G (Edge Gold)',
      'OSB - T/G (Advantech)': 'Plywood OSB - T/G (Advantech)',
      Exterior: 'Plywood Exterior - AC',
      '7-Ply': 'Plywood BBOES 7-ply',
      'Concrete Form': 'Plywood HDO Concrete Form',
      Marine: 'Marine Plywood',
      Birch: 'Shop Plywood Birch',
      Maple: 'Shop Plywood  Maple',
      'Maple - Clear': 'Shop Plywood Clear Finish - Maple',
    },
    '1 1/8"': {
      CDX: 'Plywood CDX',
      'CDX - T/G': 'Plywood CDX - T/G',
      'PT + T/G': 'Plywood CDX Pressure Treated T/G',
      Sturdifloor: 'Plywood Sturdifloor T/G',
    },
    '3/8"': { 'CDX - Struct1': 'Plywood STRUCT1  CDX - Struct1', Exterior: 'Plywood Exterior - AC' },
    '1/2"': {
      'CDX - Struct1': 'Plywood STRUCT1  CDX - Struct1',
      'Fire Treated - Struct1': 'Plywood STRUCT1 Interior Fire Treated',
      'Fire Treated - AC': 'Plywood AC Interior Fire Treated',
      PT: 'Plywood CDX Pressure Treated',
      'OSB - Struct1': 'Plywood OSB - Struct1',
      'OSB - FlameBlock': 'Plywood OSB - FlameBlock',
      'OSB - Radiant': 'Plywood OSB - Radiant Barrier',
      Exterior: 'Plywood Exterior - AC',
      Marine: 'Marine Plywood',
      Birch: 'Shop Plywood Birch',
      'Maple - Clear': 'Shop Plywood Clear Finish - Maple',
    },
    '1/4"': {
      Exterior: 'Plywood Exterior - AC',
      Birch: 'Birch Plywood',
      Maple: 'Shop Plywood Maple',
      'Maple - Clear': 'Shop Plywood Clear Finish - Maple',
    },
  },
  'Thermo Ply': { '1/8"': { '': 'Thermo Ply  ' } },
}

export const formatValues = (values: SheetFormValues) => {
  const displayGrade =
    _.get(gradeDisplayConfig, [values.type, values.thickness, values.grade]) || `${values.type} ${values.grade || ''}`
  return `${values.size || ''}${values.thickness ? ` x ${values.thickness}` : ''} ${displayGrade}`.trim()
}

export default SheetConfig
