import { useEffect, useRef } from 'react'
import useStores from '@supplyhound/hooks/useStores'

export type Prediction = {
  name: string
  address: string
  place_id?: string
}

export type GoogleAutocompleteServiceHelpers = {
  search: (query: string) => Promise<Prediction[]>
}

export const useGoogleAutocompleteService = (): GoogleAutocompleteServiceHelpers => {
  const serviceRef = useRef<google.maps.places.AutocompleteService | null>(null)
  const {
    userProfileStore: { profile },
  } = useStores()

  const SAN_FRANCISCO_COORDINATES = new google.maps.LatLng(37.7775, -122.416389)
  const PHOENIX_COORDINATES = new google.maps.LatLng(33.448333, -112.073889)

  const userMarketCoordinates = profile?.market.id === 1 ? SAN_FRANCISCO_COORDINATES : PHOENIX_COORDINATES

  const search = async (input: string) => {
    if (!serviceRef.current) return Promise.resolve([])

    const response = await serviceRef.current.getPlacePredictions({
      input,
      componentRestrictions: {
        country: 'us',
      },
      location: userMarketCoordinates,
      radius: 1000,
    })

    if (!response) return Promise.resolve([])

    const predictions = response.predictions.map(
      ({
        description,
        place_id,
        structured_formatting: { main_text },
      }: google.maps.places.AutocompletePrediction): Prediction => {
        return {
          name: main_text,
          address: description,
          place_id,
        }
      }
    )
    return predictions.filter(prediction => !!prediction)
  }

  useEffect(() => {
    if (!serviceRef.current) serviceRef.current = new google.maps.places.AutocompleteService()
  }, [])

  return { search }
}

export default useGoogleAutocompleteService
