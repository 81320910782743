import React from 'react'
import { FieldProps, getIn } from 'formik'
import { IonItemGroup, IonDatetime, IonDatetimeButton, IonModal } from '@ionic/react'

import FieldHeader from './FieldHeader'
import InputBorder from './InputBorder'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'

type Props = {
  label: string
  className?: string
  disabled?: boolean
  minuteValues?: string
  min?: string
  isDateEnabled?: (dateIsoString: string) => boolean
}

const DatetimeInputField: React.FC<FieldProps & Props> = ({
  field,
  form,
  label,
  minuteValues,
  className,
  disabled,
  min,
  isDateEnabled,
}) => {
  const { name, value } = field
  const error = getIn(form.errors, name)
  const touched = getIn(form.touched, name)
  const id = `datetime_${name}`
  return (
    <IonItemGroup className={className}>
      <FieldHeader label={label} />

      <InputBorder mode="ios" isError={error && touched} disabledBackground={disabled}>
        <IonDatetimeButton datetime={id} />
        <IonModal
          keepContentsMounted={true}
          onDidDismiss={() => {
            form.setFieldTouched(name, true)
          }}
        >
          <IonDatetime
            id={id}
            name={name}
            minuteValues={minuteValues}
            onIonChange={form.handleChange}
            min={min}
            isDateEnabled={isDateEnabled}
            value={value}
          ></IonDatetime>
        </IonModal>
      </InputBorder>
      {error && touched ? <ErrorLabel>{error}</ErrorLabel> : null}
    </IonItemGroup>
  )
}

export default DatetimeInputField
