import { PickupTask, PickupTaskStatus } from 'types/tasks'

import { apiAxiosInstance } from '.'

interface PickupTaskResponse {
  pickup_task: PickupTask
}

export async function fetchPickupTask(taskId: number | string): Promise<PickupTaskResponse> {
  const response = await apiAxiosInstance.get<PickupTaskResponse>(`/v1/pickup_tasks/${taskId}`)

  return response.data
}

export async function updatePickupTaskStatus(
  taskId: number | string,
  event: string,
  payload?: any
): Promise<PickupTaskResponse> {
  const response = await apiAxiosInstance.post<PickupTaskResponse>(`/v1/pickup_tasks/${taskId}/${event}`, payload)

  return response.data
}

export async function overridePickupTaskStatus(
  taskId: number | string,
  status: PickupTaskStatus
): Promise<PickupTaskResponse> {
  const response = await apiAxiosInstance.post<PickupTaskResponse>(`/v1/pickup_tasks/${taskId}/override_status`, {
    status,
  })

  return response.data
}

export async function deleteTask(taskId: number | string): Promise<PickupTaskResponse> {
  const response = await apiAxiosInstance.delete<PickupTaskResponse>(`/v1/pickup_tasks/${taskId}`)

  return response.data
}

export async function updatePickupTask(
  taskId: number | string,
  pickupTask: Partial<PickupTask>
): Promise<PickupTaskResponse> {
  const response = await apiAxiosInstance.patch<PickupTaskResponse>(`/v1/pickup_tasks/${taskId}`, {
    pickup_task: pickupTask,
  })

  return response.data
}
