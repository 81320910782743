import React, { useState, useMemo, useEffect, useCallback } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import get from 'lodash/get'

import Spacer from '@supplyhound/components/common/Spacer'
import { IonSelectOption, IonSelect } from '@ionic/react'
import ToggleableTabs from '@supplyhound/components/ToggleableTabs'

interface Meta {
  total_count: number
}

const FooterPageSize = styled.div`
  display: flex;
  align-items: center;
`
const StyledIonSelectOption = styled(IonSelectOption)`
  ::part(native) {
    padding: 2px 0px 2px 30px;
  }
`

const FooterPageSelect = styled.div`
  display: flex;
`
const Page = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  width: 50px;
  border: 1px solid var(--ion-border-color);
  border-right: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  :first-child {
    border-top-left-radius: var(--ion-border-radius);
    border-bottom-left-radius: var(--ion-border-radius);
  }
  :last-child {
    border-top-right-radius: var(--ion-border-radius);
    border-bottom-right-radius: var(--ion-border-radius);
    border-right: 1px solid var(--ion-border-color);
  }
  :hover {
    ${({ isSelected }) => (isSelected ? '' : 'background-color: var(--greyscale-3);')}
  }
  ${({ isSelected }) => (isSelected ? 'background-color: var(--ion-color-primary); cursor: default;' : '')}
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const PageSelect = ({
  totalCount,
  page: currentPage,
  sizePerPage,
  onClick,
}: {
  totalCount: number
  page: number
  sizePerPage: number
  onClick: (page: number) => void
}) => {
  const totalPages = Math.ceil(totalCount / sizePerPage)
  const startPage = Math.max(currentPage > totalPages - 5 ? totalPages - 5 : currentPage - 2, 1)
  const endPage = Math.min(currentPage < 3 ? 5 : currentPage + 2, totalPages)

  return (
    <FooterPageSelect>
      {currentPage > 3 && <Page onClick={() => onClick(1)}>{'<<'}</Page>}
      {currentPage > 1 && <Page onClick={() => onClick(currentPage - 1)}>{'<'}</Page>}
      {_.range(startPage, endPage + 1).map(num => {
        const isSelected = num === currentPage
        return (
          <Page key={num} onClick={isSelected ? undefined : () => onClick(num)} isSelected={isSelected}>
            {num}
          </Page>
        )
      })}
      {currentPage < totalPages && <Page onClick={() => onClick(currentPage + 1)}>{'>'}</Page>}
      {currentPage < totalPages - 2 && <Page onClick={() => onClick(totalPages)}>{'>>'}</Page>}
    </FooterPageSelect>
  )
}

const PaginationControl = ({
  sizePerPage,
  totalCount,
  currentPage,
  setPage,
  setSizePerPage,
}: {
  sizePerPage: number
  totalCount: number
  currentPage: number
  setPage: (page: number) => void
  setSizePerPage: (size: number) => void
}) => {
  return (
    <Wrapper>
      <FooterPageSize>
        <IonSelect interface="popover" value={sizePerPage} onIonChange={e => setSizePerPage(e.target.value)}>
          <StyledIonSelectOption value={10}>10</StyledIonSelectOption>
          <StyledIonSelectOption value={25}>25</StyledIonSelectOption>
          <StyledIonSelectOption value={30}>30</StyledIonSelectOption>
          <StyledIonSelectOption value={50}>50</StyledIonSelectOption>
        </IonSelect>
        <Spacer width={10} />
        {`Showing rows ${sizePerPage * (currentPage - 1) + 1} to ${Math.min(
          totalCount,
          sizePerPage * currentPage
        )} of ${totalCount}`}
      </FooterPageSize>
      <PageSelect
        totalCount={totalCount}
        page={currentPage}
        sizePerPage={sizePerPage}
        onClick={(pageNum: number) => setPage(pageNum)}
      />
    </Wrapper>
  )
}

export default PaginationControl
