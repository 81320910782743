import { StreamChat, Channel, ConnectAPIResponse, EventHandler } from 'stream-chat'

import { User } from 'types/users'
import { Conversation } from 'types/conversations'

const CHANNEL_TYPE = 'messaging'
const CHANNEL_ID_PREFIX = 'conversations'

const streamChatPublicKey = process.env.REACT_APP_STREAM_CHAT_PUBLIC_KEY

const userIdToStreamChatUserId = (user: User): string => `${user.role}-${user.id}`
const conversationIdToStreamChatChannelId = (conversationId: number): string => `${CHANNEL_ID_PREFIX}-${conversationId}`

const streamChatChannelIdToConversationId = (channelId: string): number => parseInt(channelId.split('-')[1])

class StreamChatAdapter {
  client: StreamChat

  constructor() {
    this.client = StreamChat.getInstance(streamChatPublicKey as string)
  }

  /**
   * Connects the user to streamchat.
   * @param user
   * @returns Connection promise.
   */
  connectUser(user: User): ConnectAPIResponse {
    const { name, stream_chat_token: streamChatToken } = user

    if (streamChatToken) {
      return this.client.connectUser({ id: userIdToStreamChatUserId(user), name }, streamChatToken)
    } else {
      return Promise.reject('User has no streamchat token.')
    }
  }

  disconnectUser(): Promise<void> {
    return this.client.disconnectUser()
  }

  getChannel(conversation: Conversation): Channel {
    const channelId = conversationIdToStreamChatChannelId(conversation.id)

    return this.client.channel(CHANNEL_TYPE, channelId)
  }

  loadConversationChannels(conversations: Conversation[]): void {
    const channelIds = conversations.map(({ id }) => conversationIdToStreamChatChannelId(id))

    this.client.queryChannels({
      id: { $in: channelIds },
    })
  }

  /**
   * Registers a callback for new messages.
   * @param callback
   * @returns A function to cancel the subscription.
   */
  onNewMessage(callback: EventHandler): () => void {
    const { unsubscribe } = this.client.on('message.new', event => callback(event))

    return unsubscribe
  }

  /**
   * Registers a callback for when a message is added to a channel. For clients that are not currently watching the channel.
   * @param callback
   * @returns A function to cancel the subscription.
   */
  onNotificationMessageNew(callback: EventHandler): () => void {
    const { unsubscribe } = this.client.on('notification.message_new', event => callback(event))

    return unsubscribe
  }
}

export { streamChatChannelIdToConversationId }

const streamChatAdapter = new StreamChatAdapter()
export default streamChatAdapter
