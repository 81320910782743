import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Moment } from 'moment'
import styled from 'styled-components'
import mixpanel from 'mixpanel-browser'

import OutlineButton from '@supplyhound/components/buttons/OutlineButton'
import {
  DeliveryTaskStatusStepEvents,
  DeliveryTaskStatusStepEvent,
  PickupTaskStatusStepEvents,
  Task,
  PickupTaskStatusStepEvent,
} from 'types/tasks'
import ContactUsModal from 'components/modals/ContactUsModal'
import ChangeRequestModal from 'components/modals/ChangeRequestModal'
import AcceptPickupTaskModal from 'components/modals/AcceptPickupTaskModal'
import ChangePickupTaskPickupDatetimeModal from 'components/modals/ChangePickupTaskPickupDatetimeModal'
import Card from '@supplyhound/components/common/Card'
import { useIonModal } from '@ionic/react'
import { useLoadingState } from '@supplyhound/hooks'
import useStores from 'hooks/useStores'
import { TaskTypes } from 'types/tasks'
import {
  deliveryActionTemplateMap,
  adminDeliveryActionTemplateMap,
  pickupActionTemplateMap,
  adminPickupActionTemplateMap,
} from './config'
import Spacer from '@supplyhound/components/common/Spacer'

const StatusAction = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px;
`

const StatusActionButton = styled(OutlineButton)`
  margin-bottom: 10px;
`

const TaskStatusActionCard = ({
  task,
  setTask,
  chatEnabled,
  onChatButtonClick,
}: {
  task: Task
  setTask: (task: Task) => void
  chatEnabled: boolean
  onChatButtonClick: () => void
}) => {
  const [isAdminChangeRequest, setIsAdminChangeRequest] = useState(false)
  const { loadWhile } = useLoadingState()
  const { taskStore, authStore } = useStores()
  const isAdmin = authStore.getIsAdmin

  let template
  if (task.type === TaskTypes.Delivery) {
    template = isAdmin
      ? adminDeliveryActionTemplateMap[task.status] || deliveryActionTemplateMap[task.status]
      : deliveryActionTemplateMap[task.status]
  } else {
    template = isAdmin
      ? adminPickupActionTemplateMap[task.status] || pickupActionTemplateMap[task.status]
      : pickupActionTemplateMap[task.status]
  }

  const updateDeliveryTaskStatus = (event: DeliveryTaskStatusStepEvent, payload?: any) => {
    loadWhile(() =>
      taskStore.dispatchUpdateDeliveryTaskStatus(task.id, event, payload).then(updatedTask => setTask(updatedTask))
    )
  }

  const handleDeliveryTaskStepEvent = (event: DeliveryTaskStatusStepEvent) => {
    if (event === DeliveryTaskStatusStepEvents.Reject) {
      presentContactUsModal()
      mixpanel.identify(task.orderer.id.toString())
      mixpanel.track('Reject Clicked', {
        $distinct_id: task.orderer.id,
        'Order ID': task.id,
        'Supplier ID': task.supplier_id,
        Type: task.type === 'DeliveryTask' ? 'Delivery' : 'Pickup',
      })
    } else if (event === DeliveryTaskStatusStepEvents.RequestChange) {
      setIsAdminChangeRequest(false)
      presentChangeRequestModal()
    } else if (event === DeliveryTaskStatusStepEvents.AdminRequestChange) {
      setIsAdminChangeRequest(true)
      presentChangeRequestModal()
    } else {
      updateDeliveryTaskStatus(event)
    }
  }

  const updatePickupTaskStatus = (event: PickupTaskStatusStepEvent, payload?: any) => {
    return loadWhile(() =>
      taskStore.dispatchUpdatePickupTaskStatus(task.id, event, payload).then(updatedTask => setTask(updatedTask))
    )
  }

  const [presentChangePickupTaskPickupDatetimeModal, dismissChangePickupTaskPickupDatetimeModal] = useIonModal(
    ChangePickupTaskPickupDatetimeModal,
    {
      dismiss: () => dismissChangePickupTaskPickupDatetimeModal(),
      onSubmit: (pickupDatetime: Moment) => {
        dismissChangePickupTaskPickupDatetimeModal()

        loadWhile(async () => {
          await taskStore.dispatchUpdatePickupTask(task.id, {
            pickup_datetime: pickupDatetime.toISOString(),
          })
          await updatePickupTaskStatus(PickupTaskStatusStepEvents.Accept)
        })
      },
      initialValue: task.pickup_datetime,
    }
  )

  const [presentAcceptPickupTaskModal, dismissAcceptPickupTaskModal] = useIonModal(AcceptPickupTaskModal, {
    dismiss: () => dismissAcceptPickupTaskModal(),
    onAccept: () => {
      updatePickupTaskStatus(PickupTaskStatusStepEvents.Accept).then(() => dismissAcceptPickupTaskModal())
    },
    onChange: () => {
      presentChangePickupTaskPickupDatetimeModal()
      dismissAcceptPickupTaskModal()
    },
    pickupDatetime: task.pickup_datetime,
  })

  const handlePickupTaskStepEvent = (event: PickupTaskStatusStepEvent, payload?: any) => {
    if (event === PickupTaskStatusStepEvents.Accept) {
      presentAcceptPickupTaskModal()
    } else {
      updatePickupTaskStatus(event, payload)
    }
  }

  const handleChangeRequest = (payload?: any) => {
    dismissChangeRequestModal()
    const event = isAdminChangeRequest
      ? DeliveryTaskStatusStepEvents.AdminRequestChange
      : DeliveryTaskStatusStepEvents.RequestChange
    updateDeliveryTaskStatus(event, payload)
  }

  const [presentChangeRequestModal, dismissChangeRequestModal] = useIonModal(ChangeRequestModal, {
    onSubmit: handleChangeRequest,
    dismiss: () => dismissChangeRequestModal(),
  })

  const [presentContactUsModal, dismissContactUsModal] = useIonModal(ContactUsModal, {
    dismiss: () => dismissContactUsModal(),
  })

  return (
    <StatusAction>
      <h1>{template.title}</h1>
      <Spacer height={20} />
      <p>{template.description}</p>
      <Spacer height={50} />
      {template.actions.map(action => {
        return (
          <StatusActionButton
            key={action.event}
            borderColor="border-default"
            color={action.buttonColor}
            onClick={() =>
              task.type === TaskTypes.Delivery
                ? handleDeliveryTaskStepEvent(action.event as DeliveryTaskStatusStepEvent)
                : handlePickupTaskStepEvent(action.event as PickupTaskStatusStepEvent)
            }
          >
            {action.buttonText}
          </StatusActionButton>
        )
      })}
      {chatEnabled && (
        <StatusActionButton borderColor="border-default" color="secondary" onClick={onChatButtonClick}>
          Chat with contractor
        </StatusActionButton>
      )}
    </StatusAction>
  )
}

export default observer(TaskStatusActionCard)
