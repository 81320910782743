import { OrdererListItem, UnidentifiedOrdererListItem, AwsSignedID, FileWithUrl, ID } from '@supplyhound/types'
import { axiosInstance, transformJsonToFormData } from './axios'
import { uploadImageToS3 } from '@supplyhound/utils/directUpload'
import * as Sentry from '@sentry/react'

const mutatingRequestConfigDefaults = {
  transformRequest: [
    transformJsonToFormData, // transform image blobs
  ],
}

export const createOrdererListItem = async (
  orderer_list_item: UnidentifiedOrdererListItem
): Promise<OrdererListItem> => {
  let signedUrl: AwsSignedID | undefined
  if (orderer_list_item.image) {
    try {
      signedUrl = await uploadImageToS3(orderer_list_item.image)
    } catch (e) {
      Sentry.captureException(e)
    }
  }
  return axiosInstance
    .post(
      `/v1/orderer_list_items`,
      {
        orderer_list_item: signedUrl ? { ...orderer_list_item, image: signedUrl } : orderer_list_item,
      },
      signedUrl ? {} : mutatingRequestConfigDefaults
    )
    .then(({ data: { orderer_list_item } }) => orderer_list_item)
}

export const readdOrderListItem = async ({
  orderer_list_id,
  overwrite_params,
  source_task_item_id,
}: {
  orderer_list_id: ID
  overwrite_params: {
    description: string
    quantity: number | ''
    image?: FileWithUrl
  }
  source_task_item_id: ID
}): Promise<OrdererListItem> => {
  let signedUrl: AwsSignedID | undefined
  if (overwrite_params.image && overwrite_params.image instanceof File) {
    try {
      signedUrl = await uploadImageToS3(overwrite_params.image)
    } catch (e) {
      Sentry.captureException(e)
    }
  }
  return axiosInstance
    .post(
      `/v1/orderer_list_items/create_from_task_item`,
      {
        orderer_list_item: {
          orderer_list_id,
          overwrite_params: signedUrl ? { ...overwrite_params, image: signedUrl } : overwrite_params,
          source_task_item_id,
        },
      },
      signedUrl ? {} : mutatingRequestConfigDefaults
    )
    .then(({ data: { orderer_list_item } }) => orderer_list_item)
}

export const updateOrdererListItem = async (orderer_list_item: OrdererListItem): Promise<OrdererListItem> => {
  let signedUrl: AwsSignedID | undefined
  if (orderer_list_item.image && orderer_list_item.image instanceof File) {
    try {
      signedUrl = await uploadImageToS3(orderer_list_item.image)
    } catch (e) {
      Sentry.captureException(e)
    }
  }
  return axiosInstance
    .patch(
      `/v1/orderer_list_items/${orderer_list_item.id}`,
      {
        orderer_list_item: signedUrl ? { ...orderer_list_item, image: signedUrl } : orderer_list_item,
      },
      signedUrl ? {} : mutatingRequestConfigDefaults
    )
    .then(({ data: { orderer_list_item } }) => orderer_list_item)
}

export const deleteOrdererListItem = (item: OrdererListItem) =>
  axiosInstance.delete(`/v1/orderer_list_items/${item.id}`).then(({ data: { status } }) => status)

export const archiveOrdererListItem = (item: OrdererListItem) =>
  axiosInstance.post(`/v1/orderer_list_items/${item.id}/archive`).then(({ data: { status } }) => status)
