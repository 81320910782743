import React from 'react'
import styled from 'styled-components'
import { IonButton } from '@ionic/react'
import OutlineButton from '@supplyhound/components/buttons/OutlineButton'
import Icon from '@supplyhound/components/common/Icon'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  text-align: center;
`

const Title = styled.h1`
  margin-bottom: calc(2 * var(--space-unit));
`

const Text = styled.p`
  color: var(--greyscale-6);
  font-size: 1.125rem;
`

const MainIcon = styled(Icon)`
  margin-bottom: var(--space-unit);
`

const CancelButton = styled(IonButton)`
  margin-top: calc(2 * var(--space-unit));
`

interface ModalProps {
  icon: string
  iconColor?: string
  title: string
  subtext: string
  submitText?: string
  onDismiss: () => void
  onSubmit?: () => void
  showCancel?: boolean
  ActionButtons?: React.FC
}

const ActionModal: React.FC<ModalProps> = ({
  icon,
  iconColor = 'danger',
  title,
  subtext,
  submitText,
  onDismiss,
  onSubmit,
  showCancel = true,
  ActionButtons,
}) => {
  return (
    <BaseModal onDidDismiss={() => onDismiss()}>
      <Container>
        <TitleContainer>
          <MainIcon width={90} icon={icon} color={iconColor} />
          <Title>{title}</Title>
          <Text>{subtext}</Text>
        </TitleContainer>

        <div>
          {!!ActionButtons ? (
            ActionButtons
          ) : (
            <OutlineButton
              borderColor="danger"
              textColor="danger"
              color="light"
              onClick={() => (onSubmit ? onSubmit() : null)}
            >
              {submitText}
            </OutlineButton>
          )}
          {showCancel && (
            <CancelButton color="dark" fill="clear" expand="block" onClick={() => onDismiss()}>
              Cancel
            </CancelButton>
          )}
        </div>
      </Container>
    </BaseModal>
  )
}

export default ActionModal
