import React from 'react'
import styled from 'styled-components'
import Item from '@supplyhound/components/common/Item'

type ListIemProps = React.ComponentProps<typeof Item>

const StyledItem = styled(Item)`
  --inner-padding-bottom: 20px;
  --inner-padding-top: 20px;
  --inner-padding-start: 13px;
  --inner-padding-end: 13px;
  border-radius: 0px;
  &::part(native) {
    padding: 0px;
  }
`

const ListItem: React.FC<ListIemProps> = props => <StyledItem {...props} />

export default ListItem
