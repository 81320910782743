import { RefObject, useRef, useState } from 'react'
import { useClickAway, useKey } from 'react-use'

import { toEnumType } from '@supplyhound/types'

export const DEFAULT_TRANSITION_DURATION = 400

export const CollapsibleStates = {
  Expanded: 'Expanded',
  Expanding: 'Expanding',
} as const

export const ExpandableStates = {
  Collapsed: 'Collapsed',
  Collapsing: 'Collapsing',
} as const

export const HeightStates = {
  ...ExpandableStates,
  ...CollapsibleStates,
} as const

export type HeightState = toEnumType<typeof HeightStates>

export type DynamicHeightProps = {
  onStateChange?: (state: HeightState) => any
  transitionDuration?: number // milliseconds
  initial?: HeightState
  closeOnOutsideClick?: boolean
  closeOnEscapeKey?: boolean
}

type DynamicHeightHelpers<HostRefType> = {
  heightState: HeightState
  setHeightState: (state: HeightState) => void
  collapse: () => void
  expand: () => void
  toggle: () => void
  hostRef: RefObject<HostRefType>
}

export const useDynamicHeight = <HostRefType extends HTMLElement>({
  initial = HeightStates.Collapsed,
  onStateChange,
  transitionDuration = DEFAULT_TRANSITION_DURATION,
  closeOnOutsideClick = true,
  closeOnEscapeKey = true,
}: DynamicHeightProps = {}): DynamicHeightHelpers<HostRefType> => {
  const hostRef = useRef<HostRefType | null>(null)
  const [state, _setState] = useState<HeightState>(initial)

  useKey('Escape', () => {
    state in CollapsibleStates && closeOnEscapeKey && collapse()
  })
  useClickAway(hostRef, () => {
    state in CollapsibleStates && closeOnOutsideClick && collapse()
  })

  const setState = (state: HeightState) => {
    _setState(state)
    onStateChange && onStateChange(state)
  }

  const twoStepTransition = (stepA: HeightState, stepB: HeightState) => {
    setState(stepA)
    setTimeout(() => setState(stepB), transitionDuration)
  }

  const collapse = () => twoStepTransition(HeightStates.Collapsing, HeightStates.Collapsed)
  const expand = () => twoStepTransition(HeightStates.Expanding, HeightStates.Expanded)

  const toggle = () => {
    switch (state) {
      case HeightStates.Collapsed:
        expand()
        break
      case HeightStates.Expanded:
        collapse()
    }
  }

  return {
    heightState: state,
    setHeightState: setState,
    collapse,
    expand,
    toggle,
    hostRef,
  }
}

export default useDynamicHeight
