import { AxiosRequestConfig } from 'axios'
import { axiosInstance, transformJsonToFormData } from './axios'
import { UnidentifiedTaskItem, UnidentifiedPickupTask, PickupTask } from '@supplyhound/types'

export type CreatablePickupTask = Omit<UnidentifiedPickupTask, 'itemIds' | 'supplier'> & {
  items: UnidentifiedTaskItem[]
  stripe_payment_method_id: string
}

type CreatePickupTaskRequestData = {
  pickup_task: CreatablePickupTask
}

const defaultCreatePickupTaskRequestConfig = {
  transformRequest: [transformJsonToFormData],
}

export const createPickupTask = (
  data: CreatePickupTaskRequestData,
  config: AxiosRequestConfig = defaultCreatePickupTaskRequestConfig
): Promise<PickupTask> =>
  axiosInstance.post('/v1/pickup_tasks', data, config).then(({ data: { pickup_task } }) => pickup_task)
