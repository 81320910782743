import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import useQuery from 'hooks/useQuery'
import ToggleableTabs from '@supplyhound/components/ToggleableTabs'
import Spacer from '@supplyhound/components/common/Spacer'
import OrderForm from './OrderForm'
import OrderHistoryPage from './OrderHistory'

const tabMap = {
  order: {
    label: 'Place Order',
    name: 'order',
  },
  history: {
    label: 'Order History',
    name: 'history',
  },
}

const Layout = styled.div`
  width: 100%;
`

const ToggleContainer = styled.div`
  width: 220px;
`

const Order = () => {
  const history = useHistory()
  const { query } = useQuery<{ tab?: 'order' | 'history' }>()
  const initialTab = query.tab === 'history' ? tabMap.history : tabMap.order
  const [currentTab, setCurrentTab] = useState<string>(initialTab.name)

  return (
    <Layout>
      <Spacer height={20} />
      <ToggleContainer>
        <ToggleableTabs
          activeTabName={currentTab}
          tabs={[tabMap.order, tabMap.history]}
          onToggle={tab => {
            history.replace(`order?tab=${tab}`)
            setCurrentTab(tab)
          }}
        />
      </ToggleContainer>
      <Spacer height={20} />
      {currentTab === 'order' && <OrderForm />}
      {currentTab === 'history' && <OrderHistoryPage />}
    </Layout>
  )
}

export default observer(Order)
