import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { IonCol, IonRow } from '@ionic/react'
import { useHistory } from 'react-router-dom'

import { DashboardItem } from 'types/dashboard'
import { Task } from 'types/tasks'
import { Conversation } from 'types/conversations'

import useStores from 'hooks/useStores'

import TypeCol from './Columns/TypeCol'
import OrderPlacedCol from './Columns/OrderPlacedCol'
import ReadyByCol from './Columns/ReadyByCol'
import StatusCol from './Columns/StatusCol'
import ConversationStatusCol from './Columns/ConversationStatusCol'
import NetworkCol, { Col as NetworkCoLDiv } from './Columns/NetworkCol'

import { TASKS_PATH, CONVERSATIONS_PATH } from 'config/paths'

import { formatDateTime } from 'helpers/datetimes'

const RowHeader = styled(IonRow)`
  background-color: var(--greyscale-1);
  padding: 15px 30px;
  color: var(--greyscale-5);
  border-top-left-radius: var(--ion-border-radius);
  border-top-right-radius: var(--ion-border-radius);
`

const GridRow = styled(RowHeader)`
  color: var(--ion-text-color);
  cursor: pointer;
  &:nth-child(even) {
    background-color: var(--ion-background-color);
  }
  &:last-child {
    border-bottom-left-radius: var(--ion-border-radius);
    border-bottom-right-radius: var(--ion-border-radius);
  }
  &:hover {
    background-color: var(--ion-color-tertiary);
  }
`

const Column = styled(IonCol)`
  flex: 0 0 97px;
  text-align: center;
  width: 100px;
`

const StyledTypeCol = styled(IonCol)<{ $isAdmin: boolean }>`
  flex: 0 0 ${props => (props.$isAdmin ? '135px' : '200px')};
  text-align: center;
`

const CustomerCol = styled(IonCol)`
  flex: 0 0 140px;
  text-align: center;
`

const StyledStatusCol = styled(IonCol)<{ $isAdmin: boolean }>`
  flex: 0 0 ${props => (props.$isAdmin ? '175px' : '200px')};
  text-align: center;
`

const ContactCol = styled(IonCol)`
  flex: 0 0 200px;
  text-align: center;
`

const SubmittedCol = styled(IonCol)<{ $isAdmin: boolean }>`
  flex: 0 0 ${props => (props.$isAdmin ? '125px' : '160px')};
  text-align: center;
`

const StyledReadyByCol = styled(IonCol)<{ $isAdmin: boolean }>`
  flex: 0 0 ${props => (props.$isAdmin ? '125px' : '165px')};
  text-align: center;
`

const StyledSupplierNameCol = styled(IonCol)`
  flex: 0 0 170px;
  text-align: center;
`

const TaskRow = observer(
  ({ task, refreshTable, setLoading }: { task: Task; refreshTable: () => void; setLoading: () => void }) => {
    const history = useHistory()
    const { authStore } = useStores()
    const isAdmin = !!authStore.user && authStore.user!.role === 'admin'
    return (
      <GridRow
        onClick={() => {
          history.push(`${TASKS_PATH}/${task.id}`)
        }}
      >
        <Column>{task.id}</Column>
        <StyledTypeCol $isAdmin={isAdmin}>
          <TypeCol task={task} />
        </StyledTypeCol>
        {isAdmin && <Column>{task.vehicle_type}</Column>}
        <CustomerCol>{task.orderer.company_name}</CustomerCol>
        <StyledStatusCol $isAdmin={isAdmin}>
          <StatusCol task={task} isAdmin={isAdmin} refreshTable={refreshTable} key={task.id} />
        </StyledStatusCol>
        {isAdmin && <NetworkCol task={task} refreshTable={refreshTable} setLoading={setLoading} />}
        {isAdmin && <Column>{task.network_status}</Column>}
        <ContactCol>{task.orderer.name}</ContactCol>
        <SubmittedCol $isAdmin={isAdmin}>
          <OrderPlacedCol task={task} />
        </SubmittedCol>
        <StyledReadyByCol $isAdmin={isAdmin}>
          <ReadyByCol task={task} />
        </StyledReadyByCol>
        {isAdmin && <StyledSupplierNameCol>{task.store_name}</StyledSupplierNameCol>}
      </GridRow>
    )
  }
)

const ConversationRow = observer(
  ({ conversation, refreshTable }: { conversation: Conversation; refreshTable: () => void }) => {
    const history = useHistory()
    const { authStore } = useStores()
    const isAdmin = !!authStore.user && authStore.user!.role === 'admin'

    return (
      <GridRow
        onClick={() => {
          history.push(`${CONVERSATIONS_PATH}/${conversation.id}`)
        }}
      >
        <Column>{conversation.id}</Column>
        <StyledTypeCol $isAdmin={isAdmin}>Message</StyledTypeCol>
        {isAdmin && <Column>N/A</Column>}
        <CustomerCol>{conversation.orderer?.company_name}</CustomerCol>
        <StyledStatusCol $isAdmin={isAdmin}>
          <ConversationStatusCol
            conversationId={conversation.id}
            status={conversation.status}
            isAdmin={isAdmin}
            refreshTable={refreshTable}
          />
        </StyledStatusCol>
        {isAdmin && <NetworkCoLDiv>N/A</NetworkCoLDiv>}
        {isAdmin && <Column>N/A</Column>}
        <ContactCol>{`${conversation.orderer?.first_name} ${conversation.orderer?.last_name}`}</ContactCol>
        <SubmittedCol $isAdmin={isAdmin}>{formatDateTime(conversation.replied_at)}</SubmittedCol>
        <StyledReadyByCol $isAdmin={isAdmin}>N/A</StyledReadyByCol>
        {isAdmin && <StyledSupplierNameCol>SupplyHound</StyledSupplierNameCol>}
      </GridRow>
    )
  }
)

const Row = ({
  setLoading,
  isHeader,
  dashboardItem,
  refreshTable = () => {},
}: {
  setLoading: () => void
  isHeader: boolean
  dashboardItem?: DashboardItem
  refreshTable?: () => void
}) => {
  const { authStore } = useStores()
  const isAdmin = !!authStore.user && authStore.user!.role === 'admin'

  if (isHeader) {
    return (
      <RowHeader>
        <Column>Reference #</Column>
        <StyledTypeCol $isAdmin={isAdmin}>Type</StyledTypeCol>
        {isAdmin && <Column>Vehicle</Column>}
        <CustomerCol>Customer</CustomerCol>
        <StyledStatusCol $isAdmin={isAdmin}>Status</StyledStatusCol>
        {isAdmin && <Column>Network</Column>}
        {isAdmin && <Column>Network Status</Column>}
        <ContactCol>Contact</ContactCol>
        <SubmittedCol $isAdmin={isAdmin}>Submitted</SubmittedCol>
        <StyledReadyByCol $isAdmin={isAdmin}>Ready By</StyledReadyByCol>
        {isAdmin && <StyledSupplierNameCol>Company</StyledSupplierNameCol>}
      </RowHeader>
    )
  }

  const data = dashboardItem!.data
  const taskData = {
    ...data,
    delivery_network: dashboardItem?.delivery_network,
    network_status: dashboardItem?.network_status,
  }
  return dashboardItem?.type === 'task' ? (
    <TaskRow task={taskData as Task} refreshTable={refreshTable} setLoading={setLoading} />
  ) : (
    <ConversationRow conversation={dashboardItem!.data as Conversation} refreshTable={refreshTable} />
  )
}

export default observer(Row)
