import React, { useEffect, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import usePaymentMethods from '@supplyhound/hooks/usePaymentMethods'
import useLoadingState from '@supplyhound/hooks/useLoadingState'

type OrdererAppProps = {
  children: ReactNode
}

const OrdererApp: React.FC<OrdererAppProps> = ({ children }) => {
  const { userProfileStore, paymentMethodStore, sessionStore } = useStores()
  const { loadWhile } = useLoadingState()
  const { fetchPaymentMethods } = usePaymentMethods()

  useEffect(() => {
    if (userProfileStore.authStore.isAuthenticated) {
      loadWhile(() =>
        Promise.all([
          fetchPaymentMethods(),
          userProfileStore.dispatchLoadModalViews(),
          paymentMethodStore.dispatchFetchPaymentMethods().then(() => {
            paymentMethodStore.selectDefaultPaymentMethod()
          }),
        ])
      )
    }
  }, [userProfileStore.authStore.isAuthenticated, fetchPaymentMethods])

  if (userProfileStore.authStore.isTokenExpired) {
    sessionStore.setIsSessionExpired(true)
    return null
  }

  // Prevent the rest of the app from rendering until we have profile w/ feature flags to ensure that the user only
  // sees what they are intended to see
  if (userProfileStore.authStore.isAuthenticated && !userProfileStore.profile) {
    return null
  }

  return <>{children}</>
}

export default observer(OrdererApp)
