import React from 'react'
import styled from 'styled-components'

const Row = styled.div`
  background-color: var(--greyscale-3-opaque);
  height: 32px;
  display: flex;
  align-items: center;
  margin: 20px -20px;
  padding-left: 20px;
`
const StyledLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
`

const SectionLabel: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Row>
      <StyledLabel>{label}</StyledLabel>
    </Row>
  )
}

export default SectionLabel
