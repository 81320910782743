import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div<{
  width: number
  height: number
}>`
  width: ${props => props.width + 'px' || 0};
  height: ${props => props.height + 'px' || 0};
  min-height: ${props => props.height + 'px' || 0};
`

const Spacer: React.FC<{ width?: number; height?: number }> = ({ width = 0, height = 0 }) => (
  <StyledDiv width={width} height={height}></StyledDiv>
)

export default Spacer
