import { makeAutoObservable } from 'mobx'
import { ID } from '@supplyhound/types'
import { fetchConversation, overrideConversationStatus } from 'api/conversations'
import type { ConversationStatus } from 'types/conversations'

export class ConversationStore {
  constructor() {
    makeAutoObservable(this)
  }

  dispatchFetchConversation = async (conversationId: ID) => {
    return fetchConversation(conversationId)
  }

  dispatchOverrideConversationStatus = async (conversationId: ID, status: ConversationStatus) => {
    await overrideConversationStatus(conversationId, status)
  }
}
