import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Channel as StreamChatClientChannel } from 'stream-chat'
import { Chat, MessageInput, MessageInputSmall, MessageList, Window, Channel } from 'stream-chat-react'

import useStores from 'hooks/useStores'

import useStreamChat from 'hooks/useStreamChat'
import { Conversation } from 'types/conversations'

import styled from 'styled-components'
import LoadingSpinner from 'components/LoadingSpinner'

import 'stream-chat-react/dist/css/index.css'
import styleVars from 'assets/styles/_variables.scss'

import CustomInput from './CustomInput'

interface Props {
  conversation: Conversation
  className?: string
  fullInputMessage?: boolean
}

const StyledContainerDiv = styled.div`
  .str-chat {
    border-radius: 0.5rem;

    .str-chat__main-panel {
      padding: var(--xs-p) var(--xs-p) 0 var(--xs-p);
    }

    .str-chat__list {
      padding: 0 var(--xs-p) 0;
    }

    .str-chat__fileupload-wrapper,
    .str-chat__emojiselect-wrapper {
      display: none;
    }

    .str-chat__message--me {
      .str-chat__avatar-fallback {
        background-color: ${styleVars.primaryColor};
      }
    }
  }
`

const TaskStreamChat = ({ conversation, className, fullInputMessage }: Props) => {
  const { authStore } = useStores()
  const { userIsConnectedToStreamChat } = authStore
  const [channel, setChannel] = useState<StreamChatClientChannel | null>(null)
  const { adapter } = useStreamChat()

  useEffect(() => {
    if (!conversation) return
    if (!userIsConnectedToStreamChat) return

    const channel = adapter.getChannel(conversation)
    setChannel(channel)
  }, [adapter, conversation, userIsConnectedToStreamChat])

  return channel ? (
    <StyledContainerDiv className={className}>
      <Chat client={adapter.client} theme="messaging light">
        <Channel channel={channel} Input={fullInputMessage ? CustomInput : MessageInputSmall}>
          <Window>
            <MessageList
              // Nothing fancy, conversation only.
              messageActions={[]}
              disableQuotedMessages={true}
            />
            <MessageInput
              // Focus on mount.
              focus={true}
              // We're not currently supporting file uploads.
              // This prop, however, doesn't appear to do anything,
              // so for now we also display:none the file input.
              noFiles={true}
              // Nor do we use typing events, so don't spam our
              // webhook endpoint with them.
              publishTypingEvent={false}
            />
          </Window>
        </Channel>
      </Chat>
    </StyledContainerDiv>
  ) : (
    <LoadingSpinner />
  )
}

export default observer(TaskStreamChat)
