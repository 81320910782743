import React from 'react'
import styled from 'styled-components'

const Border = styled.div`
  border: 1px solid var(--ion-border-color);
  border-radius: var(--ion-border-radius);
  margin: var(--space-unit) 0px;
  background-color: var(--greyscale-1);
`

const Card: React.FC<{ onClick?: () => void; className?: string }> = props => <Border {...props} />

export default Card
