import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'

import { HistoryTask, TaskTypes } from '@supplyhound/types'
import Card from '@supplyhound/components/common/Card'
import { GoogleMap } from '@react-google-maps/api'
import DirectionsMap from '@supplyhound/components/DirectionsMap'
import OrderDetails from '@supplyhound/components/OrderDetails'
import MaterialsList from '@supplyhound/components/MaterialsList'
import CreditCardDisplay from '@supplyhound/components/CreditCardDisplay'
import useStores from '@supplyhound/hooks/useStores'
import MapPin from '@supplyhound/components/MapPin'
import { GoogleMapStyles } from '@supplyhound/styles'

const StyledCard = styled(Card)`
  padding: calc(1.5 * var(--space-unit));
`

const TaskStatus = styled(StyledCard)<{
  $task: HistoryTask
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: ${({ $task: { task_complete, status } }) => {
    if (status === 'canceled') {
      return 'var(--ion-color-medium)'
    }
    return task_complete ? 'var(--ion-color-success)' : 'var(--ion-color-primary)'
  }};
`
const StyledMaterialsList = styled.div`
  margin: calc(var(--space-unit) * 2) 0px;
`

const TaskSummaryModal: React.FC<{
  task: HistoryTask
  setShowModal: (state: boolean) => void
}> = ({ task, setShowModal }) => {
  const { paymentMethodStore } = useStores()
  const { paymentMethods } = paymentMethodStore
  const card = paymentMethods.find(card => card.id === task.stripe_payment_method_id)
  const wasOrderBilledToAccount = task.stripe_payment_method_id === ''

  return (
    <BaseModal label="Order Summary" onDidDismiss={() => setShowModal(false)}>
      <div>
        <TaskStatus $task={task}>{task.delivery_job_status || task.pickup_job_status}</TaskStatus>
        {task.type === TaskTypes.Delivery && (
          <GoogleMap options={{ gestureHandling: 'none' }} mapContainerStyle={GoogleMapStyles}>
            <DirectionsMap
              origin={task.pickup_address}
              destination={task.type === 'DeliveryTask' ? task.delivery_address! : task.pickup_address}
              travelMode={google.maps.TravelMode.DRIVING}
            />
          </GoogleMap>
        )}
        {task.type === TaskTypes.Pickup && (
          <Card>
            <MapPin address={task.pickup_address} />
          </Card>
        )}

        <OrderDetails task={task} billingDetails={task.billingDetails} />
        {!task.ordered_directly && (
          <StyledMaterialsList>
            <MaterialsList
              jobSiteId={task.job_site_id}
              items={task.items}
              displayOnly
              canRequestChange={!task.task_complete}
            />
          </StyledMaterialsList>
        )}
        {wasOrderBilledToAccount ? (
          <StyledCard>This order was billed to your account.</StyledCard>
        ) : card ? (
          <CreditCardDisplay editable={false} onClick={() => {}} card={card.card} />
        ) : (
          <StyledCard>The payment method has been removed from your account.</StyledCard>
        )}
      </div>
    </BaseModal>
  )
}

export default observer(TaskSummaryModal)
