import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import get from 'lodash/get'

import { Meta } from 'types/base'
import { Task, TasksChannelResponse } from 'types/tasks'

import useStores from 'hooks/useStores'

import { fetchTasks as fetchTasksApi } from 'api/tasks'

import OverlaySpinner from 'components/OverlaySpinner'

import useActionCableConsumer from 'hooks/useActionCable'
import Spacer from '@supplyhound/components/common/Spacer'
import { IonCol, IonGrid, IonRow } from '@ionic/react'

import PaginationControls from '@supplyhound/components/common/PaginationControl'
import StatusCol from 'pages/Dashboard/Table/Row/Columns/StatusCol'
import { ORDER_PATH } from 'config/paths'

const RowHeader = styled(IonRow)`
  background-color: var(--greyscale-1);
  padding: 15px 30px;
  color: var(--greyscale-5);
  border-top-left-radius: var(--ion-border-radius);
  border-top-right-radius: var(--ion-border-radius);
`
const GridRow = styled(RowHeader)`
  color: var(--ion-text-color);
  cursor: pointer;
  &:nth-child(even) {
    background-color: var(--ion-background-color);
  }
  &:last-child {
    border-bottom-left-radius: var(--ion-border-radius);
    border-bottom-right-radius: var(--ion-border-radius);
  }
  &:hover {
    background-color: var(--ion-color-tertiary);
  }
`

const TaskNoCol = styled(IonCol)`
  flex: 0 0 100px;
  text-align: center;
`

const OrderNoCol = styled(IonCol)`
  flex: 0 0 130px;
  text-align: center;
`

const JobSiteCol = styled(IonCol)`
  flex: 0 0 150px;
  text-align: center;
`

const DeliveryAddressCol = styled(IonCol)`
  flex: 1 0 200px;
  text-align: center;
`

const StatusColContainer = styled(IonCol)`
  flex: 0 0 200px;
  display: flex;
  justify-content: center;
`

const OrderHistory = () => {
  const { authStore } = useStores()
  const actionCableConsumer = useActionCableConsumer()
  const history = useHistory()
  const user = authStore.user

  const [loading, setLoading] = useState<boolean>(false)
  const [tasks, setTasks] = useState<Task[]>([])

  const [page, setPage] = useState<number>(1)
  const [sizePerPage, setSizePerPage] = useState<number>(10)
  const [meta, setMeta] = useState<Meta | null>(null)
  const [error, setError] = useState<string | null>(null)

  const totalCount = useMemo<number>(() => get(meta, 'total_count', 0), [meta])
  const isAdmin = authStore.user && authStore.user!.role === 'admin'

  const fetchTasks = useCallback(async () => {
    try {
      setLoading(true)

      const response = await fetchTasksApi({
        status: 'all',
        page,
        per_page: sizePerPage,
        ordered_directly: false,
        supplier_orders_only: true,
      })

      setMeta(response.meta)
      setTasks(response.tasks)
    } catch (err) {
      // @ts-ignore
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }, [page, sizePerPage, setMeta, setTasks, setError, setLoading])

  const handleRowClick = (task: Task) => {
    history.push(`${ORDER_PATH}/${task.id}`)
  }

  const handleReceivedTask = useCallback(
    (response: TasksChannelResponse) => {
      // If the current user is supplier user, ignore responding to the tasks for other suppliers
      if (user?.supplier_id && user.supplier_id !== response.task.supplier_id) {
        return
      }

      // Reload tasks
      fetchTasks()
    },
    [user, fetchTasks]
  )

  useEffect(() => {
    setPage(1)
  })

  useEffect(() => {
    const subscription = actionCableConsumer.subscriptions.create(
      {
        channel: 'TasksChannel',
      },
      {
        received: (response: TasksChannelResponse) => {
          handleReceivedTask(response)
        },
      }
    )
    return () => {
      subscription.unsubscribe()
    }
  }, [actionCableConsumer, handleReceivedTask])

  useEffect(() => {
    fetchTasks()
  }, [page, sizePerPage, fetchTasks])

  return (
    <div>
      <Spacer height={20} />
      {loading && <OverlaySpinner />}

      {error && <Alert variant="danger">{error}</Alert>}

      <div>
        <IonGrid>
          <RowHeader>
            <TaskNoCol>Task #</TaskNoCol>
            <OrderNoCol>Order/PO Number</OrderNoCol>
            <JobSiteCol>Job Site</JobSiteCol>
            <DeliveryAddressCol>Delivery Address</DeliveryAddressCol>
            <StatusColContainer>Status</StatusColContainer>
          </RowHeader>
          {tasks.map((task, idx) => {
            return (
              <GridRow onClick={() => handleRowClick(task)} key={idx}>
                <TaskNoCol>{task.id}</TaskNoCol>
                <OrderNoCol>{task.order_no}</OrderNoCol>
                <JobSiteCol>{task.job_site_name}</JobSiteCol>
                <DeliveryAddressCol>
                  {
                    // @ts-ignore
                    task.delivery_address
                  }
                </DeliveryAddressCol>
                <StatusColContainer>
                  <StatusCol
                    task={task}
                    isAdmin={!!isAdmin}
                    refreshTable={() => {
                      fetchTasks()
                    }}
                  />
                </StatusColContainer>
              </GridRow>
            )
          })}
        </IonGrid>
        <Spacer height={20} />
        <PaginationControls
          sizePerPage={sizePerPage}
          totalCount={totalCount}
          currentPage={page}
          setPage={setPage}
          setSizePerPage={setSizePerPage}
        />
        <Spacer height={20} />
      </div>
    </div>
  )
}

export default observer(OrderHistory)
