import _ from 'lodash'

type DimensionalConfigType = {
  [key: string]: { size: Array<string>; length: Array<string>; grade: Array<string> }
}

export interface DimensionalFormValues {
  type: string
  size: string
  length: string
  grade: string
}

const DimensionalConfig: DimensionalConfigType = {
  'Douglas Fir': {
    size: [
      '1" x 3"',
      '1" x 4"',
      '1" x 6"',
      '1" x 8"',
      '1" x 10"',
      '1" x 12"',
      '2" x 2"',
      '2" x 4"',
      '2" x 6"',
      '2" x 8"',
      '2" x 10"',
      '2" x 12"',
      '3" x 4"',
      '3" x 6"',
      '3" x 8"',
      '3" x 12"',
      '4" x 4"',
      '4" x 6"',
      '4" x 8"',
      '4" x 10"',
      '4" x 12"',
      '6" x 6"',
      '6" x 8"',
      '6" x 10"',
      '6" x 12"',
    ],
    length: ["6'", "8'", "10'", "12'", "14'", "16'", "18'", "20'", "24'"],
    grade: ['#1 S4S', '#1 S4S Dry', '#2 S3S', '#2 S4S', 'Clear S4S KD'],
  },
  'Pressure Treated (DF)': {
    size: [
      '2" x 4"',
      '2" x 6"',
      '2" x 8"',
      '2" x 10"',
      '2" x 12"',
      '3" x 4"',
      '3" x 6"',
      '3" x 8"',
      '3" x 10"',
      '3" x 12"',
      '4" x 4"',
      '4" x 6"',
      '4" x 8"',
      '4" x 10"',
      '4" x 12"',
      '6" x 6"',
      '6" x 8"',
      '6" x 10"',
      '6" x 12"',
      '8" x 8"',
    ],
    length: ["6'", "8'", "10'", "12'", "14'", "16'", "18'", "20'", "24'"],
    grade: ['#1 Brown', '#1 Green', '#2 Brown', 'Surfaced Brown'],
  },
  'Primed Pine': {
    size: [
      '1" x 2"',
      '1" x 3"',
      '1" x 4"',
      '1" x 6"',
      '1" x 8"',
      '1" x 10"',
      '1" x 12"',
      '2" x 2"',
      '2" x 4"',
      '2" x 6"',
      '2" x 8"',
      '2" x 10"',
      '2" x 12"',
      '5/4" x 4"',
      '5/4" x 6"',
      '5/4" x 8"',
    ],
    length: ["6'", "8'", "10'", "12'", "14'", "16'", "18'", "20'", "24'"],
    grade: ['S4S', 'Treated FJ S1S2E'],
  },
  'Primed MDF': {
    size: ['1" x 4"', '1" x 6"', '1" x 8"', '1" x 12"'],
    length: ["6'", "8'", "10'", "12'", "14'", "16'", "18'", "20'", "24'"],
    grade: [],
  },
  Pine: {
    size: ['3/4" x 1 5/8"', '3/4" x 3 1/2"', '3/4" x 5 1/2"', '1" x 4"', '1" x 6"', '1" x 8"', '1" x 10"', '1" x 12"'],
    length: ["6'", "8'", "10'", "12'", "14'", "16'", "18'", "20'", "24'"],
    grade: ['#2', '#2 T/G', '#3'],
  },
  Redwood: {
    size: [
      '1" x 1"',
      '1" x 2"',
      '1" x 3"',
      '1" x 4"',
      '1" x 6"',
      '1" x 8"',
      '1" x 10"',
      '1" x 12"',
      '2" x 2"',
      '2" x 4"',
      '2" x 6"',
      '2" x 8"',
      '2" x 12"',
      '3" x 12"',
      '4" x 4"',
      '4" x 6"',
      '4" x 12"',
      '6" x 6"',
    ],
    length: ["6'", "8'", "10'", "12'", "14'", "16'", "18'", "20'", "24'"],
    grade: [
      'Clear Rough',
      'Clear S1S2E Green',
      'Clear S4S KD',
      'Clear S4S Green',
      'Con. Heart Rough',
      'Con. Heart S4S',
      'Selected Heart',
      'Selected Heart S4S',
    ],
  },
  'Western Red Cedar': {
    size: [
      '1" x 4"',
      '1" x 6"',
      '1" x 8"',
      '1" x 12"',
      '2" x 2"',
      '2" x 4"',
      '2" x 6"',
      '2" x 8"',
      '2" x 10"',
      '2" x 12"',
      '4" x 4"',
      '4" x 6"',
      '4" x 8"',
      '4" x 10"',
      '4" x 12"',
      '6" x 6"',
      '6" x 8"',
      '6" x 10"',
      '6" x 12"',
    ],
    length: ["6'", "8'", "10'", "12'", "14'", "16'", "18'", "20'", "24'"],
    grade: ['Clear', 'Clear - T/G', 'STK', 'STK - KD'],
  },
  LVL: {
    size: ['1 3/4" x 7 1/4"', '1 3/4" x 9 1/2"', '1 3/4" x 11 7/8"', '1 3/4" x 14"'],
    length: ["6'", "8'", "10'", "12'", "14'", "16'", "18'", "20'", "24'"],
    grade: ['Microlam'],
  },
}

const gradeDisplayConfig = {
  'Douglas Fir': {
    '1" x 3"': { 'Clear S4S KD': 'Douglas Fir Clear S4S KD' },
    '1" x 4"': { 'Clear S4S KD': 'Douglas Fir Clear S4S KD' },
    '1" x 6"': { 'Clear S4S KD': 'Douglas Fir Clear S4S KD' },
    '1" x 8"': { 'Clear S4S KD': 'Douglas Fir Clear S4S KD' },
    '1" x 10"': { 'Clear S4S KD': 'Douglas Fir Clear S4S KD' },
    '1" x 12"': { 'Clear S4S KD': 'Douglas Fir Clear S4S KD' },
    '2" x 2"': { '#2 S3S': 'Douglas Fir #2 S3S', 'Clear S4S KD': 'Douglas Fir Clear S4S KD' },
    '2" x 4"': {
      '#1 S4S Dry': 'Douglas Fir #1 S4S Dry',
      '#2 S3S': 'Douglas Fir #2 S3S',
      'Clear S4S KD': 'Douglas Fir Clear S4S KD',
    },
    '2" x 6"': {
      '#1 S4S Dry': 'Douglas Fir #1 S4S Dry',
      '#2 S4S': 'Douglas Fir #2 S4S',
      'Clear S4S KD': 'Douglas Fir Clear S4S KD',
    },
    '2" x 8"': {
      '#1 S4S Dry': 'Douglas Fir #1 S4S Dry',
      '#2 S4S': 'Douglas Fir #2 S4S',
      'Clear S4S KD': 'Douglas Fir Clear S4S KD',
    },
    '2" x 10"': {
      '#1 S4S Dry': 'Douglas Fir #1 S4S Dry',
      '#2 S4S': 'Douglas Fir #2 S4S',
      'Clear S4S KD': 'Douglas Fir Clear S4S KD',
    },
    '2" x 12"': {
      '#1 S4S Dry': 'Douglas Fir #1 S4S Dry',
      '#2 S4S': 'Douglas Fir #2 S4S',
      'Clear S4S KD': 'Douglas Fir Clear S4S KD',
    },
    '3" x 4"': { '#1 S4S': 'Douglas Fir #1 S4S' },
    '3" x 6"': { '#1 S4S': 'Douglas Fir #1 S4S' },
    '3" x 8"': { '#1 S4S': 'Douglas Fir #1 S4S' },
    '3" x 12"': { '#1 S4S': 'Douglas Fir #1 S4S' },
    '4" x 4"': {
      '#1 S4S': 'Douglas Fir #1 S4S',
      '#1 S4S Dry': 'Douglas Fir #1 S4S Dry',
      'Clear S4S KD': 'Douglas Fir Clear S4S KD',
    },
    '4" x 6"': { '#1 S4S': 'Douglas Fir #1 S4S', '#1 S4S Dry': 'Douglas Fir #1 S4S Dry' },
    '4" x 8"': { '#1 S4S': 'Douglas Fir #1 S4S' },
    '4" x 10"': { '#1 S4S': 'Douglas Fir #1 S4S' },
    '4" x 12"': { '#1 S4S': 'Douglas Fir #1 S4S' },
    '6" x 6"': { '#1 S4S': 'Douglas Fir #1 S4S' },
    '6" x 8"': { '#1 S4S': 'Douglas Fir #1 S4S' },
    '6" x 10"': { '#1 S4S': 'Douglas Fir #1 S4S' },
    '6" x 12"': { '#1 S4S': 'Douglas Fir #1 S4S' },
  },
  LVL: {
    '1 3/4" x 14"': { Microlam: 'LVL Microlam' },
    '1 3/4" x 11 7/8"': { Microlam: 'LVL Microlam' },
    '1 3/4" x 7 1/4"': { Microlam: 'LVL Microlam' },
    '1 3/4" x 9 1/2"': { Microlam: 'LVL Microlam' },
  },
  Pine: {
    '1" x 4"': { '#2': 'Pine 2/COMMON S4S' },
    '1" x 6"': { '#2': 'Pine 2/COMMON S4S', '#2 T/G': 'Pine 2/COMMON T/G' },
    '1" x 8"': { '#2': 'Pine 2/COMMON S4S' },
    '1" x 10"': { '#2': 'Pine 2/COMMON S4S' },
    '1" x 12"': { '#3': 'Pine 3/COMMON S4S' },
    '3/4" x 1 5/8"': { '#2': 'Pine S4S' },
    '3/4" x 3 1/2"': { '#2': 'Pine S4S' },
    '3/4" x 5 1/2"': { '#2': 'Pine S4S' },
  },
  'Pressure Treated (DF)': {
    '2" x 4"': { '#2 Brown': 'Pressure Treated (DF) STD/BTR UC4A - Brown' },
    '2" x 6"': { '#2 Brown': 'Pressure Treated (DF) 2/BTR UC4A - Brown' },
    '2" x 8"': { '#2 Brown': 'Pressure Treated (DF) 2/BTR UC4A - Brown' },
    '2" x 10"': { '#2 Brown': 'Pressure Treated (DF) 2/BTR UC4A - Brown' },
    '2" x 12"': {
      '#1 Green': 'Pressure Treated (DF) #1 UC4B - Green Rough',
      '#2 Brown': 'Pressure Treated (DF) n/a UC4A - Rough Brown',
      'Surfaced Brown': 'Pressure Treated (DF) n/a UC4A - Surfaced Brown',
    },
    '3" x 4"': { '#2 Brown': 'Pressure Treated (DF) STD/BTR UC4A - Brown' },
    '3" x 6"': { '#2 Brown': 'Pressure Treated (DF) 2/BTR UC4A - Brown' },
    '3" x 8"': { '#2 Brown': 'Pressure Treated (DF) 2/BTR UC4A - Brown' },
    '3" x 10"': { '#2 Brown': 'Pressure Treated (DF) 2/BTR UC4A - Brown' },
    '3" x 12"': {
      '#1 Green': 'Pressure Treated (DF) #1 UC4B - Green Rough',
      '#2 Brown': 'Pressure Treated (DF) 2/BTR UC4A - Rough Brown',
    },
    '4" x 4"': {
      '#1 Green': 'Pressure Treated (DF) #1 UC4B - Green Rough',
      '#2 Brown': 'Pressure Treated (DF) STD/BTR UC4A - Brown',
    },
    '4" x 6"': {
      '#1 Green': 'Pressure Treated (DF) #1 UC4B - Green Rough',
      '#2 Brown': 'Pressure Treated (DF) 2/BTR UC4A - Brown',
    },
    '4" x 8"': { '#2 Brown': 'Pressure Treated (DF) 2/BTR UC4A - Brown' },
    '4" x 10"': { '#2 Brown': 'Pressure Treated (DF) 2/BTR UC4A - Brown' },
    '4" x 12"': {
      '#1 Green': 'Pressure Treated (DF) #1 UC4B - Green Rough',
      '#2 Brown': 'Pressure Treated (DF) 2/BTR UC4A - Brown',
    },
    '6" x 6"': {
      '#1 Brown': 'Pressure Treated (DF) #1 UC4B - Brown',
      '#1 Green': 'Pressure Treated (DF) #1 UC4B - Green Rough',
    },
    '6" x 8"': { '#1 Brown': 'Pressure Treated (DF) #1 UC4B - Brown' },
    '6" x 10"': { '#1 Brown': 'Pressure Treated (DF) #1 UC4B - Brown' },
    '6" x 12"': { '#1 Brown': 'Pressure Treated (DF) #1 UC4B - Brown' },
    '8" x 8"': {
      '#1 Brown': 'Pressure Treated (DF) #1 UC4A - Brown',
      '#1 Green': 'Pressure Treated (DF) #1 UC4B - Green Rough',
    },
  },
  'Primed MDF': {
    '1" x 4"': { ' ': 'Primed MDF S4S E2E' },
    '1" x 6"': { ' ': 'Primed MDF S4S E2E' },
    '1" x 8"': { ' ': 'Primed MDF S4S E2E' },
    '1" x 12"': { ' ': 'Primed MDF S4S E2E' },
  },
  'Primed Pine': {
    '1" x 2"': { 'Treated FJ S1S2E': 'Primed Pine Treated FJ S1S2E' },
    '1" x 3"': { S4S: 'Primed Pine S4S', 'Treated FJ S1S2E': 'Primed Pine Treated FJ S1S2E' },
    '1" x 4"': { S4S: 'Primed Pine S4S', 'Treated FJ S1S2E': 'Primed Pine Treated FJ S1S2E' },
    '1" x 6"': { S4S: 'Primed Pine S4S', 'Treated FJ S1S2E': 'Primed Pine Treated FJ S1S2E' },
    '1" x 8"': { S4S: 'Primed Pine S4S' },
    '1" x 10"': { S4S: 'Primed Pine S4S' },
    '1" x 12"': { S4S: 'Primed Pine S4S' },
    '2" x 2"': { 'Treated FJ S1S2E': 'Primed Pine Treated FJ S1S2E' },
    '2" x 4"': { 'Treated FJ S1S2E': 'Primed Pine Treated FJ S1S2E' },
    '2" x 6"': { 'Treated FJ S1S2E': 'Primed Pine Treated FJ S1S2E' },
    '2" x 8"': { 'Treated FJ S1S2E': 'Primed Pine Treated FJ S1S2E' },
    '2" x 10"': { 'Treated FJ S1S2E': 'Primed Pine Treated FJ S1S2E' },
    '2" x 12"': { 'Treated FJ S1S2E': 'Primed Pine Treated FJ S1S2E' },
    '5/4" x 4"': { S4S: 'Primed Pine S4S' },
    '5/4" x 6"': { S4S: 'Primed Pine S4S' },
    '5/4" x 8"': { S4S: 'Primed Pine S4S' },
  },
  Redwood: {
    '1" x 1"': { 'Clear Rough': 'Redwood Clear Rough' },
    '1" x 2"': { 'Clear Rough': 'Redwood Clear Rough', 'Clear S4S KD': 'Redwood Clear S4S KD' },
    '1" x 3"': { 'Clear S4S KD': 'Redwood Clear S4S KD' },
    '1" x 4"': {
      'Clear S1S2E Green': 'Redwood Clear S1S2E Green',
      'Clear S4S KD': 'Redwood Clear S4S KD',
      'Con. Heart Rough': 'Redwood Con. Heart Rough',
    },
    '1" x 6"': {
      'Clear S1S2E Green': 'Redwood Clear S1S2E Green',
      'Clear S4S KD': 'Redwood Clear S4S KD',
      'Con. Heart Rough': 'Redwood Con. Heart Rough',
    },
    '1" x 8"': {
      'Clear S1S2E Green': 'Redwood Clear S1S2E Green',
      'Clear S4S KD': 'Redwood Clear S4S KD',
      'Con. Heart Rough': 'Redwood Con. Heart Rough',
    },
    '1" x 10"': { 'Clear S4S KD': 'Redwood Clear S4S KD' },
    '1" x 12"': {
      'Clear S1S2E Green': 'Redwood Clear S1S2E Green',
      'Clear S4S KD': 'Redwood Clear S4S KD',
      'Con. Heart Rough': 'Redwood Con. Heart Rough',
    },
    '2" x 2"': {
      'Clear S4S Green': 'Redwood Clear S4S Green',
      'Clear S4S KD': 'Redwood Clear S4S KD',
      'Clear Rough': 'Redwood Clear Rough',
    },
    '2" x 4"': {
      'Clear S4S Green': 'Redwood Clear S4S Green',
      'Clear S4S KD': 'Redwood Clear S4S KD',
      'Con. Heart S4S': 'Redwood Con. Heart S4S',
      'Con. Heart Rough': 'Redwood Con. Heart Rough',
    },
    '2" x 6"': {
      'Clear S4S Green': 'Redwood Clear S4S Green',
      'Clear S4S KD': 'Redwood Clear S4S KD',
      'Con. Heart Rough': 'Redwood Con. Heart Rough',
      'Selected Heart': 'Redwood Selected Heart',
    },
    '2" x 8"': {
      'Clear S1S2E Green': 'Redwood Clear S1S2E Green',
      'Clear S4S KD': 'Redwood Clear S4S KD',
      'Con. Heart Rough': 'Redwood Con. Heart Rough',
      'Selected Heart S4S': 'Redwood Selected Heart S4S',
    },
    '2" x 12"': {
      'Clear S1S2E Green': 'Redwood Clear S1S2E Green',
      'Clear S4S KD': 'Redwood Clear S4S KD',
      'Con. Heart Rough': 'Redwood Con. Heart Rough',
      'Selected Heart S4S': 'Redwood Selected Heart S4S',
    },
    '3" x 12"': { 'Con. Heart Rough': 'Redwood Con. Heart Rough' },
    '4" x 4"': {
      'Clear S4S Green': 'Redwood Clear S4S Green',
      'Con. Heart Rough': 'Redwood Con. Heart Rough',
      'Selected Heart S4S': 'Redwood Selected Heart S4S',
    },
    '4" x 6"': { 'Con. Heart Rough': 'Redwood Con. Heart Rough' },
    '4" x 12"': { 'Con. Heart Rough': 'Redwood Con. Heart Rough' },
    '6" x 6"': { 'Con. Heart Rough': 'Redwood Con. Heart Rough' },
  },
  'Western Red Cedar': {
    '1" x 4"': {
      Clear: 'Western Red Cedar Clear S1S2E',
      'Clear - T/G': 'Western Red Cedar Clear T/G S1S EE R/S1S Siding',
      'STK - KD': 'Western Red Cedar STK S1S2E KD',
    },
    '1" x 6"': {
      Clear: 'Western Red Cedar Clear S1S2E',
      'Clear - T/G': 'Western Red Cedar Clear T/G S1S EE R/S1S Siding',
      'STK - KD': 'Western Red Cedar STK S1S2E KD',
    },
    '1" x 8"': { Clear: 'Western Red Cedar Clear S1S2E', 'STK - KD': 'Western Red Cedar STK S1S2E KD' },
    '1" x 12"': { Clear: 'Western Red Cedar Clear S1S2E', 'STK - KD': 'Western Red Cedar STK S1S2E KD' },
    '2" x 2"': { Clear: 'Western Red Cedar Clear S4S' },
    '2" x 4"': { Clear: 'Western Red Cedar Clear S4S', STK: 'Western Red Cedar STK S4S' },
    '2" x 6"': { Clear: 'Western Red Cedar Clear S4S', STK: 'Western Red Cedar STK S4S' },
    '2" x 8"': { Clear: 'Western Red Cedar Clear S1S2E', 'STK - KD': 'Western Red Cedar STK S1S2E KD' },
    '2" x 10"': { Clear: 'Western Red Cedar Clear S1S2E', 'STK - KD': 'Western Red Cedar STK S1S2E KD' },
    '2" x 12"': { Clear: 'Western Red Cedar Clear S1S2E', 'STK - KD': 'Western Red Cedar STK S1S2E KD' },
    '4" x 4"': { Clear: 'Western Red Cedar Clear S4S', STK: 'Western Red Cedar STK S4S' },
    '4" x 6"': { Clear: 'Western Red Cedar Clear Rough' },
    '4" x 8"': { Clear: 'Western Red Cedar Clear Rough' },
    '4" x 10"': { Clear: 'Western Red Cedar Clear Rough' },
    '4" x 12"': { Clear: 'Western Red Cedar Clear Rough' },
    '6" x 6"': { Clear: 'Western Red Cedar Clear Rough' },
    '6" x 8"': { Clear: 'Western Red Cedar Clear Rough' },
    '6" x 10"': { Clear: 'Western Red Cedar Clear Rough' },
    '6" x 12"': { Clear: 'Western Red Cedar Clear Rough' },
  },
}

export const formatValues = (values: DimensionalFormValues) => {
  const displayedGrade =
    _.get(gradeDisplayConfig, [values.type, values.size, values.grade]) || `${values.type} ${values.grade || ''}`
  return `${values.size || ''}${values.length ? ` x ${values.length}` : ''} ${displayedGrade}`.trim()
}

export default DimensionalConfig
