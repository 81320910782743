import React, { ComponentProps, ReactNode } from 'react'
import styled from 'styled-components'
import { FieldProps } from 'formik'
import { IonCheckbox, IonLabel, IonRow } from '@ionic/react'
import Checkbox from '@supplyhound/components/common/Checkbox'

interface Props extends ComponentProps<typeof IonCheckbox> {
  label: ReactNode
  slot?: string
}

const StyledLabel = styled(IonLabel)`
  font-size: 14px;
  font-weight: 500;
  color: #616161;
  line-height: 17px;
  width: 85%;
  margin-left: 12px;
`

const CheckboxField: React.FC<FieldProps & Props> = ({
  field: { name, value },
  form,
  label,
  slot = 'start',
  className,
  ...props
}) => {
  return (
    <>
      <IonRow className={className}>
        <Checkbox
          checked={value}
          onIonBlur={() => form.setFieldTouched(name)}
          onIonChange={e => {
            form.setFieldValue(name, e.detail.checked!)
          }}
          {...props}
        />
        <StyledLabel>{label}</StyledLabel>
      </IonRow>
    </>
  )
}

export default CheckboxField
