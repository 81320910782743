import React from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, useLocation } from 'react-router-dom'
import get from 'lodash/get'

import useStores from 'hooks/useStores'

const Unauthenticated: React.FC = ({ children }) => {
  const location = useLocation()
  const { authStore } = useStores()
  const isLoggedIn = authStore.getIsLoggedIn

  if (isLoggedIn) {
    return <Redirect to={get(location, ['state', 'from'], '/')} />
  }

  return <>{children}</>
}

export default observer(Unauthenticated)
