import React, { useEffect, useState } from 'react'
import { IonGrid, IonRow, IonCol } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Conversation } from 'types/conversations'
import { Task } from 'types/tasks'
import Card from '@supplyhound/components/common/Card'
import Spacer from '@supplyhound/components/common/Spacer'
import { useLoadingState } from '@supplyhound/hooks'
import useStores from 'hooks/useStores'
import { useParams } from 'react-router-dom'

import Chat from 'components/Chat'
import TopPageNavigation from 'containers/Layout/TopPageNavigation'

import { DASHBOARD_PATH, TASKS_PATH } from 'config/paths'
import { formatPhoneNumber } from 'helpers/phone'
import { formatDateTime } from 'helpers/datetimes'

const Main = styled.div`
  display: flex;
`

const Side = styled.div`
  width: 361px;
`

const DetailsCard = styled(Card)`
  padding: 20px;
`

const ChatCard = styled(Card)`
  display: flex;
  padding: 20px;
  width: 861px;
`

const ChatBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 700px;
`

const ChatDetail = styled.div`
  flex: 0 0 100px;
`

const StyledChat = styled(Chat)`
  .str-chat {
    height: 600px;
  }
`

const DetailRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const DetailRowValue = styled.span`
  width: 250px;
  text-align: right;
`

const DetailRow = ({ label, value = '' }: { label: string; value?: string }) => {
  return (
    <DetailRowContainer>
      <span>{label}</span>
      <DetailRowValue>{value}</DetailRowValue>
    </DetailRowContainer>
  )
}

const Grid = styled(IonGrid)`
  border: 1px solid var(--ion-border-color);
  border-radius: var(--ion-border-radius);
`

const Row = styled(IonRow)`
  border-bottom: 1px solid var(--ion-border-color);
  background-color: var(--ion-background-color);

  &:nth-of-type(odd) {
    background-color: #ffffff;
  }

  &:first-child {
    border-top-left-radius: var(--ion-border-radius);
    border-top-right-radius: var(--ion-border-radius);
  }

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: var(--ion-border-radius);
    border-bottom-right-radius: var(--ion-border-radius);
  }
`

const Col = styled(IonCol)`
  border-right: 1px solid var(--ion-border-color);

  &:last-child {
    border-right: none;
  }
`

const ConversationPage = () => {
  const { conversationId } = useParams<{
    conversationId: string
  }>()
  const [conversation, setConversation] = useState<Conversation | null>(null)
  const [recentTasks, setRecentTasks] = useState<Task[]>([])
  const { loadWhile } = useLoadingState()
  const { conversationStore, authStore, taskStore } = useStores()
  const user = authStore.user
  const [chatEnabled, setChatEnabled] = useState<boolean>(false)

  useEffect(() => setChatEnabled(!!conversation && !!user?.stream_chat_token), [user, conversation])

  useEffect(() => {
    loadWhile(async () => {
      const conversation = await conversationStore.dispatchFetchConversation(parseInt(conversationId))
      setConversation(conversation)

      const tasks = await taskStore.dispatchFetchRecentTasks(conversation.orderer!.phone, 10)
      setRecentTasks(tasks)
    })
  }, [conversationId])

  if (conversation === null || !chatEnabled) {
    return null
  }

  return (
    <div>
      <TopPageNavigation backButtonLink={DASHBOARD_PATH} backButtonText="Dashboard" />
      <Main>
        <ChatCard>
          <ChatDetail>
            <strong>Chat #{conversationId}</strong>
          </ChatDetail>
          <ChatBox>
            <StyledChat conversation={conversation!} fullInputMessage />
            <Spacer height={130} />
          </ChatBox>
        </ChatCard>
        <Spacer width={20} />
        <Side>
          <DetailsCard>
            <h2>Contact</h2>
            <DetailRow label="Name" value={`${conversation.orderer?.first_name} ${conversation.orderer?.last_name}`} />
            <DetailRow label="Company" value={conversation.orderer?.company_name} />
            <DetailRow label="Phone" value={formatPhoneNumber(conversation.orderer?.phone)} />
            <DetailRow label="Email" value={conversation.orderer?.email} />
          </DetailsCard>
          <DetailsCard>
            <h2>Order History</h2>
            <Grid>
              <Row>
                <Col size="6">Order #</Col>
                <Col size="4">Date</Col>
              </Row>
              {recentTasks.map(task => {
                return (
                  <Row key={task.id}>
                    <Col size="6">
                      <a href={`${TASKS_PATH}/${task.id}?chat=open`}>{task.id}</a>
                    </Col>
                    <Col size="4">{formatDateTime(task.created_at, 'M/dd/yy')}</Col>
                  </Row>
                )
              })}
            </Grid>
          </DetailsCard>
        </Side>
      </Main>
    </div>
  )
}

export default observer(ConversationPage)
