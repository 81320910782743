import { AxiosError } from 'axios'
import { FormikErrors } from 'formik'

type CatchAndSetErrors = (submit: () => Promise<any>, setErrors: (errors: FormikErrors<any>) => void) => any

const catchAndSetErrors: CatchAndSetErrors = (submit, setErrors) => {
  return submit().catch((e: AxiosError) => {
    if (!(e.response && e.response.data)) throw e

    setErrors(e.response.data)
  })
}

type CatchErrors = (submit: () => Promise<any>, callbackFn: (data: any) => void) => any

const catchErrors: CatchErrors = (submit, callbackFn) => {
  return submit().catch((e: AxiosError) => {
    if (!(e.response && e.response.data)) throw e

    callbackFn(e.response.data)
  })
}

export { catchAndSetErrors, catchErrors }
