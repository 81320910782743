import React, { ComponentProps, ReactElement } from 'react'
import { FieldProps, getIn } from 'formik'
import { IonItemGroup, IonTextarea, TextareaChangeEventDetail } from '@ionic/react'

import FieldHeader from './FieldHeader'
import { withInputBorder } from './InputBorder'
import styled from 'styled-components'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'

interface Props extends ComponentProps<typeof IonTextarea> {
  label: string
  extraContent?: ReactElement
  extraHeader?: ReactElement
  disableNewLine?: boolean
}

const StyledIonTextarea = withInputBorder(styled(IonTextarea)<{ isError: boolean }>`
  font-weight: 400;
  font-size: 18px;
  caret-color: black;
  padding: 5px 10px 5px 10px;
  background-color: var(--greyscale-1);
  &:focus-within ~ .extraContent {
    border: 1px dashed ${props => (props.isError ? 'var(--ion-color-danger)' : 'var(--greyscale-10)')};
  }
  & ~ .extraContent {
    border: 1px dashed ${props => (props.isError ? 'var(--ion-color-danger)' : 'var(--greyscale-3)')};
  }
  --placeholder-opacity: 0.7;
`)

const TextAreaField: React.FC<FieldProps & Props> = ({
  field: { name, value },
  form,
  label,
  extraContent,
  extraHeader,
  disableNewLine,
  ...props
}) => {
  const error = getIn(form.errors, name)
  const touched = getIn(form.touched, name)

  return (
    <IonItemGroup>
      <div>
        <FieldHeader label={label} />
        <StyledIonTextarea
          {...props}
          value={value}
          onIonBlur={() => form.setFieldTouched(name)}
          onIonChange={(e: CustomEvent<TextareaChangeEventDetail>) => {
            let newVal = e.detail.value!
            if (disableNewLine) {
              newVal = newVal.replace(/[\r\n\v]+/g, '')
            }
            form.setFieldValue(name, newVal)
          }}
          isError={error && touched}
        />
        {extraContent}
        {error && touched ? <ErrorLabel>{error}</ErrorLabel> : null}
      </div>
    </IonItemGroup>
  )
}

export default TextAreaField
