import React from 'react'
import styled from 'styled-components'
import { IonChip, IonLabel } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'

const StyledChip = styled(IonChip)<{ isSelected: boolean }>`
  width: max-content;
  height: inherit;
  padding: 11px 22px;
  font-size: 1rem;
  border: 1px solid var(--ion-border-color);
  border-radius: 100px;
  color: var(--greyscale-6);
  font-weight: 500;
  line-height: 1rem;
  ${props =>
    props.isSelected
      ? `background-color: var(--ion-color-dark); 
color: var(--ion-color-dark-contrast);
border: 1px solid var(--greyscale-6);
ion-app.dark & {
  border: 1px solid var(--ion-border-color);
}`
      : ''}
`

const StyledIcon = styled(Icon)`
  margin: 0px 5px 0px 0px;
`

interface Props {
  isSelected: boolean
  isDisabled?: boolean
  label: string
  icon?: string
  onClick: () => void
}

const Pill: React.FC<Props> = ({ isSelected, isDisabled = false, label, icon, onClick }) => (
  <div>
    <StyledChip outline isSelected={isSelected} onClick={isDisabled ? undefined : onClick} disabled={isDisabled}>
      {icon && <StyledIcon icon={icon} />}
      <IonLabel>{label}</IonLabel>
    </StyledChip>
  </div>
)

export default Pill
