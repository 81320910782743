import styled from 'styled-components'

const HorizontalRule = styled.hr`
  width: 100%;
  height: 0px;
  border-top: 1px solid var(--ion-border-color);
  margin: 0px;
`

export default HorizontalRule
