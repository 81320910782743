import { axiosInstance } from './axios'

export const createPasswordReset = (email: string, updatePath: string) =>
  axiosInstance.post('/v1/password_resets', {
    password_reset: { email, update_path: updatePath },
  })

export const updatePasswordReset = (email: string, password: string, token: string) =>
  axiosInstance
    .patch(`/v1/password_resets/${token}`, {
      password_reset: { email, password },
    })
    .then(({ data }) => data.attributes)
