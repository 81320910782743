import React from 'react'
import styled from 'styled-components'
import {
  Task,
  CommonTaskStatuses,
  DeliveryTaskStatuses,
  DeliveryTaskStatus,
  PickupTaskStatuses,
  TaskTypes,
} from 'types/tasks'
import { TaskStatuses } from 'config/taskStatuses'
import { useIonModal } from '@ionic/react'
import useStores from 'hooks/useStores'
import DeleteTaskModal from 'components/modals/DeleteTaskModal'

const statuses = TaskStatuses

const Badge = styled.div<{ color: string; background: string }>`
  width: 90%;
  display: flex;
  justify-content: center;
  border-radius: var(--ion-border-radius);
  padding: 5px 10px;
  background-color: rgba(${({ background }) => background}, 0.4);
  color: ${({ color }) => color};
`

const StatusSelect = styled.select<{ value: string }>`
  border: none;
  padding: 5px 10px;
  border-radius: var(--ion-border-radius);
  width: 175px;
  ${({ value }) => {
    switch (value) {
      case CommonTaskStatuses.New:
      case DeliveryTaskStatuses.Delivered:
        return `color: var(--ion-color-success); background-color: rgba(var(--ion-color-success-rgb), 0.4);`
      case CommonTaskStatuses.UnderReview:
      case CommonTaskStatuses.ChangeRequested:
      case CommonTaskStatuses.Canceled:
      case PickupTaskStatuses.Restocked:
        return `color: var(--ion-color-warning); background-color: rgba(var(--ion-color-warning-rgb), 0.4);`
      case CommonTaskStatuses.AwaitingReceipt:
      case CommonTaskStatuses.ReceivedReceipt:
      case CommonTaskStatuses.Confirmed:
      case CommonTaskStatuses.PickedUp:
        return `color: #3880ff; background-color: rgba(56,128,255, 0.4);`
      default:
        return ''
    }
  }};
`

const StatusCol = ({ task, isAdmin, refreshTable }: { task: Task; isAdmin: boolean; refreshTable: () => void }) => {
  let badge

  const { taskStore } = useStores()
  const [presentDeleteTaskModal, dismissDeleteTaskModal] = useIonModal(DeleteTaskModal, {
    dismiss: () => dismissDeleteTaskModal(),
    task: task,
    deleteTask: async () => {
      await taskStore.dispatchDeleteTask(task.id)
      await refreshTable()
      dismissDeleteTaskModal()
    },
  })

  if (isAdmin) {
    return (
      <div
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <StatusSelect
          value={task.status}
          onChange={async e => {
            const newStatus = e.target.value
            if (newStatus === 'delete') {
              presentDeleteTaskModal()
              return
            }
            if (task.type === TaskTypes.Delivery) {
              await taskStore.dispatchOverrideDeliveryStatus(task.id, newStatus as DeliveryTaskStatus)
            } else if (task.type === TaskTypes.Pickup) {
              await taskStore.dispatchOveridePickupStatus(task.id, newStatus)
            }
            await refreshTable()
          }}
        >
          <option value={DeliveryTaskStatuses.Delivered}>Delivered</option>
          <option value={CommonTaskStatuses.New}>New</option>
          <option value={CommonTaskStatuses.UnderReview}>Under Review</option>
          <option value={CommonTaskStatuses.ChangeRequested}>Change Requested</option>
          <option value={CommonTaskStatuses.AwaitingReceipt}>Awaiting Reciept</option>
          <option value={CommonTaskStatuses.ReceivedReceipt}>Recieved Reciept</option>
          <option value={CommonTaskStatuses.Confirmed}>Confirmed</option>
          <option value={CommonTaskStatuses.PickedUp}>Picked up</option>
          <option value={CommonTaskStatuses.Canceled}>Cancelled</option>
          <option value={'delete'}>Delete</option>
        </StatusSelect>
      </div>
    )
  }

  switch (task.status) {
    case CommonTaskStatuses.New:
    case DeliveryTaskStatuses.Delivered:
      badge = (
        <Badge color="var(--ion-color-success)" background="var(--ion-color-success-rgb)">
          {statuses[task.status]}
        </Badge>
      )
      break
    case CommonTaskStatuses.UnderReview:
    case CommonTaskStatuses.ChangeRequested:
    case CommonTaskStatuses.Canceled:
    case PickupTaskStatuses.Restocked:
      badge = (
        <Badge color="var(--ion-color-warning)" background="var(--ion-color-warning-rgb)">
          {statuses[task.status]}
        </Badge>
      )
      break
    case CommonTaskStatuses.AwaitingReceipt:
    case CommonTaskStatuses.ReceivedReceipt:
    case CommonTaskStatuses.Confirmed:
    case CommonTaskStatuses.PickedUp:
      badge = (
        <Badge color="#3880ff" background="56,128,255">
          {statuses[task.status]}
        </Badge>
      )
      break
    default:
      badge = task.status
  }

  return <>{badge}</>
}

export default StatusCol
