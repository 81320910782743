import React from 'react'
import { observer } from 'mobx-react-lite'
import { GlobalLoading } from '@supplyhound/layout'
import { SessionExpiredModal } from '@supplyhound/modals'

const ScreenConstraint: React.FC = ({ children }) => {
  return (
    <div className="screen-constraint">
      <GlobalLoading />
      <SessionExpiredModal />

      {children}
    </div>
  )
}

export default observer(ScreenConstraint)
