import { Conversation } from './conversations'

// Base enums

export const CommonTaskStatuses = {
  New: 'new',
  UnderReview: 'under_review',
  ChangeRequested: 'change_requested',
  AwaitingReceipt: 'awaiting_receipt',
  ReceivedReceipt: 'received_receipt',
  Confirmed: 'confirmed',
  PickedUp: 'picked_up',
  Canceled: 'canceled',
} as const

export const CommonTaskEvents = {
  Placed: 'placed',
  New: 'new',
  StartedReview: 'started_review',
  ChangeRequested: 'change_requested',
  Accepted: 'accepted',
  ReceivedReceipt: 'received_receipt',
  Confirmed: 'confirmed',
  PickedUp: 'picked_up',
  Canceled: 'canceled',
} as const

export const CommonTaskStatusStepEvents = {
  Accept: 'accept',
  Review: 'review',
  Reject: 'reject',
  RequestChange: 'request_change',
  AdminRequestChange: 'admin_request_change',
  ReceiveReceipt: 'receive_receipt',
  Confirm: 'confirm',
} as const

export const SupplierDeliveryTypes = {
  anytime: 'Anytime',
  '9am_to_12pm': '9am to 12pm',
  afternoon: 'Afternoon',
} as const

// Extended enums

export const DeliveryTaskStatuses = {
  Delivered: 'delivered',
  ...CommonTaskStatuses,
} as const

export const PickupTaskStatuses = {
  Restocked: 'restocked',
  ...CommonTaskStatuses,
}

export const DeliveryTaskEvents = {
  Delivered: 'delivered',
  ...CommonTaskEvents,
} as const
export const PickupTaskEvents = {
  Restocked: 'restocked',
  ...CommonTaskEvents,
} as const

export const DeliveryTaskStatusStepEvents = {
  ...CommonTaskStatusStepEvents,
} as const
export const PickupTaskStatusStepEvents = {
  ConfirmPickup: 'confirm_pickup',
  Restock: 'restock',
  ...CommonTaskStatusStepEvents,
} as const

// Types of the enums

type toEnumType<EnumType> = EnumType[keyof EnumType]

export type CommonTaskStatus = toEnumType<typeof CommonTaskStatuses>
export type DeliveryTaskStatus = toEnumType<typeof DeliveryTaskStatuses>
export type PickupTaskStatus = toEnumType<typeof PickupTaskStatuses>
export type TaskStatus = DeliveryTaskStatus | PickupTaskStatus

export type CommonTaskEvent = toEnumType<typeof CommonTaskEvents>
export type DeliveryTaskEvent = toEnumType<typeof DeliveryTaskEvents>
export type PickupTaskEvent = toEnumType<typeof PickupTaskEvents>
export type TaskEvent = DeliveryTaskEvent | PickupTaskEvent

export type CommonTaskStatusStepEvent = toEnumType<typeof CommonTaskStatusStepEvents>
export type DeliveryTaskStatusStepEvent = toEnumType<typeof DeliveryTaskStatusStepEvents>
export type PickupTaskStatusStepEvent = toEnumType<typeof PickupTaskStatusStepEvents>
export type TaskStatusStepEvent = DeliveryTaskStatusStepEvent | PickupTaskStatusStepEvent
export type SupplierDeliveryTypesType = keyof typeof SupplierDeliveryTypes

// & etc...

type StateLog<StatusType, EventType> = {
  event: EventType
  created_at: string
  id: number
  note: string
  from_state: StatusType | ''
  to_state: StatusType
}

export type DeliveryTaskStateLog = StateLog<DeliveryTaskEvent, DeliveryTaskStatus>
export type PickupTaskStateLog = StateLog<PickupTaskEvent, PickupTaskStatus>
export type TaskStateLog = DeliveryTaskStateLog | PickupTaskStateLog

export interface Orderer {
  id: number
  accounting_email: string
  company_name: string
  email: string
  first_name: string
  last_name: string
  name: string
  phone: string
  rol: string
}

export interface TaskItemImage {
  name: string
  url: string
  thumbnail_url?: string
}

export interface TaskItem {
  id: number
  description: string
  quantity: string
  image: TaskItemImage
}

export enum TaskTypes {
  Delivery = 'DeliveryTask',
  Pickup = 'PickupTask',
}

export type TaskType = 'DeliveryTask' | 'PickupTask'

export interface AbstractTask {
  created_at: string
  id: number
  items: TaskItem[]
  job_site_name: string
  orderer: Orderer
  pickup_address: string
  pickup_note: string | null
  pickup_datetime: string
  store_name: string
  supplier_id: number | null
  conversation?: Conversation
  type: TaskType
  vehicle_type: string
  ready_by: string
  order_no?: string
}

export interface DeliveryTask extends AbstractTask {
  delivery_address: string
  delivery_datetime: string
  delivery_note: string | null
  delivery_phone: string | null
  delivery_username: string | null
  delivery_type: string
  order_no: string | null
  vehicle_type: string
  status: DeliveryTaskStatus
  state_logs: DeliveryTaskStateLog[]
  type: TaskTypes.Delivery
  ready_by: string
  pickup_name?: string
  pickup_phone?: string
  delivery_network?: string
  network_status?: string
  quote_code?: string
  quote_id?: string
}

export interface PickupTask extends AbstractTask {
  pickup_type: string
  status: PickupTaskStatus
  state_logs: PickupTaskStateLog[]
  type: TaskTypes.Pickup
}

export type Task = DeliveryTask | PickupTask

export interface TasksChannelResponse {
  task: Task
  event: string
}

export enum TaskChatStates {
  Open = 'open',
  Closed = 'closed',
}

export type TaskDetailQuery = {
  chat?: TaskChatStates
}

export interface TaskRouteParams {
  taskId: string
}

export type HistoryItem = {
  id: number
  type: string
  description: string
  creation_datetime: string
  label_description: string
}

export interface OrderHistorytask extends DeliveryTask {
  task_history: {
    pickup: HistoryItem[]
    delivery: HistoryItem[]
  }
}
