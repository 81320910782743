import React from 'react'
import styled from 'styled-components'
import { IonModal } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'

import { closeCircleOutline, callOutline, chatboxOutline } from 'ionicons/icons'
import HorizontalRule from '@supplyhound/components/common/HorizontalRule'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import Spacer from '@supplyhound/components/common/Spacer'
import { TeamMemberInfo } from '@supplyhound/types'

interface Props {
  showModal: boolean
  setShowModal: () => void
  teamMember: TeamMemberInfo
}

const StyledModal = styled(IonModal)`
  --width: 389px;
  --backdrop-opacity: 0.5;
  --border-color: var(--ion-border-color);
  --border-radius: var(--ion-border-radius);
  --border-style: solid;
  --background-color: var(--ion-color-secondary);
  --border-width: 1px;
  --height: auto;
`

const ButtonIcon = styled(Icon)`
  font-size: 25px;
`

const CloseIcon = styled(Icon)`
  font-size: 25px;
  margin-left: auto;
  margin-bottom: auto;
  cursor: pointer;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
`

const ButtonContainer = styled.div`
  display: flex;
  padding: 15px;
`

const CallTextButton = styled(SubmitButton)`
  width: 50vw;
`

const ContactModal: React.FC<Props> = ({ showModal, setShowModal, teamMember }) => {
  return (
    <StyledModal isOpen={showModal}>
      <Container>
        <h4>Contact {teamMember.first_name}</h4>
        <CloseIcon icon={closeCircleOutline} onClick={setShowModal} />
      </Container>
      <HorizontalRule />
      <ButtonContainer>
        <CallTextButton color="dark" size="small" onClick={() => window.open(`tel:${teamMember.phone}`, '_self')}>
          <ButtonIcon icon={callOutline} />
          <Spacer width={10} />
          Call
        </CallTextButton>
        <Spacer width={15} />
        <CallTextButton color="dark" size="small" onClick={() => window.open(`sms:${teamMember.phone}`, '_self')}>
          <ButtonIcon icon={chatboxOutline} />
          <Spacer width={10} />
          Text
        </CallTextButton>
      </ButtonContainer>
    </StyledModal>
  )
}

export default ContactModal
