import { makeAutoObservable } from 'mobx'
import { fetchSupplierIdByPlaceId } from '@supplyhound/api'
import { fetchRecentSuppliers } from '@supplyhound/api'

export class SuppliersStore {
  constructor() {
    makeAutoObservable(this)
  }

  dispatchFetchSupplierIdByPlaceId = async (placeId: string) => {
    const supplierId = await fetchSupplierIdByPlaceId(placeId)
    return supplierId
  }

  dispatchFetchRecentSuppliers = async () => {
    const recentSuppliers = await fetchRecentSuppliers()
    recentSuppliers.forEach(supplier => {
      if (!supplier.name) {
        supplier.name = supplier.address.split(',')[0]
      }
    })
    return recentSuppliers
  }
}
