import React, { useState, useCallback, useMemo } from 'react'

export type ModallyEditableItemsProps<ItemType> = {
  onSubmit: (values: any) => Promise<void>
  items: ItemType[]
}

export type ModallyEditableItemsHelpers<ItemType> = {
  handleAddItem: () => void
  handleEditItem: (itemIndex: number) => void
  handleEditItemCancel: () => void
  handleEditItemSubmit: (item: ItemType) => Promise<any>
  showModal: boolean
  itemInEdit?: ItemType
  itemIndexInEdit: number
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  setItemIndexInEdit: React.Dispatch<React.SetStateAction<number>>
}

export function useModallyEditableItems<ItemType>(
  props: ModallyEditableItemsProps<ItemType>
): ModallyEditableItemsHelpers<ItemType> {
  const { onSubmit, items } = props
  const [showModal, setShowModal] = useState<boolean>(false)
  const [itemIndexInEdit, setItemIndexInEdit] = useState<number>(-1)
  const itemInEdit = useMemo(() => items[itemIndexInEdit], [itemIndexInEdit, items])

  const handleEditItem = useCallback(
    (index: number) => {
      setItemIndexInEdit(index)
      setShowModal(true)
    },
    [setShowModal, setItemIndexInEdit]
  )

  const handleAddItem = () => handleEditItem(-1)

  const handleEditItemCancel = useCallback(() => {
    setShowModal(false)
    setItemIndexInEdit(-1)
  }, [setShowModal])

  const handleEditItemSubmit = useCallback(
    (item: ItemType) => {
      return onSubmit(item).then(result => {
        setShowModal(false)
        setItemIndexInEdit(-1)
        return result
      })
    },
    [onSubmit, setShowModal, setItemIndexInEdit]
  )

  return {
    handleAddItem,
    handleEditItem,
    handleEditItemCancel,
    handleEditItemSubmit,
    showModal,
    itemInEdit,
    itemIndexInEdit,
    setShowModal,
    setItemIndexInEdit,
  }
}

export default useModallyEditableItems
