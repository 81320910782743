import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import useStores from 'hooks/useStores'
import { LOGIN_PATH, RESET_PASSWORD_UPDATE_PATH } from 'config/paths'
import Spacer from '@supplyhound/components/common/Spacer'
import { Container, StyledCard, StyledInput, BottomLink, Error, Success, StyledButton } from './common'

const RequestPasswordReset = () => {
  const [email, setEmail] = useState('')

  const [error, setError] = useState<string>()
  const { passwordResetStore } = useStores()
  const { resetEmailSent } = passwordResetStore

  const handleSubmit = (event: React.FormEvent<HTMLIonButtonElement>) => {
    passwordResetStore
      .dispatchRequestPasswordReset(email, RESET_PASSWORD_UPDATE_PATH)
      .catch(error => setError(error.message))
    event.preventDefault()
  }

  const renderError = !!error ? <Error>{error}</Error> : null

  const renderResetEmailSent = resetEmailSent ? <Success>Check your email for a recovery link</Success> : null

  const renderFeedback =
    !!error || resetEmailSent ? (
      <>
        {renderError}
        {renderResetEmailSent}
      </>
    ) : null

  return (
    <Container>
      <Spacer height={80} />
      <StyledCard>
        <h3>Recover Password</h3>
        <Spacer height={10} />
        <StyledInput
          type="email"
          value={email}
          onIonChange={e => {
            e.detail.value && setEmail(e.detail.value)
          }}
          placeholder="email"
        />
        <Spacer height={10} />

        {renderFeedback && (
          <>
            {renderFeedback} <Spacer height={10} />
          </>
        )}
        <StyledButton onClick={e => handleSubmit(e)}>Send me a recovery link</StyledButton>
        <Spacer height={30} />

        <div>
          <a href={LOGIN_PATH}>
            <BottomLink>Back to login</BottomLink>
          </a>
        </div>
      </StyledCard>
    </Container>
  )
}

export default observer(RequestPasswordReset)
