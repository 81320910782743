import { ID } from '@supplyhound/types'
import useStores from 'hooks/useStores'
import React from 'react'
import styled from 'styled-components'
import type { ConversationStatus } from 'types/conversations'

const Badge = styled.div<{ color: string; background: string }>`
  width: 90%;
  display: flex;
  justify-content: center;
  border-radius: var(--ion-border-radius);
  padding: 5px 10px;
  background-color: rgba(${({ background }) => background}, 0.4);
  color: ${({ color }) => color};
  text-transform: capitalize;
`

const StatusSelect = styled.select<{ value: string }>`
  border: none;
  padding: 5px 10px;
  border-radius: var(--ion-border-radius);
  width: 175px;
  ${({ value }) => {
    switch (value) {
      case 'new':
        return `color: var(--ion-color-success); background-color: rgba(var(--ion-color-success-rgb), 0.4);`
      case 'replied':
        return `color: #3880ff; background-color: rgba(56, 128, 255, 0.4);`
      default:
        return ''
    }
  }};
`

const StatusCol = ({
  status,
  isAdmin,
  refreshTable,
  conversationId,
}: {
  conversationId: ID
  status: ConversationStatus
  isAdmin: boolean
  refreshTable: () => void
}) => {
  const { conversationStore } = useStores()

  if (isAdmin) {
    return (
      <div
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <StatusSelect
          value={status}
          onChange={async e => {
            const newStatus = e.target.value
            await conversationStore.dispatchOverrideConversationStatus(conversationId, newStatus as ConversationStatus)
            await refreshTable()
          }}
        >
          <option value="new">New</option>
          <option value="replied">Replied</option>
        </StatusSelect>
      </div>
    )
  }

  let badge
  switch (status) {
    case 'new':
      badge = (
        <Badge color="var(--ion-color-success)" background="var(--ion-color-success-rgb)">
          {status}
        </Badge>
      )
      break
    case 'replied':
      badge = (
        <Badge color="#3880ff" background="56,128,255">
          {status}
        </Badge>
      )
      break
    default:
      badge = status
  }

  return <>{badge}</>
}

export default StatusCol
