import React from 'react'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'

import styled from 'styled-components'
import useStores from '@supplyhound/hooks/useStores'

const FieldSection = styled.div`
  padding-bottom: 20px;
`

const FieldHeader = styled.h2`
  padding-bottom: 10px;
`

const FieldExplanation = styled.div`
  color: var(--greyscale-7);
`

const DeliveryFormHelpModal = ({
  dismiss,
  isDeliveryOnly,
  isWillCall,
}: {
  dismiss: () => void
  isDeliveryOnly?: boolean
  isWillCall?: boolean
}) => {
  const { userProfileStore } = useStores()

  const supplierDeliveryFeatureFlag = userProfileStore.profile?.feature_flags.supplier_delivery

  return (
    <BaseModal label={'Information'} onDidDismiss={() => dismiss()}>
      {!isDeliveryOnly && (
        <FieldSection>
          <FieldHeader>Delivery or Will Call</FieldHeader>
          <FieldExplanation>
            Click the box for Delivery to have your order delivered. Click the box for Will Call to schedule a time to
            pick up the order yourself.
          </FieldExplanation>
        </FieldSection>
      )}
      {!isWillCall && !isDeliveryOnly && (
        <>
          <FieldSection>
            <FieldHeader>Delivery to</FieldHeader>
            <FieldExplanation>
              This is the address where the delivery will be sent. The app will auto-fill your jobsite address as the
              delivery address, but can be edited on this page
            </FieldExplanation>
          </FieldSection>
          {!isWillCall && supplierDeliveryFeatureFlag && (
            <FieldSection>
              <FieldHeader>Delivery by</FieldHeader>
              <FieldExplanation>
                Select Supplier or SupplyHound delivery. SupplyHound is best for hot shot deliveries and Supplier is
                best for route based deliveries. Supplier delivery services will be billed directly by the supplier
                under their standard service terms.
              </FieldExplanation>
            </FieldSection>
          )}
          <FieldSection>
            <FieldHeader>Delivery date</FieldHeader>
            <FieldExplanation>
              Select the date and time you would like the delivery completed. Expedited options have additional fees
              displayed. To specify a custom date and time, select “Schedule”
            </FieldExplanation>
          </FieldSection>
          <FieldSection>
            <FieldHeader>Delivery with</FieldHeader>
            <FieldExplanation>
              Select the appropriate vehicle for your order. Pickup trucks are ideal for loads up to 1,500 lbs. Rack
              Pickups can handle material up to 24’ on the rack. Cars are for items under 50 lbs. and 4 ft in length.
              Sprinter vans offer enclosed protection, with a max weight of 2,500 lbs.
            </FieldExplanation>
          </FieldSection>
        </>
      )}

      {isDeliveryOnly && (
        <>
          <FieldSection>
            <FieldHeader>Job site</FieldHeader>
            <FieldExplanation>
              This is the job site that will be associate with this order. This association will be saved in your app
              and reported on the SupplyHound receipt.
            </FieldExplanation>
          </FieldSection>
          <FieldSection>
            <FieldHeader>Deliver to</FieldHeader>
            <FieldExplanation>
              This is the address where the delivery will be sent. The app will auto-fill your jobsite address as the
              delivery address, but can be edited on this page.
            </FieldExplanation>
          </FieldSection>
          <FieldSection>
            <FieldHeader>Delivery by</FieldHeader>
            <FieldExplanation>
              Select Supplier or SupplyHound delivery. SupplyHound is best for hot shot deliveries and Supplier is best
              for route based deliveries. Supplier delivery services will be billed directly by the supplier under their
              standard service terms.
            </FieldExplanation>
          </FieldSection>
          <FieldSection>
            <FieldHeader>Deliver when</FieldHeader>
            <FieldExplanation>
              Select the date and time you would like the delivery completed. Expedited options have additional fees
              displayed. To specify a custom date and time, select “Schedule”
            </FieldExplanation>
          </FieldSection>
          <FieldSection>
            <FieldHeader>Deliver with</FieldHeader>
            <FieldExplanation>
              Select the appropriate vehicle for your order. Pickup trucks are ideal for loads up to 1,500 lbs. Rack
              Pickups can handle material up to 24’ on the rack. Cars are for items under 50 lbs. and 4 ft in length.
              Sprinter vans offer enclosed protection, with a max weight of 2,500 lbs.
            </FieldExplanation>
          </FieldSection>
          <FieldSection>
            <FieldHeader>PO#</FieldHeader>
            <FieldExplanation>
              If you have an order for pickup, enter your purchase order number from your supplier.
            </FieldExplanation>
          </FieldSection>
        </>
      )}
      {!isDeliveryOnly && isWillCall && (
        <FieldSection>
          <FieldHeader>Pickup by</FieldHeader>
          <FieldExplanation>
            For Will Call, pickup by is the time you want the supplies ready to be picked up. We will notify you when
            your order is ready.
          </FieldExplanation>
        </FieldSection>
      )}
      <FieldSection>
        <FieldHeader>Note</FieldHeader>
        <FieldExplanation>
          Add special instructions or any information that can be helpful with your order. The note will be seen by
          SupplyHound, the driver and the supplier.
        </FieldExplanation>
      </FieldSection>
    </BaseModal>
  )
}

export default DeliveryFormHelpModal
