import React from 'react'
import Icon from '@supplyhound/components/common/Icon'

import { chevronBackOutline } from 'ionicons/icons'
import NavigationButton from './NavigationButton'
import { ButtonType } from '@supplyhound/types'

const BackButton: ButtonType = props => (
  <NavigationButton {...props} color="secondary">
    <div slot="icon-only">
      <Icon color="dark" icon={chevronBackOutline} width={30} />
    </div>
  </NavigationButton>
)

export default BackButton
