import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import styled from 'styled-components'
import OvalButton from '@supplyhound/components/buttons/OvalButton'
import Spacer from '@supplyhound/components/common/Spacer'
import { ContactModal } from '@supplyhound/modals'
import { TeamMemberInfo } from '@supplyhound/types'

interface Props {
  teamMember?: TeamMemberInfo
}
const Container = styled.div`
  height: 66px;
  display: flex;
  align-items: center;
`
const ContactButton = styled(OvalButton)`
  ::part(native) {
    text-transform: none;
    padding-top: var(--space-unit);
    padding-bottom: var(--space-unit);
    border: none;
    background-color: var(--ion-color-tertiary);
  }
  margin-left: auto;
  margin-right: calc(1.5 * var(--space-unit));
`

const NameLabel = styled.h4`
  margin-left: calc(1.5 * var(--space-unit));
`
const TeamMemberHeader: React.FC<Props> = ({ teamMember, ...props }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      {teamMember && (
        <Container>
          <NameLabel>{teamMember.first_name}</NameLabel>
          <ContactButton {...props} color="dark" fill="outline" onClick={() => setShowModal(!showModal)}>
            <Spacer width={8} />
            Contact {teamMember.first_name}
          </ContactButton>
          <ContactModal showModal={showModal} setShowModal={() => setShowModal(!showModal)} teamMember={teamMember} />
        </Container>
      )}
    </>
  )
}

export default observer(TeamMemberHeader)
