import styled from 'styled-components'

const SunkenBorder = styled.div`
  background-color: var(--greyscale-1);
  border: 1px solid var(--ion-border-color);
  box-sizing: border-box;
  border-radius: var(--ion-border-radius);
`

export default SunkenBorder
