import { Meta } from 'types/base'
import { Task } from 'types/tasks'

import { apiAxiosInstance } from '.'

interface TasksResponse {
  tasks: Task[]
  meta: Meta
}

interface TaskResponse {
  task: Task
}

export async function fetchTasks(params: any = {}): Promise<TasksResponse> {
  const response = await apiAxiosInstance.get<TasksResponse>(`/v1/tasks`, { params })

  return response.data
}

export async function fetchTask(taskId: number | string): Promise<TaskResponse> {
  const response = await apiAxiosInstance.get<TaskResponse>(`/v1/tasks/${taskId}`)

  return response.data
}

export async function deleteTask(taskId: number | string): Promise<void> {
  const response = await apiAxiosInstance.delete<void>(`/v1/tasks/${taskId}`)

  return response.data
}

export async function fetchRecentTaskHistory(userPhone: string, limit: number): Promise<Task[]> {
  const response = await apiAxiosInstance.get<{ tasks: Task[] }>(`/v1/tasks/recent`, {
    params: { phone: userPhone, limit },
  })

  return response.data.tasks
}
