import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import React from 'react'
import { IonToast } from '@ionic/react'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { TOAST_DURATION } from '@supplyhound/utils/config'

type Props = Partial<React.ComponentProps<typeof IonToast>>

const StyledToast = styled(IonToast)`
  ::part(container) {
    border: 1px solid var(--ion-color-success);
    border-radius: var(--ion-border-radius);
  }

  ::part(message) {
    font-size: 1rem;
    margin-left: 0;
  }

  ::part(icon) {
    font-size: 1.5rem;
    color: var(--ion-color-success);
  }
`

const NotificationBanner: React.FC<Props> = ({ duration, ...props }) => {
  return (
    <StyledToast {...props} isOpen icon={checkmarkCircleOutline} position="top" duration={duration || TOAST_DURATION} />
  )
}

export default observer(NotificationBanner)
