import { axiosInstance } from './axios'
import { TeamMemberProfile, AuthToken } from '@supplyhound/types'
import { AxiosRequestConfig } from 'axios'

export const updateTeamMemberProfile = (
  team_member: TeamMemberProfile,
  config: AxiosRequestConfig
): Promise<TeamMemberProfile> =>
  axiosInstance
    .patch(`/v1/team_members/${team_member.phone}`, { team_member }, config)
    .then(({ data: { team_member } }) => team_member)

export const fetchTeamMemberProfile = (config: AxiosRequestConfig): Promise<TeamMemberProfile> =>
  axiosInstance.get(`/v1/team_member_auth/profile`, config).then(({ data: { team_member } }) => team_member)

export const confirmTeamMember = (
  { phone, token }: { phone: string; token: string },
  config: AxiosRequestConfig
): Promise<AuthToken> =>
  axiosInstance
    .post(
      `/v1/team_member_auth/confirm`,
      {
        confirmation: { token },
        auth: { phone },
      },
      config
    )
    .then(({ data: { jwt } }) => jwt)

export const loginTeamMember = (phone: string, config: AxiosRequestConfig): Promise<AuthToken> =>
  axiosInstance.post(`/v1/team_member_auth/login`, { auth: { phone } }, config).then(({ data: { jwt } }) => jwt)
