import axios, { AxiosRequestConfig } from 'axios'
import jsonToFormData from 'json-form-data'

import { USER_PROFILE_TOKEN_KEY } from '@supplyhound/utils/config'
import { getItem } from '@supplyhound/utils/storage'

const transformJsonToFormData = (data: any) => jsonToFormData(data, { showLeafArrayIndexes: false })

const authorizationHeader = (token: string) => `Bearer ${token}`

const axiosInstanceFactory = (profileTokenKey: string) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Accept: 'application/json',
    },
  })

  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.headers.Authorization) {
      return config
    }

    const token = getItem(profileTokenKey)

    if (token) {
      config.headers.Authorization = authorizationHeader(token)
    }

    return config
  })

  return instance
}

const axiosInstance = axiosInstanceFactory(USER_PROFILE_TOKEN_KEY)

export { axiosInstance, transformJsonToFormData, axiosInstanceFactory, authorizationHeader }
