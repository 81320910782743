import { User } from 'types/users'

import { apiAxiosInstance } from '.'

export interface UserResponse {
  user: User
}

export async function login(email: string, password: string): Promise<Record<string, any>> {
  const url = '/login'
  const params = { auth: { email, password, type: 'dashboard' } }

  const { data } = await apiAxiosInstance.post<UserResponse>(url, params)

  return data
}

export async function createPasswordReset(email: string, updatePath: string): Promise<void> {
  const url = '/admin/password_resets'

  await apiAxiosInstance.post<void>(url, { password_reset: { email, update_path: updatePath } })
}

export async function updatePasswordReset(email: string, password: string, token: string): Promise<UserResponse> {
  const url = `/admin/password_resets/${token}`

  const response = await apiAxiosInstance.patch<UserResponse>(url, { password_reset: { email, password } })
  return response.data
}

export async function fetchProfile(): Promise<UserResponse> {
  const url = '/profile'

  const response = await apiAxiosInstance.get<UserResponse>(url)
  return response.data
}

export async function updateConfirmation(email: string, password: string, token: string): Promise<UserResponse> {
  const url = `/admin/confirmations/${token}`

  const response = await apiAxiosInstance.patch<UserResponse>(url, { confirmation: { email, password } })
  return response.data
}
