import React from 'react'
import styled from 'styled-components'
import { DeliveryTask, PickupTask, CommonTaskStatuses, TaskStateLog, TaskTypes } from 'types/tasks'
import Spacer from '@supplyhound/components/common/Spacer'
import Card from '@supplyhound/components/common/Card'
import ImagePreview from '@supplyhound/components/ImagePreview'
import moment from 'moment'
import { TIME_DATE_FORMAT } from 'config/date'
import { formatReadyByDatetime, formatDateTime } from 'helpers/datetimes'
import { formatPhoneNumber } from 'helpers/phone'
import { IonGrid, IonRow, IonCol } from '@ionic/react'

const TaskDetailRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const TaskImagePreview = styled(ImagePreview)`
  margin: 7px 0 7px 10px;
`

const Layout = styled.div`
  display: flex;
`

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 240px;
`

const OrderDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.span`
  color: var(--greyscale-6);
`

const Grid = styled(IonGrid)`
  border: 1px solid var(--ion-border-color);
  border-radius: var(--ion-border-radius);
`

const Col = styled(IonCol)`
  border-right: 1px solid var(--ion-border-color);

  &:last-child {
    border-right: none;
  }
`

const Row = styled(IonRow)`
  border-bottom: 1px solid var(--ion-border-color);
  background-color: var(--ion-background-color);

  &:nth-of-type(odd) {
    background-color: #ffffff;
  }

  &:first-child {
    border-top-left-radius: var(--ion-border-radius);
    border-top-right-radius: var(--ion-border-radius);
  }

  &:last-child {
    height: 20px;
    border-bottom: none;
    border-bottom-left-radius: var(--ion-border-radius);
    border-bottom-right-radius: var(--ion-border-radius);
  }
`

const QtyCol = styled(Col)`
  text-align: center;
`

const OrderDetail = ({ label, value }: { label: string; value: string | number | null }) => {
  return (
    <OrderDetailContainer>
      <Label>{label}</Label>
      <span>{value}</span>
      <Spacer height={15} />
    </OrderDetailContainer>
  )
}

const RightSide = styled.div`
  flex: 1 0 500px;
`

const InformationGroup = styled(Card)`
  background-color: var(--ion-background-color);
  padding: 20px;
`

const TaskDetailRow = ({ label, value }: { label: string; value: string | number | null }) => {
  return (
    <TaskDetailRowContainer>
      <Label>{label}</Label>
      {value}
    </TaskDetailRowContainer>
  )
}

const formatDeliveryType = (type: string) => {
  switch (type) {
    case 'two_hours':
      return 'Two Hours'
    case 'four_hours':
      return 'Four Hours'
    case 'same_day':
      return 'Same Day'
    case 'tomorrow_9am':
      return 'Tomorrow 9am'
    case 'tomorrow_2pm':
      return 'Tomorrow 2pm'
    case 'custom':
      return 'Scheduled'
    case 'anytime':
      return 'Anytime'
    case 'morning':
      return 'Morning'
    case 'afternoon':
      return 'Afternoon'
    case 'rush':
      return 'Rush'
    case 'tomorrow_9am_curri':
      return 'Tomorrow 9am'
    case 'tomorrow_3pm_curri':
      return 'Tomorrow 3pm'
    case 'monday_9am_curri':
      return 'Monday 9am'
    case 'monday_3pm_curri':
      return 'Monday 3pm'
    case 'scheduled':
      return 'Scheduled'
    default:
      return type
  }
}

const DeliveryTaskDetail = ({ task }: { task: DeliveryTask | PickupTask }) => {
  let readyByDate = formatReadyByDatetime(task)
  const stateLogs: TaskStateLog[] = task.state_logs
  const changeRequests = stateLogs.filter(
    stateLog => stateLog.to_state === CommonTaskStatuses.ChangeRequested || stateLog.to_state === CommonTaskStatuses.New
  )

  return (
    <Layout>
      <LeftSide>
        <h2>Order #: {task.id}</h2>
        <Spacer height={25} />
        <OrderDetail
          label={'Task Type'}
          value={task.delivery_type ? formatDeliveryType(task.delivery_type) : 'Pickup'}
        />
        {task.order_no && <OrderDetail label="Order No" value={task.order_no} />}
        <OrderDetail label={task.type === TaskTypes.Delivery ? 'Ready By' : 'Pickup Time'} value={readyByDate} />
        <OrderDetail label="Job Site" value={task.job_site_name} />
        {task.pickup_address && <OrderDetail label="Pickup Address" value={task.pickup_address} />}
        {task.pickup_datetime && (
          <OrderDetail
            label="Pickup Time"
            value={task.pickup_datetime ? formatDateTime(task.pickup_datetime) : 'N/A'}
          />
        )}
        {task.delivery_address && <OrderDetail label="Delivery Address" value={task.delivery_address} />}
        {task.delivery_datetime && <OrderDetail label="Delivery Time" value={formatDateTime(task.delivery_datetime)} />}
      </LeftSide>
      <Spacer width={50} />
      <RightSide>
        <InformationGroup>
          <h3>Contact</h3>
          <TaskDetailRow label="Name" value={task.orderer.name} />
          <TaskDetailRow label="Company" value={task.orderer.company_name} />
          <TaskDetailRow label="Phone" value={formatPhoneNumber(task.orderer.phone)} />
          <TaskDetailRow label="Email" value={task.orderer.email} />
        </InformationGroup>
        <InformationGroup>
          <h3>Notes</h3>
          {task.type === TaskTypes.Delivery ? task.delivery_note : task.pickup_note}
        </InformationGroup>
        <InformationGroup>
          <h3>{`${task.items.length} Items`}</h3>
          <Grid>
            <Row>
              <Col size="10">Item</Col>
              <QtyCol size="2">Quantity</QtyCol>
            </Row>
            {task.items.map(item => {
              return (
                <Row key={item.id}>
                  <Col size="10">
                    {item.description}
                    {item.image && (
                      <TaskImagePreview
                        height={100}
                        width={100}
                        thumbnailSrc={item.image.thumbnail_url}
                        src={item.image.url}
                      />
                    )}
                  </Col>
                  <QtyCol size="2">{item.quantity}</QtyCol>
                </Row>
              )
            })}
            <Row></Row>
          </Grid>
        </InformationGroup>
        {changeRequests.length > 0 && (
          <InformationGroup>
            <h3>Change Requests</h3>
            {changeRequests.map(({ id, created_at, note }) => {
              const createdAt = moment(created_at).format(TIME_DATE_FORMAT)
              return <TaskDetailRow key={id} label={createdAt} value={note} />
            })}
          </InformationGroup>
        )}
      </RightSide>
    </Layout>
  )
}

export default DeliveryTaskDetail
