import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { observer } from 'mobx-react-lite'

import Icon from '@supplyhound/components/common/Icon'
import { chevronForwardOutline } from 'ionicons/icons'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { AbstractItem, ID } from '@supplyhound/types'
import Checkbox from '@supplyhound/components/common/Checkbox'
import ImagePreview from './ImagePreview'
import Ellipsis from '@supplyhound/components/common/text/Ellipsis'

type CheckableAbstractItem = AbstractItem & { id: ID; selected: boolean }

interface Props<ItemType extends CheckableAbstractItem> {
  items: ItemType[]
  onItemClick?: (index: number) => any
  onCheckboxClick?: (item: ItemType, index: number) => any
  onCheckboxControlClick?: (isCheckboxOn: boolean) => any
  displayOnly?: boolean
  editedItem?: ItemType
}

const Column = styled(IonCol)`
  display: flex;
  align-items: center;
`

const BorderedRow = styled(IonRow)`
  border-top: var(--thin-light-border);
  height: 80px;
  font-size: 1.125rem;
  padding: 20px 13px;
`

const BorderedColumn = styled(Column)`
  width: 50px;
  max-width: 50px;
  min-width: 50px;
`

const ControlRow = styled(BorderedRow)`
  font-size: 0.875rem;
  color: var(--ion-color-medium);
`

const CenteredBorderedColumn = styled(BorderedColumn)`
  justify-content: center;
`

const DescriptionColumn = styled(Column)<{ $displayOnly?: boolean }>`
  justify-content: space-between;
  padding-left: calc(var(--space-unit) * 1.3);
  padding-right: calc(var(--space-unit) * 1.3);
  cursor: ${props => (props.$displayOnly ? 'auto' : 'pointer')};
  overflow-x: hidden;
`

const DescriptionHeader = styled(DescriptionColumn)`
  cursor: auto;
`

const QuantityColumn = styled(BorderedColumn)`
  padding-left: var(--space-unit);
`

const StyledImagePreview = styled(ImagePreview)`
  margin-right: var(--space-unit);
`

const DescriptionContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 90%;
`

const QuantitySpan = styled.span`
  width: 100%;
  text-align: center;
`
function CheckableItemList<ItemType extends CheckableAbstractItem>({
  items,
  onItemClick,
  onCheckboxClick,
  onCheckboxControlClick,
  displayOnly,
  editedItem,
}: Props<ItemType>) {
  const [rows, setRows] = useState(items)
  const [checkboxControl, setCheckboxControl] = useState(false)

  useEffect(() => {
    setRows(items)
  }, [items])

  // Update the list when an item is edited
  useEffect(() => {
    if (editedItem) {
      setRows(rows.map(row => (row.id === editedItem.id ? { ...editedItem, selected: row.selected } : row)))
    }
  }, [editedItem])

  return (
    <IonGrid>
      <ControlRow>
        {!displayOnly && (
          <CenteredBorderedColumn>
            <Checkbox
              checked={rows.filter(row => row.selected).length === rows.length}
              onClick={() => {
                const newValue = !checkboxControl
                setRows(
                  rows.map(row => ({
                    ...row,
                    selected: newValue,
                  }))
                )
                setCheckboxControl(newValue)
                if (onCheckboxControlClick) {
                  onCheckboxControlClick(newValue)
                }
              }}
            />
          </CenteredBorderedColumn>
        )}
        <QuantityColumn>QTY</QuantityColumn>
        <DescriptionHeader>ITEM DESCRIPTION</DescriptionHeader>
      </ControlRow>
      {rows.map((item, idx) => {
        return (
          <BorderedRow key={item.id}>
            {!displayOnly && (
              <CenteredBorderedColumn>
                <Checkbox
                  checked={item.selected}
                  onClick={() => {
                    rows[idx].selected = !rows[idx].selected
                    setRows(rows)
                    if (onCheckboxClick) {
                      onCheckboxClick(rows[idx], idx)
                    }
                  }}
                />
              </CenteredBorderedColumn>
            )}
            <QuantityColumn>
              <QuantitySpan>{item.quantity}</QuantitySpan>
            </QuantityColumn>
            <DescriptionColumn
              onClick={() => {
                if (onItemClick && !displayOnly) {
                  onItemClick(idx)
                }
              }}
              $displayOnly={displayOnly}
            >
              <DescriptionContent>
                {item.image && (
                  <StyledImagePreview
                    height={30}
                    width={30}
                    noPreview
                    thumbnailSrc={item.image.thumbnail_url || item.image.url}
                    src={item.image.url}
                  />
                )}
                <Ellipsis text={item.description}></Ellipsis>
              </DescriptionContent>
              {!displayOnly && <Icon icon={chevronForwardOutline} />}
            </DescriptionColumn>
          </BorderedRow>
        )
      })}
    </IonGrid>
  )
}

export default observer(CheckableItemList)
