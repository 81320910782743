import React from 'react'
import styled from 'styled-components'
import { Field, withFormik, FormikProps, FormikBag } from 'formik'
import * as Yup from 'yup'
import TextInputField from '@supplyhound/forms/fields/TextInputField'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'

export interface EnterNewPasswordFormValues {
  new_password: string
  confirm_new_password: string
}

interface EnterNewPasswordFormProps {
  new_password?: string
  onSubmit: (values: EnterNewPasswordFormValues) => Promise<void>
}

const ButtonTier = styled.div`
  margin-top: 20px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
`

const ResetPasswordHeader = styled.h1`
  padding-top: 20px;
  text-align: center;
`

const PasswordField = styled(Field)`
  margin-top: 20px;
`

const EnterNewPasswordForm: React.FC<EnterNewPasswordFormProps & FormikProps<EnterNewPasswordFormValues>> = ({
  submitForm,
}) => {
  return (
    <ContentWrapper>
      <ResetPasswordHeader>Reset password</ResetPasswordHeader>
      {/* HACK!! - Empty input field for browser to autofocus on instead of the email field.
        Without this empty input, the first field will auto validate and display validation
        error message on load. 
        */}
      <input type="text" style={{ display: 'none' }} />
      {/* End Hack */}
      <PasswordField
        label="New password"
        name="new_password"
        placeholder="new password"
        component={TextInputField}
        type="password"
      />
      <PasswordField
        label="Confirm new password"
        name="confirm_new_password"
        placeholder="confirm new password"
        component={TextInputField}
        type="password"
      />
      <ButtonTier>
        <SubmitButton color="dark" onClick={submitForm}>
          Reset password
        </SubmitButton>
      </ButtonTier>
    </ContentWrapper>
  )
}

export default withFormik<EnterNewPasswordFormProps, EnterNewPasswordFormValues>({
  displayName: 'EnterNewPasswordForm',
  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    new_password: Yup.string().min(6, 'Minimum 6 characters required').required('Required'),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref('new_password'), ''], "Passwords don't match")
      .required('Required'),
  }),

  mapPropsToValues(): EnterNewPasswordFormValues {
    return { new_password: '', confirm_new_password: '' }
  },

  handleSubmit(
    values: EnterNewPasswordFormValues,
    { props: { onSubmit } }: FormikBag<EnterNewPasswordFormProps, EnterNewPasswordFormValues>
  ) {
    onSubmit(values)
  },
})(EnterNewPasswordForm)
