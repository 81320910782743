import React from 'react'
import { ForwardProps } from '@supplyhound/types'
import { IonButton } from '@ionic/react'
import styled from 'styled-components'

type Props = ForwardProps<typeof IonButton> & {
  contentTag?: string
}

const StyledIonButton = styled(IonButton)`
  --border-radius: var(--ion-border-radius);
  font-size: 1.25rem;
  margin: 0;
`

const BaseButton: React.FC<Props> = ({ children, ...props }) => (
  <StyledIonButton size="large" expand="block" {...props}>
    {children}
  </StyledIonButton>
)

export default BaseButton
