import React, { useState } from 'react'
import Logo from '@supplyhound/images/sh-logo-icon.svg'
import Icon from '@supplyhound/components/common/Icon'
import Spacer from '@supplyhound/components/common/Spacer'
import { useStores } from 'hooks/useStores'
import { RESET_PASSWORD_CREATE_PATH } from 'config/paths'

import { Container, StyledCard, StyledInput, StyledButton, Error, BottomLink } from './common'

const Login = () => {
  const { authStore } = useStores()
  const [error, setError] = useState()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = (event: React.FormEvent<HTMLIonButtonElement>) => {
    event.preventDefault()

    authStore.dispatchLogin(email, password).catch(error => {
      setError(error.message)
    })
  }
  const renderError = error ? <div>{error}</div> : null

  return (
    <Container>
      <Spacer height={80} />
      <StyledCard>
        <Icon icon={Logo} width={200} />

        <h2>Welcome!</h2>
        <h3>Sign in to view your orders</h3>
        <Spacer height={10} />

        <StyledInput
          placeholder="email"
          type="email"
          value={email}
          onIonChange={e => {
            e.detail.value && setEmail(e.detail.value)
          }}
        />
        <StyledInput
          placeholder="password"
          type="password"
          value={password}
          onIonChange={e => e.detail.value && setPassword(e.detail.value)}
        />
        <Spacer height={10} />
        {renderError && (
          <>
            <Error>{renderError}</Error>
            <Spacer height={10} />
          </>
        )}

        <StyledButton
          onClick={event => {
            handleSubmit(event)
          }}
        >
          Login
        </StyledButton>
        <Spacer height={30} />

        <div>
          <a href={RESET_PASSWORD_CREATE_PATH}>
            <BottomLink>Forgot Password?</BottomLink>
          </a>
        </div>
      </StyledCard>
    </Container>
  )
}

export default Login
