import { axiosInstance } from './axios'
import { ID, User, UserProfile, BasicUser } from '@supplyhound/types'
import { AxiosResponse } from 'axios'

export const signUp = (payload: User) => axiosInstance.post('/signup', payload)
export const acceptInvite = (payload: { email: string; invite_token: string }) =>
  axiosInstance.post('/v1/accept_invite', payload)

export const logIn = (user: Pick<User, 'email' | 'password' | 'supplier_id'>) =>
  axiosInstance.post('/login', { auth: { ...user, type: 'orderer' } })

export const fetchProfile = () => axiosInstance.get('/profile')

export const dispatchUserUpdate = (
  id: ID,
  payload: BasicUser | Pick<UserProfile, 'accounting_emails'>
): Promise<AxiosResponse<{ user: BasicUser }>> => axiosInstance.patch(`/v1/users/${id}`, payload)

export const destroyUser = (id: ID): Promise<AxiosResponse> => axiosInstance.delete(`/v1/users/${id}`)
