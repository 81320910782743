import React from 'react'
import styled from 'styled-components'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'
import Button from '@supplyhound/components/buttons/BaseButton'
import Spacer from '@supplyhound/components/common/Spacer'
import { Task } from 'types/tasks'

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  padding: 20px;
  text-align: center;
`

const StyledButton = styled(Button)`
  width: 200px;
`

const DeleteTaskModal = ({
  task,
  dismiss,
  deleteTask,
}: {
  dismiss: () => void
  task: Task
  deleteTask: () => void
}) => (
  <BaseModal onDidDismiss={dismiss}>
    <Content>
      <h2>Delete task #{task.id}?</h2>
      <Spacer height={20} />
      <StyledButtonGroup>
        <StyledButton color="tertiary" onClick={deleteTask}>
          Delete
        </StyledButton>
        <Spacer width={20} />
        <StyledButton onClick={dismiss}>Cancel</StyledButton>
      </StyledButtonGroup>
    </Content>
  </BaseModal>
)

export default DeleteTaskModal
