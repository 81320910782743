import React, { useState } from 'react'
import styled from 'styled-components'
import { IonIcon, IonPopover } from '@ionic/react'
import { receiptOutline } from 'ionicons/icons'
import BaseButton from '@supplyhound/components/buttons/BaseButton'
import { MessageInputSmall, useMessageInputContext } from 'stream-chat-react'

const Container = styled.div`
  position: relative;
`

const Button = styled(BaseButton)`
  position: absolute;
  width: 100%;
  top: 70px;
`

const MacroIconButton = styled(BaseButton)`
  position: absolute;
  top: 12px;
  right: 12px;

  &&& {
    --padding: 5px;
    height: 30px;
    width: 30px;
  }
`

const StyledIonPopOver = styled(IonPopover)`
  width: 100px;
  height: 100px;
  padding: 10px;

  ::part(content) {
    padding: 10px;
    border-color: var(--ion-border-color);
  }
`

const CustomMessageInput = () => {
  const { handleSubmit, insertText, textareaRef } = useMessageInputContext()
  const [open, setOpen] = useState(false)
  return (
    <Container>
      <MessageInputSmall />
      <Button onClick={handleSubmit}>Reply</Button>
      <MacroIconButton
        id="macro"
        onMouseLeave={() => {
          setOpen(false)
        }}
        onMouseEnter={() => {
          setOpen(true)
        }}
        onClick={() => {
          insertText(
            "Hey - it looks like you're ready to place an order. Try using our order flow. Click https://app.supplyhound.com"
          )
          textareaRef.current?.focus()
        }}
      >
        <IonIcon src={receiptOutline} />
      </MacroIconButton>
      <StyledIonPopOver isOpen={open} trigger="macro" showBackdrop={false} side="top">
        Move to Order Message
      </StyledIonPopOver>
    </Container>
  )
}

export default CustomMessageInput
