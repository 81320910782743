import {
  DeliveryType,
  VehicleType,
  UnidentifiedJobSite,
  JobSiteStatuses,
  PickupType,
  UnidentifiedTeamMember,
  TaskTypes,
  UnidentifiedDeliveryTask,
  UnidentifiedGenericTask,
  UnidentifiedOrdererListItem,
  UnidentifiedPickupTask,
  DeliveryBy,
} from '@supplyhound/types'
import { buildLinkToClientAppURI } from '@supplyhound/utils/urls'

export const METERS_PER_MILE = 1609

export const DESKTOP_WIDTH = 900

export const USER_PROFILE_TOKEN_KEY = 'token'
export const TEAM_MEMBER_PROFILE_TOKEN_KEY = 'team-member-token'
export const TOAST_DURATION = 2000

export const DISTANCE_THRESHOLDS = {
  [VehicleType.Truck]: 10,
  [VehicleType.Car]: 10,
  [VehicleType.Supplier]: 0,
}

export const BASE_FARES = {
  [VehicleType.Truck]: 79,
  [VehicleType.Car]: 29,
  [VehicleType.Supplier]: 0,
}

export const DISTANCE_RATES = {
  [VehicleType.Truck]: 3,
  [VehicleType.Car]: 1.5,
  [VehicleType.Supplier]: 0,
}

export const ORDER_MANAGEMENT_FEE = 15

export const DELIVERY_OPTION_EXTRA_FEE: { [key in DeliveryType]?: number } = {
  [DeliveryType.TwoHours]: 29,
  [DeliveryType.FourHours]: 14,
  [DeliveryType.Tomorrow9am]: 14,
  [DeliveryType.Rush]: 19,
  [DeliveryType.Tomorrow9amCurri]: 19,
  [DeliveryType.Monday9amCurri]: 19,
  [DeliveryType.Scheduled]: 12,
}

export const DEFAULT_JOB_SITE: UnidentifiedJobSite = {
  contact_name: '',
  phone: '',
  note: '',
  formatted_address: '',
  name: '',
  nickname: '',
  status: JobSiteStatuses.current,
  location: {
    country: '',
    state: '',
    city: '',
    street: '',
    street2: '',
    zipcode: '',
    full_address: '',
  },
}

export const HOME_ROUTE = '/home'
export const SIGNUP_ROUTE = '/signup'
export const JOB_SITE_LIST_DETAIL_ROUTE = '/job-sites/:id([0-9]+)'
export const JOB_SITE_LIST_SELECT_ROUTE = '/job-sites/select'
export const JOB_SITE_TASK_ADD_SUPPLIER_ROUTE = '/job-sites/:id([0-9]+)/add-supplier'
export const PAYMENT_SELECT_ROUTE = '/job-sites/:id(-?[0-9]+)/payment-select'
export const PAYMENT_ADD_ROUTE = '/payment-add'
export const JOB_SITE_TASK_DETAIL_ROUTE = '/job-sites/:id(-?[0-9]+)/order-summary'
export const JOB_SITE_TASK_EDIT_ROUTE = '/job-sites/:id([0-9]+)/order-details'
export const DELIVERY_ONLY_ADD_SUPPLIER_ROUTE = '/delivery-only/add-supplier'
export const DELIVERY_ONLY_DETAIL_ROUTE = '/delivery-only/detail'
export const JOB_SITE_ORDER_RECEIPT_ROUTE = '/job-sites/order-receipt'
export const TEAM_MEMBERS_ROUTE = '/team-members'
export const TEAM_MEMBER_ADD_ROUTE = '/team-members/add'
export const TEAM_MEMBER_EDIT_ROUTE = '/team-members/edit/:id([0-9]+)?'
export const TEAM_MEMBER_SCHEDULE_ROUTE = '/team-members/schedule'
export const HELP_ROUTE = '/help'
export const HISTORY_ROUTE = '/history'
export const HISTORY_JOBSITE_SELECT = '/history/jobsite-select'
export const HISTORY_ADD_ROUTE = '/history/add-item'
export const HISTORY_ADD_TO_JOBSITE_ROUTE = '/history/:jobSiteId([0-9]+)/add-item'
export const MANAGE_CONTACTS_ROUTE = '/contacts'

export const ACCOUNT_ROUTE = '/account'
export const MANAGE_JOB_SITES_SELECT_ROUTE = '/manage/job-sites/select'
export const MANAGE_JOB_SITES_DETAIL_ROUTE = '/manage/job-sites/:id([0-9]+)'

export const COMPANY_ROUTE = '/company'

export const TEAM_MEMBER_ROUTE_BASE = '/team'
export const TEAM_MEMBER_HOME_ROUTE = `${TEAM_MEMBER_ROUTE_BASE}`
export const TEAM_MEMBER_LOGIN_ROUTE = `${TEAM_MEMBER_ROUTE_BASE}/login`
export const TEAM_MEMBER_CONFIRMATION_ROUTE = `${TEAM_MEMBER_ROUTE_BASE}/confirm`
export const TEAM_MEMBER_REMINDER_ACCEPT_ROUTE = TEAM_MEMBER_HOME_ROUTE
export const TEAM_MEMBER_REMINDER_DECLINE_ROUTE = `${TEAM_MEMBER_ROUTE_BASE}/none`
export const TEAM_MEMBER_LIST_ROUTE = `${TEAM_MEMBER_ROUTE_BASE}/list/:id([0-9]+)`

export const DEFAULT_ORDERER_LIST_ITEM: UnidentifiedOrdererListItem = {
  description: '',
  quantity: '',
  image: undefined,
}

export const DEFAULT_DELIVERY_TASK: UnidentifiedDeliveryTask = {
  type: TaskTypes.Delivery,
  supplier_id: undefined,
  job_site_id: undefined,
  job_site_name: '',
  delivery_by: DeliveryBy.SupplyHound,
  vehicle_type: VehicleType.Truck,
  ordered_directly: false,
  itemIds: [],

  pickup_address: '',
  pickup_datetime: '',
  pickup_note: '',

  delivery_address: '',
  delivery_datetime: '',
  delivery_type: DeliveryType.None,
  delivery_username: '',
  delivery_phone: '',
  delivery_note: '',

  job_distance: 0,
}

export const DEFAULT_PICKUP_TASK: UnidentifiedPickupTask = {
  type: TaskTypes.Pickup,
  supplier_id: undefined,
  job_site_id: undefined,
  job_site_name: '',
  itemIds: [],

  pickup_address: '',
  pickup_datetime: '',
  pickup_note: '',
  pickup_type: PickupType.None,
}

export const DEFAULT_GENERIC_TASK: UnidentifiedGenericTask = {
  ...DEFAULT_PICKUP_TASK,
  ...DEFAULT_DELIVERY_TASK,
}

export const DEFAULT_TEAM_MEMBER: UnidentifiedTeamMember = {
  first_name: '',
  last_name: '',
  phone: '',
  should_receive_daily_reminder: true,
  confirmation_url: buildLinkToClientAppURI(TEAM_MEMBER_CONFIRMATION_ROUTE),
  reminder_accept_url: buildLinkToClientAppURI(TEAM_MEMBER_HOME_ROUTE),
  reminder_decline_url: buildLinkToClientAppURI(TEAM_MEMBER_REMINDER_DECLINE_ROUTE),
}

export const VEHICLE_TYPES = [
  {
    label: VehicleType.Truck,
    value: VehicleType.Truck,
  },
  {
    label: VehicleType.Car,
    value: VehicleType.Car,
  },
]

export const CURRI_VEHICLE_TYPES = [
  {
    label: 'Pickup',
    value: VehicleType.Truck,
  },
  {
    label: 'Rack Pickup',
    value: VehicleType.RackTruck,
  },
  {
    label: VehicleType.Car,
    value: VehicleType.Car,
  },
  {
    label: 'Sprinter Van',
    value: VehicleType.SprinterVan,
  },
]
