import {
  HOME_ROUTE,
  HISTORY_ROUTE,
  JOB_SITE_LIST_SELECT_ROUTE,
  MANAGE_JOB_SITES_SELECT_ROUTE,
  TEAM_MEMBERS_ROUTE,
  ACCOUNT_ROUTE,
  COMPANY_ROUTE,
  HELP_ROUTE,
  TEAM_MEMBER_HOME_ROUTE,
  MANAGE_CONTACTS_ROUTE,
} from '@supplyhound/utils/config'
import { UserProfile } from '@supplyhound/types'

import { fingerPrintOutline, cardOutline, helpCircleOutline, businessOutline } from 'ionicons/icons'

interface MenuItems {
  title: string
  routeProps: {
    routeMatcher: RegExp
    routerLink: string
  }
  conditionalRender?: (userProfile?: UserProfile) => boolean
}
interface DropdownItems {
  title: string
  icon: string
  routeProps: {
    routerLink: string
  }
  conditionalRender?: (userProfile?: UserProfile) => boolean
}

export const ordererMenuItems: MenuItems[] = [
  {
    title: 'Home',
    routeProps: {
      routeMatcher: /\/home/,
      routerLink: HOME_ROUTE,
    },
  },
  {
    title: 'Team',
    routeProps: {
      routeMatcher: /\/team-members/,
      routerLink: TEAM_MEMBERS_ROUTE,
    },
  },
  {
    title: 'List',
    routeProps: {
      routeMatcher: /^\/job-sites\/\d*/,
      routerLink: JOB_SITE_LIST_SELECT_ROUTE,
    },
  },
  {
    title: 'Jobsites',
    routeProps: {
      routeMatcher: /\/manage\/job-sites/,
      routerLink: MANAGE_JOB_SITES_SELECT_ROUTE,
    },
  },
  {
    title: 'Contacts',
    routeProps: {
      routeMatcher: /\/contacts/,
      routerLink: MANAGE_CONTACTS_ROUTE,
    },
    conditionalRender: (userProfile?: UserProfile) => {
      return !!userProfile?.feature_flags.supplier_contacts
    },
  },
  {
    title: 'Orders',
    routeProps: {
      routeMatcher: /\/history/,
      routerLink: HISTORY_ROUTE,
    },
  },
]

export const ordererDropdownItems: DropdownItems[] = [
  {
    title: 'Profile',
    icon: fingerPrintOutline,
    routeProps: {
      routerLink: `${ACCOUNT_ROUTE}?tab=profile`,
    },
  },
  {
    title: 'Company',
    icon: businessOutline,
    routeProps: {
      routerLink: COMPANY_ROUTE,
    },
    conditionalRender: (userProfile?: UserProfile) => {
      return (
        !!userProfile?.feature_flags.saas &&
        !!userProfile?.subscription &&
        userProfile?.subscription.subscription_type !== 'legacy'
      )
    },
  },
  {
    title: 'Payment',
    icon: cardOutline,
    routeProps: {
      routerLink: `${ACCOUNT_ROUTE}?tab=payment`,
    },
  },
  {
    title: 'Help',
    icon: helpCircleOutline,
    routeProps: {
      routerLink: HELP_ROUTE,
    },
  },
]

export const teamMemberMenuItems: MenuItems[] = [
  {
    title: 'Lists',
    routeProps: {
      routeMatcher: /\/team(?!\/login)/,
      routerLink: TEAM_MEMBER_HOME_ROUTE,
    },
  },
]

export const teamMemberDropdownItems: DropdownItems[] = []

export const whiteLabelMenuItems: MenuItems[] = [
  {
    title: 'Home',
    routeProps: {
      routeMatcher: /\/home/,
      routerLink: HOME_ROUTE,
    },
  },
  {
    title: 'Jobsites',
    routeProps: {
      routeMatcher: /\/manage\/job-sites/,
      routerLink: MANAGE_JOB_SITES_SELECT_ROUTE,
    },
  },
  {
    title: 'Orders',
    routeProps: {
      routeMatcher: /\/history/,
      routerLink: HISTORY_ROUTE,
    },
  },
]

export const whiteLabelDropdownItems: DropdownItems[] = [
  {
    title: 'Profile',
    icon: fingerPrintOutline,
    routeProps: {
      routerLink: `${ACCOUNT_ROUTE}?tab=profile`,
    },
  },
  {
    title: 'Help',
    icon: helpCircleOutline,
    routeProps: {
      routerLink: HELP_ROUTE,
    },
  },
]

export const getMenuItems = (
  type: 'orderer' | 'teammember' | 'whitelabel' | 'none'
): [MenuItems[], DropdownItems[]] => {
  switch (type) {
    case 'orderer':
      return [ordererMenuItems, ordererDropdownItems]
    case 'teammember':
      return [teamMemberMenuItems, teamMemberDropdownItems]
    case 'whitelabel':
      return [whiteLabelMenuItems, whiteLabelDropdownItems]
    default:
      return [[], []]
  }
}
