import { createContext } from 'react'
import { AuthStore } from 'store/AuthStore'
import { PasswordResetStore } from 'store/PasswordResetStore'
import { TaskStore } from 'store/TaskStore'
import { ConversationStore } from 'store/ConversationStore'

const context = {
  authStore: new AuthStore(),
  passwordResetStore: new PasswordResetStore(),
  taskStore: new TaskStore(),
  conversationStore: new ConversationStore(),
}

export const StoresContext = createContext({
  ...context,
  resetStores() {
    const { authStore, passwordResetStore } = context

    authStore.reset()
    passwordResetStore.reset()
  },
})

export default StoresContext
