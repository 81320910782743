import React from 'react'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import Icon from '@supplyhound/components/common/Icon'
import { logOutOutline, settingsOutline } from 'ionicons/icons'
import Spacer from '@supplyhound/components/common/Spacer'
import Button from '@supplyhound/components/buttons/BaseButton'
import { calendarClearOutline, homeOutline } from 'ionicons/icons'
import { popoverController } from '@ionic/core'

import { IonPopover, IonHeader, IonRouterLink } from '@ionic/react'
import useStores from 'hooks/useStores'
import HorizontalRule from '@supplyhound/components/common/HorizontalRule'

import { DASHBOARD_PATH, ORDER_PATH } from 'config/paths'

const HeaderContainer = styled(IonHeader)`
  background-color: var(--greyscale-1);
  height: 90px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--ion-border-color);
`

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Constraint = styled.div`
  width: 1240px;
  justify-content: space-between;
  display: flex;
  padding: 10px 0px;
  align-items: center;
`

const Popover = styled(IonPopover)``

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
`

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 20px;
`

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
`

const StyledButton = styled(Button)`
  ::part(native) {
    font-size: 18px;
  }
`
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`
const IconContainer = styled.div`
  color: var(--greyscale-5);
  border: 1px solid var(--greyscale-5);
  border-radius: var(--ion-border-radius);
  cursor: pointer;
`

export const Header: React.FC = () => {
  const location = useLocation()
  const { authStore } = useStores()
  const user = authStore.getUser
  const isLoggedIn = user!!

  const logout = (): void => {
    authStore.logout()
  }

  const isSupplierOrderPage = location.pathname === ORDER_PATH

  return (
    <HeaderContainer>
      <Container>
        <Constraint style={{ maxWidth: '1240px' }}>
          <IonRouterLink color="dark" href={DASHBOARD_PATH}>
            <h3>{user?.company_name || 'Dashboard'}</h3>
          </IonRouterLink>
          <DropdownContainer>
            <IconContainer>
              <Icon width={50} icon={settingsOutline} id="menu-settings" containerPadding={15} />
            </IconContainer>
            <Popover trigger={isLoggedIn ? 'menu-settings' : ''} triggerAction="click">
              {isLoggedIn && (
                <>
                  <DropdownHeader>
                    <h2>{user.name}</h2>
                  </DropdownHeader>
                  <HorizontalRule />
                  <DropdownItem>
                    <Icon width={30} icon={logOutOutline} />
                    <Spacer width={10} />
                    <div
                      onClick={() => {
                        logout()
                        popoverController.dismiss()
                      }}
                    >
                      Logout
                    </div>
                  </DropdownItem>
                </>
              )}
            </Popover>
            <Spacer width={10} />
            {isSupplierOrderPage ? (
              <StyledButton disabled={!isLoggedIn} href={DASHBOARD_PATH}>
                <ButtonContent>
                  <Icon icon={homeOutline} />
                  <Spacer width={10} />
                  Dashboard
                </ButtonContent>
              </StyledButton>
            ) : (
              <StyledButton disabled={!isLoggedIn} href={ORDER_PATH}>
                <ButtonContent>
                  <Icon icon={calendarClearOutline} />
                  <Spacer width={10} />
                  Schedule Delivery
                </ButtonContent>
              </StyledButton>
            )}
          </DropdownContainer>
        </Constraint>
      </Container>
    </HeaderContainer>
  )
}

export default observer(Header)
