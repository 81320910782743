import { makeAutoObservable, runInAction } from 'mobx'
import { findIndex } from 'lodash'
import { OrdererList, OrdererListItem, UnidentifiedOrdererListItem, ID } from '@supplyhound/types'
import {
  createOrdererListItem,
  updateOrdererListItem,
  deleteOrdererListItem,
  archiveOrdererListItem,
} from '@supplyhound/api'
import { fetchOrdererList } from '@supplyhound/api'

type OrdererListMap = Record<ID, OrdererList>

export class OrdererListStore {
  lists: OrdererListMap = {}

  constructor() {
    makeAutoObservable(this)
  }

  getList(id: ID) {
    return this.lists[id]
  }

  reset() {
    this.lists = {}
  }

  async dispatchFetchList(id: ID) {
    const ordererList = await fetchOrdererList(id)
    runInAction(() => {
      this.lists[id] = ordererList
    })
  }

  async dispatchCreateItem(listId: ID, item: UnidentifiedOrdererListItem) {
    const list = this.lists[listId]

    if (!list) throw new Error(`Can't create item for list (${listId}) that doesn't exist!`)

    item.orderer_list_id = list.id
    const newItem = await createOrdererListItem(item)
    runInAction(() => list.active_items.push(newItem))
    return newItem
  }

  async dispatchUpdateItem(item: OrdererListItem) {
    const updatedItem = await updateOrdererListItem(item)

    this.replaceItem(updatedItem)
  }

  async dispatchDeleteItem(item: OrdererListItem) {
    await deleteOrdererListItem(item)
    this.removeItem(item)
  }

  dispatchArchiveItem = async (item: OrdererListItem) => {
    await archiveOrdererListItem(item)
    this.removeItem(item)
  }

  idxOfItem(item: OrdererListItem) {
    const { id } = item
    const list = this.lists[item.orderer_list_id]

    if (!list) throw new Error(`Can't check the index of item (${id}) that has no list!`)

    return findIndex(list.active_items, { id })
  }

  removeItem(item: OrdererListItem) {
    const list = this.lists[item.orderer_list_id]

    if (!list) throw new Error(`Can't remove item (${item.id}) that has no list!`)

    list.active_items.splice(this.idxOfItem(item), 1)
  }

  replaceItem(item: OrdererListItem) {
    const list = this.lists[item.orderer_list_id]

    if (!list) throw new Error(`Can't replace item (${item.id}) that has no list!`)

    list.active_items.splice(this.idxOfItem(item), 1, item)
  }
}
