import React from 'react'
import { IonMenuButton } from '@ionic/react'
import { withNavigationButtonStyle } from './NavigationButton'
import styled from 'styled-components'
import { menuOutline } from 'ionicons/icons'
import Icon from '@supplyhound/components/common/Icon'
import { ForwardProps } from '@supplyhound/types'

const StyledIonMenuButton = withNavigationButtonStyle(styled(IonMenuButton)`
  &&& {
    border: none;
  }
`)

const MenuButton: React.FC<ForwardProps<typeof IonMenuButton>> = props => (
  <StyledIonMenuButton {...props}>
    <Icon icon={menuOutline} width={30} />
  </StyledIonMenuButton>
)

export default MenuButton
