import { IonButton } from '@ionic/react'
import React from 'react'
import styled from 'styled-components'
import { ButtonType } from '@supplyhound/types'

export const withNavigationButtonStyle = (component: React.FunctionComponent) => styled(component)`
  color: var(--ion-color-dark);
  border-radius: var(--ion-border-radius);
  width: 50px;
  height: 50px;
  margin: 0;
  --background-hover-opacity: 0;

  &::part(native) {
    background: var(--ion-background-color);
  }
`

const NavigationButton: ButtonType = withNavigationButtonStyle(IonButton)

export default NavigationButton
