import { useEffect } from 'react'
import useStores from './useStores'
import { Event } from 'stream-chat'
import usePushNotification from './usePushNotification'
import useStreamChat from './useStreamChat'
import { streamChatChannelIdToConversationId } from 'helpers/streamChatAdapter'

export type OnNewStreamChatMessageProps = {
  conversationId: number
  event: Event
}

/**
 * Hook for responding to new message notifications from streamchat.
 * @param onNewMessage Function to call when a new message arrives. Receives
 *  the id of the conversation whose channel has received a message
 *  and the subscribed event.
 */
export const useOnNewStreamChatMessage = (
  onNewMessage: ({ conversationId, event }: OnNewStreamChatMessageProps) => void
) => {
  const { authStore } = useStores()
  const { adapter } = useStreamChat()
  const userIsConnectedToStreamChat = authStore.userIsConnectedToStreamChat

  useEffect(() => {
    // It's possible that a call to connect the user
    // to streamchat has not completed.
    if (!userIsConnectedToStreamChat) return

    const handleNewMessage = (event: Event) => {
      // Ignore events not associated with a channel.
      if (!event.channel_id) return
      // Ignore events from messages sent by the current user.
      if (event.user?.id === adapter.client.userID) return

      // Ignore events not related to current user.
      if (!!adapter.client.activeChannels[event.cid || '']) {
        const conversationId = streamChatChannelIdToConversationId(event.channel_id)
        onNewMessage({ conversationId, event })
      }
    }

    // Returns unsubscription func.
    return adapter.onNewMessage(handleNewMessage)
  }, [adapter, onNewMessage, userIsConnectedToStreamChat])
}

/**
 * Hook for responding to when a message is added to a channel. For clients that are not currently watching the channel.
 * @param onNotificationMessageNew callback function.
 */
export const useOnNotificationMessageNew = (
  onNotificationMessageNew: ({ conversationId, event }: OnNewStreamChatMessageProps) => void
) => {
  const { authStore } = useStores()
  const { adapter } = useStreamChat()
  const userIsConnectedToStreamChat = authStore.userIsConnectedToStreamChat

  useEffect(() => {
    // It's possible that a call to connect the user
    // to streamchat has not completed.
    if (!userIsConnectedToStreamChat) return

    const handleEvent = (event: Event) => {
      // Ignore events not associated with a channel.
      if (!event.channel_id) return

      // Ignore events not related to current user.
      if (event.channel?.members?.find(member => member.user_id === adapter.client.userID)) {
        const conversationId = streamChatChannelIdToConversationId(event.channel_id)
        onNotificationMessageNew({ conversationId, event })
      }
    }

    // Returns unsubscription func.
    return adapter.onNotificationMessageNew(handleEvent)
  }, [adapter, onNotificationMessageNew, userIsConnectedToStreamChat])
}

/**
 * Hook for responding to new message notifications from streamchat with
 * a push notification.
 */
export const usePushNotificationOnNewStreamChatMessage = () => {
  const { notifier } = usePushNotification()

  const onNewMessage = ({ event }: OnNewStreamChatMessageProps) => {
    if (event.user) {
      const title = `New message from ${event.user.name}!`
      const body = event.message?.text
      notifier.notify(title, body)
    }
  }

  useOnNewStreamChatMessage(onNewMessage)
}
