import { DeliveryTask, DeliveryTaskStatus } from 'types/tasks'

import { apiAxiosInstance } from '.'

interface DeliveryTaskResponse {
  delivery_task: DeliveryTask
}

type NewDeliveryTask = Omit<
  DeliveryTask,
  'status' | 'state_logs' | 'ready_by' | 'id' | 'created_at' | 'orderer' | 'store_name' | 'conversation'
> & {
  ordered_with_subscription: boolean
  ordered_directly: boolean
  job_distance: number
}
export async function updateDeliveryTaskStatus(
  taskId: number | string,
  event: string,
  payload?: any
): Promise<DeliveryTaskResponse> {
  const response = await apiAxiosInstance.post<DeliveryTaskResponse>(`/v1/delivery_tasks/${taskId}/${event}`, payload)

  return response.data
}

export async function overrideDeliveryTaskStatus(
  taskId: number | string,
  status: DeliveryTaskStatus
): Promise<DeliveryTaskResponse> {
  const response = await apiAxiosInstance.post<DeliveryTaskResponse>(`/v1/delivery_tasks/${taskId}/override_status`, {
    status,
  })

  return response.data
}

export async function createDeliveryTask(data: { delivery_task: NewDeliveryTask }): Promise<DeliveryTaskResponse> {
  const response = await apiAxiosInstance.post<DeliveryTaskResponse>('/v1/delivery_tasks', data)

  return response.data
}

export const getDeliveryQuotes = (task: NewDeliveryTask) =>
  apiAxiosInstance
    .post('/v1/delivery_price/quote', {
      ...task,
    })
    .then(({ data }) => data)
