import React from 'react'
import ReactDOM from 'react-dom'
import { init } from '@sentry/browser'
import { createConsumer } from '@rails/actioncable'
import ActionCableConsumerContext from 'contexts/ActionCableConsumerContext'
import { ThemeProvider } from 'styled-components'
import mixpanel from 'mixpanel-browser'
import { setupIonicReact } from '@ionic/react'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import theme from 'theme'

import { LoadScript } from '@react-google-maps/api'

import App from './containers/App'

import 'react-datetime/css/react-datetime.css'
import './assets/styles/app.scss'

setupIonicReact({
  mode: 'ios',
})

const render = (): void => {
  if (process.env.NODE_ENV !== 'development') {
    init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENV,
    })
  }

  const consumer = createConsumer(process.env.REACT_APP_API_WS_URL)

  if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)
  }

  ReactDOM.render(
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY!} libraries={['places']}>
      <ActionCableConsumerContext.Provider value={consumer}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ActionCableConsumerContext.Provider>
    </LoadScript>,
    document.getElementById('root')
  )
}

render()

defineCustomElements(window)

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./containers/App', render)
}

// This is a comment to force deploy - 7/23/2023 #1
