import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'
import Spacer from '@supplyhound/components/common/Spacer'
import { Task } from 'types/tasks'
import { Field, Form, Formik } from 'formik'
import { CurriBookingDetails, getCurriBookingDetails } from 'api/network'
import TextInputField from '@supplyhound/forms/fields/TextInputField'
import DatetimePicker from 'components/forms/fields/DatetimePicker'
import TextAreaField from '@supplyhound/forms/fields/TextAreaField'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { Moment } from 'moment'
import { updateDeliveryNetwork } from 'api/network'
import { useLoadingState } from '@supplyhound/hooks'

const Content = styled.div`
  padding: 20px;
  text-align: center;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const BookCurriModal = ({
  task,
  dismiss,
  refreshTable,
}: {
  dismiss: () => void
  task: Task
  refreshTable: () => void
}) => {
  const { loadWhile } = useLoadingState()

  const [details, setDetails] = useState<CurriBookingDetails>()
  const [pickupTime, setPickupTime] = useState()
  useEffect(() => {
    getCurriBookingDetails(task.id).then(data => setDetails(data))
  }, [])
  useEffect(() => {
    setPickupTime(details?.scheduledAt)
  }, [details?.scheduledAt])

  if (!details) {
    return <></>
  }

  return (
    <BaseModal onDidDismiss={dismiss}>
      <Content>
        <h2>Confirm curri details for order {task.id}</h2>
        <Spacer height={20} />
        <Formik
          onSubmit={async vals => {
            loadWhile(async () => {
              const trimmedVals = {
                ...vals,
                metaDropoffNote: vals.metaDropoffNote.trim(),
                metaPickupNote: vals.metaPickupNote.trim(),
              }
              await updateDeliveryNetwork(task.id, 'CURRI', { ...trimmedVals, scheduledAt: pickupTime! })
              dismiss()
              refreshTable()
            })
          }}
          initialValues={details}
        >
          <Form>
            <FormContainer>
              <Field
                name="scheduledAt"
                label="Scheduled Pickup"
                component={DatetimePicker}
                initialValue={details.scheduledAt}
                onChange={(datetime: Moment) => {
                  setPickupTime(datetime)
                }}
              />

              <Field name="dropoffContactName" label="Dropoff Contact" component={TextInputField} />
              <Field name="dropoffContactPhone" label="Dropoff Contact Phone" component={TextInputField} />
              <div>
                <Field name="metaDropoffNote" label="Dropoff Note" component={TextAreaField} disableNewLine />
              </div>
              <Field name="metaOrderNo" label="Order No" component={TextInputField} />
              <div>
                <Field name="metaPickupNote" label="Pickup Note" component={TextAreaField} disableNewLine />
              </div>
              <Field name="metaPo" label="PO" component={TextInputField} />
              <Field name="pickupCompany" label="pickupCompany" component={TextInputField} />
              <Field name="pickupContactName" label="Pickup Contact" component={TextInputField} />
              <Field name="pickupContactPhone" label="Pickup Contact Phone" component={TextInputField} />
            </FormContainer>
            <Spacer height={20} />
            <SubmitButton type="submit">Submit</SubmitButton>
          </Form>
        </Formik>
      </Content>
    </BaseModal>
  )
}

export default BookCurriModal
