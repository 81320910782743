import { useCallback, useReducer } from 'react'
import { createStore } from 'reusable'

import { ID, PaymentMethod } from '@supplyhound/types'
import {
  fetchPaymentMethods as fetchPaymentMethodsApi,
  deletePaymentMethod as deletePaymentMethodApi,
} from '@supplyhound/api'

type State = {
  loaded: boolean
  loading: boolean
  error: string
  paymentMethods: PaymentMethod[]
}

enum ActionType {
  FetchStart = 'FETCH_START',
  FetchSuccess = 'FETCH_SUCCESS',
  DeleteStart = 'DELETE_START',
  DeleteSuccess = 'DELETE_SUCCESS',
  Error = 'ERROR',
}

type Action = {
  type: ActionType
  payload?: any
}

type HookValue = {
  state: State
  fetchPaymentMethods: () => Promise<any>
  deletePaymentMethod: (paymentMethodId: ID) => Promise<any>
}

const initialState = {
  loaded: false,
  loading: false,
  error: '',
  paymentMethods: [],
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.FetchStart:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case ActionType.FetchSuccess:
      return {
        ...state,
        loaded: true,
        loading: false,
        paymentMethods: action.payload as PaymentMethod[],
      }

    case ActionType.DeleteStart:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case ActionType.DeleteSuccess:
      return {
        ...state,
        loading: false,
        paymentMethods: state.paymentMethods.filter(paymentMethod => paymentMethod.id !== action.payload),
      }

    case ActionType.Error:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      throw new Error()
  }
}

export const usePaymentMethods = createStore<HookValue>(() => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const fetchPaymentMethods = useCallback(async () => {
    try {
      dispatch({ type: ActionType.FetchStart })

      const response = await fetchPaymentMethodsApi()

      dispatch({
        type: ActionType.FetchSuccess,
        payload: response.data,
      })
    } catch (e) {
      dispatch({
        type: ActionType.Error,
        payload: e.toString(),
      })
    }
  }, [dispatch])

  const deletePaymentMethod = useCallback(
    async (paymentMethodId: ID) => {
      try {
        dispatch({ type: ActionType.DeleteStart })

        await deletePaymentMethodApi(paymentMethodId)

        dispatch({
          type: ActionType.DeleteSuccess,
          payload: paymentMethodId,
        })
      } catch (e) {
        dispatch({
          type: ActionType.Error,
          payload: 'Error in deleting a payment method',
        })
      }
    },
    [dispatch]
  )

  return {
    state,
    fetchPaymentMethods,
    deletePaymentMethod,
  }
})

export default usePaymentMethods
