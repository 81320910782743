import { makeAutoObservable, runInAction } from 'mobx'
import { createPasswordReset, updatePasswordReset, updateConfirmation } from 'api/users'

export class PasswordResetStore {
  resetEmailSent: boolean

  constructor() {
    this.resetEmailSent = false
    makeAutoObservable(this)
  }

  reset() {
    this.resetEmailSent = false
  }

  dispatchRequestPasswordReset = async (email: string, updatePath: string) => {
    try {
      await createPasswordReset(email, updatePath)
      runInAction(() => {
        this.resetEmailSent = true
      })
    } catch (err) {
      const error = 'Unexpected error. Please contact SupplyHound'
      runInAction(() => {
        this.resetEmailSent = false
      })
      throw new Error(error)
    }
  }

  dispatchSubmitPasswordReset = async (email: string, password: string, token: string) => {
    try {
      const { user } = await updatePasswordReset(email, password, token)
      return user
    } catch (err: any) {
      const response = err.response

      let error = 'Unexpected error. Please contact SupplyHound'
      if (response.status === 404) {
        error = 'Invalid link. Please request a new password reset link'
      } else if (response.status === 400) {
        error = response.data.message
      }
      throw new Error(error)
    }
  }

  dispatchSubmitConfirmation = async (email: string, password: string, token: string) => {
    try {
      const { user } = await updateConfirmation(email, password, token)
      return user
    } catch (err: any) {
      const response = err.response

      let error = 'Unexpected error. Please contact SupplyHound'
      if (response?.status === 403) {
        error = 'Invalid link. Please request a new confirmation link'
      } else if (response.status === 400) {
        error = response.data.message
      }
      throw new Error(error)
    }
  }
}
