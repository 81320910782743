import React from 'react'
import { observer } from 'mobx-react-lite'
import SectionLabel from '@supplyhound/components/common/SectionLabel'
import SupplierRow from '@supplyhound/components/SupplierRow'
import { SupplierSearchResult } from '@supplyhound/types'
import styled from 'styled-components'

const Container = styled.div<{ dropDown?: boolean }>`
  ${({ dropDown }) =>
    dropDown
      ? `
      position: absolute;
      border: 2px solid var(--ion-border-color);
      z-index: 1000;
      width: 100%;
      opacity: 1;
      background-color: white;
      padding: 0px 20px;
      border-radius: var(--ion-border-radius);
  `
      : ''};
`

interface Props {
  onSupplierSelected: (supplier: SupplierSearchResult) => void
  supplierOptions: SupplierSearchResult[]
  sectionLabel: string
  dropDown?: boolean
}
const SupplierOptions: React.FC<Props> = ({ onSupplierSelected, supplierOptions, sectionLabel, dropDown }) => {
  if (dropDown && supplierOptions.length === 0) return null
  return (
    <Container dropDown={dropDown}>
      <SectionLabel label={sectionLabel} />
      {supplierOptions.map((supplier, index) => (
        <SupplierRow key={index} supplier={supplier} onSupplierClicked={() => onSupplierSelected(supplier)} />
      ))}
    </Container>
  )
}

export default observer(SupplierOptions)
