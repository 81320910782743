import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { fetchOrdererTeamMemberLists } from '@supplyhound/api'
import { ID, SelectableTeamMemberListItem, OrdererTeamMemberList } from '@supplyhound/types'
import { acceptTeamMemberListItems, archiveTeamMemberListItems, updateTeamMemberListItem } from '@supplyhound/api'

export class OrdererTeamRequestsStore {
  teamMemberLists: OrdererTeamMemberList[] = []
  selectedItemIds = new Set<number>()

  constructor() {
    makeAutoObservable(this)
  }

  get selected() {
    return Array.from(toJS(this.selectedItemIds))
  }

  dispatchFetchLists = async (teamId: ID, jobSiteId: ID) => {
    const lists = await fetchOrdererTeamMemberLists(teamId, jobSiteId)
    runInAction(() => {
      this.teamMemberLists = lists
    })
  }

  dispatchArchiveItems = async (teamId: ID) => {
    const selectedItemsArray = Array.from(toJS(this.selectedItemIds))
    await archiveTeamMemberListItems(teamId, selectedItemsArray)
    runInAction(() => {
      this.removeItems()
    })
  }

  dispatchAcceptTeamMemberListItems = async (teamId: ID, jobSiteId: ID) => {
    await acceptTeamMemberListItems(teamId, jobSiteId, Array.from(toJS(this.selectedItemIds)))
    runInAction(() => {
      this.removeItems()
    })
  }

  dispatchUpdateSubmittedTeamMemberItem = async (item: TeamMemberListItem, listId: ID) => {
    await updateTeamMemberListItem(item)
    runInAction(() => {
      this.teamMemberLists.forEach(list => {
        if (list.id === listId) {
          list.items = list.items.map(listItem => {
            if (listItem.id === item.id) {
              return item
            }
            return listItem
          })
        }
      })
    })
  }

  removeItems = () => {
    this.teamMemberLists.forEach(teamMember => {
      const newItems: SelectableTeamMemberListItem[] = []

      teamMember.items.forEach(item => {
        if (!this.selectedItemIds.has(item.id)) {
          newItems.push(item)
        }
      })
      runInAction(() => {
        teamMember.items = newItems
      })
    })
  }

  setTeamMemberListItemSelectState = (targetItemId: ID, isSelected: boolean) => {
    if (isSelected) {
      this.selectedItemIds.add(targetItemId)
    } else {
      this.selectedItemIds.delete(targetItemId)
    }
  }

  get teamMemberListCount() {
    let count = 0
    for (let list of this.teamMemberLists) {
      count += list.items.length
    }

    return count
  }

  get isAllTeamMemberListItemsEmpty() {
    for (let list of this.teamMemberLists) {
      if (list.items.length) {
        return false
      }
    }
    return true
  }
}
