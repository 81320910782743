import _ from 'lodash'

export const REMINDER_DAYS = [
  {
    label: 'Monday - Friday',
    scheduleDays: ['mon', 'tue', 'wed', 'thu', 'fri'],
  },
  {
    label: 'Monday - Saturday',
    scheduleDays: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
  },
  {
    label: 'Everyday',
    scheduleDays: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
  },
  {
    label: 'Pause all team reminders',
    scheduleDays: [],
  },
]

export const REMINDER_TIME = ['11:30', '14:30']

export const getReminderDaysLabel = (days: string[]) => {
  for (let d of REMINDER_DAYS) {
    if (_.isEqual(d.scheduleDays, days)) {
      return d.label
    }
  }
}

export const formatTime = (time: string) => {
  const [hours, minutes]: string[] = time.split(':')
  let h: number = parseInt(hours) % 12
  if (h === 0) h = 12
  return `${h}:${minutes} ${parseInt(hours) < 12 ? 'AM' : 'PM'}`
}
