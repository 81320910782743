import React, { useEffect, useState } from 'react'
import { IonModal, IonButton, IonImg } from '@ionic/react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import Spacer from '@supplyhound/components/common/Spacer'
import { ShLogoIcon } from '@supplyhound/images'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Top = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
`
const Main = styled.div`
  flex: 2 0;
  text-align: center;
  padding: 20px;
`
const Bottom = styled.div`
  flex: 1 0;
`

const Logo = styled(IonImg)`
  height: 100px;
  width: 100px;
`

const SessionExpiredModal = () => {
  const { sessionStore, resetStores } = useStores()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setShowModal(sessionStore.isSessionExpired)
  }, [sessionStore.isSessionExpired])

  return (
    <IonModal
      isOpen={showModal}
      onDidDismiss={() => {
        resetStores()
      }}
    >
      <Content>
        <Top>
          <Logo src={ShLogoIcon} />
        </Top>
        <Main>
          <h1>Your session has expired, please login again.</h1>
          <Spacer height={20} />
          <IonButton
            type="button"
            color="primary"
            onClick={() => {
              setShowModal(false)
            }}
          >
            Login
          </IonButton>
        </Main>
        <Bottom />
      </Content>
    </IonModal>
  )
}

export default observer(SessionExpiredModal)
