import React from 'react'
import { ForwardProps } from '@supplyhound/types'
import styled from 'styled-components'
import BaseButton from '@supplyhound/components/buttons/BaseButton'

type Props = ForwardProps<typeof BaseButton> & {
  borderColor: string
  textColor?: string
}

const StyledButton = styled(BaseButton)<{ borderColor: string; textColor?: string }>`
  --border-color: var(--ion-color-${props => props.borderColor});
  --border-width: 1px;
  --border-style: solid;

  ${props =>
    props.textColor ? '::part(native) {--ion-color-contrast: var(--ion-color-' + props.textColor + ');}' : ''}
`

const OutlineButton: React.FC<Props> = ({ borderColor, textColor, children, ...props }) => (
  <StyledButton borderColor={borderColor} textColor={textColor} {...props}>
    {children}
  </StyledButton>
)

export default OutlineButton
