import _ from 'lodash'

type FastenerConfigType = {
  [category: string]: {
    [subcategory: string]: {
      [field: string]: string[]
    }
  }
}

export interface FastenerFormValues {
  category: string
  subcategory: string
  size: string
  drive: string
  materialGrade: string
  finish: string
  threadStyle: string
  type: string
  outsideDiameter: string
  outsideDimension: string
  collation: string
  shank: string
  cap: string
  crown: string
  leg: string
  length: string
  boxSizeLb: string
  boxSizeCt: string
}

export enum FastenerFields {
  size = 'Size',
  length = 'Length',
  drive = 'Drive',
  materialGrade = 'Material Grade',
  finish = 'Finish',
  threadStyle = 'Thread Style',
  type = 'Type',
  outsideDiameter = 'Outside Diameter',
  outsideDimension = 'Outside Dimension',
  collation = 'Collation',
  shank = 'Shank',
  cap = 'Cap',
  crown = 'Crown',
  leg = 'Leg',
  boxSizeLb = 'Box Size (lb)',
  boxSizeCt = 'Box Size (ct)',
}

const FastenerConfig: FastenerConfigType = {
  Screws: {
    'Drywall Screws': {
      size: ['#6', '#7', '#8', '#10'],
      drive: ['Phillips', 'Star', 'Square', 'Slotted'],
      materialGrade: [],
      finish: [],
      threadStyle: ['Fine', 'Course'],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: ['1', '5', '10'],
      boxSizeCt: [],
      length: [
        '1/2"',
        '3/4"',
        '1"',
        '1 1/8"',
        '1 1/4"',
        '1 1/2"',
        '1 5/8"',
        '1 3/4"',
        '2"',
        '2 1/4"',
        '2 1/2"',
        '3"',
        '3 1/2"',
        '4"',
      ],
    },
    'Wood Screws': {
      size: ['#4', '#5', '#6', '#7', '#8', '#9', '#10', '#12', '#14'],
      drive: ['Phillips', 'Star', 'Square', 'Slotted'],
      materialGrade: [],
      finish: [],
      threadStyle: [],
      type: ['Exterior', 'Interior'],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: ['1', '5', '10'],
      boxSizeCt: [],
      length: [
        '1/4"',
        '3/8"',
        '1/2"',
        '5/8"',
        '3/4"',
        '7/8"',
        '1"',
        '1 1/4"',
        '1 1/2"',
        '1 5/8"',
        '1 3/4"',
        '2"',
        '2 1/4"',
        '2 1/2"',
        '3"',
        '3 1/2"',
        '4"',
      ],
    },
    'Structural Wood Screws': {
      size: ['3/16"', '1/4"', '5/16"', '3/8"', '1/2"'],
      drive: ['External Hex', 'Internal Hex', 'Star', 'Phillips'],
      materialGrade: [],
      finish: [],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: ['1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '4 1/2"', '5"', '6"', '8"', '10"'],
    },
    'Deck Screws': {
      size: ['#6', '#7', '#8', '#9', '#10', '#12'],
      drive: ['Star', 'Phillips'],
      materialGrade: [],
      finish: [],
      threadStyle: [],
      type: ['Wood', 'Composite'],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: ['1', '5', '10'],
      boxSizeCt: [],
      length: ['1 1/4"', '1 1/2"', '1 5/8"', '1 3/4"', '2"', '2 1/2"', '2 3/4"', '3"', '3 1/2"', '4"', '5"', '6"'],
    },
    'Lag Screws': {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: ['Hex Head', 'Star', 'Phillips'],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Exterior (coated)'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: ['1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '4 1/2"', '5"', '5 1/2"', '6"'],
    },
    'Concrete Screws': {
      size: ['3/16"', '1/4"', '5/16"', '3/8"', '1/2"'],
      drive: ['Hex Head', 'Star', 'Phillips'],
      materialGrade: [],
      finish: [],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: ['1', '10', '25', '50', '75', '100'],
      length: ['1 1/2"', '2"', '2 3/8"', '3"', '3 1/4"', '3 1/2"', '4"', '4 1/2"', '5 1/2"'],
    },
  },
  Bolts: {
    'Hex Head': {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless'],
      threadStyle: ['Full', 'Coarse', 'Partial', 'Fine'],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [
        '1/2"',
        '5/8"',
        '3/4"',
        '7/8"',
        '1"',
        '1 1/4"',
        '1 1/2"',
        '1 3/4"',
        '2"',
        '2 1/4"',
        '2 1/2"',
        '3"',
        '3 1/2"',
        '4"',
        '4 1/2"',
        '5"',
        '5 1/2"',
        '6"',
        '8"',
        '10"',
      ],
    },
    Carriage: {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless'],
      threadStyle: ['Full', 'Partial'],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [
        '1/2"',
        '5/8"',
        '3/4"',
        '7/8"',
        '1"',
        '1 1/4"',
        '1 1/2"',
        '1 3/4"',
        '2"',
        '2 1/4"',
        '2 1/2"',
        '3"',
        '3 1/2"',
        '4"',
        '4 1/2"',
        '5"',
        '5 1/2"',
        '6"',
        '8"',
        '10"',
      ],
    },
    'Lag Bolt (Screw)': {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: ['Hex Head', 'Star', 'Phillips'],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Exterior (coated)'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: ['1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '3 1/2"', '4"', '4 1/2"', '5"', '5 1/2"', '6"'],
    },
  },
  Nuts: {
    'Hex Nut': {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless', 'Brass'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
    'Flange Nut': {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless', 'Brass'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
    'Coupling Nut': {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless', 'Brass'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
    'Jam Nut': {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless', 'Brass'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
    'Lock Nut': {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless', 'Brass'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
    'Square Nut': {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless', 'Brass'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
    'T-Nut': {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Zinc', 'Stainless'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
    'Wing Nut': {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
  },
  Washers: {
    Flat: {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless', 'Brass'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
    Lock: {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Zinc', 'Stainless'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
    Square: {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: ['1"', '1 1/2"', '2"', '2 1/2"', '3"'],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
    Fender: {
      size: ['1/4"', '5/16"', '3/8"', '7/16"', '1/2"', '5/8"', '3/4"'],
      drive: [],
      materialGrade: [],
      finish: ['Zinc', 'Stainless'],
      threadStyle: [],
      type: [],
      outsideDiameter: ['1/2"', '3/4"', '1"', '1 1/2"', '2"'],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: [],
    },
  },
  'Nails - Collated': {
    Framing: {
      size: ['.113"', '.120"', '.131"', '.148"'],
      drive: [],
      materialGrade: [],
      finish: ['Bright', 'Galvanized (HDG)', 'Vinyl Coated'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: ['15° Wire Coil', '21° Plastic Strip', '28° Wire Strip', '30° Paper Strip'],
      shank: ['Smooth', 'Ring', 'Screw'],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: ['1000', '2000', '3000', '4000', '5000'],
      length: ['2"', '2 3/8"', '2 1/2"', '3"', '3 1/4"', '3 1/2"'],
    },
    Siding: {
      size: ['.080" (14 GA)', '.092" (13 GA)', '.120" (11 GA)'],
      drive: [],
      materialGrade: [],
      finish: ['Stainless', 'Galvanized'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: ['15° Wire Coil'],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: ['1000', '2000', '3000', '4000', '5000'],
      length: ['1 1/2"', '2"', '2 1/4"', '2 3/8"'],
    },
    Roofing: {
      size: ['11 GA (.120)'],
      drive: [],
      materialGrade: [],
      finish: ['Stainless', 'Galvanized'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: ['15° Wire Coil'],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: ['1000', '2000', '3000', '4000', '5000'],
      length: ['1 1/8"', '1 1/2"', '1 5/8"'],
    },
    Finish: {
      size: ['16 GA', '15 GA'],
      drive: [],
      materialGrade: [],
      finish: ['Stainless', 'Galvanized'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: ['Straight Glued', 'Angle Glued'],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: ['1000', '2000', '5000'],
      length: ['1 1/4"', '1 1/2"', '1 3/4"', '2"', '2 1/2"'],
    },
    Brad: {
      size: ['18 GA'],
      drive: [],
      materialGrade: [],
      finish: ['Stainless', 'Galvanized'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: ['Straight Glued'],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: ['1000', '2000', '5000'],
      length: ['3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"'],
    },
  },
  'Nails - Loose': {
    Framing: {
      size: ['6D (#11 x 1 3/4")', '8D (#11 x 2 3/8")', '10D (#11 x 2 7/8")', '12D (#10 x 3 1/8")', '16D (#9 x 3 1/4")'],
      drive: [],
      materialGrade: [],
      finish: ['Bright', 'Galvanized (HDG)', 'Vinyl Coated'],
      threadStyle: [],
      type: ['Sinker'],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: ['1', '5', '10'],
      boxSizeCt: [],
      length: [],
    },
    Roofing: {
      size: ['#10', '#11', '#12'],
      drive: [],
      materialGrade: [],
      finish: ['Stainless', 'Galvanized'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: ['None', 'Plastic', 'Neoprene'],
      crown: [],
      leg: [],
      boxSizeLb: ['1', '5', '10'],
      boxSizeCt: [],
      length: ['7/8"', '1"', '1 1/4"', '1 1/2"', '1 3/4"', '2"'],
    },
    Drywall: {
      size: ['13 GA x 1 3/8"', '12 GA x 1 3/8"'],
      drive: [],
      materialGrade: [],
      finish: ['Bright', 'Phosphate Coated'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: ['1', '5', '10'],
      boxSizeCt: [],
      length: [],
    },
    Common: {
      size: ['6D (#11 x 1 3/4")', '8D (#11 x 2 3/8")', '10D (#11 x 2 7/8")', '12D (#10 x 3 1/8")', '16D (#9 x 3 1/4")'],
      drive: [],
      materialGrade: [],
      finish: ['Bright', 'Galvanized (HDG)', 'Vinyl Coated'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: ['1', '5', '10'],
      boxSizeCt: [],
      length: [],
    },
    Deck: {
      size: ['8D (#11 x 2 3/8")', '16D (#9 x 3 1/4")'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized (HDG)'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: ['Spiral'],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: ['1', '5', '10'],
      boxSizeCt: [],
      length: [],
    },
  },
  Staples: {
    'Heavy Duty Staples': {
      size: [],
      drive: [],
      materialGrade: [],
      finish: [],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: ['Narrow', 'Medium'],
      leg: ['1/4"', '5/16"', '3/8"', '1/2"', '9/16"'],
      boxSizeLb: [],
      boxSizeCt: ['1000', '5000'],
      length: [],
    },
    'Flooring Staples': {
      size: [],
      drive: [],
      materialGrade: [],
      finish: [],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: ['1', '2"'],
      leg: ['2"'],
      boxSizeLb: [],
      boxSizeCt: ['1000', '5000'],
      length: [],
    },
  },
  'Threaded Rod': {
    'Threaded Rod': {
      size: ['3/8"', '1/2"', '5/8"'],
      drive: [],
      materialGrade: [],
      finish: ['Galvanized', 'Zinc', 'Stainless'],
      threadStyle: [],
      type: [],
      outsideDiameter: [],
      outsideDimension: [],
      collation: [],
      shank: [],
      cap: [],
      crown: [],
      leg: [],
      boxSizeLb: [],
      boxSizeCt: [],
      length: ['24"', '36"', '72"'],
    },
  },
}

export const formatValues = (values: FastenerFormValues) => {
  const {
    category,
    subcategory,
    size,
    drive,
    materialGrade,
    finish,
    threadStyle,
    type,
    outsideDiameter,
    outsideDimension,
    collation,
    shank,
    cap,
    crown,
    leg,
    length,
    boxSizeLb,
    boxSizeCt,
  } = values

  switch (category + '/' + subcategory) {
    case 'Screws/Drywall Screws':
      return `${size} x ${length} ${subcategory} - ${drive} - ${threadStyle} - ${boxSizeLb} lb box`
    case 'Screws/Wood Screws':
      return `${size} x ${length} ${type} ${subcategory} - ${drive} - ${boxSizeLb} lb box`
    case 'Screws/Structural Wood Screws':
      return `${size} x ${length} ${subcategory} - ${drive}`
    case 'Screws/Deck Screws':
      return `${size} x ${length} ${type} ${subcategory} - ${drive} - ${boxSizeLb} lb box`
    case 'Screws/Lag Screws':
      return `${size} x ${length} ${subcategory} - ${drive} - ${finish}`
    case 'Screws/Concrete Screws':
      return `${size} x ${length} ${subcategory} - ${drive} - ${boxSizeCt} ct box`
    case 'Bolts/Hex Head':
      return `${size} x ${length} Hex Head Bolt - ${materialGrade} grade - ${finish} - ${threadStyle} thread`
    case 'Bolts/Carriage':
      return `${size} x ${length} Carriage Bolt - ${materialGrade} grade - ${finish} - ${threadStyle}`
    case 'Bolts/Lag Bolt (Screw)':
      return `${size} x ${length} ${subcategory} - ${drive} - ${finish}`
    case 'Nuts/Hex Nut':
    case 'Nuts/Flange Nut':
    case 'Nuts/Coupling Nut':
    case 'Nuts/Jam Nut':
    case 'Nuts/Lock Nut':
    case 'Nuts/Square Nut':
    case 'Nuts/T-Nut':
    case 'Nuts/Wing Nut':
      return `${size} ${subcategory} - ${finish}`
    case 'Washers/Flat':
    case 'Washers/Lock':
    case 'Washers/Square':
    case 'Washers/Fender':
      return `${size} ${subcategory} Washer - ${finish}`
    case 'Nails - Collated/Framing':
      return `${collation} Framing Nails - ${size} x ${length} ${finish} - ${shank} shank - ${boxSizeCt} ct box`
    case 'Nails - Collated/Siding':
      return `${collation} Siding Nails ${size} x ${length} ${finish} - ${boxSizeCt} ct box`
    case 'Nails - Collated/Roofing':
      return `${collation} Roofing Nails ${size} x ${length} ${finish} - ${boxSizeCt} ct box`
    case 'Nails - Collated/Finish':
      return `${collation} Collated Finish Nails ${size} x ${length} ${finish} - ${boxSizeCt} ct box`
    case 'Nails - Collated/Brad':
      return `${collation} Collated Brad Nails ${size} x ${length} ${finish} - ${boxSizeCt} ct box`
    case 'Nails - Loose/Framing':
      return `${size} Sinker Nails - ${finish} - ${boxSizeLb} lb box`
    case 'Nails - Loose/Roofing':
      return `${size} x ${length} Roofing Nails - ${finish} ${cap === 'None' ? 'No' : cap} cap - ${boxSizeLb} lb box`
    case 'Nails - Loose/Drywall':
      return `${size} Drywall Nails - ${finish} - ${boxSizeLb} lb box`
    case 'Nails - Loose/Common':
      return `${size} Nails - ${finish} - ${boxSizeLb} lb box`
    case 'Nails - Loose/Deck':
      return `${size} Deck Nails - ${finish} - ${shank} shank - ${boxSizeLb} lb box`
    case 'Staples/Heavy Duty Staples':
      return `${leg} ${crown} crown Heavy Duty Staples - ${boxSizeCt} ct box`
    case 'Staples/Flooring Staples':
      return `${crown} x ${leg} Flooring Staples - ${boxSizeCt} ct box"`
    case 'Threaded Rod/Threaded Rod':
      return `${size} x ${length} Threaded Rod - ${finish}`
  }
}

export default FastenerConfig
