import { RefObject, useRef, useState } from 'react'
import useResizeObserver from '@react-hook/resize-observer'

type UseMinHeightHelpers<TargetRefType extends HTMLElement> = {
  targetRef: RefObject<TargetRefType>
  height: number
}

export const useTargetHeight = <TargetRefType extends HTMLElement>(): UseMinHeightHelpers<TargetRefType> => {
  const targetRef = useRef<TargetRefType | null>(null)
  const [targetRect, setTargetRect] = useState<DOMRect | undefined>()

  useResizeObserver(targetRef, target => setTargetRect(target.contentRect))

  return {
    targetRef,
    height: targetRect?.height ?? 0,
  }
}

export default useTargetHeight
