import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { TaskRouteParams, HistoryItem, OrderHistorytask } from 'types/tasks'
import Card from '@supplyhound/components/common/Card'
import Spacer from '@supplyhound/components/common/Spacer'
import HistoryDetails from './HistoryDetails'
import { useLoadingState } from '@supplyhound/hooks'
import useStores from 'hooks/useStores'

import TopPageNavigation from 'containers/Layout/TopPageNavigation'

import { ORDER_PATH } from 'config/paths'
import moment from 'moment'
import ImagePreview from '@supplyhound/components/ImagePreview'

const Main = styled.div`
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 881px;
`

const Side = styled.div`
  width: 341px;
`

const DetailsCard = styled(Card)`
  padding: 20px;
`

const StyledHistory = styled.div`
  padding: 5px 0;
  margin: 10px 0;
`

const HistoryBadge = styled.div`
  border: 1px solid #000;
  border-radius: 8px;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  padding: 5px;
  margin-right: 5px;
  text-align: center;
`
const HistoryDatetime = styled.div`
  display: flex;
  align-items: center;
`

const HistoryThumbnail = styled(ImagePreview)`
  cursor: pointer;
`

const renderPickupHistoryItem = (historyItem: HistoryItem) => {
  if (historyItem.description.toLowerCase().includes('created')) {
    return (
      <StyledHistory key={historyItem.id}>
        <HistoryBadge>Created</HistoryBadge>
        <HistoryDatetime>{moment(historyItem.creation_datetime).format('MM/D/YYYY h:mm A')}</HistoryDatetime>
      </StyledHistory>
    )
  } else if (historyItem.description.toLowerCase().includes('arrived')) {
    return (
      <StyledHistory key={historyItem.id}>
        <HistoryBadge>Arrived</HistoryBadge>
        <HistoryDatetime>{moment(historyItem.creation_datetime).format('MM/D/YYYY h:mm A')}</HistoryDatetime>
      </StyledHistory>
    )
  } else if (historyItem.description.toLowerCase().includes('started')) {
    return (
      <StyledHistory key={historyItem.id}>
        <HistoryBadge>Pickup Started</HistoryBadge>
        <HistoryDatetime>{moment(historyItem.creation_datetime).format('MM/D/YYYY h:mm A')}</HistoryDatetime>
      </StyledHistory>
    )
  } else if (historyItem.description.toLowerCase().includes('successful')) {
    return (
      <StyledHistory key={historyItem.id}>
        <HistoryBadge>Pickup Complete</HistoryBadge>
        <HistoryDatetime>{moment(historyItem.creation_datetime).format('MM/D/YYYY h:mm A')}</HistoryDatetime>
      </StyledHistory>
    )
  }
  return null
}

const renderDeliveryHistoryItem = (historyItem: HistoryItem) => {
  if (historyItem.description.toLowerCase().includes('arrived')) {
    return (
      <StyledHistory key={historyItem.id}>
        <HistoryBadge>Arrived</HistoryBadge>
        <HistoryDatetime>{moment(historyItem.creation_datetime).format('MM/D/YYYY h:mm A')}</HistoryDatetime>
      </StyledHistory>
    )
  } else if (historyItem.description.toLowerCase().includes('successful')) {
    return (
      <StyledHistory key={historyItem.id}>
        <HistoryBadge>Delivery Complete</HistoryBadge>
        <HistoryDatetime>{moment(historyItem.creation_datetime).format('MM/D/YYYY h:mm A')}</HistoryDatetime>
      </StyledHistory>
    )
  } else if (historyItem.type === 'signature_image_added' || historyItem.type === 'image_added') {
    return <HistoryImageContainer key={historyItem.id} historyItem={historyItem} />
  }
  return null
}

const HistoryImageContainer = ({ historyItem }: { historyItem: HistoryItem }) => {
  return (
    <StyledHistory>
      <HistoryBadge>Image Upload</HistoryBadge>
      <HistoryDatetime>{moment(historyItem.creation_datetime).format('MM/D/YYYY h:mm A')}</HistoryDatetime>
      <HistoryThumbnail src={historyItem.description} thumbnailSrc={historyItem.description} width={85} height={85} />
    </StyledHistory>
  )
}

const OrderDetail = () => {
  const { taskId } = useParams<TaskRouteParams>()
  const [task, setTask] = useState<OrderHistorytask | null>(null)
  const { loadWhile } = useLoadingState()
  const { taskStore } = useStores()

  useEffect(() => {
    loadWhile(() => taskStore.dispatchFetchOrderHistory(parseInt(taskId)).then(task => setTask(task)))
  }, [taskId])

  if (task === null) {
    return null
  }

  return (
    <div>
      <TopPageNavigation backButtonLink={`${ORDER_PATH}?tab=history`} backButtonText="History" />
      <Main>
        <Content>
          <DetailsCard>
            <HistoryDetails task={task} />
          </DetailsCard>
        </Content>
        <Spacer width={20} />
        <Side>
          <DetailsCard>
            <h2>Activity Timeline</h2>
            {task?.task_history.pickup &&
              task.task_history.pickup.map(history => {
                return renderPickupHistoryItem(history)
              })}
            {task?.task_history.delivery &&
              task.task_history.delivery.map(history => {
                return renderDeliveryHistoryItem(history)
              })}
          </DetailsCard>
        </Side>
      </Main>
    </div>
  )
}

export default observer(OrderDetail)
