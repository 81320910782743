import { CommonTaskStatuses, DeliveryTaskStatuses, PickupTaskStatuses } from 'types/tasks'

export const TaskStatuses = {
  [CommonTaskStatuses.New]: 'New',
  [CommonTaskStatuses.UnderReview]: 'Under Review',
  [CommonTaskStatuses.ChangeRequested]: 'Change Requested',
  [CommonTaskStatuses.AwaitingReceipt]: 'Awaiting Receipt',
  [CommonTaskStatuses.ReceivedReceipt]: 'Recieved Receipt',
  [CommonTaskStatuses.Confirmed]: 'Confirmed',
  [CommonTaskStatuses.PickedUp]: 'Picked Up',
  [DeliveryTaskStatuses.Delivered]: 'Delivered',
  [CommonTaskStatuses.Canceled]: 'Cancelled',
  [PickupTaskStatuses.Restocked]: 'Restocked',
}
