import { action, makeAutoObservable } from 'mobx'

export class SessionStore {
  private _isSessionExpired = false

  constructor() {
    makeAutoObservable(this, {
      setIsSessionExpired: action,
    })
  }

  get isSessionExpired() {
    return this._isSessionExpired
  }

  setIsSessionExpired = (isSessionExpired: boolean) => {
    this._isSessionExpired = isSessionExpired
  }

  reset = () => {
    this._isSessionExpired = false
  }
}
