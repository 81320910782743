import React from 'react'
import styled from 'styled-components'
import { IonPopover } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'

type IonPopoverProps = React.ComponentProps<typeof IonPopover>

type Props = {
  className?: string
  content: React.ReactNode
  icon: string
  id: string
  clickedIcon?: string
  ionPopoverStyle?: React.CSSProperties
  trackIconClicked?: () => void
}

const StyledIcon = styled(Icon)`
  cursor: pointer;
`
const PopoverContent = styled.div`
  border-radius: 10px;
  font-size: 0.75rem;
  font-weight: 400;
  border: 1px solid var(--ion-color-dark);
  padding: var(--space-unit);
`

const IconPopover: React.FC<Props & IonPopoverProps> = ({
  className,
  content,
  icon,
  id,
  clickedIcon,
  ionPopoverStyle,
  alignment = 'end',
  side = 'top',
  trackIconClicked,
}) => {
  const [isOpen, setOpened] = React.useState(false)
  return (
    <div
      className={className}
      onClick={() => {
        setOpened(true)
        if (trackIconClicked) trackIconClicked()
      }}
    >
      <div id={id}>
        <StyledIcon icon={clickedIcon && isOpen ? clickedIcon : icon} width={30} />
      </div>
      <IonPopover
        trigger={id}
        side={side}
        onDidDismiss={() => setOpened(false)}
        style={ionPopoverStyle}
        alignment={alignment}
        arrow={false}
      >
        <PopoverContent>{content}</PopoverContent>
      </IonPopover>
    </div>
  )
}

export default IconPopover
