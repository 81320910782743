import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import Alert from 'react-bootstrap/Alert'

import OverlaySpinner from 'components/OverlaySpinner'

import Spacer from '@supplyhound/components/common/Spacer'

import Table from './Table'

const Layout = styled.div`
  width: 100%;
`

const DashboardPage = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  return (
    <Layout>
      <Spacer height={20} />
      {loading && <OverlaySpinner />}

      {error && <Alert variant="danger">{error}</Alert>}

      <Table setLoading={setLoading} setError={setError} />
      <Spacer height={20} />
    </Layout>
  )
}

export default observer(DashboardPage)
