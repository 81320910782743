import * as Yup from 'yup'

export const GenericItemValidationSchema = Yup.object().shape({
  image: Yup.mixed().nullable(),
  description: Yup.string().when('image', {
    is: image => !!image,
    then: Yup.string(),
    otherwise: Yup.string().required('Enter a description or upload an image'),
  }),
  quantity: Yup.number().integer().required('Enter a number').moreThan(0, 'Quantity must be more than 0'),
})
