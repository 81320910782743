import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react'
import styled from 'styled-components'

export type ToggleableTab = {
  name: string
  label: string
}

const StyledIonSegmentButton = styled(IonSegmentButton)<{ checked: boolean }>`
  padding: 8px;

  &::before {
    border: none;
  }

  &.segment-button-checked {
    --indicator-box-shadow: none;
    background-color: var(--ion-color-tertiary);

    ::part(indicator-background) {
      background-color: var(--ion-color-tertiary);
    }
  }
  ${props => (props.checked ? 'border: 3px solid var(--ion-color-dark);' : '')}
  ${props => (props.checked ? 'background-color: var(--ion-background-color)' : '')}
`

const StyledIonSegment = styled(IonSegment)`
  background-color: var(--greyscale-1);
  ion-app.dark & {
    color: unset;
  }
  .segment-button-checked {
    border: 3px solid var(--ion-color-primary);
    ion-app.dark & {
      color: var(--perma-dark-color);
    }
  }
`

type TabProps = {
  isActive: boolean
  label: string
  onClick: () => void
}

const Tab: React.FC<TabProps> = ({ onClick, isActive, label, ...props }) => {
  return (
    <StyledIonSegmentButton checked={isActive} value={label} onClick={onClick} {...props}>
      <IonLabel>{label}</IonLabel>
    </StyledIonSegmentButton>
  )
}

type ToggleableTabsProps = {
  activeTabName: string
  tabs: ToggleableTab[]
  onToggle: (name: string) => void
}

const ToggleableTabs: React.FC<ToggleableTabsProps> = ({ activeTabName, tabs, onToggle }) => {
  const [activeState, setActiveState] = useState(activeTabName)

  useEffect(() => {
    if (activeTabName !== activeState) {
      setActiveState(activeTabName)
    }
  }, [activeTabName])

  return (
    <StyledIonSegment>
      {tabs.map(({ name, label }) => {
        return (
          <Tab
            isActive={activeState === name}
            key={name}
            label={label}
            onClick={() => {
              setActiveState(name)
              onToggle(name)
            }}
          />
        )
      })}
    </StyledIonSegment>
  )
}

export default observer(ToggleableTabs)
