import React from 'react'
import styled from 'styled-components'
import { FormikProps, Field } from 'formik'
import { IonLabel } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import { cameraOutline } from 'ionicons/icons'
import { useDropzone } from 'react-dropzone'
import { Photo } from '@capacitor/camera'
import { Capacitor } from '@capacitor/core'
import usePhoto from '@supplyhound/hooks/usePhoto'
import clsx from 'clsx'
import { FileWithUrl, OptionallyIdentifiedOrdererListItem, toEnumType, OrdererListItem } from '@supplyhound/types'

import InlineModal from '@supplyhound/components/common/Modals/InlineModal'
import TextAreaField from '@supplyhound/forms/fields/TextAreaField'
import TextInputField from './fields/TextInputField'

import ImagePreview from '@supplyhound/components/ImagePreview'
import withOrdererListItemFormikOptions from '@supplyhound/components/hoc/withOrdererListItemFormikOptions'
import useStores from '@supplyhound/hooks/useStores'
import BaseButton from '@supplyhound/components/buttons/BaseButton'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { HeaderModes } from '@supplyhound/layout'

import { BoldHistory } from '@supplyhound/icons'

import ShortCuts, { ALL_SHORTCUTS } from './shortcuts'

export const FormModes = {
  Add: 'Add',
  Edit: 'Edit',
}

type FormMode = toEnumType<typeof FormModes>

export interface OrdererListItemModalFormProps {
  isOpen: boolean
  item?: OptionallyIdentifiedOrdererListItem
  onCancel: () => void
  onSubmit: (item: OptionallyIdentifiedOrdererListItem) => Promise<void>
  headerSubtitle?: string
  mode?: FormMode
  onAddFromHistoryClick?: () => void
  shortcuts?: string[]
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Row = styled.div`
  padding-top: calc(2.5 * var(--space-unit));
`

const FooterRow = styled(Row)`
  flex: auto 1 0;
  padding: calc(2 * var(--space-unit)) 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const StyledTextAreaField = styled(TextAreaField)`
  .native-textarea {
    min-height: 140px;
    margin-bottom: 40px;
  }
`

const ImageLabel = styled(IonLabel)`
  padding-left: calc(0.5 * var(--space-unit));
`

const AddImageLabel = styled(ImageLabel)`
  color: var(--ion-color-anchor);
`

const StyledImagePreview = styled(ImagePreview)`
  position: absolute;
  top: 50px;
  left: 10px;
  z-index: 2;
`

const DescriptionWrapper = styled.div`
  position: relative;

  &.image-attached ${StyledTextAreaField} {
    padding-left: 100px;
  }
`

const MoreAddOption = styled.div<{ showAddFromHistory: boolean }>`
  width: 100%;
  display: flex;
  position: absolute;
  top: 185px;
  padding: ${props => (props.showAddFromHistory ? '10px;' : '10px 20px 10px 20px;')};
  justify-content: ${props => (props.showAddFromHistory ? 'space-around' : 'space-between')};
  background-color: var(--ion-color-tertiary);
  z-index: 1000;
  font-weight: 500;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const ImageLink = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--perma-dark-color);
`

const AddFromHistory = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--perma-dark-color);
`

const History = styled(Icon)`
  width: 25px;
  color: var(--perma-dark-color);
`
const HistoryLabel = styled.div`
  padding-left: calc(0.5 * var(--space-unit));
`

const RemoveItemButton = styled(BaseButton)`
  color: var(--ion-color-danger);
  height: 16px;
`
const RemoveButtonRow = styled(Row)`
  display: flex;
  justify-content: center;
`

const OrdererListItemModalForm = ({
  isOpen,
  values,
  onCancel,
  setFieldValue,
  submitForm,
  headerSubtitle,
  mode,
  resetForm,
  onAddFromHistoryClick,
  item,
  shortcuts = ALL_SHORTCUTS,
}: OrdererListItemModalFormProps & FormikProps<OptionallyIdentifiedOrdererListItem>): React.ReactElement => {
  const {
    jobSitesStore: { listStore },
  } = useStores()

  const addCTAText = 'Add item to list'
  const titleText = mode === FormModes.Edit ? 'Edit item' : addCTAText
  const submitText = mode === FormModes.Edit ? 'Finish editing' : addCTAText
  const isNative = Capacitor.isNativePlatform()

  const {
    getRootProps: getRootDropzoneProps,
    getInputProps: getInputDropzoneProps,
    open: openDropzone,
  } = useDropzone({
    accept: 'image/*',
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles: FileWithUrl[]) => {
      const image = acceptedFiles[0]

      image.url = URL.createObjectURL(image)
      setFieldValue('image', image)
    },
  })

  const { takePicture } = usePhoto({
    takePictureCallback: async (photo: Photo) => {
      if (photo.webPath) {
        const fetchedImage = await fetch(photo.webPath)
        const imageBlob = await fetchedImage.blob()
        const file = new File([imageBlob], `uploaded-image-${Date.now()}`) as FileWithUrl
        file.url = photo.webPath
        setFieldValue('image', file)
      }
    },
  })
  const uploadPicture = Capacitor.getPlatform() === 'web' ? openDropzone : takePicture

  const handleDismiss = () => {
    resetForm()
    onCancel()
  }

  const modalMode = mode === FormModes.Edit ? HeaderModes.Leaf : HeaderModes.Cancel

  let placeholderText = `Order anything from any of your suppliers by\n    -  Typing a description like "2x4x10 DF Dry"\n    -  Adding a picture of your list or the item\n    -  Re-ordering from your order history`
  if (isNative) {
    placeholderText = `describe the item for your supplier                    \n    -  add a picture                                   \n    -  or use a shortcut below`
  }

  return (
    <InlineModal
      isOpen={isOpen}
      onDidDismiss={handleDismiss}
      back={handleDismiss}
      label={titleText}
      subLabel={headerSubtitle}
      mode={modalMode}
    >
      <ContentWrapper>
        <Row>
          <DescriptionWrapper
            className={clsx({
              'image-attached': !!values.image?.url,
            })}
          >
            <Field
              name="description"
              component={StyledTextAreaField}
              label="ITEM DESCRIPTION"
              placeholder={!!values.image?.url ? 'Add description (optional)' : placeholderText}
              extraContent={
                <MoreAddOption showAddFromHistory={!!onAddFromHistoryClick} className="extraContent">
                  <ImageLink onClick={uploadPicture}>
                    <Icon width={25} icon={cameraOutline} />
                    <AddImageLabel>Add Image</AddImageLabel>
                    {/* @ts-ignore https://github.com/react-dropzone/react-dropzone/issues/1052 */}
                    <div {...getRootDropzoneProps()}>
                      <input {...getInputDropzoneProps()} />
                    </div>
                  </ImageLink>
                  {onAddFromHistoryClick && (
                    <AddFromHistory
                      onClick={() => {
                        onAddFromHistoryClick()
                      }}
                    >
                      <History icon={BoldHistory} />
                      <HistoryLabel>Re-order</HistoryLabel>
                    </AddFromHistory>
                  )}
                </MoreAddOption>
              }
            />
            {values.image && (
              <StyledImagePreview
                height={75}
                width={75}
                thumbnailSrc={values.image.thumbnail_url || values.image.url}
                src={values.image.url}
              />
            )}
          </DescriptionWrapper>
        </Row>

        {(values.description || values.image) && (
          <Row>
            <Field
              name="quantity"
              component={TextInputField}
              type="number"
              label="QUANTITY"
              placeholder="Add quantity"
            />
          </Row>
        )}

        {mode === FormModes.Edit && (
          <RemoveButtonRow>
            <RemoveItemButton
              fill="clear"
              size="small"
              onClick={() => {
                if (item) {
                  listStore.dispatchArchiveItem(item as OrdererListItem).then(() => handleDismiss())
                }
              }}
            >
              Delete Item
            </RemoveItemButton>
          </RemoveButtonRow>
        )}
        {!values.description && <ShortCuts setFieldValue={setFieldValue} shortcuts={shortcuts} />}

        <FooterRow>
          <SubmitButton onClick={submitForm}>{submitText}</SubmitButton>
        </FooterRow>
      </ContentWrapper>
    </InlineModal>
  )
}

export default withOrdererListItemFormikOptions<OrdererListItemModalFormProps>()(OrdererListItemModalForm)
