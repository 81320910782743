import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { IonToast } from '@ionic/react'
import useStores from '@supplyhound/hooks/useStores'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { ShLogo } from '@supplyhound/images'
import LoginForm, { LoginFormValues } from '@supplyhound/forms/LoginForm'
import { logIn as logInApi } from '@supplyhound/api'
import { createPasswordReset, updatePasswordReset } from '@supplyhound/api'
import ResetPasswordForm, { ResetPasswordFormValues } from '@supplyhound/forms/ResetPasswordForm'
import EnterNewPasswordForm, { EnterNewPasswordFormValues } from '@supplyhound/forms/EnterNewPasswordForm'
import { HOME_ROUTE } from '@supplyhound/utils/config'
import { close } from 'ionicons/icons'

export type LoginFlowStateType = 'login' | 'reset_password' | 'enter_password'
const LogoImg = styled.img`
  height: 60%;
  margin-left: 15px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #413e3e;
  align-items: center;
`

const ForProsLabel = styled.h4`
  position: absolute;
  right: 17px;
`

const LoginModal = ({
  setLoginFlowState,
  loginFlowState,
  dismiss,
  email,
  token,
}: {
  setLoginFlowState: (state: LoginFlowStateType) => any
  loginFlowState: LoginFlowStateType
  dismiss: () => void
  email: string
  token: string
}) => {
  const { userProfileStore } = useStores()
  const { loadWhile } = useLoadingState()
  const [error, setError] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState(
    "Enter your email if you forgot your password or need a new one. We'll send a link to reset it."
  )

  const handleSubmitLoginForm = async ({ email, password }: LoginFormValues) => {
    try {
      const { data } = await loadWhile(() => logInApi({ email: email.trim(), password }))
      const user = userProfileStore.decodedToken

      if (typeof user === 'object' && user?.role !== 'orderer') {
        setError('Invalid user.')
        return
      }

      // Set token and load profile
      userProfileStore.authStore.setToken(data.jwt)
      dismiss()

      await userProfileStore.dispatchLoadProfile()
      await userProfileStore.identifyFullStoryUser()
    } catch (e) {
      setError('Incorrect email or password, please try again.')
    }
  }

  const handleSubmitResetPassword = async ({ email }: ResetPasswordFormValues) => {
    loadWhile(() =>
      createPasswordReset(email, HOME_ROUTE)
        .then(() => {
          setShowToast(true)
          setMessage('Check your email for a recovery link.')
        })
        .catch(() => console.error('Request password reset failed'))
    )
  }

  const handleSubmitEnterPassword = ({ new_password }: EnterNewPasswordFormValues) =>
    loadWhile(() =>
      updatePasswordReset(email, new_password, token)
        .then(() => {
          setLoginFlowState('login')
        })
        .catch(() => {
          console.log('Oops. Something went wrong')
        })
    )

  return (
    <div>
      <Row className="inconsolata-ff">
        <LogoImg src={ShLogo} alt="logo" />
        <ForProsLabel>For pros</ForProsLabel>
      </Row>
      {loginFlowState === 'login' && (
        <LoginForm
          error={error}
          onSubmit={handleSubmitLoginForm}
          triggerResetPasswordModal={() => setLoginFlowState('reset_password')}
        />
      )}
      {loginFlowState === 'reset_password' && (
        <ResetPasswordForm
          message={message}
          onSubmit={handleSubmitResetPassword}
          onBackToLoginClick={() => setLoginFlowState('login')}
        />
      )}
      {loginFlowState === 'enter_password' && <EnterNewPasswordForm onSubmit={handleSubmitEnterPassword} />}
      <IonToast
        isOpen={showToast}
        mode="ios"
        color="dark"
        onDidDismiss={() => setShowToast(false)}
        message="Check your email for a recovery link."
        position="top"
        buttons={[
          {
            icon: close,
            role: 'cancel',
          },
        ]}
      />
    </div>
  )
}

export default observer(LoginModal)
