import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { createSubscription, updateSubscription, deleteSubscription } from '@supplyhound/api'
import { BillingPlanType, SubscriptionType, Subscription, ID } from '@supplyhound/types'

export class SubscriptionStore {
  subscription: Subscription | undefined = undefined

  constructor() {
    makeAutoObservable(this)
  }

  reset() {
    this.subscription = undefined
  }

  get getSubscriptionJson() {
    return toJS(this.subscription)
  }

  setSubscription = (subscription: Subscription) => {
    this.subscription = subscription
  }

  dispatchCreateSubscription = async ({
    subscriptionType,
    billingPlanType,
    stripePaymentMethodId,
  }: {
    subscriptionType: SubscriptionType
    billingPlanType: BillingPlanType
    stripePaymentMethodId: string
  }) => {
    const subscription = await createSubscription({ subscriptionType, billingPlanType, stripePaymentMethodId })
    runInAction(() => {
      this.subscription = subscription
    })
    return subscription
  }

  dispatchUpdateSubscription = async (
    subscriptionId: ID,
    {
      subscriptionType,
      billingPlanType,
      stripePaymentMethodId,
    }: {
      subscriptionType: SubscriptionType
      billingPlanType: BillingPlanType
      stripePaymentMethodId: string
    }
  ) => {
    await updateSubscription(subscriptionId, {
      subscriptionType,
      billingPlanType,
      stripePaymentMethodId,
    })
    runInAction(() => {
      this.subscription = {
        ...this.subscription,
        stripePaymentMethodId,
        billingPlanType,
      } as Subscription
    })
  }

  dispatchDeleteSubscription = async (subscriptionId: ID) => {
    return deleteSubscription(subscriptionId)
  }
}
