import { apiAxiosInstance } from '.'

export type CurriBookingDetails = {
  scheduledAt: string
  pickupContactName: string
  pickupContactPhone: string
  pickupCompany: string
  dropoffContactName: string
  dropoffContactPhone: string
  metaPo: string
  metaOrderNo: string
  metaPickupNote: string
  metaDropoffNote: string
}

export async function updateDeliveryNetwork(
  taskId: number | string,
  network: string,
  bookingDetails?: CurriBookingDetails
): Promise<any> {
  const payload = {
    task_id: taskId,
    network,
    booking_details: bookingDetails,
  }

  const response = await apiAxiosInstance.post<any>(`/v1/network_delivery/set_network`, payload)

  return response.data
}

export async function getCurriBookingDetails(taskId: number | string): Promise<CurriBookingDetails> {
  const response = await apiAxiosInstance.get<CurriBookingDetails>(
    `/v1/network_delivery/curri_booking_details/${taskId}`
  )

  return response.data
}
