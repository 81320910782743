import _ from 'lodash'

type PlumbingConfigType = {
  'Pipe & Tubing': {
    [key: string]: {
      specification: string[]
      applicationType: string[]
      endType: string[]
      lengthAngle: string[]
      insideDiameter: string[]
    }
  }
  'Pipe Fittings': {
    [key: string]: {
      [key: string]: {
        specification: string[]
        endType: string[]
        lengthAngle: string[]
        insideDiameter: string[]
      }
    }
  }
}
export interface PlumbingFormValues {
  plumbingCategory: string
  material: string
  specification: string
  applicationType: string
  endType: string
  lengthAngle: string
  insideDiameter: string
}

const PlumbingConfig: PlumbingConfigType = {
  'Pipe & Tubing': {
    PVC: {
      specification: ['Schedule 40', 'Schedule 40 - Foam Core', 'Schedule 80', 'Perforated', 'Purple Reclaimed'],
      applicationType: ['Pressure', 'Drainage (DWV)'],
      endType: ['Plain', 'Bell', 'Threaded'],
      lengthAngle: ["5'", "10'", "20'"],
      insideDiameter: ['1/2"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
    },
    ABS: {
      specification: ['Schedule 40', 'Schedule 40 - Cellular Core'],
      applicationType: ['Drainage (DWV)'],
      endType: ['Bell'],
      lengthAngle: ["5'", "10'", "20'"],
      insideDiameter: ['1 1/2"', '2"', '3"', '4"'],
    },
    'Copper - Soft': {
      specification: ['Type K', 'Type L'],
      applicationType: [],
      endType: [],
      lengthAngle: ["40'", "50'", "60'", "100'"],
      insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"'],
    },
    'Copper - Hard': {
      specification: ['Type K', 'Type L', 'Type M'],
      applicationType: [],
      endType: [],
      lengthAngle: ["2'", "5'", "10'", "20'"],
      insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"'],
    },
    Steel: {
      specification: ['Schedule 40', 'Schedule 80'],
      applicationType: ['Black', 'Galvanized'],
      endType: ['Plain', 'Threaded'],
      lengthAngle: ["2'", "3'", "4'", "5'", "6'", "10'", "21'"],
      insideDiameter: [
        '1/4"',
        '3/8"',
        '1/2"',
        '3/4"',
        '1"',
        '1 1/4"',
        '1 1/2"',
        '2"',
        '2 1/2"',
        '3"',
        '4"',
        '6"',
        '8"',
      ],
    },
    PEX: {
      specification: ['White', 'Blue', 'Red'],
      applicationType: [],
      endType: [],
      lengthAngle: ["10'", "20'", "100'", "300'", "500'"],
      insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"'],
    },
  },
  'Pipe Fittings': {
    PVC: {
      Elbow: {
        specification: ['Schedule 40', 'Schedule 80'],
        endType: ['All Hub', 'Socket Weld', 'Spigot x Hub'],
        lengthAngle: ['22.5°', '45°', '90°', '90° long'],
        insideDiameter: ['1/2"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
      Tee: {
        specification: ['Schedule 40', 'Schedule 80'],
        endType: ['All Hub', 'Socket Weld'],
        lengthAngle: [],
        insideDiameter: ['1/2"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
      Wye: {
        specification: ['Schedule 40', 'Schedule 80'],
        endType: ['All Hub', 'Socket Weld'],
        lengthAngle: [],
        insideDiameter: ['1/2"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
      Coupling: {
        specification: ['Schedule 40', 'Schedule 80'],
        endType: ['All Hub', 'FPT x FPT', 'Socket Weld', 'FPT'],
        lengthAngle: [],
        insideDiameter: ['1/2"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
      Cap: {
        specification: ['Schedule 40', 'Schedule 80'],
        endType: ['Socket Weld', 'FPT Cap'],
        lengthAngle: [],
        insideDiameter: ['1/2"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
      Plug: {
        specification: ['Schedule 40', 'Schedule 80'],
        endType: ['MPT Plug'],
        lengthAngle: [],
        insideDiameter: ['1/2"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
    },
    ABS: {
      Elbow: {
        specification: [],
        endType: ['All Hub', 'Socket Weld', 'Spigot x Hub'],
        lengthAngle: ['22.5°', '45°', '90°', '90° long'],
        insideDiameter: ['1 1/2"', '2"', '3"', '4"'],
      },
      Tee: {
        specification: [],
        endType: ['All Hub', 'Socket Weld'],
        lengthAngle: [],
        insideDiameter: ['1 1/2"', '2"', '3"', '4"'],
      },
      Wye: {
        specification: [],
        endType: ['All Hub', 'Socket Weld'],
        lengthAngle: [],
        insideDiameter: ['1 1/2"', '2"', '3"', '4"'],
      },
      Coupling: {
        specification: [],
        endType: ['All Hub', 'FPT', 'Socket Weld'],
        lengthAngle: [],
        insideDiameter: ['1 1/2"', '2"', '3"', '4"'],
      },
      Cap: {
        specification: [],
        endType: ['Socket Weld', 'FPT Cap'],
        lengthAngle: [],
        insideDiameter: ['1 1/2"', '2"', '3"', '4"'],
      },
      Plug: {
        specification: [],
        endType: ['MPT Plug'],
        lengthAngle: [],
        insideDiameter: ['1 1/2"', '2"', '3"', '4"'],
      },
    },
    Copper: {
      Adapter: {
        specification: ['Wrot - Sweat Fit', 'Wrot - Press Fit'],
        endType: ['Cup x MPT', 'Cup x FPT', 'Press x MPT', 'Press x FPT'],
        lengthAngle: [],
        insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"'],
      },
      Elbow: {
        specification: ['Wrot - Sweat Fit', 'Wrot - Press Fit'],
        endType: ['Cup', 'Press'],
        lengthAngle: ['22.5°', '45°', '90°', '90° long'],
        insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"'],
      },
      Tee: {
        specification: ['Wrot - Sweat Fit', 'Wrot - Press Fit'],
        endType: ['Cup', 'Press'],
        lengthAngle: [],
        insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"'],
      },
      Coupling: {
        specification: ['Wrot - Sweat Fit', 'Wrot - Press Fit'],
        endType: ['Cup', 'Press'],
        lengthAngle: [],
        insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"'],
      },
      Cap: {
        specification: ['Wrot - Sweat Fit', 'Wrot - Press Fit'],
        endType: ['Cup', 'Press'],
        lengthAngle: [],
        insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"'],
      },
    },
    Steel: {
      'Floor Flange': {
        specification: ['Black', 'Galvanized'],
        endType: ['FPT'],
        lengthAngle: [],
        insideDiameter: ['1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
      'Union Fitting': {
        specification: ['Black', 'Galvanized'],
        endType: ['FPT x FPT'],
        lengthAngle: [],
        insideDiameter: ['1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
      Nipple: {
        specification: ['Black', 'Galvanized'],
        endType: ['MPT'],
        lengthAngle: ['3"', '4"', '5"', '6"', '8"', '10"', '12"'],
        insideDiameter: ['1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
      Elbow: {
        specification: ['Black', 'Galvanized'],
        endType: ['Threaded', 'Butt Weld'],
        lengthAngle: ['22.5°', '45°', '90°'],
        insideDiameter: ['1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
      Tee: {
        specification: ['Black', 'Galvanized'],
        endType: ['Threaded'],
        lengthAngle: [],
        insideDiameter: ['1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
      Cap: {
        specification: ['Black', 'Galvanized'],
        endType: ['Threaded'],
        lengthAngle: [],
        insideDiameter: ['1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
      Plug: {
        specification: ['Black', 'Galvanized'],
        endType: ['Threaded'],
        lengthAngle: [],
        insideDiameter: ['1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"', '4"', '6"', '8"'],
      },
    },
    PEX: {
      Elbow: {
        specification: ['Plastic', 'Brass'],
        endType: ['Barbed'],
        lengthAngle: ['45°', '90°'],
        insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"'],
      },
      Tee: {
        specification: ['Plastic', 'Brass'],
        endType: ['Barbed'],
        lengthAngle: [],
        insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"'],
      },
      Coupling: {
        specification: ['Plastic', 'Brass'],
        endType: ['Barbed'],
        lengthAngle: [],
        insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"'],
      },
      Plug: {
        specification: ['Plastic', 'Brass'],
        endType: ['Barbed'],
        lengthAngle: [],
        insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"'],
      },
      Ring: {
        specification: ['Plastic', 'Brass', 'Copper'],
        endType: [],
        lengthAngle: [],
        insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"'],
      },
      Adapter: {
        specification: ['Brass'],
        endType: ['PEX x MNPT', 'PEX x FNPT'],
        lengthAngle: [],
        insideDiameter: ['1/4"', '3/8"', '1/2"', '5/8"', '3/4"', '1"', '1 1/4"', '1 1/2"', '2"', '2 1/2"', '3"'],
      },
    },
  },
}

const plumbingDisplayConfig = {
  "Pipe & Tubing/PVC/Schedule 40/Pressure/Plain/5'": 'XX" PVC Schedule 40 - 5\' Pressure Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 40/Pressure/Plain/10'": 'XX" PVC Schedule 40 - 10\' Pressure Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 40/Pressure/Plain/20'": 'XX" PVC Schedule 40 - 20\' Pressure Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 40/Pressure/Bell/5'": 'XX" PVC Schedule 40 - 5\' Pressure Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 40/Pressure/Bell/10'": 'XX" PVC Schedule 40 - 10\' Pressure Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 40/Pressure/Bell/20'": 'XX" PVC Schedule 40 - 20\' Pressure Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 40/Drainage (DWV)/Plain/5'": 'XX" PVC Schedule 40 - 5\' DWV Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 40/Drainage (DWV)/Plain/10'": 'XX" PVC Schedule 40 - 10\' DWV Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 40/Drainage (DWV)/Plain/20'": 'XX" PVC Schedule 40 - 20\' DWV Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 40/Drainage (DWV)/Bell/5'": 'XX" PVC Schedule 40 - 5\' DWV Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 40/Drainage (DWV)/Bell/10'": 'XX" PVC Schedule 40 - 10\' DWV Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 40/Drainage (DWV)/Bell/20'": 'XX" PVC Schedule 40 - 20\' DWV Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 40 - Foam Core/Drainage (DWV)/Plain/5'":
    'XX" PVC Schedule 40 (foam core) - 5\' DWV Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 40 - Foam Core/Drainage (DWV)/Plain/10'":
    'XX" PVC Schedule 40 (foam core) - 10\' DWV Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 40 - Foam Core/Drainage (DWV)/Plain/20'":
    'XX" PVC Schedule 40 (foam core) - 20\' DWV Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 40 - Foam Core/Drainage (DWV)/Bell/5'":
    'XX" PVC Schedule 40 (foam core) - 5\' DWV Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 40 - Foam Core/Drainage (DWV)/Bell/10'":
    'XX" PVC Schedule 40 (foam core) - 10\' DWV Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 40 - Foam Core/Drainage (DWV)/Bell/20'":
    'XX" PVC Schedule 40 (foam core) - 20\' DWV Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 80/Pressure/Plain/5'": 'XX" PVC Schedule 80 - 5\' Pressure Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 80/Pressure/Plain/10'": 'XX" PVC Schedule 80 - 10\' Pressure Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 80/Pressure/Plain/20'": 'XX" PVC Schedule 80 - 20\' Pressure Pipe - Plain End',
  "Pipe & Tubing/PVC/Schedule 80/Pressure/Bell/5'": 'XX" PVC Schedule 80 - 5\' Pressure Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 80/Pressure/Bell/10'": 'XX" PVC Schedule 80 - 10\' Pressure Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 80/Pressure/Bell/20'": 'XX" PVC Schedule 80 - 20\' Pressure Pipe - Bell End',
  "Pipe & Tubing/PVC/Schedule 80/Pressure/Threaded/5'": 'XX" PVC Schedule 80 - 5\' Pressure Pipe - Threaded',
  "Pipe & Tubing/PVC/Schedule 80/Pressure/Threaded/10'": 'XX" PVC Schedule 80 - 10\' Pressure Pipe - Threaded',
  "Pipe & Tubing/PVC/Schedule 80/Pressure/Threaded/20'": 'XX" PVC Schedule 80 - 20\' Pressure Pipe - Threaded',
  "Pipe & Tubing/PVC/Perforated/Drainage (DWV)/Bell/10'": 'XX" PVC Perforated - 10\' DWV Pipe - Bell End',
  "Pipe & Tubing/PVC/Perforated/Drainage (DWV)/Bell/20'": 'XX" PVC Perforated - 20\' DWV Pipe - Bell End',
  "Pipe & Tubing/PVC/Purple Reclaimed/Drainage (DWV)/Bell/10'": 'XX" PVC Purple Reclaimed - 10\' DWV Pipe - Bell End',
  "Pipe & Tubing/PVC/Purple Reclaimed/Drainage (DWV)/Bell/20'": 'XX" PVC Purple Reclaimed - 20\' DWV Pipe - Bell End',
  "Pipe & Tubing/ABS/Schedule 40/Drainage (DWV)/Bell/5'": 'XX" ABS Schedule 40 - 5\' DWV Pipe - Bell End',
  "Pipe & Tubing/ABS/Schedule 40/Drainage (DWV)/Bell/10'": 'XX" ABS Schedule 40 - 10\' DWV Pipe - Bell End',
  "Pipe & Tubing/ABS/Schedule 40/Drainage (DWV)/Bell/20'": 'XX" ABS Schedule 40 - 20\' DWV Pipe - Bell End',
  "Pipe & Tubing/ABS/Schedule 40 - Cellular Core/Drainage (DWV)/Bell/5'":
    'XX" ABS Schedule 40 (cellular core) - 5\' DWV Pipe - Bell End',
  "Pipe & Tubing/ABS/Schedule 40 - Cellular Core/Drainage (DWV)/Bell/10'":
    'XX" ABS Schedule 40 (cellular core) - 10\' DWV Pipe - Bell End',
  "Pipe & Tubing/ABS/Schedule 40 - Cellular Core/Drainage (DWV)/Bell/20'":
    'XX" ABS Schedule 40 (cellular core) - 20\' DWV Pipe - Bell End',
  "Pipe & Tubing/Copper - Soft/Type K///40'": 'XX" Copper Type K (soft) - 40\' Pipe',
  "Pipe & Tubing/Copper - Soft/Type K///50'": 'XX" Copper Type K (soft) - 50\' Pipe',
  "Pipe & Tubing/Copper - Soft/Type K///60'": 'XX" Copper Type K (soft) - 60\' Pipe',
  "Pipe & Tubing/Copper - Soft/Type K///100'": 'XX" Copper Type K (soft) - 100\' Pipe',
  "Pipe & Tubing/Copper - Soft/Type L///40'": 'XX" Copper Type L (soft) - 40\' Pipe',
  "Pipe & Tubing/Copper - Soft/Type L///50'": 'XX" Copper Type L (soft) - 50\' Pipe',
  "Pipe & Tubing/Copper - Soft/Type L///60'": 'XX" Copper Type L (soft) - 60\' Pipe',
  "Pipe & Tubing/Copper - Soft/Type L///100'": 'XX" Copper Type L (soft) - 100\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type K///2'": 'XX" Copper Type K (hard) - 2\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type K///5'": 'XX" Copper Type K (hard) - 5\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type K///10'": 'XX" Copper Type K (hard) - 10\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type K///20'": 'XX" Copper Type K (hard) - 20\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type L///2'": 'XX" Copper Type L (hard) - 2\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type L///5'": 'XX" Copper Type L (hard) - 5\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type L///10'": 'XX" Copper Type L (hard) - 10\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type L///20'": 'XX" Copper Type L (hard) - 20\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type M///2'": 'XX" Copper Type M (hard) - 2\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type M///5'": 'XX" Copper Type M (hard) - 5\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type M///10'": 'XX" Copper Type M (hard) - 10\' Pipe',
  "Pipe & Tubing/Copper - Hard/Type M///20'": 'XX" Copper Type M (hard) - 20\' Pipe',
  "Pipe & Tubing/Steel/Schedule 40/Black/Plain/2'": 'XX" Black Steel Schedule 40 - 2\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Black/Plain/3'": 'XX" Black Steel Schedule 40 - 3\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Black/Plain/4'": 'XX" Black Steel Schedule 40 - 4\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Black/Plain/5'": 'XX" Black Steel Schedule 40 - 5\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Black/Plain/6'": 'XX" Black Steel Schedule 40 - 6\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Black/Plain/10'": 'XX" Black Steel Schedule 40 - 10\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Black/Plain/21'": 'XX" Black Steel Schedule 40 - 21\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Black/Threaded/2'": 'XX" Black Steel Schedule 40 - 2\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Black/Threaded/3'": 'XX" Black Steel Schedule 40 - 3\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Black/Threaded/4'": 'XX" Black Steel Schedule 40 - 4\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Black/Threaded/5'": 'XX" Black Steel Schedule 40 - 5\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Black/Threaded/6'": 'XX" Black Steel Schedule 40 - 6\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Black/Threaded/10'": 'XX" Black Steel Schedule 40 - 10\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Black/Threaded/21'": 'XX" Black Steel Schedule 40 - 21\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Plain/2'": 'XX" Galvanized Steel Schedule 40 - 2\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Plain/3'": 'XX" Galvanized Steel Schedule 40 - 3\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Plain/4'": 'XX" Galvanized Steel Schedule 40 - 4\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Plain/5'": 'XX" Galvanized Steel Schedule 40 - 5\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Plain/6'": 'XX" Galvanized Steel Schedule 40 - 6\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Plain/10'": 'XX" Galvanized Steel Schedule 40 - 10\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Plain/21'": 'XX" Galvanized Steel Schedule 40 - 21\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Threaded/2'": 'XX" Galvanized Steel Schedule 40 - 2\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Threaded/3'": 'XX" Galvanized Steel Schedule 40 - 3\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Threaded/4'": 'XX" Galvanized Steel Schedule 40 - 4\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Threaded/5'": 'XX" Galvanized Steel Schedule 40 - 5\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Threaded/6'": 'XX" Galvanized Steel Schedule 40 - 6\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Threaded/10'": 'XX" Galvanized Steel Schedule 40 - 10\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 40/Galvanized/Threaded/21'": 'XX" Galvanized Steel Schedule 40 - 21\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Black/Plain/2'": 'XX" Black Steel Schedule 80 - 2\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Black/Plain/3'": 'XX" Black Steel Schedule 80 - 3\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Black/Plain/4'": 'XX" Black Steel Schedule 80 - 4\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Black/Plain/5'": 'XX" Black Steel Schedule 80 - 5\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Black/Plain/6'": 'XX" Black Steel Schedule 80 - 6\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Black/Plain/10'": 'XX" Black Steel Schedule 80 - 10\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Black/Plain/21'": 'XX" Black Steel Schedule 80 - 21\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Black/Threaded/2'": 'XX" Black Steel Schedule 80 - 2\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Black/Threaded/3'": 'XX" Black Steel Schedule 80 - 3\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Black/Threaded/4'": 'XX" Black Steel Schedule 80 - 4\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Black/Threaded/5'": 'XX" Black Steel Schedule 80 - 5\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Black/Threaded/6'": 'XX" Black Steel Schedule 80 - 6\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Black/Threaded/10'": 'XX" Black Steel Schedule 80 - 10\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Black/Threaded/21'": 'XX" Black Steel Schedule 80 - 21\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Plain/2'": 'XX" Galvanized Steel Schedule 80 - 2\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Plain/3'": 'XX" Galvanized Steel Schedule 80 - 3\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Plain/4'": 'XX" Galvanized Steel Schedule 80 - 4\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Plain/5'": 'XX" Galvanized Steel Schedule 80 - 5\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Plain/6'": 'XX" Galvanized Steel Schedule 80 - 6\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Plain/10'": 'XX" Galvanized Steel Schedule 80 - 10\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Plain/21'": 'XX" Galvanized Steel Schedule 80 - 21\' Pipe - Plain End',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Threaded/2'": 'XX" Galvanized Steel Schedule 80 - 2\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Threaded/3'": 'XX" Galvanized Steel Schedule 80 - 3\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Threaded/4'": 'XX" Galvanized Steel Schedule 80 - 4\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Threaded/5'": 'XX" Galvanized Steel Schedule 80 - 5\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Threaded/6'": 'XX" Galvanized Steel Schedule 80 - 6\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Threaded/10'": 'XX" Galvanized Steel Schedule 80 - 10\' Pipe - Threaded',
  "Pipe & Tubing/Steel/Schedule 80/Galvanized/Threaded/21'": 'XX" Galvanized Steel Schedule 80 - 21\' Pipe - Threaded',
  "Pipe & Tubing/PEX/White///10'": 'XX" PEX White - 10\' Pipe',
  "Pipe & Tubing/PEX/White///20'": 'XX" PEX White - 20\' Pipe',
  "Pipe & Tubing/PEX/White///100'": 'XX" PEX White - 100\' Pipe',
  "Pipe & Tubing/PEX/White///300'": 'XX" PEX White - 300\' Pipe',
  "Pipe & Tubing/PEX/White///500'": 'XX" PEX White - 500\' Pipe',
  "Pipe & Tubing/PEX/Blue///10'": 'XX" PEX Blue - 10\' Pipe',
  "Pipe & Tubing/PEX/Blue///20'": 'XX" PEX Blue - 20\' Pipe',
  "Pipe & Tubing/PEX/Blue///100'": 'XX" PEX Blue - 100\' Pipe',
  "Pipe & Tubing/PEX/Blue///300'": 'XX" PEX Blue - 300\' Pipe',
  "Pipe & Tubing/PEX/Blue///500'": 'XX" PEX Blue - 500\' Pipe',
  "Pipe & Tubing/PEX/Red///10'": 'XX" PEX Red - 10\' Pipe',
  "Pipe & Tubing/PEX/Red///20'": 'XX" PEX Red - 20\' Pipe',
  "Pipe & Tubing/PEX/Red///100'": 'XX" PEX Red - 100\' Pipe',
  "Pipe & Tubing/PEX/Red///300'": 'XX" PEX Red - 300\' Pipe',
  "Pipe & Tubing/PEX/Red///500'": 'XX" PEX Red - 500\' Pipe',
  'Pipe Fittings/PVC/Schedule 40/Elbow/All Hub/22.5°': 'XX" PVC Schedule 40 - 22.5° Elbow - All Hub',
  'Pipe Fittings/PVC/Schedule 40/Elbow/All Hub/45°': 'XX" PVC Schedule 40 - 45° Elbow - All Hub',
  'Pipe Fittings/PVC/Schedule 40/Elbow/All Hub/90°': 'XX" PVC Schedule 40 - 90° Elbow - All Hub',
  'Pipe Fittings/PVC/Schedule 40/Elbow/All Hub/90° long': 'XX" PVC Schedule 40 - 90° Long Sweep Elbow - All Hub',
  'Pipe Fittings/PVC/Schedule 40/Elbow/Socket Weld/22.5°': 'XX" PVC Schedule 40 - 22.5° Elbow - Socket Weld',
  'Pipe Fittings/PVC/Schedule 40/Elbow/Socket Weld/45°': 'XX" PVC Schedule 40 - 45° Elbow - Socket Weld',
  'Pipe Fittings/PVC/Schedule 40/Elbow/Socket Weld/90°': 'XX" PVC Schedule 40 - 90° Elbow - Socket Weld',
  'Pipe Fittings/PVC/Schedule 40/Elbow/Socket Weld/90° long':
    'XX" PVC Schedule 40 - 90° Long Sweep Elbow - Socket Weld',
  'Pipe Fittings/PVC/Schedule 40/Elbow/Spigot x Hub/22.5°': 'XX" PVC Schedule 40 - 22.5° Elbow - Spigot x Hub',
  'Pipe Fittings/PVC/Schedule 40/Elbow/Spigot x Hub/45°': 'XX" PVC Schedule 40 - 45° Elbow - Spigot x Hub',
  'Pipe Fittings/PVC/Schedule 40/Elbow/Spigot x Hub/90°': 'XX" PVC Schedule 40 - 90° Elbow - Spigot x Hub',
  'Pipe Fittings/PVC/Schedule 40/Elbow/Spigot x Hub/90° long':
    'XX" PVC Schedule 40 - 90° Long Sweep Elbow - Spigot x Hub',
  'Pipe Fittings/PVC/Schedule 40/Tee/All Hub/': 'XX" PVC Schedule 40 - Tee - All Hub',
  'Pipe Fittings/PVC/Schedule 40/Tee/Socket Weld/': 'XX" PVC Schedule 40 - Tee - Socket Weld',
  'Pipe Fittings/PVC/Schedule 40/Wye/All Hub/': 'XX" PVC Schedule 40 - Wye - All Hub',
  'Pipe Fittings/PVC/Schedule 40/Wye/Socket Weld/': 'XX" PVC Schedule 40 - Wye - Socket Weld',
  'Pipe Fittings/PVC/Schedule 40/Coupling/All Hub/': 'XX" PVC Schedule 40 - Coupling - All Hub',
  'Pipe Fittings/PVC/Schedule 40/Coupling/FPT x FPT/': 'XX" PVC Schedule 40 - Coupling - FPT x FPT',
  'Pipe Fittings/PVC/Schedule 40/Coupling/Socket Weld/': 'XX" PVC Schedule 40 - Coupling - Socket Weld',
  'Pipe Fittings/PVC/Schedule 40/Cap/Socket Weld/': 'XX" PVC Schedule 40 - Socket Weld Cap',
  'Pipe Fittings/PVC/Schedule 40/Cap/FPT Cap/': 'XX" PVC Schedule 40 - FPT Cap',
  'Pipe Fittings/PVC/Schedule 40/Plug/MPT Plug/': 'XX" PVC Schedule 40 - MPT Plug',
  'Pipe Fittings/PVC/Schedule 80/Elbow/All Hub/22.5°': 'XX" PVC Schedule 80 - 22.5° Elbow - All Hub',
  'Pipe Fittings/PVC/Schedule 80/Elbow/All Hub/45°': 'XX" PVC Schedule 80 - 45° Elbow - All Hub',
  'Pipe Fittings/PVC/Schedule 80/Elbow/All Hub/90°': 'XX" PVC Schedule 80 - 90° Elbow - All Hub',
  'Pipe Fittings/PVC/Schedule 80/Elbow/All Hub/90° long': 'XX" PVC Schedule 80 - 90° Long Sweep Elbow - All Hub',
  'Pipe Fittings/PVC/Schedule 80/Elbow/Socket Weld/22.5°': 'XX" PVC Schedule 80 - 22.5° Elbow - Socket Weld',
  'Pipe Fittings/PVC/Schedule 80/Elbow/Socket Weld/45°': 'XX" PVC Schedule 80 - 45° Elbow - Socket Weld',
  'Pipe Fittings/PVC/Schedule 80/Elbow/Socket Weld/90°': 'XX" PVC Schedule 80 - 90° Elbow - Socket Weld',
  'Pipe Fittings/PVC/Schedule 80/Elbow/Socket Weld/90° long':
    'XX" PVC Schedule 80 - 90° Long Sweep Elbow - Socket Weld',
  'Pipe Fittings/PVC/Schedule 80/Elbow/Spigot x Hub/22.5°': 'XX" PVC Schedule 80 - 22.5° Elbow - Spigot x Hub',
  'Pipe Fittings/PVC/Schedule 80/Elbow/Spigot x Hub/45°': 'XX" PVC Schedule 80 - 45° Elbow - Spigot x Hub',
  'Pipe Fittings/PVC/Schedule 80/Elbow/Spigot x Hub/90°': 'XX" PVC Schedule 80 - 90° Elbow - Spigot x Hub',
  'Pipe Fittings/PVC/Schedule 80/Elbow/Spigot x Hub/90° long':
    'XX" PVC Schedule 80 - 90° Long Sweep Elbow - Spigot x Hub',
  'Pipe Fittings/PVC/Schedule 80/Tee/All Hub/': 'XX" PVC Schedule 80 - Tee - All Hub',
  'Pipe Fittings/PVC/Schedule 80/Tee/Socket Weld/': 'XX" PVC Schedule 80 - Tee - Socket Weld',
  'Pipe Fittings/PVC/Schedule 80/Wye/All Hub/': 'XX" PVC Schedule 80 - Wye - All Hub',
  'Pipe Fittings/PVC/Schedule 80/Wye/Socket Weld/': 'XX" PVC Schedule 80 - Wye - Socket Weld',
  'Pipe Fittings/PVC/Schedule 80/Coupling/All Hub/': 'XX" PVC Schedule 80 - Coupling - All Hub',
  'Pipe Fittings/PVC/Schedule 80/Coupling/FPT/': 'XX" PVC Schedule 80 - Coupling - FPT x FPT',
  'Pipe Fittings/PVC/Schedule 80/Coupling/Socket Weld/': 'XX" PVC Schedule 80 - Coupling - Socket Weld',
  'Pipe Fittings/PVC/Schedule 80/Cap/Socket Weld/': 'XX" PVC Schedule 80 - Socket Weld Cap',
  'Pipe Fittings/PVC/Schedule 80/Cap/FPT Cap/': 'XX" PVC Schedule 80 - FPT Cap',
  'Pipe Fittings/PVC/Schedule 80/Plug/MPT Plug/': 'XX" PVC Schedule 80 - MPT Plug',
  'Pipe Fittings/ABS//Elbow/All Hub/22.5°': 'XX" ABS - 22.5° Elbow - All Hub',
  'Pipe Fittings/ABS//Elbow/All Hub/45°': 'XX" ABS - 45° Elbow - All Hub',
  'Pipe Fittings/ABS//Elbow/All Hub/90°': 'XX" ABS - 90° Elbow - All Hub',
  'Pipe Fittings/ABS//Elbow/All Hub/90° long': 'XX" ABS - 90° Long Sweep Elbow - All Hub',
  'Pipe Fittings/ABS//Elbow/Socket Weld/22.5°': 'XX" ABS - 22.5° Elbow - Socket Weld',
  'Pipe Fittings/ABS//Elbow/Socket Weld/45°': 'XX" ABS - 45° Elbow - Socket Weld',
  'Pipe Fittings/ABS//Elbow/Socket Weld/90°': 'XX" ABS - 90° Elbow - Socket Weld',
  'Pipe Fittings/ABS//Elbow/Socket Weld/90° long': 'XX" ABS - 90° Long Sweep Elbow - Socket Weld',
  'Pipe Fittings/ABS//Elbow/Spigot x Hub/22.5°': 'XX" ABS - 22.5° Elbow - Spigot x Hub',
  'Pipe Fittings/ABS//Elbow/Spigot x Hub/45°': 'XX" ABS - 45° Elbow - Spigot x Hub',
  'Pipe Fittings/ABS//Elbow/Spigot x Hub/90°': 'XX" ABS - 90° Elbow - Spigot x Hub',
  'Pipe Fittings/ABS//Elbow/Spigot x Hub/90° long': 'XX" ABS - 90° Long Sweep Elbow - Spigot x Hub',
  'Pipe Fittings/ABS//Tee/All Hub/': 'XX" ABS - Tee - All Hub',
  'Pipe Fittings/ABS//Tee/Socket Weld/': 'XX" ABS - Tee - Socket Weld',
  'Pipe Fittings/ABS//Wye/All Hub/': 'XX" ABS - Wye - All Hub',
  'Pipe Fittings/ABS//Wye/Socket Weld/': 'XX" ABS - Wye - Socket Weld',
  'Pipe Fittings/ABS//Coupling/All Hub/': 'XX" ABS - Coupling - All Hub',
  'Pipe Fittings/ABS//Coupling/FPT/': 'XX" ABS - Coupling - FPT x FPT',
  'Pipe Fittings/ABS//Coupling/Socket Weld/': 'XX" ABS - Coupling - Socket Weld',
  'Pipe Fittings/ABS//Cap/Socket Weld/': 'XX" ABS - Socket Weld Cap',
  'Pipe Fittings/ABS//Cap/FPT Cap/': 'XX" ABS - FPT Cap',
  'Pipe Fittings/ABS//Plug/MPT Plug/': 'XX" ABS - MPT Plug',
  'Pipe Fittings/Copper/Wrot - Sweat Fit/Adapter/Cup x MPT/': 'XX" Wrot Copper Pipe Adapter - sweat fit - cup x MPT',
  'Pipe Fittings/Copper/Wrot - Sweat Fit/Adapter/Cup x FPT/': 'XX" Wrot Copper Pipe Adapter - sweat fit - cup x FPT',
  'Pipe Fittings/Copper/Wrot - Sweat Fit/Elbow/Cup/22.5°': 'XX" Wrot Copper Elbow - sweat fit - 22.5°',
  'Pipe Fittings/Copper/Wrot - Sweat Fit/Elbow/Cup/45°': 'XX" Wrot Copper Elbow - sweat fit - 45°',
  'Pipe Fittings/Copper/Wrot - Sweat Fit/Elbow/Cup/90°': 'XX" Wrot Copper Elbow - sweat fit - 90°',
  'Pipe Fittings/Copper/Wrot - Sweat Fit/Elbow/Cup/90° long': 'XX" Wrot Copper Elbow - sweat fit - 90° long',
  'Pipe Fittings/Copper/Wrot - Sweat Fit/Tee/Cup/': 'XX" Wrot Copper Tee - sweat fit',
  'Pipe Fittings/Copper/Wrot - Sweat Fit/Coupling/Cup/': 'XX" Wrot Copper Coupling - sweat fit',
  'Pipe Fittings/Copper/Wrot - Sweat Fit/Cap/Cup/': 'XX" Wrot Copper Cap - sweat fit',
  'Pipe Fittings/Copper/Wrot - Press Fit/Adapter/Press x MPT/':
    'XX" Wrot Copper Pipe Adapter - press fit - press x MPT',
  'Pipe Fittings/Copper/Wrot - Press Fit/Adapter/Press x FPT/':
    'XX" Wrot Copper Pipe Adapter - press fit - press x FPT',
  'Pipe Fittings/Copper/Wrot - Press Fit/Elbow/Press/22.5°': 'XX" Wrot Copper Elbow - press fit - 22.5°',
  'Pipe Fittings/Copper/Wrot - Press Fit/Elbow/Press/45°': 'XX" Wrot Copper Elbow - press fit - 45°',
  'Pipe Fittings/Copper/Wrot - Press Fit/Elbow/Press/90°': 'XX" Wrot Copper Elbow - press fit - 90°',
  'Pipe Fittings/Copper/Wrot - Press Fit/Elbow/Press/90° long': 'XX" Wrot Copper Elbow - press fit - 90° long',
  'Pipe Fittings/Copper/Wrot - Press Fit/Tee/Press/': 'XX" Wrot Copper Tee - press fit',
  'Pipe Fittings/Copper/Wrot - Press Fit/Coupling/Press/': 'XX" Wrot Copper Coupling - press fit',
  'Pipe Fittings/Copper/Wrot - Press Fit/Cap/Press/': 'XX" Wrot Copper Cap - press fit',
  'Pipe Fittings/Steel/Black/Floor Flange/FPT/': 'XX" Black Steel - FPT Floor Flange',
  'Pipe Fittings/Steel/Black/Union Fitting/FPT x FPT/': 'XX" Black Steel - Union Fitting - FPT x FPT',
  'Pipe Fittings/Steel/Black/Nipple/MPT/3"': 'XX" Black Steel - 3" Nipple - MPT',
  'Pipe Fittings/Steel/Black/Nipple/MPT/4"': 'XX" Black Steel - 4" Nipple - MPT',
  'Pipe Fittings/Steel/Black/Nipple/MPT/5"': 'XX" Black Steel - 5" Nipple - MPT',
  'Pipe Fittings/Steel/Black/Nipple/MPT/6"': 'XX" Black Steel - 6" Nipple - MPT',
  'Pipe Fittings/Steel/Black/Nipple/MPT/8"': 'XX" Black Steel - 8" Nipple - MPT',
  'Pipe Fittings/Steel/Black/Nipple/MPT/10"': 'XX" Black Steel - 10" Nipple - MPT',
  'Pipe Fittings/Steel/Black/Nipple/MPT/12"': 'XX" Black Steel - 12" Nipple - MPT',
  'Pipe Fittings/Steel/Black/Elbow/Threaded/22.5°': 'XX" Black Steel - 22.5° Elbow - Threaded',
  'Pipe Fittings/Steel/Black/Elbow/Threaded/45°': 'XX" Black Steel - 45° Elbow - Threaded',
  'Pipe Fittings/Steel/Black/Elbow/Threaded/90°': 'XX" Black Steel - 90° Elbow - Threaded',
  'Pipe Fittings/Steel/Black/Elbow/Butt Weld/22.5°': 'XX" Black Steel - 22.5° Elbow - Butt Weld',
  'Pipe Fittings/Steel/Black/Elbow/Butt Weld/45°': 'XX" Black Steel - 45° Elbow - Butt Weld',
  'Pipe Fittings/Steel/Black/Elbow/Butt Weld/90°': 'XX" Black Steel - 90° Elbow - Butt Weld',
  'Pipe Fittings/Steel/Black/Tee/Threaded/': 'XX" Black Steel - Tee - Threaded',
  'Pipe Fittings/Steel/Black/Cap/Threaded/': 'XX" Black Steel - Cap - Threaded',
  'Pipe Fittings/Steel/Black/Plug/Threaded/': 'XX" Black Steel - Plug - Threaded',
  'Pipe Fittings/Steel/Galvanized/Floor Flange/FPT/': 'XX" Galvanized Steel - FPT Floor Flange',
  'Pipe Fittings/Steel/Galvanized/Union Fitting/FPT x FPT/': 'XX" Galvanized Steel - Union Fitting - FPT x FPT',
  'Pipe Fittings/Steel/Galvanized/Nipple/MPT/3"': 'XX" Galvanized Steel - 3" Nipple - MPT',
  'Pipe Fittings/Steel/Galvanized/Nipple/MPT/4"': 'XX" Galvanized Steel - 4" Nipple - MPT',
  'Pipe Fittings/Steel/Galvanized/Nipple/MPT/5"': 'XX" Galvanized Steel - 5" Nipple - MPT',
  'Pipe Fittings/Steel/Galvanized/Nipple/MPT/6"': 'XX" Galvanized Steel - 6" Nipple - MPT',
  'Pipe Fittings/Steel/Galvanized/Nipple/MPT/8"': 'XX" Galvanized Steel - 8" Nipple - MPT',
  'Pipe Fittings/Steel/Galvanized/Nipple/MPT/10"': 'XX" Galvanized Steel - 10" Nipple - MPT',
  'Pipe Fittings/Steel/Galvanized/Nipple/MPT/12"': 'XX" Galvanized Steel - 12" Nipple - MPT',
  'Pipe Fittings/Steel/Galvanized/Elbow/Threaded/22.5°': 'XX" Galvanized Steel - 22.5° Elbow - Threaded',
  'Pipe Fittings/Steel/Galvanized/Elbow/Threaded/45°': 'XX" Galvanized Steel - 45° Elbow - Threaded',
  'Pipe Fittings/Steel/Galvanized/Elbow/Threaded/90°': 'XX" Galvanized Steel - 90° Elbow - Threaded',
  'Pipe Fittings/Steel/Galvanized/Elbow/Butt Weld/22.5°': 'XX" Galvanized Steel - 22.5° Elbow - Butt Weld',
  'Pipe Fittings/Steel/Galvanized/Elbow/Butt Weld/45°': 'XX" Galvanized Steel - 45° Elbow - Butt Weld',
  'Pipe Fittings/Steel/Galvanized/Elbow/Butt Weld/90°': 'XX" Galvanized Steel - 90° Elbow - Butt Weld',
  'Pipe Fittings/Steel/Galvanized/Tee/Threaded/': 'XX" Galvanized Steel - Tee - Threaded',
  'Pipe Fittings/Steel/Galvanized/Cap/Threaded/': 'XX" Galvanized Steel - Cap - Threaded',
  'Pipe Fittings/Steel/Galvanized/Plug/Threaded/': 'XX" Galvanized Steel - Plug - Threaded',
  'Pipe Fittings/PEX/Plastic/Elbow/Barbed/45°': 'XX" PEX 45° Plastic Elbow - Barbed',
  'Pipe Fittings/PEX/Plastic/Elbow/Barbed/90°': 'XX" PEX 90° Plastic Elbow - Barbed',
  'Pipe Fittings/PEX/Plastic/Tee/Barbed/': 'XX" Plastic PEX Tee - Barbed',
  'Pipe Fittings/PEX/Plastic/Coupling/Barbed/': 'XX" Plastic PEX Coupling - Barbed',
  'Pipe Fittings/PEX/Plastic/Plug/Barbed/': 'XX" Plastic PEX Plug - Barbed',
  'Pipe Fittings/PEX/Plastic/Ring//': 'XX" Plastic PEX Ring',
  'Pipe Fittings/PEX/Brass/Elbow/Barbed/45°': 'XX" PEX 45° Brass Elbow - Barbed',
  'Pipe Fittings/PEX/Brass/Elbow/Barbed/90°': 'XX" PEX 90° Brass Elbow - Barbed',
  'Pipe Fittings/PEX/Brass/Tee/Barbed/': 'XX" Brass PEX Tee - Barbed',
  'Pipe Fittings/PEX/Brass/Adapter/PEX x MNPT/': 'XX" Brass PEX Adapter - PEX x MNPT',
  'Pipe Fittings/PEX/Brass/Adapter/PEX x FNPT/': 'XX" Brass PEX Adapter - PEX x FNPT',
  'Pipe Fittings/PEX/Brass/Coupling/Barbed/': 'XX" Brass PEX Coupling - Barbed',
  'Pipe Fittings/PEX/Brass/Plug/Barbed/': 'XX" Brass PEX Plug - Barbed',
  'Pipe Fittings/PEX/Brass/Ring//': 'XX" Brass PEX Ring',
  'Pipe Fittings/PEX/Copper/Ring//': 'XX" Copper PEX Ring',
}

export const formatValues = (values: PlumbingFormValues) => {
  const lookupValues = [
    values.plumbingCategory,
    values.material,
    values.specification,
    values.applicationType,
    values.endType,
    values.lengthAngle,
  ]
  let displayedValue = _.get(plumbingDisplayConfig, lookupValues.map(val => val.trim()).join('/')) || undefined
  if (displayedValue) {
    displayedValue = displayedValue.replace('XX"', values.insideDiameter)
  } else {
    lookupValues.push(values.insideDiameter)
    lookupValues.shift()
    displayedValue = lookupValues.join(' ')
  }
  return displayedValue
}

export default PlumbingConfig
