import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import ContactForm from '@supplyhound/forms/ContactForm'
import { ContactWithCommunicationPreference, UnidentifiedContactWithCommunicationPreference } from '@supplyhound/types'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'
import { createContact, updateContact } from '@supplyhound/api'
import NotificationBanner from '@supplyhound/components/NotificationBanner'
import styled from 'styled-components'
import { banOutline } from 'ionicons/icons'

const StyledBanner = styled(NotificationBanner)`
  ::part(container) {
    border: 1px solid var(--ion-color-danger);
    border-radius: var(--ion-border-radius);
  }
  ::part(icon) {
    font-size: 1.5rem;
    color: var(--ion-color-danger);
  }
`

const AddEditContactModal: React.FC<{
  dismiss: () => void
  loadContacts: () => Promise<void>
  contact?: ContactWithCommunicationPreference
}> = ({ dismiss, loadContacts, contact }) => {
  const { loadWhile } = useLoadingState()

  const handleSubmit = (
    values: ContactWithCommunicationPreference | UnidentifiedContactWithCommunicationPreference
  ) => {
    return loadWhile(async () => {
      let response
      if (contact) {
        response = await updateContact(values)
      } else {
        response = await createContact(values)
      }
      if ('error' in response && response.error) {
        if (response.error === 'DUPLICATE_CONTACT') {
          setToastMsg(
            response.archived
              ? 'Archived contact for this supplier already exists. Update existing contact'
              : 'Contact for this supplier already exists. Update existing contact'
          )
          setShowToast(true)
        }
      }

      if (response && !('error' in response)) {
        await loadContacts()
        dismiss()
      }
    })
  }

  const [showToast, setShowToast] = useState(false)
  const [toastMsg, setToastMsg] = useState('')

  return (
    <BaseModal label={contact ? 'Edit contact' : 'Add contact'} onDidDismiss={dismiss}>
      {showToast && (
        <StyledBanner
          onDidDismiss={() => setShowToast(false)}
          message={toastMsg}
          duration={10000}
          icon={banOutline}
          buttons={[
            {
              text: 'Dismiss',
              role: 'cancel',
            },
          ]}
        />
      )}
      <ContactForm onSubmit={handleSubmit} contact={contact} />
    </BaseModal>
  )
}

export default observer(AddEditContactModal)
