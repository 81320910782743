import React from 'react'
import styled from 'styled-components'
import { FormikProps } from 'formik'
import { useIonModal } from '@ionic/react'

import Caption from '@supplyhound/components/common/text/Caption'
import Spacer from '@supplyhound/components/common/Spacer'
import Card from '@supplyhound/components/common/Card'
import Icon from '@supplyhound/components/common/Icon'

import { ShortcutsModal } from '@supplyhound/modals'
import DimensionalForm from './DimensionalForm'
import SheetForm from './SheetForm'
import PlumbingForm from './PlumbingForm'
import FastenerForm from './FastenerForm'
import ConnectorsAndHangersForm from './ConnectorsAndHangersForm'

import { formatValues as formatDimensionalValues } from './DimensionalConfig'
import { formatValues as formatSheetValues } from './SheetConfig'
import { formatValues as formatPlumbingValues } from './PlumbingConfig'
import { formatValues as formatFastenerValues } from './FastnerConfig'
import { formatValues as formatConnectorsAndHangersValues } from './ConnectorsAndHangersConfig'

import { OptionallyIdentifiedOrdererListItem } from '@supplyhound/types'

import {
  DimensionalLumber,
  SheetLumber,
  Painting,
  Hardware,
  Landscape,
  Plumbing,
  Hvac,
  Fasteners,
  Electrical,
  Tools,
  ConnectorHanger,
} from '@supplyhound/icons'

export const ALL_SHORTCUTS = [
  'Dimensional',
  'Sheet',
  'Plumbing',
  'Painting',
  'Hardware',
  'Landscape',
  'Plumbing',
  'HVAC',
  'Fasteners',
  'Electrical',
  'Tools',
  'ConnectorAndHanger',
]

const Strike = styled.div`
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  & > span {
    position: relative;
    display: inline-block;
  }
  & > span:before,
  & > span:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: var(--greyscale-3);
  }
  & > span:before {
    right: 100%;
    margin-right: 20px;
  }
  & > span:after {
    left: 100%;
    margin-left: 20px;
  }
`

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Category = styled(Card)<{ disabled?: boolean }>`
  width: 49%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  margin: 5px 0px;
  position: relative;
  ${({ disabled }) => {
    if (disabled) {
      return `
    cursor: auto;
    padding: 0px;
    background-color: var(--grayscale-3)
      `
    }
  }}
`

const ShortCutCategoryCaption = styled(Caption)`
  border: 1px solid var(--ion-border-color);
  border-radius: 8px;
  padding: 2px 15px;
  background-color: var(--greyscale-1);
`

const ShortCuts = ({
  setFieldValue,
  shortcuts = ALL_SHORTCUTS,
}: {
  setFieldValue: FormikProps<OptionallyIdentifiedOrdererListItem>['setFieldValue']
  shortcuts?: string[]
}) => {
  const [presentDimensional, dismissDimensional] = useIonModal(ShortcutsModal, {
    dismiss: () => dismissDimensional(),
    onSubmit: (values: any) => {
      const description = formatDimensionalValues(values)
      setFieldValue('description', description)
      dismissDimensional()
    },
    startingLabel: 'Dimensional',
    Form: DimensionalForm,
  })

  const [presentSheet, dismissSheet] = useIonModal(ShortcutsModal, {
    dismiss: () => dismissSheet(),
    onSubmit: (values: any) => {
      const description = formatSheetValues(values)
      setFieldValue('description', description)
      dismissSheet()
    },
    startingLabel: 'Sheet',
    Form: SheetForm,
  })

  const [presentPlumbing, dismissPlumbing] = useIonModal(ShortcutsModal, {
    dismiss: () => dismissPlumbing(),
    onSubmit: (values: any) => {
      const description = formatPlumbingValues(values)
      setFieldValue('description', description)
      dismissPlumbing()
    },
    startingLabel: 'Plumbing',
    Form: PlumbingForm,
  })

  const [presentFasteners, dismissFasteners] = useIonModal(ShortcutsModal, {
    dismiss: () => dismissFasteners(),
    onSubmit: (values: any) => {
      const description = formatFastenerValues(values)
      setFieldValue('description', description)
      dismissFasteners()
    },
    startingLabel: 'Fastener',
    Form: FastenerForm,
  })

  const [presentConnectorsAndHangers, dismissConnectorsAndHangers] = useIonModal(ShortcutsModal, {
    dismiss: () => dismissConnectorsAndHangers(),
    onSubmit: (values: any) => {
      const description = formatConnectorsAndHangersValues(values)
      setFieldValue('description', description)
      dismissConnectorsAndHangers()
    },
    startingLabel: 'Hangers and Connectors',
    Form: ConnectorsAndHangersForm,
  })

  return (
    <div>
      <Spacer height={30} />
      <Strike>
        <span>or</span>
        <Spacer height={30} />
      </Strike>
      <h3>Use shortcuts to save time and keystrokes</h3>
      <CategoryContainer>
        {shortcuts.indexOf('Dimensional') > -1 && (
          <Category onClick={() => presentDimensional({ initialBreakpoint: 0.9, breakpoints: [0.9, 1] })}>
            <Icon icon={DimensionalLumber} width={50} />
            Dimensional
          </Category>
        )}
        {shortcuts.indexOf('Sheet') > -1 && (
          <Category onClick={() => presentSheet({ initialBreakpoint: 0.9, breakpoints: [0.9, 1] })}>
            <Icon icon={SheetLumber} width={50} />
            Sheet
          </Category>
        )}
        {shortcuts.indexOf('Fasteners') > -1 && (
          <Category onClick={() => presentFasteners({ initialBreakpoint: 0.9, breakpoints: [0.9, 1] })}>
            <Icon icon={Fasteners} width={50} />
            Fasteners
          </Category>
        )}
        {shortcuts.indexOf('Plumbing') > -1 && (
          <Category onClick={() => presentPlumbing({ initialBreakpoint: 0.9, breakpoints: [0.9, 1] })}>
            <Icon icon={Plumbing} width={50} />
            Plumbing
          </Category>
        )}
        {shortcuts.indexOf('ConnectorAndHanger') > -1 && (
          <Category onClick={() => presentConnectorsAndHangers({ initialBreakpoint: 0.9, breakpoints: [0.9, 1] })}>
            <Icon icon={ConnectorHanger} width={50} />
            Hangers & Connectors
          </Category>
        )}
        {shortcuts.indexOf('Painting') > -1 && (
          <Category disabled>
            <ShortCutCategoryCaption fontWeight="medium">Coming soon</ShortCutCategoryCaption>
            <Icon icon={Painting} width={50} />
            Painting
          </Category>
        )}
        {shortcuts.indexOf('Hardware') > -1 && (
          <Category disabled>
            <ShortCutCategoryCaption fontWeight="medium">Coming soon</ShortCutCategoryCaption>
            <Icon icon={Hardware} width={50} />
            Hardware
          </Category>
        )}
        {shortcuts.indexOf('Landscape') > -1 && (
          <Category disabled>
            <ShortCutCategoryCaption fontWeight="medium">Coming soon</ShortCutCategoryCaption>
            <Icon icon={Landscape} width={50} />
            Landscape
          </Category>
        )}
        {shortcuts.indexOf('HVAC') > -1 && (
          <Category disabled>
            <ShortCutCategoryCaption fontWeight="medium">Coming soon</ShortCutCategoryCaption>
            <Icon icon={Hvac} width={50} />
            HVAC
          </Category>
        )}
        {shortcuts.indexOf('Electrical') > -1 && (
          <Category disabled>
            <ShortCutCategoryCaption fontWeight="medium">Coming soon</ShortCutCategoryCaption>
            <Icon icon={Electrical} width={50} />
            Electrical
          </Category>
        )}
        {shortcuts.indexOf('Tools') > -1 && (
          <Category disabled>
            <ShortCutCategoryCaption fontWeight="medium">Coming soon</ShortCutCategoryCaption>
            <Icon icon={Tools} width={50} />
            Tools
          </Category>
        )}
      </CategoryContainer>
    </div>
  )
}

export default ShortCuts
