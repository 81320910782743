export function setItem(name: string, value: any): void {
  if (value === null || typeof value === 'undefined') {
    localStorage.removeItem(name)
  } else {
    localStorage.setItem(name, JSON.stringify(value))
  }
}

export function getItem(name: string): any {
  const json = localStorage.getItem(name)

  if (!json) {
    return null
  }

  try {
    return JSON.parse(json)
  } catch (e) {
    return null
  }
}
