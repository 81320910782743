import { createContext } from 'react'
import { USER_PROFILE_TOKEN_KEY, TEAM_MEMBER_PROFILE_TOKEN_KEY } from '@supplyhound/utils/config'
import {
  UserProfileStore,
  AuthStore,
  SuppliersStore,
  TeamStore,
  TeamMemberProfileStore,
  OrdererListStore,
  JobSitesStore,
  TaskStore,
  PaymentMethodStore,
  HistoryStore,
  OrdererTeamRequestsStore,
  SessionStore,
  SubscriptionStore,
} from '@supplyhound/stores'
import { UserProfile, TeamMemberProfile } from '@supplyhound/types'

const context = {
  userProfileStore: new UserProfileStore({
    authStore: new AuthStore<UserProfile>(USER_PROFILE_TOKEN_KEY),
    subscriptionStore: new SubscriptionStore(),
  }),
  jobSitesStore: new JobSitesStore({
    listStore: new OrdererListStore(),
    taskStore: new TaskStore(),
  }),
  suppliersStore: new SuppliersStore(),
  paymentMethodStore: new PaymentMethodStore(),
  historyStore: new HistoryStore(),
  ordererTeamRequestsStore: new OrdererTeamRequestsStore(),
  sessionStore: new SessionStore(),
  teamStore: new TeamStore(),
  teamMemberProfileStore: new TeamMemberProfileStore({
    authStore: new AuthStore<TeamMemberProfile>(TEAM_MEMBER_PROFILE_TOKEN_KEY),
  }),
}

export const StoresContext = createContext({
  ...context,
  resetStores() {
    const {
      userProfileStore,
      jobSitesStore,
      teamStore,
      teamMemberProfileStore,
      paymentMethodStore,
      historyStore,
      sessionStore,
    } = context

    sessionStore.reset()
    userProfileStore.reset()
    jobSitesStore.reset()
    teamStore.reset()
    teamMemberProfileStore.reset()
    paymentMethodStore.reset()
    historyStore.reset()
  },
})

export default StoresContext
