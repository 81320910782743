import React from 'react'
import { IonItem } from '@ionic/react'
import styled from 'styled-components'

type IonItemProps = React.ComponentProps<typeof IonItem>

const StyledItem = styled(IonItem)`
  --detail-icon-opacity: 100%;
  --detail-icon-color: var(--greyscale-4);
  background-color: var(--greyscale-1);
  border-radius: 8px;
`

const Item: React.FC<IonItemProps> = props => <StyledItem {...props} />

export default Item
