import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { TaskRouteParams, Task, TaskDetailQuery, TaskChatStates } from 'types/tasks'
import Card from '@supplyhound/components/common/Card'
import Spacer from '@supplyhound/components/common/Spacer'
import Details from './Details'
import TaskStatusProgression from './TaskStatusProgression'
import TaskStatusActionCard from './TaskStatusActionCard'
import { useLoadingState } from '@supplyhound/hooks'
import useStores from 'hooks/useStores'
import { useOnNewStreamChatMessage } from 'hooks/useOnNewStreamChatMessage'

import useQuery from 'hooks/useQuery'
import Chat from 'components/Chat'
import OutlineButton from '@supplyhound/components/buttons/OutlineButton'
import TopPageNavigation from 'containers/Layout/TopPageNavigation'

import { DASHBOARD_PATH } from 'config/paths'

const Main = styled.div`
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 881px;
`

const Side = styled.div`
  width: 341px;
`

const DetailsCard = styled(Card)`
  padding: 20px;
`

const ChatCard = styled(Card)`
  width: 341px;
  padding: 20px;
`

const StyledChat = styled(Chat)`
  .str-chat {
    height: 600px;
  }
`

const OrderDetail = () => {
  const { taskId } = useParams<TaskRouteParams>()
  const [task, setTask] = useState<Task | null>(null)
  const { loadWhile } = useLoadingState()
  const { taskStore, authStore } = useStores()
  const user = authStore.user

  const [chatEnabled, setChatEnabled] = useState<boolean>(false)
  useEffect(() => setChatEnabled(!!task?.conversation && !!user?.stream_chat_token), [user, task])
  const { query, setQuery } = useQuery<TaskDetailQuery>()
  const chatIsOpen = () => !!query.chat && query.chat === TaskChatStates.Open

  const setChatIsOpen = (val: boolean): void => {
    setQuery({
      chat: val ? TaskChatStates.Open : TaskChatStates.Closed,
    })
  }

  useOnNewStreamChatMessage(() => {
    if (!chatIsOpen) {
      setChatIsOpen(true)
    }
  })

  useEffect(() => {
    loadWhile(() => taskStore.dispatchFetchTask(parseInt(taskId)).then(task => setTask(task)))
  }, [taskId])

  if (task === null) {
    return null
  }

  return (
    <div>
      <TopPageNavigation backButtonLink={DASHBOARD_PATH} backButtonText="Dashboard" />
      <Main>
        <Content>
          <TaskStatusProgression task={task} />
          <DetailsCard>
            <Details task={task} />
          </DetailsCard>
        </Content>
        <Spacer width={20} />
        <Side>
          {!chatIsOpen() && (
            <TaskStatusActionCard
              task={task}
              setTask={setTask}
              chatEnabled={chatEnabled}
              onChatButtonClick={() => {
                setChatIsOpen(true)
              }}
            />
          )}
          {chatEnabled && chatIsOpen() && (
            <ChatCard>
              <StyledChat conversation={task.conversation!} />
              <Spacer height={20} />
              <OutlineButton
                borderColor="border-default"
                onClick={() => {
                  setChatIsOpen(false)
                }}
                color="tertiary"
              >
                Close chat with contractor
              </OutlineButton>
            </ChatCard>
          )}
        </Side>
      </Main>
    </div>
  )
}

export default observer(OrderDetail)
