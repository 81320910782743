import { axiosInstance } from './axios'
import {
  Contact,
  ContactWithCommunicationPreference,
  UnidentifiedContactWithCommunicationPreference,
} from '@supplyhound/types'

type ResponseError = {
  error: string
  archived: boolean
}

export const fetchContacts = (showArchived: boolean): Promise<ContactWithCommunicationPreference[]> =>
  axiosInstance.get('/v1/supplier_contacts/contacts', { params: { archived: showArchived } }).then(({ data }) => data)

export const createContact = (
  contact: UnidentifiedContactWithCommunicationPreference | { error: string; archived: boolean }
): Promise<Contact | ResponseError> =>
  axiosInstance.post('/v1/supplier_contacts/create', { contact_details: contact }).then(({ data: contact }) => contact)

export const updateContact = (
  contact: Partial<ContactWithCommunicationPreference>
): Promise<ContactWithCommunicationPreference> =>
  axiosInstance
    .patch('/v1/supplier_contacts/update', {
      contact_details: contact,
    })
    .then(({ data: contact }) => contact)
