import React, { useState, useMemo } from 'react'
import { Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api'

import { METERS_PER_MILE } from '@supplyhound/utils/config'

type Props = google.maps.DirectionsRequest & {
  callback?: (miles: number) => void
}

const DIRECTION_RENDER_OPTIONS: google.maps.DirectionsRendererOptions = {
  suppressMarkers: true,
}

const MARKER_LABEL: Partial<google.maps.MarkerLabel> = {
  color: '#fff',
  fontWeight: 'bold',
}

const DirectionsMap: React.FC<Props> = ({ origin, destination, travelMode, callback }) => {
  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null)
  const locations = useMemo<google.maps.LatLng[] | null>(() => {
    if (!directions) {
      return null
    }

    const leg = directions.routes[0].legs[0]

    return [leg.start_location, leg.end_location]
  }, [directions])

  const directionsServiceCallback = (result: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
    if (status === google.maps.DirectionsStatus.OK) {
      setDirections(result)

      const { distance } = result.routes[0].legs[0]
      if (distance) {
        const miles = distance.value / METERS_PER_MILE

        if (!!callback) {
          callback(miles)
        }
      }
    } else {
      console.log('DirectionsService error', result)
    }
  }

  return (
    <>
      {origin && destination && !directions && (
        <DirectionsService
          options={{
            origin,
            destination,
            travelMode,
            unitSystem: google.maps.UnitSystem.IMPERIAL,
          }}
          callback={directionsServiceCallback}
        />
      )}

      {directions && locations && (
        <>
          <DirectionsRenderer directions={directions} options={DIRECTION_RENDER_OPTIONS} />
          <Marker position={locations[0]} label={{ ...MARKER_LABEL, text: 'P' }} />
          <Marker position={locations[1]} label={{ ...MARKER_LABEL, text: 'D' }} />
        </>
      )}
    </>
  )
}

export default DirectionsMap
