import React, { useEffect } from 'react'
import clsx from 'clsx'

import Icon from '@supplyhound/components/common/Icon'
import { IonImg } from '@ionic/react'
import { chevronForwardOutline } from 'ionicons/icons'
import styled from 'styled-components'
import { Field, withFormik, FormikProps, FormikBag, FieldProps } from 'formik'
import * as Yup from 'yup'

import ListItem from '@supplyhound/components/common/ListItem'
import PillSelectField from '@supplyhound/forms/fields/PillSelect'
import ListField from './fields/ListField'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import Spacer from '@supplyhound/components/common/Spacer'
import ConnectorsAndHangersConfig, {
  ConnectorAndHangerFormValues,
  connectorAndHangerByDimensionFields,
  ConnectorAndHangerByDimensionFieldType,
  formatValues,
  getHeaderText,
  getCategoryText,
  initialFormValues,
} from './ConnectorsAndHangersConfig'

import { IMAGE_HOST_URL } from '@supplyhound/utils/urls'

const StyledListItem = styled(ListItem)`
  cursor: pointer;

  &.grid {
    width: 48%;
    text-align: center;
    border: 1px solid var(--ion-border-color);
    border-radius: var(--ion-border-radius);
    margin-bottom: 10px;
    --inner-padding-bottom: 0px;
    --inner-padding-top: 0px;

    ::part(native) {
      height: 100%;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      border-radius: var(--ion-border-radius);
    }
  }

  &.grid:nth-of-type(odd) {
    margin-right: 10px;
  }
`

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledImg = styled(IonImg)`
  width: 125px;
  height: 125px;
`

const DiagramImg = styled(StyledImg)`
  ::part(image) {
    background-color: #ffffff;
    border: 1px solid var(--ion-border-color);
    border-radius: var(--ion-border-radius);
  }
`

const TypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  &.grid {
    justify-content: center;
    color: var(--perma-dark-color);
  }
`

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
`

const FieldContainer = styled.div`
  position: relative;
`

const CategoryContainer = styled.div`
  &.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
  }
`

interface FormProps {
  onSubmit: (values: ConnectorAndHangerFormValues) => Promise<void>
  setHeaderLabel: (label: string) => void
  setSubmitForm: Function
  setOnModelBack: Function
  dismiss: Function
}

const CategoryMenu: React.FC<
  FieldProps & { nextPage: () => void; categoryItems: string[]; gridLayout?: boolean; setSubmitForm: Function }
> = ({ field, form, nextPage, categoryItems, gridLayout = false, setSubmitForm }) => {
  useEffect(() => {
    setSubmitForm({ fn: null })
  }, [])

  let imgUrl = `${IMAGE_HOST_URL}/${form.values.category}`

  if (gridLayout) {
    if (field.name === 'subcategory2') {
      imgUrl += `/${form.values.subcategory1}`
    } else if (field.name === 'subcategory3') {
      imgUrl += `/${form.values.subcategory1}/${form.values.subcategory2}`
    }
  }

  return (
    <CategoryContainer className={clsx({ grid: gridLayout })}>
      {categoryItems.map(category => {
        /*
          This replace is important because some category names have '/' in their name but AWS S3 does not allow file objects to have a '/' in their name.
          The colon character ':' is what is replaces the '/' in the AWS S3 file object name, so we replace the forward slash with the colon here.
        */
        const completeImgUrl = `${imgUrl}/${category.replace('/', ':')}.jpeg`.toLowerCase()
        return (
          <StyledListItem
            key={category}
            className={clsx({ grid: gridLayout })}
            lines="none"
            onClick={() => {
              form.setFieldValue(field.name, category)
              nextPage()
            }}
          >
            {gridLayout && <StyledImg src={completeImgUrl} />}
            <TypeContainer className={clsx({ grid: gridLayout })}>
              {getCategoryText(category)}
              {!gridLayout && <Icon icon={chevronForwardOutline} />}
            </TypeContainer>
          </StyledListItem>
        )
      })}
    </CategoryContainer>
  )
}

const OptionsPage: React.FC<{
  submitForm: () => void
  values: ConnectorAndHangerFormValues
  setSubmitForm: Function
  options: { [key: string]: string[] } | string[]
}> = ({ submitForm, values, setSubmitForm, options }) => {
  useEffect(() => {
    setSubmitForm({ fn: submitForm })
  }, [submitForm])

  const isCategoryTypeandDimension = values.category === 'Type and Dimension'
  let imgUrl = `${IMAGE_HOST_URL}/${values.category}`
  if (isCategoryTypeandDimension) {
    /*
      This replace is important because some category names have '/' in their name but AWS S3 does not allow file objects to have a '/' in their name.
      The colon character ':' is what is replaces the '/' in the AWS S3 file object name, so we replace the forward slash with the colon here.
    */
    const subcategory1 = values.subcategory1
    const subcategory2 = values.subcategory2.replace('/', ':')
    const subcategory3 = values.subcategory3.replace('/', ':')
    const diagramText = ['Hangers', 'Plates', 'Straps & Ties'].includes(subcategory1) ? '' : ' diagram'
    imgUrl += `/${subcategory1}/${subcategory2}${!!subcategory3 ? '/' + subcategory3 : ''}${diagramText}.jpeg`
  }

  return (
    <>
      {isCategoryTypeandDimension && (
        <>
          <ImgContainer>
            <DiagramImg src={imgUrl.toLowerCase()} />
          </ImgContainer>
          <Spacer height={20} />
        </>
      )}
      {Array.isArray(options) && <Field component={ListField} name="item" options={options} />}
      {!Array.isArray(options) &&
        Object.keys(options).map((option, idx) => {
          return (
            <div key={`${option}${idx}`}>
              {options[option] && (
                <FieldContainer>
                  <Field
                    component={PillSelectField}
                    label={connectorAndHangerByDimensionFields[option as ConnectorAndHangerByDimensionFieldType]}
                    options={options[option].map(o => ({ label: o, value: o }))}
                    name={option}
                  />
                </FieldContainer>
              )}
            </div>
          )
        })}
      <Spacer height={20} />
      <Textarea readOnly value={formatValues(values)} />
      <SubmitButton onClick={() => submitForm()}>Add item</SubmitButton>
    </>
  )
}

const ConnectorsAndHangersForm: React.FC<FormProps & FormikProps<ConnectorAndHangerFormValues>> = ({
  submitForm,
  values,
  setHeaderLabel,
  setSubmitForm,
  setOnModelBack,
  dismiss,
  resetForm,
}) => {
  const [currentPage, setCurrentPage] = React.useState(0)

  useEffect(() => {
    setOnModelBack(() => () => {
      const previousPage = currentPage - 1
      if (previousPage < 0) {
        dismiss()
      } else {
        // Reset the form and populate the next state with values that lead up to what is required for the previous page
        // that will be re-rendered
        const newInitialValues: ConnectorAndHangerFormValues = {
          ...initialFormValues,
          category: previousPage < 1 ? '' : values.category,
          subcategory1: previousPage < 2 ? '' : values.subcategory1,
          subcategory2: previousPage < 3 ? '' : values.subcategory2,
          subcategory3: previousPage < 4 ? '' : values.subcategory3,
        }
        resetForm({ values: newInitialValues })
        setCurrentPage(previousPage)
      }
    })
  }, [currentPage])

  useEffect(() => {
    if (values.subcategory3) {
      setHeaderLabel(
        getHeaderText(values.subcategory3, {
          category: values.category,
          subcategory1: values.subcategory1,
        })
      )
    } else if (values.subcategory2) {
      setHeaderLabel(
        getHeaderText(values.subcategory2, {
          category: values.category,
          subcategory1: values.subcategory1,
        })
      )
    } else if (values.subcategory1) {
      setHeaderLabel(
        getHeaderText(values.subcategory1, {
          category: values.category,
          subcategory1: values.subcategory1,
        })
      )
    } else if (values.category) {
      setHeaderLabel(values.category)
    }
  }, [values.category, values.subcategory1, values.subcategory2, values.subcategory3])

  const pages = [
    <Field
      name="category"
      component={CategoryMenu}
      nextPage={() => setCurrentPage(1)}
      categoryItems={Object.keys(ConnectorsAndHangersConfig)}
      setSubmitForm={setSubmitForm}
    />,
  ]

  if (values.category) {
    pages.push(
      <Field
        name="subcategory1"
        component={CategoryMenu}
        nextPage={() => setCurrentPage(2)}
        categoryItems={Object.keys(ConnectorsAndHangersConfig[values.category])}
        setSubmitForm={setSubmitForm}
      />
    )
  }

  if (values.subcategory1) {
    pages.push(
      <Field
        name="subcategory2"
        component={CategoryMenu}
        nextPage={() => setCurrentPage(3)}
        categoryItems={Object.keys(ConnectorsAndHangersConfig[values.category][values.subcategory1])}
        gridLayout
        setSubmitForm={setSubmitForm}
      />
    )
  }

  if (values.category === 'Simpson Model Number') {
    if (values.subcategory2) {
      const subcategory2 = ConnectorsAndHangersConfig[values.category][values.subcategory1][values.subcategory2]
      // If the first property (could be any) of the subcategory has an array, then we know that we should render the options view. Otherwise
      // it's an object and we know that we should render another category view.
      if (Array.isArray(subcategory2)) {
        pages.push(
          <OptionsPage submitForm={submitForm} values={values} setSubmitForm={setSubmitForm} options={subcategory2} />
        )
      } else {
        pages.push(
          <Field
            name="subcategory3"
            component={CategoryMenu}
            nextPage={() => setCurrentPage(4)}
            categoryItems={Object.keys(subcategory2)}
            gridLayout
            setSubmitForm={setSubmitForm}
          />
        )
      }

      if (values.subcategory3 && !Array.isArray(subcategory2)) {
        const subcategory3 = subcategory2[values.subcategory3]
        pages.push(
          <OptionsPage
            submitForm={submitForm}
            values={values}
            setSubmitForm={setSubmitForm}
            options={Array.isArray(subcategory3) ? subcategory3 : []}
          />
        )
      }
    }
  } else {
    if (values.subcategory2) {
      const subcategory2 = ConnectorsAndHangersConfig[values.category][values.subcategory1][values.subcategory2]
      if (values.subcategory1 === 'Bases' || values.subcategory1 === 'Caps') {
        pages.push(
          <Field
            name="subcategory3"
            component={CategoryMenu}
            nextPage={() => setCurrentPage(4)}
            categoryItems={Object.keys(subcategory2)}
            gridLayout
            setSubmitForm={setSubmitForm}
          />
        )
      } else {
        pages.push(
          <OptionsPage
            submitForm={submitForm}
            values={values}
            setSubmitForm={setSubmitForm}
            options={subcategory2 as { [field: string]: string[] }}
          />
        )
      }

      if (values.subcategory3 && !Array.isArray(subcategory2)) {
        pages.push(
          <OptionsPage
            submitForm={submitForm}
            values={values}
            setSubmitForm={setSubmitForm}
            options={subcategory2[values.subcategory3]}
          />
        )
      }
    }
  }

  return <div>{pages[currentPage]}</div>
}

export default withFormik<FormProps, ConnectorAndHangerFormValues>({
  displayName: 'ConnectorsAndHangersForm',
  validationSchema: Yup.object().shape({
    category: Yup.string(),
    subcategory1: Yup.string(),
    subcategory2: Yup.string(),
    subcategory3: Yup.string(),
    flangeWidthW1: Yup.string().when(['category', 'subcategory1'], {
      is: (category, subcategory1) => category === 'Type and Dimension' && subcategory1 == 'Angles',
      then: (schema: Yup.MixedSchema) => schema.required('Select a flange width'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    flangeWidthW2: Yup.string().when(['category', 'subcategory1'], {
      is: (category, subcategory1) => category === 'Type and Dimension' && subcategory1 === 'Angles',
      then: (schema: Yup.MixedSchema) => schema.required('Select a flange width'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    length: Yup.string().when(['category', 'subcategory1', 'subcategory2'], {
      is: (category, subcategory1, subcategory2) => {
        return category === 'Type and Dimension' && (subcategory1 === 'Angles' || subcategory2 === 'Stair (TA)')
      },
      then: (schema: Yup.MixedSchema) => schema.required('Select a length'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    width: Yup.string().when(['category', 'subcategory2'], {
      is: (category, subcategory2) => category === 'Type and Dimension' && subcategory2 === 'Lateral Tie Plate (LTP)',
      then: (schema: Yup.MixedSchema) => schema.required('Select a width'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    nominalColumnSize: Yup.string().when(['category', 'subcategory2'], {
      is: (category, subcategory2) => category === 'Type and Dimension' && subcategory2 === 'Column Bases',
      then: (schema: Yup.MixedSchema) => schema.required('Select a nominal column size'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    nominalPostSize: Yup.string().when(['category', 'subcategory3'], {
      is: (category, subcategory3) =>
        category === 'Type and Dimension' &&
        [
          'Adjustable Post Base w/ Uplift (ABU)',
          'Adjustable Post Base w/ Wind Uplift (ABW)',
          'Concealed Post Tie (CPTZ)',
          'Post Base (PB)',
          'Post Base w/ Standoff (PBS)',
          'Post Caps (BC)',
        ].includes(subcategory3),
      then: (schema: Yup.MixedSchema) => schema.required('Select a a nominal post size'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    beamWidth: Yup.string().when(['category', 'subcategory2'], {
      is: (category, subcategory2) => category === 'Type and Dimension' && subcategory2 === 'Column Caps',
      then: (schema: Yup.MixedSchema) => schema.required('Select a beam width'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    columnWidth: Yup.string().when(['category', 'subcategory2'], {
      is: (category, subcategory2) => category === 'Type and Dimension' && subcategory2 === 'Column Caps',
      then: (schema: Yup.MixedSchema) => schema.required('Select a column width'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    nominalPostWidth: Yup.string().when(['category', 'subcategory3'], {
      is: (category, subcategory3) =>
        category === 'Type and Dimension' &&
        ['Post Base (BC)', 'Adjustable Post Caps (AC)', 'End Post Caps (EPCZ)', 'One-Piece Post Caps (PCZ)'].includes(
          subcategory3
        ),
      then: (schema: Yup.MixedSchema) => schema.required('Select a a nominal post width'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    nominalJoistSize: Yup.string().when(['category', 'subcategory2'], {
      is: (category, subcategory2) =>
        category === 'Type and Dimension' &&
        [
          'Light Joist (LUS)',
          'Light Concealed Joist (LUC)',
          'Heavy Joist (HUS)',
          'Heavy Concealed Joist (HUC)',
          'Light Adjustable Jack (LSSJ)',
          'Adjustable Rafter (LSSR)',
          'Skewed (SUL / SUR)',
        ].includes(subcategory2),
      then: (schema: Yup.MixedSchema) => schema.required('Select a a nominal joist size'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    headerWidth: Yup.string().when(['category', 'subcategory2'], {
      is: (category, subcategory2) => category === 'Type and Dimension' && subcategory2 === 'Header (HH)',
      then: (schema: Yup.MixedSchema) => schema.required('Select a header width'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    rafterSize: Yup.string().when(['category', 'subcategory2'], {
      is: (category, subcategory2) => category === 'Type and Dimension' && subcategory2 === 'Rafter (LRUZ)',
      then: (schema: Yup.MixedSchema) => schema.required('Select a rafter size'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    skewDirection: Yup.string().when(['category', 'subcategory2'], {
      is: (category, subcategory2) =>
        category === 'Type and Dimension' &&
        ['Light Adjustable Jack (LSSJ)', 'Skewed (SUL / SUR)'].includes(subcategory2),
      then: (schema: Yup.MixedSchema) => schema.required('Select a skew direction'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    gauge: Yup.string().when(['category', 'subcategory1'], {
      is: (category, subcategory1) => category === 'Type and Dimension' && subcategory1 === 'Straps & Ties',
      then: (schema: Yup.MixedSchema) => schema.required('Select a gauge'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    coating: Yup.string().when(['category', 'subcategory1', 'subcategory2', 'subcategory3'], {
      is: (category, subcategory1, subcategory2, subcategory3) => {
        if (category !== 'Type and Dimension') {
          return false
        }

        if (subcategory1 === 'Angles') {
          return true
        } else if (
          [
            'Light Joist (LUS)',
            'Light Concealed Joist (LUC)',
            'Heavy Joist (HUS)',
            'Heavy Concealed Joist (HUC)',
          ].includes(subcategory2)
        ) {
          return true
        } else if (
          [
            'Column Base (CB)',
            'Column Base w/ Standoff (CBS)',
            'Column Base w/ Standoff - Quick Install (CBSQ)',
            'Adjustable Post Base w/ Uplift (ABU)',
            'Adjustable Post Base w/ Wind Uplift (ABW)',
            'Post Base (BC)',
            'Concealed Post Tie (CPTZ)',
            'Post Base (PB)',
            'Adjustable Post Caps (AC)',
            'End Post Caps (EPCZ)',
            'Light Post End Cap (LCE)',
            'One-Piece Post Caps (PCZ)',
          ].includes(subcategory3)
        ) {
          return true
        }
        return false
      },
      then: (schema: Yup.MixedSchema) => schema.required('Select a coating'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    application: Yup.string().when(['category', 'subcategory1'], {
      is: (category, subcategory1) => category === 'Type and Dimension' && subcategory1 === 'Angles',
      then: (schema: Yup.MixedSchema) => schema.required('Select an application'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
    item: Yup.string().when('category', {
      is: 'Simpson Model Number',
      then: (schema: Yup.MixedSchema) => schema.required('Select an item'),
      otherwise: (schema: Yup.MixedSchema) => schema.optional(),
    }),
  }),
  mapPropsToValues: () => initialFormValues,

  handleSubmit(
    values: ConnectorAndHangerFormValues,
    { props: { onSubmit } }: FormikBag<FormProps, ConnectorAndHangerFormValues>
  ) {
    onSubmit(values)
  },
})(ConnectorsAndHangersForm)
