import { makeAutoObservable } from 'mobx'
import findIndex from 'lodash/findIndex'
import { TeamMember, UnidentifiedTeamMember, ID } from '@supplyhound/types'
import {
  fetchTeamMembers as fetchTeamMembersApi,
  updateTeamMember as updateTeamMemberApi,
  createTeamMember as createTeamMemberApi,
  deleteTeamMember as deleteTeamMemberApi,
} from '@supplyhound/api'

/**
 * The TeamStore manages an orderer's operations on their team.
 */
export class TeamStore {
  id?: ID
  members: TeamMember[] = []

  constructor() {
    makeAutoObservable(this)
  }

  dispatchCreateMember(member: UnidentifiedTeamMember) {
    if (!this.id) return Promise.reject(new Error("Can't create a team member without a team!"))

    member.team_id = this.id

    return createTeamMemberApi(member).then(member => this.members.push(member))
  }

  dispatchUpdateMember(member: TeamMember) {
    return updateTeamMemberApi(member).then(member => this.replaceMember(member))
  }

  dispatchDeleteMember(member: TeamMember) {
    return deleteTeamMemberApi(member).then(() => this.removeMember(member))
  }

  dispatchLoadMembers() {
    const { id } = this

    if (!id) throw Error('No team id')

    return fetchTeamMembersApi(id).then(members => this.setMembers(members))
  }

  reset() {
    this.setMembers([])
  }

  setMembers(members: TeamMember[]) {
    this.members = members
  }

  setId(id: ID) {
    this.id = id
  }

  idxOfMember(member: TeamMember) {
    const { phone } = member
    return findIndex(this.members, { phone })
  }

  removeMember(member: TeamMember) {
    this.members.splice(this.idxOfMember(member), 1)
  }

  replaceMember(member: TeamMember) {
    this.members.splice(this.idxOfMember(member), 1, member)
  }
}
