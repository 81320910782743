import React from 'react'
import { IonButton } from '@ionic/react'
import styled from 'styled-components'
import { ForwardProps } from '@supplyhound/types'

type Props = Omit<ForwardProps<typeof IonButton>, 'shape'>

export default styled(props => <IonButton shape="round" {...props} />)<Props>`
  --border-radius: 31px;
`
