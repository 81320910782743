import { Meta } from 'types/base'
import { DashboardItem } from 'types/dashboard'

import { apiAxiosInstance } from '.'

interface Response {
  dashboard_items: DashboardItem[]
  meta: Meta
}

export async function fetchDashboardItems(params: any = {}): Promise<Response> {
  const response = await apiAxiosInstance.get<Response>(`/v1/dashboard`, { params })
  return response.data
}
