import React from 'react'
import styled from 'styled-components'
import { ForwardPartialProps } from '@supplyhound/types'
import { IonIcon } from '@ionic/react'

const Container = styled.div<Props>`
  /* icons should be square */
  width: ${props => props.width}px;
  height: ${props => props.width}px;
  box-sizing: border-box;
  border-radius: var(--ion-border-radius);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${props => (props.containerPadding ? `padding: ${props.containerPadding}px;` : '')}
  ${props => (props.border ? 'border: 1.5px solid var(--ion-color-dark);' : '')}
  ${props => (props.invertColors ? 'background-color: var(--ion-color-dark); color: var(--ion-color-light);' : '')}
  ${props => (props.color ? `color: ${props.color};` : '')}
`

const StyledIonIcon = styled(IonIcon)`
  width: 100%;
  height: 100%;
`

type Props = ForwardPartialProps<typeof IonIcon> & {
  width?: number
  border?: boolean
  invertColors?: boolean
  containerPadding?: number
  color?: string
}

const Icon: React.FC<Props> = ({
  icon,
  width = 20,
  color,
  containerPadding,
  border,
  invertColors,
  ...ionIconProps
}) => {
  return (
    <Container
      width={width}
      color={color}
      containerPadding={containerPadding}
      border={border}
      invertColors={invertColors}
    >
      <StyledIonIcon icon={icon} color={color} {...ionIconProps} />
    </Container>
  )
}

export default Icon
