import {
  CommonTaskStatuses,
  DeliveryTaskStatuses,
  DeliveryTaskStatusStepEvents,
  DeliveryTaskStatusStepEvent,
  PickupTaskStatusStepEvents,
  PickupTaskStatusStepEvent,
  PickupTaskStatuses,
} from 'types/tasks'

type Template<TaskType> = {
  title: string
  description: string
  actions: {
    event: TaskType
    buttonText: string
    buttonColor: string
  }[]
}

export const deliveryActionTemplateMap: { [key: string]: Template<DeliveryTaskStatusStepEvent> } = {
  [CommonTaskStatuses.New]: {
    title: 'New',
    description: '',
    actions: [
      {
        event: DeliveryTaskStatusStepEvents.Accept,
        buttonText: 'Accept',
        buttonColor: 'primary',
      },
      {
        event: DeliveryTaskStatusStepEvents.Reject,
        buttonText: 'Reject',
        buttonColor: 'tertiary',
      },
      {
        event: DeliveryTaskStatusStepEvents.Review,
        buttonText: 'Review',
        buttonColor: 'tertiary',
      },
    ],
  },
  [CommonTaskStatuses.UnderReview]: {
    title: 'Under Review',
    description: '',
    actions: [
      {
        event: DeliveryTaskStatusStepEvents.Accept,
        buttonText: 'Accept',
        buttonColor: 'primary',
      },
      {
        event: DeliveryTaskStatusStepEvents.Reject,
        buttonText: 'Reject',
        buttonColor: 'tertiary',
      },
    ],
  },
  [CommonTaskStatuses.AwaitingReceipt]: {
    title: 'Awaiting Receipt',
    description: '',
    actions: [
      {
        event: DeliveryTaskStatusStepEvents.ReceiveReceipt,
        buttonText: 'Received Receipt',
        buttonColor: 'primary',
      },
    ],
  },
  [CommonTaskStatuses.ReceivedReceipt]: {
    title: 'Received Receipt',
    description: 'Waiting for contractor confirmation.',
    actions: [],
  },
  [CommonTaskStatuses.ChangeRequested]: {
    title: 'Change Requested',
    description: '',
    actions: [
      {
        event: DeliveryTaskStatusStepEvents.Accept,
        buttonText: 'Accept',
        buttonColor: 'primary',
      },
      {
        event: DeliveryTaskStatusStepEvents.Reject,
        buttonText: 'Reject',
        buttonColor: 'tertiary',
      },
      {
        event: DeliveryTaskStatusStepEvents.Review,
        buttonText: 'Review',
        buttonColor: 'tertiary',
      },
    ],
  },
  [CommonTaskStatuses.Confirmed]: {
    title: 'Confirmed',
    description: 'Waiting for supplies to be picked up.',
    actions: [],
  },
  [CommonTaskStatuses.PickedUp]: {
    title: 'Picked up',
    description: 'Waiting for supplies to be delivered.',
    actions: [],
  },
  [DeliveryTaskStatuses.Delivered]: {
    title: 'Delivered',
    description: 'Supplies have been delivered',
    actions: [],
  },
  [CommonTaskStatuses.Canceled]: {
    title: 'Canceled',
    description: 'This order has been canceled',
    actions: [],
  },
}

export const adminDeliveryActionTemplateMap: { [key: string]: Template<DeliveryTaskStatusStepEvent> } = {
  [CommonTaskStatuses.New]: {
    title: 'New',
    description: '',
    actions: [
      {
        event: DeliveryTaskStatusStepEvents.Accept,
        buttonText: 'Accept',
        buttonColor: 'primary',
      },
      {
        event: DeliveryTaskStatusStepEvents.Reject,
        buttonText: 'Reject',
        buttonColor: 'tertiary',
      },
      {
        event: DeliveryTaskStatusStepEvents.Review,
        buttonText: 'Review',
        buttonColor: 'tertiary',
      },
      {
        event: DeliveryTaskStatusStepEvents.AdminRequestChange,
        buttonText: 'Admin Request Change',
        buttonColor: 'tertiary',
      },
    ],
  },
  [CommonTaskStatuses.ReceivedReceipt]: {
    title: 'Received Receipt',
    description:
      'The contractor for this order has been sent the order recipt and has accepted. Would you like to confirm the order or request changes?',
    actions: [
      {
        event: DeliveryTaskStatusStepEvents.Confirm,
        buttonText: 'Confirm',
        buttonColor: 'primary',
      },
      {
        event: DeliveryTaskStatusStepEvents.RequestChange,
        buttonText: 'Request Change',
        buttonColor: 'tertiary',
      },
    ],
  },
}

export const pickupActionTemplateMap: { [key: string]: Template<PickupTaskStatusStepEvent> } = {
  [CommonTaskStatuses.New]: {
    title: 'New',
    description: '',
    actions: [
      {
        event: PickupTaskStatusStepEvents.Accept,
        buttonText: 'Accept',
        buttonColor: 'primary',
      },
      {
        event: PickupTaskStatusStepEvents.Reject,
        buttonText: 'Reject',
        buttonColor: 'tertiary',
      },
      {
        event: PickupTaskStatusStepEvents.Review,
        buttonText: 'Review',
        buttonColor: 'tertiary',
      },
    ],
  },
  [CommonTaskStatuses.UnderReview]: {
    title: 'Under Review',
    description: '',
    actions: [
      {
        event: PickupTaskStatusStepEvents.Accept,
        buttonText: 'Accept',
        buttonColor: 'primary',
      },
      {
        event: PickupTaskStatusStepEvents.Reject,
        buttonText: 'Reject',
        buttonColor: 'tertiary',
      },
    ],
  },
  [CommonTaskStatuses.AwaitingReceipt]: {
    title: 'Awaiting Receipt',
    description: '',
    actions: [
      {
        event: PickupTaskStatusStepEvents.ReceiveReceipt,
        buttonText: 'Received Receipt',
        buttonColor: 'primary',
      },
    ],
  },
  [CommonTaskStatuses.ReceivedReceipt]: {
    title: 'Received Receipt',
    description: 'Waiting for contractor confirmation.',
    actions: [],
  },
  [CommonTaskStatuses.ChangeRequested]: {
    title: 'Change Requested',
    description: '',
    actions: [
      {
        event: PickupTaskStatusStepEvents.Accept,
        buttonText: 'Accept',
        buttonColor: 'primary',
      },
      {
        event: PickupTaskStatusStepEvents.Reject,
        buttonText: 'Reject',
        buttonColor: 'tertiary',
      },
      {
        event: PickupTaskStatusStepEvents.Review,
        buttonText: 'Review',
        buttonColor: 'tertiary',
      },
    ],
  },
  [CommonTaskStatuses.Confirmed]: {
    title: 'Confirmed',
    description: 'Waiting for supplies to be picked up.',
    actions: [],
  },
  [CommonTaskStatuses.PickedUp]: {
    title: 'Picked up',
    description: 'Waiting for supplies to be delivered.',
    actions: [],
  },
  [CommonTaskStatuses.Canceled]: {
    title: 'Canceled',
    description: 'This order has been canceled',
    actions: [],
  },
  [PickupTaskStatuses.Restocked]: {
    title: 'Restocked',
    description: 'This order has been restocked',
    actions: [],
  },
}

export const adminPickupActionTemplateMap: { [key: string]: Template<PickupTaskStatusStepEvent> } = {
  [CommonTaskStatuses.New]: {
    title: 'New',
    description: '',
    actions: [
      {
        event: PickupTaskStatusStepEvents.Accept,
        buttonText: 'Accept',
        buttonColor: 'primary',
      },
      {
        event: PickupTaskStatusStepEvents.Reject,
        buttonText: 'Reject',
        buttonColor: 'tertiary',
      },
      {
        event: PickupTaskStatusStepEvents.Review,
        buttonText: 'Review',
        buttonColor: 'tertiary',
      },
      {
        event: PickupTaskStatusStepEvents.AdminRequestChange,
        buttonText: 'Admin Request Change',
        buttonColor: 'tertiary',
      },
    ],
  },
  [CommonTaskStatuses.ReceivedReceipt]: {
    title: 'Received Receipt',
    description: '',
    actions: [
      {
        event: PickupTaskStatusStepEvents.Confirm,
        buttonText: 'Confirm',
        buttonColor: 'primary',
      },
      {
        event: PickupTaskStatusStepEvents.RequestChange,
        buttonText: 'Request Change',
        buttonColor: 'tertiary',
      },
    ],
  },
  [PickupTaskStatuses.Confirmed]: {
    title: 'Confirm Pickup',
    description: 'The contractor for this order has been sent the order recipt and has accepted.',
    actions: [
      {
        event: PickupTaskStatusStepEvents.ConfirmPickup,
        buttonText: 'Confirm',
        buttonColor: 'primary',
      },
      {
        event: PickupTaskStatusStepEvents.Restock,
        buttonText: 'Restock',
        buttonColor: 'tertiary',
      },
    ],
  },
}
