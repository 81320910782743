import React from 'react'
import styled from 'styled-components'
import { IonRouterLink, IonIcon } from '@ionic/react'
import { chevronBackOutline } from 'ionicons/icons'

const Container = styled.div`
  margin: 30px 0;
`

const Link = styled(IonRouterLink)`
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  position: relative;
`

const LinkIcon = styled(IonIcon)`
  font-size: 22px;
  position: absolute;
  top: -1px;
`

const LinkText = styled.span`
  padding-left: 30px;
`

const TopPageNavigation = ({ backButtonLink, backButtonText }: { backButtonLink: string; backButtonText: string }) => {
  return (
    <Container>
      <Link href={backButtonLink}>
        <LinkIcon src={chevronBackOutline} />
        <LinkText>{backButtonText}</LinkText>
      </Link>
    </Container>
  )
}

export default TopPageNavigation
