import { IonList } from '@ionic/react'
import React from 'react'
import styled from 'styled-components'
import { DEFAULT_TRANSITION_DURATION, HeightState, HeightStates } from '@supplyhound/hooks/useDynamicHeight'
import { ForwardProps } from '@supplyhound/types'

export interface StyleProps {
  transitionDuration?: number // milliseconds
  transitionFn?: string
  maxHeight: number
  scroll: boolean
}

export type ExpandableListProps = StyleProps &
  ForwardProps<typeof StyledIonList> & {
    state: HeightState
  } & {
    control?: React.ReactNode
  }

const transitionTmpl = (fn: string, dur: number) => `${dur}ms ${fn}`
const StyledIonList = styled(IonList)<StyleProps>`
  display: block;
  position: absolute;
  z-index: var(--dropdown-z-index);
  overflow: hidden;
  padding: 0;
  width: 100%;
  background-color: var(--greyscale-1);

  .expandable-list-content {
    overflow: hidden;
    max-height: 0;
    ${({ transitionFn, transitionDuration }) => `
      transition: max-height ${transitionTmpl(transitionFn!, transitionDuration!)};
    `}
  }

  &.${HeightStates.Expanded} .expandable-list-content {
    overflow: ${({ scroll }) => (scroll ? 'auto' : 'hidden')};
  }

  &.${HeightStates.Expanding} .expandable-list-content,
  &.${HeightStates.Expanded} .expandable-list-content {
    max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '100%')};
  }
`

const ExpandableList: React.FC<ExpandableListProps> = ({
  transitionFn = 'ease-in-out',
  transitionDuration = DEFAULT_TRANSITION_DURATION,
  control,
  maxHeight,
  scroll = false,
  children,
  state,
  className,
  ...props
}) => {
  return (
    <StyledIonList
      className={`expandable-list menu-type-overlay ${className} ${state}`}
      transitionFn={transitionFn}
      transitionDuration={transitionDuration}
      maxHeight={maxHeight}
      scroll={scroll}
      {...props}
    >
      {control}
      <div className="expandable-list-content">{children}</div>
    </StyledIonList>
  )
}

export default ExpandableList
