import React from 'react'
import styled from 'styled-components'

const StyledCaption = styled.div<CaptionProps>`
  font-size: 0.875rem;
  ${props => {
    const fontWeight = props.fontWeight
    if (!fontWeight) return ''
    switch (fontWeight) {
      case 'medium':
        return 'font-weight: 500;'
      case 'bold':
        return 'font-weight: 700;'
      default:
        return ''
    }
  }}
`

interface CaptionProps {
  fontWeight?: 'medium' | 'bold'
}

const Caption: React.FC<CaptionProps> = props => <StyledCaption {...props} />

export default Caption
