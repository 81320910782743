import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera'

export const usePhoto = ({ takePictureCallback }: { takePictureCallback: (photo: Photo) => Promise<void> }) => {
  const takePicture = async () => {
    const photo = await Camera.getPhoto({
      quality: 80,
      source: CameraSource.Prompt,
      resultType: CameraResultType.Uri,
    })

    await takePictureCallback(photo)
  }

  return {
    takePicture,
  }
}

export default usePhoto
