import { UnidentifiedDeliveryTask, UnidentifiedPickupTask } from '@supplyhound/types'
import { axiosInstance } from './axios'

// This will be replaced in the new front end app with the Voucherify JS SDK validate functionality.
export const getDeliveryQuotes = (task: UnidentifiedDeliveryTask) =>
  axiosInstance
    .post('/v1/delivery_price/quote', {
      ...task,
    })
    .then(({ data }) => data)

export const getPickupQuotes = (task: UnidentifiedPickupTask) =>
  axiosInstance
    .post('/v1/pickup_price/quote', {
      ...task,
    })
    .then(({ data }) => data)
