import React from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import {
  TaskStateLog,
  TaskTypes,
  DeliveryTaskStatuses,
  DeliveryTask,
  PickupTask,
  DeliveryTaskStatus,
  PickupTaskStatus,
  PickupTaskStatuses,
} from 'types/tasks'
import Card from '@supplyhound/components/common/Card'
import Spacer from '@supplyhound/components/common/Spacer'
import { IonIcon } from '@ionic/react'
import { checkmarkOutline } from 'ionicons/icons'

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const Header = styled.div`
  display: flex;
`

const StepsLabel = styled.span`
  display: flex;
  align-items: end;
`

const StepsSubLabel = styled.span`
  color: var(--greyscale-3);
`

const StatusesContainer = styled.div`
  display: flex;
`

const StatusContainer = styled.div`
  display: flex;
  color: var(--greyscale-3);
  align-items: end;

  &.completed,
  &.current {
    color: var(--greyscale-10);
  }
`

const StatusCircle = styled.div`
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: var(--greyscale-3);
  height: 42px;
  width: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.current {
    background-color: var(--ion-color-tertiary);
  }

  &.completed {
    background-color: var(--ion-color-success);
    color: #ffffff;
  }
`

const StatusNumber = styled.span`
  font-size: 16px;
  color: #000000;
`

const Icon = styled(IonIcon)`
  font-size: 20px;
`

const StatusLeft = styled.div`
  width: 80px;
  height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StatusRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 104px;

  &.compact {
    width: 74px;
  }
`

const StatusLabel = styled.span`
  font-size: 12px;
  text-align: center;
`

const StatusLine = styled.hr`
  margin: 0;
  width: 80px;
  border-width: 3px;
  border-top-style: dashed;

  &.current {
    border-color: var(--ion-color-primary);
    border-top-style: solid;
  }

  &.completed {
    border-color: var(--ion-color-success);
    border-top-style: solid;
  }
`

const isStatusCompleted = (stateLogs: TaskStateLog[], status: string) => {
  if (status === DeliveryTaskStatuses.New) {
    return true
  }

  const stateLog = stateLogs.find(log => log.to_state === status)

  return !!stateLog
}

const getCurrentDeliveryStatus = (status: DeliveryTaskStatus) => {
  switch (status) {
    case DeliveryTaskStatuses.New:
    case DeliveryTaskStatuses.UnderReview:
      return DeliveryTaskStatuses.AwaitingReceipt
    case DeliveryTaskStatuses.AwaitingReceipt:
      return DeliveryTaskStatuses.ReceivedReceipt
    case DeliveryTaskStatuses.ReceivedReceipt:
      return DeliveryTaskStatuses.Confirmed
    case DeliveryTaskStatuses.Confirmed:
      return DeliveryTaskStatuses.PickedUp
    case DeliveryTaskStatuses.PickedUp:
      return DeliveryTaskStatuses.Delivered
    default:
      return ''
  }
}

const getCurrentPickupStatus = (status: PickupTaskStatus) => {
  switch (status) {
    case PickupTaskStatuses.New:
    case PickupTaskStatuses.UnderReview:
      return PickupTaskStatuses.AwaitingReceipt
    case PickupTaskStatuses.AwaitingReceipt:
      return PickupTaskStatuses.ReceivedReceipt
    case PickupTaskStatuses.ReceivedReceipt:
      return PickupTaskStatuses.Confirmed
    case PickupTaskStatuses.Confirmed:
      return PickupTaskStatuses.PickedUp
    default:
      return ''
  }
}

const getCurrentStatus = (task: DeliveryTask | PickupTask) => {
  return task.type === TaskTypes.Delivery ? getCurrentDeliveryStatus(task.status) : getCurrentPickupStatus(task.status)
}

const getStatusList = (taskType: TaskTypes.Delivery | TaskTypes.Pickup) => {
  return taskType === TaskTypes.Delivery
    ? [
        { label: 'Placed', value: DeliveryTaskStatuses.New, step: 1 },
        { label: 'Accepted', value: DeliveryTaskStatuses.AwaitingReceipt, step: 2 },
        { label: 'Recieved Receipt', value: DeliveryTaskStatuses.ReceivedReceipt, step: 3 },
        { label: 'Confirmed', value: DeliveryTaskStatuses.Confirmed, step: 4 },
        { label: 'Picked Up', value: DeliveryTaskStatuses.PickedUp, step: 5 },
        { label: 'Delivered', value: DeliveryTaskStatuses.Delivered, step: 6 },
      ]
    : [
        { label: 'Placed', value: PickupTaskStatuses.New, step: 1 },
        { label: 'Accepted', value: PickupTaskStatuses.AwaitingReceipt, step: 2 },
        { label: 'Recieved Receipt', value: PickupTaskStatuses.ReceivedReceipt, step: 3 },
        { label: 'Confirmed', value: PickupTaskStatuses.Confirmed, step: 4 },
        { label: 'Picked Up', value: PickupTaskStatuses.PickedUp, step: 5 },
      ]
}

const TaskStatusProgression = ({ task }: { task: DeliveryTask | PickupTask }) => {
  const currentStatus = getCurrentStatus(task)
  const statusList = getStatusList(task.type)
  let currentStep = statusList.length
  statusList.forEach(status => {
    if (currentStatus === status.value) {
      currentStep = status.step
    }
  })
  return (
    <Container>
      <Header>
        <h2>Order Status</h2>
        <Spacer width={10} />
        <StepsLabel>
          Step {currentStep}&nbsp;
          <StepsSubLabel>of {statusList.length}</StepsSubLabel>
        </StepsLabel>
      </Header>
      <Spacer height={20} />
      <StatusesContainer>
        {statusList.map((status, idx) => {
          const isCurrentStatus = getCurrentStatus(task) === status.value
          // The reason why !isCurrentStatus is included is because some status can be revisited due to a "rejection"
          const hasStatusBeenFulfilled = !isCurrentStatus && isStatusCompleted(task.state_logs, status.value)
          return (
            <StatusContainer
              key={idx}
              className={clsx({
                current: isCurrentStatus,
                completed: hasStatusBeenFulfilled,
              })}
            >
              {idx !== 0 && (
                <StatusLeft>
                  <StatusLine
                    className={clsx({
                      current: isCurrentStatus,
                      completed: hasStatusBeenFulfilled,
                    })}
                  />
                </StatusLeft>
              )}
              <StatusRight className={clsx({ compact: task.type === TaskTypes.Delivery })}>
                <StatusLabel>{status.label}</StatusLabel>
                <Spacer height={5} />
                <StatusCircle
                  className={clsx({
                    current: isCurrentStatus,
                    completed: hasStatusBeenFulfilled,
                  })}
                >
                  {hasStatusBeenFulfilled ? <Icon src={checkmarkOutline} /> : <StatusNumber>{idx + 1}</StatusNumber>}
                </StatusCircle>
              </StatusRight>
            </StatusContainer>
          )
        })}
      </StatusesContainer>
    </Container>
  )
}

export default TaskStatusProgression
