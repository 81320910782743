import React from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, useLocation } from 'react-router-dom'

import useStores from 'hooks/useStores'

const Authenticated: React.FC<{ admin?: boolean }> = ({ children, admin }) => {
  const location = useLocation()
  const { authStore } = useStores()
  const isLoggedIn = authStore.getIsLoggedIn
  const isAdmin = authStore.getIsAdmin

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    )
  }

  if (admin && !isAdmin) {
    return <Redirect to={{ pathname: '/' }} />
  }

  return <>{children}</>
}

export default observer(Authenticated)
