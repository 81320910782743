import React from 'react'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'
import OvalButton from '@supplyhound/components/buttons/OvalButton'
import styled from 'styled-components'
import { HeaderModes } from '@supplyhound/layout'

const StyledOvalButton = styled(OvalButton)`
  &::part(native) {
    color: var(--perma-dark-color);
  }
`

const ShortcutsModal: React.FC<{
  dismiss: () => void
  Form: any
  onSubmit: Function
  startingLabel: string
}> = ({ dismiss, Form, onSubmit, startingLabel }) => {
  const [headerLabel, setHeaderLabel] = React.useState(startingLabel)
  const [submitForm, setSubmitForm] = React.useState({ fn: null })
  const [onModalBack, setOnModelBack] = React.useState<() => void>(() => () => {})

  return (
    <BaseModal
      label={headerLabel}
      end={submitForm.fn && <StyledOvalButton onClick={submitForm.fn}>Save</StyledOvalButton>}
      mode={HeaderModes.Leaf}
      back={onModalBack}
    >
      {Form && (
        <Form
          onSubmit={onSubmit}
          setHeaderLabel={setHeaderLabel}
          setSubmitForm={setSubmitForm}
          setOnModelBack={setOnModelBack}
          dismiss={dismiss}
        />
      )}
    </BaseModal>
  )
}

export default ShortcutsModal
