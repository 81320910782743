import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { IonGrid } from '@ionic/react'

import useActionCableConsumer from 'hooks/useActionCable'

import Spacer from '@supplyhound/components/common/Spacer'
import ToggleableTabs from '@supplyhound/components/ToggleableTabs'

import PaginationControls from '@supplyhound/components/common/PaginationControl'

import { useOnNewStreamChatMessage, useOnNotificationMessageNew } from 'hooks/useOnNewStreamChatMessage'
import { Meta } from 'types/base'
import { TasksChannelResponse } from 'types/tasks'
import { DashboardItem } from 'types/dashboard'
import { fetchDashboardItems as fetchDashboardItemsApi } from 'api/dashboard'
import useStores from 'hooks/useStores'

import Row from './Row'

const ToggleTabContainer = styled.div`
  max-width: 180px;
`

const Grid = styled(IonGrid)`
  overflow-x: auto;
`

const Table = ({
  setLoading,
  setError,
}: {
  setLoading: (isLoading: boolean) => void
  setError: (message: string) => void
}) => {
  const actionCableConsumer = useActionCableConsumer()
  const { authStore } = useStores()
  const user = authStore.getUser
  const isAdmin = authStore.getIsAdmin

  const [currentTab, setCurrentTab] = useState<string>('open')
  const [dashboardItems, setDashboardItems] = useState<DashboardItem[]>([])

  const [page, setPage] = useState<number>(1)
  const [sizePerPage, setSizePerPage] = useState<number>(10)
  const [meta, setMeta] = useState<Meta>({ total_count: 0, total_pages: 0 })

  const fetchDashboardItems = async () => {
    try {
      setLoading(true)

      let payload = {
        status: currentTab,
        page,
        per_page: sizePerPage,
      }
      if (!isAdmin) {
        payload.ordered_directly = false
      }

      const response = await fetchDashboardItemsApi(payload)

      setMeta(response.meta)
      setDashboardItems(response.dashboard_items)
    } catch (err) {
      // @ts-ignore
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setPage(1)
  }, [currentTab])

  useEffect(() => {
    fetchDashboardItems()
  }, [currentTab, page, sizePerPage])

  useEffect(() => {
    const subscription = actionCableConsumer.subscriptions.create(
      {
        channel: 'TasksChannel',
      },
      {
        received: (response: TasksChannelResponse) => {
          // If the current user is supplier user, ignore responding to the tasks for other suppliers
          if (user?.supplier_id && user.supplier_id !== response.task.supplier_id) {
            return
          }

          // Reload tasks
          fetchDashboardItems()
        },
      }
    )
    return () => {
      subscription.unsubscribe()
    }
  }, [actionCableConsumer])

  useOnNewStreamChatMessage(() => {
    fetchDashboardItems()
  })

  useOnNotificationMessageNew(() => {
    fetchDashboardItems()
  })

  return (
    <div>
      <ToggleTabContainer>
        <ToggleableTabs
          activeTabName={currentTab}
          tabs={[
            { name: 'open', label: 'Open' },
            { name: 'filled', label: 'History' },
          ]}
          onToggle={tab => setCurrentTab(tab)}
        />
      </ToggleTabContainer>
      <Spacer height={20} />
      <Grid>
        <Row isHeader />
        {dashboardItems.map((dashboardItem, idx) => {
          return (
            <Row
              setLoading={setLoading}
              key={idx}
              isHeader={false}
              dashboardItem={dashboardItem}
              refreshTable={() => {
                fetchDashboardItems()
              }}
            />
          )
        })}
      </Grid>
      <Spacer height={20} />
      <PaginationControls
        sizePerPage={sizePerPage}
        totalCount={meta.total_count}
        currentPage={page}
        setPage={setPage}
        setSizePerPage={setSizePerPage}
      />
    </div>
  )
}

export default observer(Table)
