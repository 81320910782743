import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import { parse } from 'query-string'

import useStores from 'hooks/useStores'

import Spacer from '@supplyhound/components/common/Spacer'
import { LOGIN_PATH } from 'config/paths'
import { Container, StyledCard, StyledInput, BottomLink, Error, StyledButton } from './common'

const PerformPasswordReset = (props: { source: 'confirmation' | 'passwordReset' }) => {
  const history = useHistory()
  const { authStore, passwordResetStore } = useStores()
  const [error, setError] = useState<string>()
  const isLoggedIn = authStore.getIsLoggedIn
  const { source } = props

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  // Unfortunate hack to unescape
  const location = useLocation().search.replace('&amp;', '&')
  const parsedSearch = parse(location)

  const email = parsedSearch.email as string
  const token = parsedSearch.token as string

  const handleSubmit = (event: React.FormEvent<HTMLIonBackButtonElement>) => {
    if (password === passwordConfirmation) {
      if (source === 'confirmation') {
        passwordResetStore
          .dispatchSubmitConfirmation(email, password, token)
          .then(user => authStore.setProfile(user))
          .catch(error => setError(error.message))
      } else {
        passwordResetStore
          .dispatchSubmitPasswordReset(email, password, token)
          .then(() => {
            history.push(LOGIN_PATH)
          })
          .catch(error => setError(error.message))
      }
    } else {
      setError('Passwords must match')
    }

    event.preventDefault()
  }

  const renderError = !!error ? <Error>{error}</Error> : null

  if (isLoggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Container>
      <Spacer height={80} />
      <StyledCard>
        <h3>Change Password</h3>
        <Spacer height={10} />
        <StyledInput
          type="password"
          value={passwordConfirmation}
          onIonChange={e => {
            e.detail.value && setPasswordConfirmation(e.detail.value)
          }}
          placeholder="password"
        />
        <Spacer height={10} />
        <StyledInput
          type="password"
          value={password}
          onIonChange={e => {
            e.detail.value && setPassword(e.detail.value)
          }}
          placeholder="confirm password"
        />
        <Spacer height={10} />
        {renderError && (
          <>
            {renderError}
            <Spacer height={10} />
          </>
        )}
        <StyledButton onClick={e => handleSubmit(e)}>Send me a recovery link</StyledButton>
        <Spacer height={30} />

        <div>
          <a href={LOGIN_PATH}>
            <BottomLink>Change password</BottomLink>
          </a>
        </div>
      </StyledCard>
    </Container>
  )
}

export default observer(PerformPasswordReset)
