import React from 'react'
import styled from 'styled-components'
import { Field, withFormik, FormikProps, FormikBag } from 'formik'
import * as Yup from 'yup'
import TextInputField from '@supplyhound/forms/fields/TextInputField'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'

export interface ResetPasswordFormValues {
  email: string
}

interface ResetPasswordFormProps {
  email: string
  message: string
  onSubmit: (values: ResetPasswordFormValues) => Promise<void>
  onBackToLoginClick: () => any
}

const ButtonTier = styled.div`
  padding-top: 20px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
`

const ResetPasswordHeader = styled.h1`
  padding-top: 48px;
  text-align: center;
`

const SubHeader = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
`
const EnterEmailField = styled(Field)`
  margin-top: 10px;
`

const BackToLoginLink = styled.h4`
  padding-top: 15px;
  text-align: center;
  cursor: pointer;
`

const ResetPasswordForm: React.FC<ResetPasswordFormProps & FormikProps<ResetPasswordFormValues>> = ({
  message,
  submitForm,
  onBackToLoginClick,
}) => {
  return (
    <ContentWrapper>
      <ResetPasswordHeader>Reset password</ResetPasswordHeader>
      <SubHeader>{message}</SubHeader>
      <EnterEmailField
        label="Enter your email"
        name="email"
        placeholder="email@domain.com"
        component={TextInputField}
      />
      <ButtonTier>
        <SubmitButton color="dark" onClick={submitForm}>
          Reset password
        </SubmitButton>
      </ButtonTier>
      <BackToLoginLink
        onClick={() => {
          onBackToLoginClick()
        }}
      >
        Back to login
      </BackToLoginLink>
    </ContentWrapper>
  )
}
export default withFormik<ResetPasswordFormProps, ResetPasswordFormValues>({
  displayName: 'ResetPasswordForm',
  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    email: Yup.string().required('Required'),
  }),

  mapPropsToValues({ email }: ResetPasswordFormProps): ResetPasswordFormValues {
    return { email }
  },

  handleSubmit(
    values: ResetPasswordFormValues,
    { props: { onSubmit } }: FormikBag<ResetPasswordFormProps, ResetPasswordFormValues>
  ) {
    onSubmit(values)
  },
})(ResetPasswordForm)
