import React, { useState } from 'react'
import { IonImg, IonPopover } from '@ionic/react'
import styled from 'styled-components'
import { ForwardProps } from '@supplyhound/types'

const Thumbnail = styled(IonImg)<{ $height: number; $width: number; $noPreview: boolean }>`
  width: ${props => props.$width + 'px'};
  height: ${props => props.$height + 'px'};
  display: flex;
  align-items: center;
  justify-content: center;
  ::part(image) {
    border-radius: 4px;
    width: initial;
    height: initial;
    max-width: 100%;
    max-height: 100%;
    cursor: ${props => (props.$noPreview ? 'cursor' : 'pointer')};
  }
`

const StyledPopover = styled(IonPopover)`
  ::part(content) {
    width: 100%;
    top: 50%;
    left: 0;
    border-radius: 0;
    transform: translate(0, -50%);
  }

  ::part(backdrop) {
    --backdrop-opacity: 1;
  }
`

type Props = ForwardProps<typeof IonImg> & {
  height: number
  width: number
  noPreview?: boolean
  thumbnailSrc?: string
  src?: string
  className?: string
}

const ImagePreview: React.FC<Props> = ({ height, width, noPreview = false, thumbnailSrc, src, className }) => {
  const [openImagePopover, setOpenImagePopover] = useState(false)
  return (
    <>
      <StyledPopover isOpen={openImagePopover} onDidDismiss={() => setOpenImagePopover(false)}>
        <IonImg src={src} />
      </StyledPopover>
      <Thumbnail
        $height={height}
        $width={width}
        $noPreview={noPreview}
        onClick={noPreview ? () => {} : () => setOpenImagePopover(true)}
        src={thumbnailSrc}
        className={className}
      />
    </>
  )
}

export default ImagePreview
