import { axiosInstance } from './axios'
import { BillingPlanType, SubscriptionType, Subscription, ID } from '@supplyhound/types'

export const deleteSubscription = (subscriptionId: ID): Promise<void> =>
  axiosInstance.delete(`/v1/subscriptions/${subscriptionId}`)

export const createSubscription = ({
  subscriptionType,
  billingPlanType,
  stripePaymentMethodId,
}: {
  subscriptionType: SubscriptionType
  billingPlanType: BillingPlanType
  stripePaymentMethodId: string
}): Promise<Subscription> =>
  axiosInstance
    .post('/v1/subscriptions', {
      subscription_type: subscriptionType,
      billing_plan_type: billingPlanType,
      stripe_payment_method_id: stripePaymentMethodId,
    })
    .then(({ data: { subscription } }) => subscription)

export const updateSubscription = (
  subscriptionId: ID,
  {
    subscriptionType,
    billingPlanType,
    stripePaymentMethodId,
  }: {
    subscriptionType: SubscriptionType
    billingPlanType: BillingPlanType
    stripePaymentMethodId: string
  }
): Promise<Subscription> =>
  axiosInstance.patch(`/v1/subscriptions/${subscriptionId}`, {
    subscription_type: subscriptionType,
    billing_plan_type: billingPlanType,
    stripe_payment_method_id: stripePaymentMethodId,
  })
