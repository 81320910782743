import React, { useState, useEffect, useRef } from 'react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import numeral from 'numeral'
import styled from 'styled-components'
import { createDeliveryTask as createDeliveryTaskApi, getDeliveryQuotes } from 'api/deliveryTasks'
import { Form, Formik, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { GoogleMap, Marker, DirectionsService } from '@react-google-maps/api'
import { TaskTypes } from 'types/tasks'
import useStores from 'hooks/useStores'
import { useLoadingState } from '@supplyhound/hooks'
import Card from '@supplyhound/components/common/Card'
import Spacer from '@supplyhound/components/common/Spacer'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import TextInputField from '@supplyhound/forms/fields/TextInputField'
import TextAreaField from '@supplyhound/forms/fields/TextAreaField'
import DatetimeInputField from '@supplyhound/forms/fields/DatetimeInputField'
import LocationSearchField from '@supplyhound/forms/fields/LocationSearchField'
import DropdownField from '@supplyhound/forms/fields/DropdownField'
import NotificationBanner from '@supplyhound/components/NotificationBanner'

export const METERS_PER_MILE = 1609

const VehicleType = {
  Car: 'Car',
  Truck: 'Truck',
  RackTruck: 'RackTruck',
  SprinterVan: 'SprinterVan',
}

export const DISTANCE_THRESHOLDS = {
  [VehicleType.Truck]: 10,
  [VehicleType.Car]: 10,
}

export const BASE_FARES = {
  [VehicleType.Truck]: 79,
  [VehicleType.Car]: 29,
}

export const DISTANCE_RATES = {
  [VehicleType.Truck]: 3,
  [VehicleType.Car]: 2.25,
}

const calculateFare = (vehicleType: 'Car' | 'Truck', job_distance: number): number => {
  const baseFare = BASE_FARES[vehicleType]
  const distanceRate = DISTANCE_RATES[vehicleType]
  const distanceThreshold = DISTANCE_THRESHOLDS[vehicleType]
  return job_distance <= distanceThreshold ? baseFare : baseFare + (job_distance - distanceThreshold) * distanceRate
}

const formatCurrency = (num: number): string =>
  // NOTE: abs() is necessary because of an errata/bug in how negative numbers are formatted to currency.
  numeral(Math.abs(num)).format('$0,0.00')

const Group = styled(Card)`
  background-color: var(--ion-background-color);
  padding: 20px;
`

type FormValue = {
  pickupName: string
  pickupPhone: string
  pickupDatetime: string
  pickupLocation: { full_address?: string }
  jobName: string
  orderNumber: string
  vehicleType: 'Truck' | 'Car'
  deliveryContact: string
  deliveryPhone: string
  deliveryByTime: string
  deliveryLocation: { full_address?: string }
  notes: string
}

const validationSchema = Yup.object().shape({
  pickupName: Yup.string().required('Required'),
  pickupPhone: Yup.string().required('Required'),
  pickupDatetime: Yup.string().required('Required'),
  pickupLocation: Yup.object({
    full_address: Yup.string().required('Search and select address from the dropdown'),
  }),
  jobName: Yup.string().required('Required'),
  orderNumber: Yup.string().required('Required'),
  vehicleType: Yup.mixed().oneOf(['Car', 'Truck', 'RackTruck', 'SprinterVan']).required('Required'),
  deliveryContact: Yup.string().optional(),
  deliveryPhone: Yup.string().required('Required'),
  deliveryByTime: Yup.string().required('Required'),
  deliveryLocation: Yup.object({
    full_address: Yup.string().required('Search and select address from the dropdown'),
  }),
  notes: Yup.string().optional(),
})

const FareInformation = styled.div`
  border: 1px solid #ced4da;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 10px;
`

const FareTotal = styled.span`
  color: var(--ion-color-success);
`

const FareLabel = styled.div`
  display: flex;
  justify-content: space-between;
`

const Layout = styled.div`
  display: flex;
`

const FormContainer = styled(Card)`
  padding: 20px;
  width: 881px;
`

const MapContainer = styled(Card)`
  width: 341px;
  height: 500px;
`

const convertFormValuesToPayload = (
  values: FormValue,
  jobsiteDistance: number,
  supplierId: number | null,
  quoteId: string,
  hasCurriIntegration: boolean
) => {
  let payload = {
    type: 'DeliveryTask' as TaskTypes.Delivery,
    vehicle_type: values.vehicleType,
    ordered_directly: true,
    delivery_address: values.deliveryLocation.full_address!,
    delivery_type: hasCurriIntegration ? 'supplier_originated' : 'custom',
    delivery_datetime: values.deliveryByTime,
    delivery_username: values.deliveryContact,
    delivery_phone: values.deliveryPhone,
    delivery_note: null,
    job_distance: jobsiteDistance,
    supplier_id: supplierId || null,
    job_site_name: values.jobName,
    pickup_name: values.pickupName,
    pickup_phone: values.pickupPhone,
    pickup_address: values.pickupLocation.full_address!,
    pickup_datetime: values.pickupDatetime,
    pickup_note: values.notes,
    ordered_with_subscription: false,
    order_no: values.orderNumber,
    items: [],
  }
  if (quoteId) {
    payload = { ...payload, quote_code: 'CURRI', quote_id: quoteId }
  }
  return payload
}

const OrderForm: React.FC = () => {
  const { authStore } = useStores()
  const { loadWhile } = useLoadingState()
  const [supplierLatLngCoordinates, setSupplierLatLngCoordinates] = useState<google.maps.LatLngLiteral | undefined>()
  const [pickupMarkerCoordinates, setPickupMarkerCoordinates] = useState<google.maps.LatLngLiteral | undefined>()
  const [deliveryMarkerCoordinates, setDeliveryMarkerCoordinates] = useState<google.maps.LatLngLiteral | undefined>()
  const [jobsiteDistance, setJobsiteDistance] = useState(0)
  const [vehicleType, setVehicleType] = useState<'Truck' | 'Car'>('Truck')
  const [fare, setFare] = useState(0)
  const [showToast, setShowToast] = useState(false)
  const [googleMap, setGoogleMap] = useState<google.maps.Map | undefined>()
  const user = authStore.user
  const supplierAddress = user?.supplier_address
  const supplierId = user?.supplier_id
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | undefined>()
  const [quoteId, setQuoteId] = useState<string>()

  const hasCurriIntegration = authStore.featureFlagValue('curri_integration')
  let vehicleOptions = [
    {
      label: VehicleType.Truck,
      value: VehicleType.Truck,
    },
    {
      label: 'Rack Truck',
      value: VehicleType.RackTruck,
    },
    {
      label: VehicleType.Car,
      value: VehicleType.Car,
    },
    {
      label: 'Sprinter Van',
      value: VehicleType.SprinterVan,
    },
  ]

  if (hasCurriIntegration) {
    vehicleOptions = [
      {
        label: VehicleType.Truck,
        value: VehicleType.Truck,
      },
      {
        label: 'Rack Truck',
        value: VehicleType.RackTruck,
      },
      {
        label: VehicleType.Car,
        value: VehicleType.Car,
      },
      {
        label: 'Sprinter Van',
        value: VehicleType.SprinterVan,
      },
    ]
  }

  const formRef = useRef<FormikProps<FormValue> | undefined>()

  useEffect(() => {
    const geocoder = new google.maps.Geocoder()
    setGeocoder(geocoder)
  }, [])

  useEffect(() => {
    if (googleMap && geocoder && supplierAddress) {
      geocoder.geocode({ address: supplierAddress }, results => {
        if (results) {
          const place = results[0]
          const latLngLiteral = place.geometry.location.toJSON()
          setSupplierLatLngCoordinates(latLngLiteral)
          setPickupMarkerCoordinates(latLngLiteral)
          googleMap?.setCenter(latLngLiteral)
        }
      })
    }
  }, [googleMap, geocoder, supplierAddress])

  useEffect(() => {
    if (vehicleType && jobsiteDistance && pickupMarkerCoordinates && deliveryMarkerCoordinates) {
      if (hasCurriIntegration) {
        const formValues = convertFormValuesToPayload(
          formRef.current.values,
          jobsiteDistance,
          supplierId,
          quoteId,
          hasCurriIntegration
        )
        loadWhile(() =>
          getDeliveryQuotes({ ...formValues, job_distance: jobsiteDistance }).then(data => {
            setFare(data.quote.total)
            setQuoteId(data.quote.quote_id)
          })
        )
      } else {
        const fare = calculateFare(vehicleType, jobsiteDistance)
        setFare(fare)
      }
      // getDeliveryQuotes
    }
  }, [pickupMarkerCoordinates, deliveryMarkerCoordinates, jobsiteDistance, vehicleType])

  const initialDateTimeValue = DateTime.now().setZone(user?.market.timezone).plus({ hours: 1 }).startOf('hour').toISO()
  const resetJobDistanceAndFare = () => {
    setJobsiteDistance(0)
    setFare(0)
  }

  return (
    <Layout>
      {showToast && (
        <NotificationBanner
          onDidDismiss={() => setShowToast(false)}
          message="Your order has successfully been placed."
          duration={10000}
          buttons={[
            {
              text: 'Dismiss',
              role: 'cancel',
            },
          ]}
        />
      )}
      <FormContainer>
        <h1>Order Form</h1>
        <Formik<FormValue>
          initialValues={{
            pickupName: '',
            pickupPhone: '',
            pickupDatetime: initialDateTimeValue,
            pickupLocation: { full_address: supplierAddress },
            jobName: '',
            orderNumber: '',
            vehicleType: 'Truck',
            deliveryContact: '',
            deliveryPhone: '',
            deliveryByTime: initialDateTimeValue,
            deliveryLocation: { full_address: '' },
            notes: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            loadWhile(() => {
              return createDeliveryTaskApi({
                delivery_task: convertFormValuesToPayload(
                  values,
                  jobsiteDistance,
                  supplierId,
                  quoteId,
                  hasCurriIntegration
                ),
              }).then(() => {
                setShowToast(true)
                setDeliveryMarkerCoordinates(undefined)
                if (supplierLatLngCoordinates) {
                  setPickupMarkerCoordinates(supplierLatLngCoordinates)
                  googleMap?.setCenter(supplierLatLngCoordinates)
                } else {
                  setPickupMarkerCoordinates(undefined)
                }
                resetJobDistanceAndFare()
                resetForm()
              })
            })
          }}
          innerRef={formRef}
        >
          {({ handleSubmit }) => {
            return (
              <Form noValidate onSubmit={handleSubmit}>
                <Group>
                  <h2>Pick-Up Details</h2>
                  <Field name="pickupName" label="Name" component={TextInputField} placeholder="Name" />
                  <Spacer height={15} />
                  <Field
                    name="pickupPhone"
                    label="Number"
                    placeholder="(xxx) xxx-xxxx"
                    component={TextInputField}
                    type="tel"
                    mask="(999)-999-9999"
                  />
                  <Spacer height={15} />
                  <Field
                    name="pickupDatetime"
                    label="Ready For Pickup Time"
                    component={DatetimeInputField}
                    min={initialDateTimeValue}
                    minuteValues="0,15,30,45"
                  />
                  <Spacer height={15} />
                  <Field
                    name="pickupLocation"
                    placeholder="Search and select an address"
                    component={LocationSearchField}
                    type="text"
                    label="Pickup Location"
                    onIonInput={() => {
                      setPickupMarkerCoordinates(undefined)
                      resetJobDistanceAndFare()
                    }}
                    onSearchResultSelect={(place: google.maps.places.PlaceResult) => {
                      const latLngLiteral = place.geometry?.location?.toJSON()
                      setPickupMarkerCoordinates(latLngLiteral)
                      googleMap?.setCenter(latLngLiteral!)
                    }}
                  />
                  <Spacer height={15} />
                  <Field name="jobName" label="Job Name" component={TextInputField} placeholder="Enter Job Name" />
                  <Spacer height={15} />
                  <Field
                    name="orderNumber"
                    label="Order/PO Number"
                    component={TextInputField}
                    placeholder="Enter Order/PO Number"
                  />
                  <Spacer height={15} />
                  <Field
                    name="vehicleType"
                    label="Deliver with"
                    component={DropdownField}
                    allowDeselect={false}
                    choices={vehicleOptions}
                    onSelect={(selection: 'Car' | 'Truck') => {
                      setVehicleType(selection)
                    }}
                  />
                </Group>
                <Group>
                  <h2>Delivery Details</h2>
                  <Field
                    name="deliveryContact"
                    label="Delivery Contact"
                    component={TextInputField}
                    placeholder="Enter delivery contact (optional)"
                  />
                  <Spacer height={15} />
                  <Field
                    name="deliveryPhone"
                    label="Delivery Contact Mobile Number"
                    placeholder="(xxx) xxx-xxxx"
                    component={TextInputField}
                    type="tel"
                    mask="(999)-999-9999"
                  />
                  <Spacer height={15} />
                  <Field
                    name="deliveryByTime"
                    label="Latest Deliver By Time"
                    component={DatetimeInputField}
                    min={initialDateTimeValue}
                    minuteValues="0,15,30,45"
                    validate={(value: string) => {
                      let errorMessage
                      const d1 = DateTime.fromISO(value).toISO()
                      const d2 = DateTime.fromISO(initialDateTimeValue).toISO()
                      if (d1 === d2) {
                        errorMessage = 'Please choose a later time'
                      }
                      return errorMessage
                    }}
                  />
                  <Spacer height={15} />
                  <Field
                    name="deliveryLocation"
                    placeholder="Search and select an address"
                    component={LocationSearchField}
                    type="text"
                    label="Delivery Location"
                    onIonInput={() => {
                      setDeliveryMarkerCoordinates(undefined)
                      resetJobDistanceAndFare()
                    }}
                    onSearchResultSelect={(place: google.maps.places.PlaceResult) => {
                      const latLngLiteral = place.geometry?.location?.toJSON()
                      setDeliveryMarkerCoordinates(latLngLiteral)
                      googleMap?.setCenter(latLngLiteral!)
                    }}
                  />
                </Group>
                <Group>
                  <h2>Notes</h2>
                  <Spacer height={15} />
                  <Field name="notes" component={TextAreaField} />
                </Group>
                {fare > 0 && (
                  <FareInformation>
                    <span>Total estimated distance: {numeral(jobsiteDistance).format('0,0')} mi</span>
                    <FareLabel>
                      <span>
                        <strong>
                          TOTAL FARE:&nbsp;
                          <FareTotal>{formatCurrency(fare)}</FareTotal>
                        </strong>
                        *
                      </span>
                      <span>* Booking fees may apply</span>
                    </FareLabel>
                  </FareInformation>
                )}
                <Spacer height={20} />
                <SubmitButton type="submit">Place Order</SubmitButton>
              </Form>
            )
          }}
        </Formik>
      </FormContainer>
      <Spacer width={20} />
      <MapContainer>
        <GoogleMap
          onLoad={(map: google.maps.Map) => {
            setGoogleMap(map)
          }}
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
          zoom={12}
        >
          {pickupMarkerCoordinates && <Marker position={pickupMarkerCoordinates} label={{ text: 'P' }} />}
          {deliveryMarkerCoordinates && <Marker position={deliveryMarkerCoordinates} label={{ text: 'D' }} />}
        </GoogleMap>
        {pickupMarkerCoordinates && deliveryMarkerCoordinates && (
          <DirectionsService
            options={{
              origin: pickupMarkerCoordinates,
              destination: deliveryMarkerCoordinates,
              travelMode: google.maps.TravelMode.DRIVING,
              unitSystem: google.maps.UnitSystem.IMPERIAL,
            }}
            callback={(result: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus) => {
              if (status === google.maps.DirectionsStatus.OK && result) {
                const { distance } = result.routes[0].legs[0]
                if (distance) {
                  const miles = distance.value / METERS_PER_MILE
                  setJobsiteDistance(miles)
                }
              } else {
                console.log('DirectionsService error', result)
              }
            }}
          />
        )}
      </MapContainer>
    </Layout>
  )
}

export default observer(OrderForm)
