import { makeAutoObservable, set, remove } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { get } from 'lodash'
import {
  TaskTypes,
  UnidentifiedDeliveryTask,
  UnidentifiedGenericTask,
  DeliveryTaskBillingDetails,
  ID,
} from '@supplyhound/types'
import {
  DEFAULT_GENERIC_TASK,
  BASE_FARES,
  DELIVERY_OPTION_EXTRA_FEE,
  DISTANCE_RATES,
  DISTANCE_THRESHOLDS,
  ORDER_MANAGEMENT_FEE,
} from '@supplyhound/utils/config'
import { DELIVERY_ONLY_JOBSITE_ID } from './JobSitesStore'

type TaskMap = Record<ID, UnidentifiedGenericTask>

export class TaskStore {
  tasks: TaskMap = {}

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'TaskStore',
      properties: ['tasks'],
    })
  }

  reset() {
    this.tasks = {}
  }

  getTask(id: ID) {
    return this.tasks[id]
  }

  removeTask(id: ID) {
    // Different path for removing delivery only jobsite because remove() will not actually remove the key -1 from localStorage for some reason.
    // Instead, we just replace the data with default generic task to reset it
    if (id === DELIVERY_ONLY_JOBSITE_ID) {
      this.tasks[id] = { ...DEFAULT_GENERIC_TASK }
    } else {
      remove(this.tasks, id.toString())
    }
  }

  getDeliveryTaskBillingDetails(
    id: ID,
    waiveOrderMangementFee: boolean,
    useNewOrderManagmentFee: boolean
  ): DeliveryTaskBillingDetails | undefined {
    if (!this.tasks[id]) {
      return
    }

    if (this.tasks[id].type === TaskTypes.Pickup) {
      return {
        subtotal: this.taskHandlingFee(waiveOrderMangementFee, useNewOrderManagmentFee),
        extraFee: 0,
        handlingFee: this.taskHandlingFee(waiveOrderMangementFee, useNewOrderManagmentFee),
        discount: 0,
        subtotalWithFees: this.taskHandlingFee(waiveOrderMangementFee, useNewOrderManagmentFee),
        total: this.taskHandlingFee(waiveOrderMangementFee, useNewOrderManagmentFee),
      }
    }

    const task = this.tasks[id] as UnidentifiedDeliveryTask

    if (!task.job_distance || task.job_distance <= 0) return

    return this.getBillingDetails(task, waiveOrderMangementFee, useNewOrderManagmentFee)
  }

  upsert(id: ID, partialTask: Partial<UnidentifiedGenericTask>) {
    if (this.tasks[id]) {
      set(this.tasks[id], partialTask)
    } else {
      this.tasks[id] = {
        ...DEFAULT_GENERIC_TASK,
        ...partialTask,
      }
    }
    return this.tasks[id]
  }

  getBillingDetails(task: UnidentifiedDeliveryTask, waiveOrderMangementFee: boolean, useNewOrderManagmentFee: boolean) {
    return {
      subtotal: this.taskSubtotal(task),
      extraFee: this.taskExtraFee(task),
      handlingFee: this.taskHandlingFee(waiveOrderMangementFee, useNewOrderManagmentFee),
      discount: this.taskDiscount(task),
      subtotalWithFees: this.taskSubtotalWithFees(task, waiveOrderMangementFee, useNewOrderManagmentFee),
      total: this.taskTotal(task, waiveOrderMangementFee, useNewOrderManagmentFee),
    }
  }

  taskExtraFee(task: UnidentifiedDeliveryTask): number {
    return get(DELIVERY_OPTION_EXTRA_FEE, task.delivery_type, 0)
  }

  taskSubtotal(task: UnidentifiedDeliveryTask): number {
    const baseFare = BASE_FARES[task.vehicle_type]
    const distanceRate = DISTANCE_RATES[task.vehicle_type]
    const distanceThreshold = DISTANCE_THRESHOLDS[task.vehicle_type]

    return task.job_distance <= distanceThreshold
      ? baseFare
      : baseFare + (task.job_distance - distanceThreshold) * distanceRate
  }

  taskDiscount(task: UnidentifiedDeliveryTask): number {
    return task.promotion ? task.promotion.discount : 0
  }

  taskSubtotalWithFees(
    task: UnidentifiedDeliveryTask,
    waiveOrderMangementFee: boolean,
    useNewOrderManagmentFee: boolean
  ): number {
    return (
      this.taskSubtotal(task) +
      this.taskHandlingFee(waiveOrderMangementFee, useNewOrderManagmentFee) +
      this.taskExtraFee(task)
    )
  }

  taskTotal(task: UnidentifiedDeliveryTask, waiveOrderMangementFee: boolean, useNewOrderManagmentFee: boolean): number {
    return this.taskSubtotalWithFees(task, waiveOrderMangementFee, useNewOrderManagmentFee)! - this.taskDiscount(task)!
  }

  taskHandlingFee(waiveOrderMangementFee: boolean, useNewOrderManagmentFee: boolean): number {
    return waiveOrderMangementFee ? 0 : useNewOrderManagmentFee ? ORDER_MANAGEMENT_FEE : 10
  }
}
