import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import DatetimePicker from 'components/forms/fields/DatetimePicker'
import moment, { Moment } from 'moment'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'
import Spacer from '@supplyhound/components/common/Spacer'
import BaseButton from '@supplyhound/components/buttons/BaseButton'

const Content = styled.div`
  font-size: ${({ theme }) => theme.fontSizeLarge};
  padding: 20px;
  text-align: center;
`

const ChangePickupTaskPickupDatetimeModal = ({
  dismiss,
  initialValue,
  onSubmit,
}: {
  dismiss: () => void
  initialValue: string
  onSubmit: (pickupDatetime: Moment) => void
}) => {
  const [selectedDatetime, setSelectedDatetime] = useState<Moment>(moment(initialValue))
  const handleDatetimePickerChange = (datetime: Moment) => setSelectedDatetime(datetime)
  const isValid = useMemo(() => selectedDatetime.isValid(), [selectedDatetime])
  const handleAcceptClick = () => isValid && onSubmit(selectedDatetime)

  return (
    <BaseModal onDidDismiss={dismiss} label="Enter a new pickup time">
      <Content>
        <DatetimePicker onChange={handleDatetimePickerChange} initialValue={initialValue} />
        <Spacer height={20} />
        <BaseButton disabled={!isValid} onClick={handleAcceptClick}>
          Accept order with new pickup time
        </BaseButton>
      </Content>
    </BaseModal>
  )
}

export default ChangePickupTaskPickupDatetimeModal
