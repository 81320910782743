import React from 'react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import HorizontalRule from '@supplyhound/components/common/HorizontalRule'
import {
  DeliveryTaskBillingDetails,
  HistoryTask,
  TaskTypes,
  UnidentifiedDeliveryTask,
  UnidentifiedGenericTask,
  UnidentifiedPickupTask,
} from '@supplyhound/types'
import styled from 'styled-components'
import Card from '@supplyhound/components/common/Card'
import { formatCurrency, formatDatetime, DEFAULT_DATETIME_FORMAT } from '@supplyhound/utils/formatters'
import { parseAddress } from '@supplyhound/utils/address'
import useStores from '@supplyhound/hooks/useStores'

const StyledCard = styled(Card)`
  background-color: var(--greyscale-1);
`

const SectionContent = styled.div`
  padding: calc(1.5 * var(--space-unit));

  div:last-child {
    margin-bottom: 0;
  }
`

const OrderRow = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(2 * var(--space-unit));
`
const OrderLabel = styled.div`
  text-align: left;
  max-width: 50%;
`
const OrderDetail = styled.div`
  text-align: right;
  flex-grow: 1;
  max-width: 75%;
  display: flex;
  flex-direction: column;
`

const SupplierName = styled.div`
  margin-bottom: var(--space-unit);
`

const SupplierAddress = styled.div`
  color: var(--greyscale-6);
`

const OrderTotal = styled.h4`
  display: flex;
  justify-content: space-between;
  margin: var(--space-unit) 0px;
`

const DeliveryDetailsSection: React.FC<{ task: UnidentifiedDeliveryTask; tz?: string }> = observer(({ task, tz }) => {
  const { delivery_address, delivery_datetime } = task
  return (
    <>
      <OrderRow>
        <OrderLabel>Delivering to</OrderLabel>
        <OrderDetail>{delivery_address}</OrderDetail>
      </OrderRow>
      <OrderRow>
        <OrderLabel>Delivery by</OrderLabel>
        <OrderDetail>{formatDatetime(delivery_datetime, 'D t', tz)}</OrderDetail>
      </OrderRow>
    </>
  )
})

const PickupDetailsSection: React.FC<{ task: UnidentifiedPickupTask; tz?: string; handlingFee: number }> = ({
  task,
  tz,
  handlingFee,
}) => {
  const { userProfileStore } = useStores()
  const { pickup_datetime } = task
  const isSaasFFOn = !!userProfileStore.profile?.feature_flags.saas
  const hasSubscription = userProfileStore.profile?.subscription
  const subscriptionType = _.capitalize(userProfileStore.profile?.subscription?.subscription_type || '')

  let orderManagementFeeLabel = isSaasFFOn ? 'Booking' : 'Order management'
  if (subscriptionType === 'Pro' || subscriptionType === 'Power') {
    orderManagementFeeLabel = `${orderManagementFeeLabel} - ${subscriptionType} member`
  }

  let orderManagementFeeValue = hasSubscription && handlingFee == 0 ? 'Free' : formatCurrency(handlingFee)
  if (subscriptionType === 'Legacy') {
    orderManagementFeeValue = 'Free with subscription'
  }
  const formattedPickupDatetime = formatDatetime(pickup_datetime, DEFAULT_DATETIME_FORMAT, tz)
  return (
    <>
      <OrderRow>
        <OrderLabel>Pickup by</OrderLabel>
        <OrderDetail>{formattedPickupDatetime}</OrderDetail>
      </OrderRow>
      <OrderRow>
        <OrderLabel>Pickup</OrderLabel>
        <OrderDetail>$0.00</OrderDetail>
      </OrderRow>
      <OrderRow>
        <OrderLabel>{orderManagementFeeLabel}</OrderLabel>
        <OrderDetail>{isSaasFFOn ? orderManagementFeeValue : '$0.00'}</OrderDetail>
      </OrderRow>
      <OrderRow>
        <OrderLabel>Expedite</OrderLabel>
        <OrderDetail>$0.00</OrderDetail>
      </OrderRow>
    </>
  )
}

const BillingDetailsSections: React.FC<{
  billingDetails: DeliveryTaskBillingDetails
  orderedWithSubscription?: boolean
}> = ({ billingDetails, orderedWithSubscription }) => {
  const { subtotal, handlingFee, extraFee, discount } = billingDetails
  const { userProfileStore } = useStores()
  const subscriptionType = _.capitalize(userProfileStore.profile?.subscription?.subscription_type || '')
  let orderManagementFeeLabel = userProfileStore.profile?.feature_flags.saas ? 'Booking fee' : 'Order management'
  if (subscriptionType === '' && userProfileStore.profile?.feature_flags.saas) {
    orderManagementFeeLabel += ' - no active subscription'
  }
  if (subscriptionType === 'Pro' || subscriptionType === 'Power') {
    orderManagementFeeLabel = `${orderManagementFeeLabel} - ${subscriptionType} member${
      subscriptionType === 'Pro' ? ' - 4 free per month' : ''
    }`
  }
  let orderManagementFeeValue = orderedWithSubscription && handlingFee === 0 ? 'Free' : formatCurrency(handlingFee)
  if (subscriptionType === 'Legacy') {
    orderManagementFeeValue = 'Free with subscription'
  }
  return (
    <>
      <OrderRow>
        <OrderLabel>Delivery</OrderLabel>
        <OrderDetail>{formatCurrency(subtotal)}</OrderDetail>
      </OrderRow>
      <OrderRow>
        <OrderLabel>{orderManagementFeeLabel}</OrderLabel>
        <OrderDetail>{orderManagementFeeValue}</OrderDetail>
      </OrderRow>
      <OrderRow>
        <OrderLabel>Expedite</OrderLabel>
        <OrderDetail>{formatCurrency(extraFee)}</OrderDetail>
      </OrderRow>
      {!!discount && (
        <OrderRow>
          <OrderLabel>Discount</OrderLabel>
          <OrderDetail>-{formatCurrency(discount)}</OrderDetail>
        </OrderRow>
      )}
    </>
  )
}

const TotalFareSection: React.FC<{ total: number }> = ({ total }) => {
  return (
    <OrderTotal>
      <div>Total</div>
      <OrderDetail>{formatCurrency(total)}</OrderDetail>
    </OrderTotal>
  )
}

interface OrderDetailProps {
  task: UnidentifiedGenericTask | HistoryTask
  billingDetails?: DeliveryTaskBillingDetails
}

const OrderDetails: React.FC<OrderDetailProps> = ({ task, billingDetails }) => {
  const isDelivery = task?.type === TaskTypes.Delivery
  const isPickup = task?.type === TaskTypes.Pickup

  const { userProfileStore } = useStores()
  const marketTimeZone = userProfileStore.marketTimezone

  return (
    <StyledCard>
      <SectionContent>
        <SupplierName>
          <h3>
            <b>{task?.supplier?.name || task?.supplier?.address.split(',')[0]}</b>
          </h3>
        </SupplierName>
        <SupplierAddress>{task?.supplier?.address && parseAddress(task?.supplier?.address)}</SupplierAddress>
      </SectionContent>
      <HorizontalRule />
      <SectionContent>
        {isDelivery && <DeliveryDetailsSection task={task as UnidentifiedDeliveryTask} tz={marketTimeZone} />}
        {isPickup && (
          <PickupDetailsSection
            task={task as UnidentifiedPickupTask}
            tz={marketTimeZone}
            handlingFee={billingDetails?.handlingFee || 0}
          />
        )}
        {isDelivery && billingDetails && (
          <BillingDetailsSections
            billingDetails={billingDetails}
            orderedWithSubscription={task?.ordered_with_subscription}
          />
        )}
      </SectionContent>
      <HorizontalRule />
      {(typeof billingDetails?.total === 'number' || isPickup) && (
        <SectionContent>
          <TotalFareSection total={billingDetails?.total || 0} />
        </SectionContent>
      )}
    </StyledCard>
  )
}

export default observer(OrderDetails)
