import React, { useEffect } from 'react'
import { Field, FormikBag, FormikProps, withFormik } from 'formik'
import * as Yup from 'yup'
import LocationSearchField from '@supplyhound/forms/fields/LocationSearchField'

import { JobSite, UnidentifiedJobSite } from '@supplyhound/types'
import { DEFAULT_JOB_SITE } from '@supplyhound/utils/config'

import { catchAndSetErrors } from '@supplyhound/utils/forms'
import TextInputField from './fields/TextInputField'
import Card from '@supplyhound/components/common/Card'
import MapPin from '@supplyhound/components/MapPin'
import Spacer from '@supplyhound/components/common/Spacer'
import styled from 'styled-components'
import IconPopover from '@supplyhound/components/IconPopover'
import { lockClosedOutline } from 'ionicons/icons'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ButtonContainer = styled.div`
  flex: auto 1 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 0 20px 0;
`

interface Props {
  jobSite?: UnidentifiedJobSite | JobSite
  onSubmit: (jobSite: UnidentifiedJobSite) => void
}

const JobSiteForm: React.FC<Props & FormikProps<UnidentifiedJobSite>> = ({
  values,
  setFieldValue,
  submitForm,
  jobSite,
}) => {
  useEffect(() => {
    if (!values.location) return

    setFieldValue('formatted_address', values.location.full_address)
  }, [values.location])

  return (
    <ContentWrapper>
      {!!jobSite ? (
        <Field
          name="location.full_address"
          component={TextInputField}
          label="Jobsite address"
          type="text"
          disabled={!!jobSite}
          extraContent={
            jobSite && (
              <IconPopover
                icon={lockClosedOutline}
                content="Address for a jobsite cannot be changed. Please create a new jobsite instead."
                id="jobsiteAddressFieldHelp"
              />
            )
          }
        />
      ) : (
        <Field
          name="location"
          placeholder="Enter your new jobsite address"
          component={LocationSearchField}
          label="Jobsite address"
          type="text"
        />
      )}
      <Spacer height={20} />
      <Field
        name="nickname"
        placeholder="Enter nickname"
        component={TextInputField}
        label="Jobsite nickname"
        type="text"
      />
      <Spacer height={20} />
      <Field
        name="contact_name"
        placeholder="Enter contact name"
        component={TextInputField}
        label="Jobsite contact"
        type="text"
      />
      <Spacer height={20} />
      <Field
        name="phone"
        placeholder="Enter contact phone number"
        component={TextInputField}
        type="tel"
        label="Jobsite phone"
        mask="(999) 999-9999"
      />
      {values.location?.full_address && (
        <Card>
          <MapPin address={values.location.full_address} />
        </Card>
      )}
      <ButtonContainer>
        <SubmitButton onClick={submitForm}>{jobSite ? 'Save changes' : 'Add jobsite'}</SubmitButton>
      </ButtonContainer>
    </ContentWrapper>
  )
}

export default withFormik<Props, UnidentifiedJobSite | JobSite>({
  displayName: 'JobSiteForm',
  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    formatted_address: Yup.string().required('Enter a street address'),
    location: Yup.object().shape({
      full_address: Yup.string().required('Enter a street address'),
    }),
    contact_name: Yup.string().required('Enter a contact name'),
    phone: Yup.string()
      .matches(/\(\d{3}\) \d{3}-\d{4}/, 'Enter a 10 digit phone number')
      .required('Enter a 10 digit phone number'),
    nickname: Yup.string(),
  }),

  mapPropsToValues({ jobSite }: Props): UnidentifiedJobSite {
    return jobSite || DEFAULT_JOB_SITE
  },

  handleSubmit(
    values: UnidentifiedJobSite,
    { props: { onSubmit }, setErrors, resetForm }: FormikBag<Props, UnidentifiedJobSite>
  ) {
    catchAndSetErrors(async () => {
      await onSubmit(values)
      resetForm()
    }, setErrors)
  },
})(JobSiteForm)
