import React from 'react'

import { Task, TaskTypes } from 'types/tasks'

const TypeCol = ({ task }: { task: Task }) => {
  const vehicleType = task.vehicle_type
  let text = task.type === TaskTypes.Delivery ? 'Delivery' : 'Will Call'
  if (task.type === TaskTypes.Delivery && vehicleType === 'Supplier') {
    text = `Delivery by - ${task.store_name}`
  }

  return <>{text}</>
}

export default TypeCol
