import logoUrl from 'assets/images/logo.png'

export default class PushNotifier {
  get isSupported() {
    return 'Notification' in window
  }

  requestPermissionToPushNotifications = async () => {
    if (!this.isSupported) return

    if (Notification.permission === 'denied') return
    await Notification.requestPermission()
  }

  onNotificationClick = (event: any) => {
    window.parent.focus()
    event.target.close()
  }

  /**
   * Taken from https://developer.mozilla.org/en-US/docs/Web/API/notification.
   * @param message Message to push to the user.
   */
  notify = async (title: string, body?: string) => {
    if (!this.isSupported) return

    // Always, just in case.
    await this.requestPermissionToPushNotifications()

    if (Notification.permission === 'granted') {
      const notification = new Notification(title, {
        body,
        icon: logoUrl,
      })
      notification.onclick = this.onNotificationClick
    }
  }
}
