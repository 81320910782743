import { AxiosRequestConfig } from 'axios'
import { axiosInstance, transformJsonToFormData } from './axios'
import { UnidentifiedDeliveryTask, UnidentifiedTaskItem, DeliveryTask } from '@supplyhound/types'

export type CreatableDeliveryTask = Omit<UnidentifiedDeliveryTask, 'itemIds' | 'promotion' | 'supplier'> & {
  items: UnidentifiedTaskItem[]
  extra_fee: number
  stripe_payment_method_id: string
  quote_id?: string
  quote_code?: string
  supplier_place_id: string | undefined
}

type CreateDeliveryTaskRequestData = {
  delivery_task: CreatableDeliveryTask
}

const defaultCreateDeliveryTaskRequestConfig = {
  transformRequest: [transformJsonToFormData],
}

export const createDeliveryTask = (
  data: CreateDeliveryTaskRequestData,
  config: AxiosRequestConfig = defaultCreateDeliveryTaskRequestConfig
): Promise<DeliveryTask> =>
  axiosInstance.post('/v1/delivery_tasks', data, config).then(({ data: { delivery_task } }) => delivery_task)
