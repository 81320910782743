import numeral from 'numeral'
import { DateTime } from 'luxon'

export const DEFAULT_DATETIME_FORMAT = 'ccc LLL d t'

export const formatCurrency = (num: number, options: { noDecimal?: boolean } = {}): string => {
  // NOTE: abs() is necessary because of an errata/bug in how negative numbers are formatted to currency.
  if (options.noDecimal) {
    return numeral(Math.abs(num)).format('$0,0')
  }
  return numeral(Math.abs(num)).format('$0,0.00')
}

export const formatDatetime = (date: string, format = DEFAULT_DATETIME_FORMAT, tz?: string): string => {
  let formattedDate = DateTime.fromISO(date)

  if (tz) {
    formattedDate = formattedDate.setZone(tz)
  }
  return formattedDate.toFormat(format)
}

//NOTE: If the default mask and validation regex are changed the formatted output of this method must match
export const formatPhoneNumber = (phoneNumberString?: string) => {
  const cleaned = (phoneNumberString ?? '').replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ')-' + match[2] + '-' + match[3]
  }
  return undefined
}
