import { formatDateTime } from 'helpers/datetimes'
import React from 'react'
import styled from 'styled-components'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'
import Button from '@supplyhound/components/buttons/BaseButton'
import Spacer from '@supplyhound/components/common/Spacer'

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  padding: 20px;
  text-align: center;
`

const StyledButton = styled(Button)`
  width: 200px;
`

const AcceptPickupTaskModal = ({
  pickupDatetime,
  onAccept,
  onChange,
  dismiss,
}: {
  dismiss: () => void
  onAccept: () => void
  onChange: () => void
  pickupDatetime: string
}) => (
  <BaseModal onDidDismiss={dismiss}>
    <Content>
      <h2>
        This order is for will call: By accepting you're agreeing to have this order ready by{' '}
        {formatDateTime(pickupDatetime)}
      </h2>
      <Spacer height={20} />
      <StyledButtonGroup>
        <StyledButton color="tertiary" onClick={onChange}>
          Change pickup time
        </StyledButton>
        <Spacer width={20} />
        <StyledButton onClick={onAccept}>Accept order</StyledButton>
      </StyledButtonGroup>
    </Content>
  </BaseModal>
)

export default AcceptPickupTaskModal
