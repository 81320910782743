import React from 'react'
import { FormikBag, FormikProps, withFormik, WithFormikConfig } from 'formik'
import { OptionallyIdentifiedOrdererListItem, OrdererListItem } from '@supplyhound/types'
import { GenericItemValidationSchema } from '@supplyhound/utils/validationSchemas'
import { DEFAULT_ORDERER_LIST_ITEM } from '@supplyhound/utils/config'
import { catchAndSetErrors } from '@supplyhound/utils/forms'

export type OrdererListItemFormProps = {
  item?: OptionallyIdentifiedOrdererListItem
  onSubmit: (item: OptionallyIdentifiedOrdererListItem) => Promise<void>
  triggerResetForm?: boolean
  setShowFullItemForm?: (showFullItemForm: boolean) => any
  showFullItemForm?: boolean
}

export type OrdererListItemFormValues = Pick<OrdererListItem, 'description' | 'quantity' | 'image'>

function withOrdererListItemFormikOptions<OuterProps extends OrdererListItemFormProps>(
  config: Partial<WithFormikConfig<OuterProps, OrdererListItemFormValues>> = {}
) {
  return function decorateComponentWithGenericItemFormikOptions(
    component: React.FC<OuterProps & FormikProps<OrdererListItemFormValues>>
  ) {
    return withFormik<OuterProps, OptionallyIdentifiedOrdererListItem>({
      displayName: 'OrdererListItemForm',
      enableReinitialize: true,
      validationSchema: GenericItemValidationSchema,
      validateOnMount: true,

      mapPropsToValues({ item }: OrdererListItemFormProps): OptionallyIdentifiedOrdererListItem {
        return item ?? DEFAULT_ORDERER_LIST_ITEM
      },

      handleSubmit(
        values: OptionallyIdentifiedOrdererListItem,
        {
          props: { onSubmit },
          resetForm,
          setErrors,
        }: FormikBag<OrdererListItemFormProps, OptionallyIdentifiedOrdererListItem>
      ) {
        catchAndSetErrors(() => onSubmit(values).then(() => resetForm()), setErrors)
      },
      ...config,
    })(component)
  }
}

export default withOrdererListItemFormikOptions
