import React, { ReactNode, useCallback } from 'react'
import styled from 'styled-components'
import { IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import BackButton from '@supplyhound/components/buttons/BackButton'
import CancelButton from '@supplyhound/components/buttons/CancelButton'
import { HOME_ROUTE, DESKTOP_WIDTH } from '@supplyhound/utils/config'
import MenuButton from '@supplyhound/components/buttons/MenuButton'
import { useHistory } from 'react-router-dom'
import { toEnumType } from '@supplyhound/types'
import { modalController } from '@ionic/core'
import Ellipsis from '@supplyhound/components/common/text/Ellipsis'
import useWindowDimensions from '@supplyhound/hooks/useWindowDimensions'
import useStores from '@supplyhound/hooks/useStores'
import { observer } from 'mobx-react-lite'

const StyledIonToolbar = styled(IonToolbar)`
  --padding-top: var(--space-unit);
  --padding-bottom: var(--space-unit);
  --padding-start: var(--space-unit);
  --padding-end: var(--space-unit);

  &&& {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Label = styled.div`
  font-size: 1rem;
  font-weight: 700;
`
const SubLabel = styled.div`
  font-size: 1rem;
  line-height: 20px;
  font-weight: 400;
  color: var(--greyscale-5);
`

export const HeaderModes = {
  Root: 'Root',
  Leaf: 'Leaf',
  Cancel: 'Cancel',
  None: 'None',
}

type HeaderMode = toEnumType<typeof HeaderModes>

type Props = {
  mode?: HeaderMode
  label?: string
  subLabel?: String
  end?: ReactNode
  onBackButtonClick?: () => void
  onCancelButtonClick?: () => void
}

const Header: React.FC<Props> = ({
  label,
  subLabel,
  end,
  mode = HeaderModes.Root,
  onBackButtonClick,
  onCancelButtonClick,
}) => {
  const { userProfileStore } = useStores()

  const history = useHistory()

  const handleBackButtonClick = useCallback(() => {
    if (!history) {
      throw new Error(
        'Back button needs to be rendered in react tree. If you are using a modal, you will need to render it inline'
      )
    }
    history.length ? history.goBack() : history.push(HOME_ROUTE)
  }, [history?.length])

  const { width } = useWindowDimensions()
  const isDesktop = width > DESKTOP_WIDTH
  const hasDesktopNavFF = userProfileStore.profile?.feature_flags.desktopnav

  const renderStart = useCallback(() => {
    switch (mode) {
      case HeaderModes.Root:
        if (hasDesktopNavFF) {
          return isDesktop ? null : (
            <IonButtons>
              <MenuButton menu="menu" autoHide={false} />
            </IonButtons>
          )
        } else {
          return (
            <IonButtons>
              <MenuButton menu="menu" autoHide={false} />
            </IonButtons>
          )
        }

      case HeaderModes.Leaf:
        return <BackButton onClick={onBackButtonClick ? onBackButtonClick : handleBackButtonClick} />
      case HeaderModes.Cancel:
        return (
          <CancelButton
            onClick={
              onCancelButtonClick
                ? onCancelButtonClick
                : async () => {
                    await modalController.dismiss()
                  }
            }
          />
        )
      case HeaderModes.None:
        return null
    }
  }, [mode, isDesktop, onBackButtonClick])

  return (
    <IonHeader class="ion-no-border">
      <StyledIonToolbar>
        <div slot="start">{renderStart()}</div>
        <IonTitle>
          <Label>
            <Ellipsis text={label} />
          </Label>
          <SubLabel>{subLabel}</SubLabel>
        </IonTitle>
        <div slot="end">{end}</div>
      </StyledIonToolbar>
    </IonHeader>
  )
}

export default observer(Header)
