import { makeAutoObservable } from 'mobx'
import { ID } from '@supplyhound/types'
import { fetchTask, deleteTask, fetchRecentTaskHistory } from 'api/tasks'
import { updateDeliveryTaskStatus, overrideDeliveryTaskStatus } from 'api/deliveryTasks'
import { updatePickupTaskStatus, updatePickupTask, overridePickupTaskStatus } from 'api/pickupTasks'
import { DeliveryTaskStatusStepEvent, DeliveryTaskStatus, PickupTaskStatusStepEvent, PickupTask } from 'types/tasks'
import { fetchOrderHistory } from 'api/order-history'

export class TaskStore {
  constructor() {
    makeAutoObservable(this)
  }

  dispatchFetchTask = async (taskId: ID) => {
    const data = await fetchTask(taskId)
    return data.task
  }

  dispatchUpdateDeliveryTaskStatus = async (taskId: ID, event: DeliveryTaskStatusStepEvent, payload: any) => {
    const data = await updateDeliveryTaskStatus(taskId, event, payload)
    return data.delivery_task
  }

  dispatchUpdatePickupTaskStatus = async (taskId: ID, event: PickupTaskStatusStepEvent, payload: any) => {
    const data = await updatePickupTaskStatus(taskId, event, payload)
    return data.pickup_task
  }

  dispatchUpdatePickupTask = async (taskId: ID, pickupTaskUpdates: Partial<PickupTask>) => {
    const data = await updatePickupTask(taskId, pickupTaskUpdates)
    return data.pickup_task
  }

  dispatchOverrideDeliveryStatus = async (taskId: ID, status: DeliveryTaskStatus) => {
    await overrideDeliveryTaskStatus(taskId, status)
  }

  dispatchOveridePickupStatus = async (taskId: ID, status: string) => {
    await overridePickupTaskStatus(taskId, status)
  }

  dispatchDeleteTask = async (taskId: ID) => {
    const data = await deleteTask(taskId)
    return data
  }

  dispatchFetchOrderHistory = async (taskId: ID) => {
    return await fetchOrderHistory(taskId)
  }

  dispatchFetchRecentTasks = async (userPhone: string, limit: number) => {
    return fetchRecentTaskHistory(userPhone, limit)
  }
}
