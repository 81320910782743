import React, { useRef, ReactElement, useEffect } from 'react'
import styled from 'styled-components'
import { FieldProps, getIn } from 'formik'
import { IonItemGroup } from '@ionic/react'
import InputMask from 'react-input-mask'

import FieldHeader from './FieldHeader'
import InputBorder from './InputBorder'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'

type Props = {
  label: string
  mask?: string | [string | RegExp]
  extraContent?: ReactElement
  extraLabel?: ReactElement
  placeholder?: string
  type?: string
  className?: string
  focus?: boolean
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const StyledInputMask = styled(InputMask)`
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0;
`

const TextInputField: React.FC<FieldProps & Props> = ({
  field,
  form,
  label,
  mask = '',
  extraContent,
  extraLabel,
  placeholder,
  className,
  type,
  focus,
  disabled,
  onChange,
}) => {
  const { name, onBlur } = field
  const error = getIn(form.errors, name)
  const touched = getIn(form.touched, name)

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focus, inputRef])

  return (
    <IonItemGroup className={className}>
      <FieldHeader label={label} extraLabel={extraLabel} />

      <InputBorder mode="ios" isError={error && touched} disabledBackground={disabled}>
        <StyledInputMask
          {...field}
          // Context on what's happening here https://github.com/sanniassin/react-input-mask/issues/159#issuecomment-871117121
          inputRef={inputElement => {
            if (inputRef) {
              inputRef.current = inputElement
            }
          }}
          placeholder={placeholder}
          mask={mask}
          onChange={e => {
            form.setFieldValue(name, e.target.value)
            if (onChange) {
              onChange(e)
            }
          }}
          onBlur={e => {
            onBlur(e)
          }}
          className="input-mask"
          type={type}
          disabled={disabled}
        />

        {extraContent}
      </InputBorder>
      {error && touched ? <ErrorLabel>{error}</ErrorLabel> : null}
    </IonItemGroup>
  )
}

export default TextInputField
