export const removeCountry = (fullAddress: string) => {
  const addressComponents = fullAddress.split(', ')
  addressComponents.pop()
  return addressComponents.join(', ')
}

// E.g. return "Golden State Lumber - San Rafael"
export const parseNameWithCity = (address: string) => {
  const addressArr = address.split(',')
  const name = addressArr[0]
  const city = addressArr.slice(-3, -2).join(',')
  return `${name} -${city}`
}

// E.g. return "San Rafael, CA 94901"
export const parseAddress = (fullAddress: string) => {
  const tokens = fullAddress.split(', ')
  tokens.shift() // Remove the first portion of address because it's already shown as the name
  tokens.pop() // Remove the country code because we only serve US
  return tokens.join(', ')
}

// E.g. convert from "807 W Broadway Rd, Mesa, AZ 85210, US" to "W Broadway Rd, Mesa, AZ"
export const removeNumberAndZipcode = (fullAddress: string) => {
  const token = fullAddress.split(', ')
  const street = token[0]
  const city = token[1]
  const state = token[2].split(' ')[0]
  const streetWithoutNumber = street.split(' ').slice(1).join(' ')
  return `${streetWithoutNumber}, ${city}, ${state}`
}
