import type { Conversation, ConversationStatus } from 'types/conversations'
import { ID } from '@supplyhound/types'

import { apiAxiosInstance } from '.'

interface ConversationResponse {
  conversation: Conversation
}
export async function fetchConversation(conversationId: ID): Promise<Conversation> {
  const response = await apiAxiosInstance.get<ConversationResponse>(`/v1/conversations/${conversationId}`)
  return response.data.conversation
}

interface ConversationsResponse {
  conversations: Conversation[]
}
export async function fetchConversations(): Promise<ConversationsResponse> {
  const response = await apiAxiosInstance.get<ConversationsResponse>('/v1/conversations')

  return response.data
}

type OverrideConversationStatusResponse = void
export async function overrideConversationStatus(
  conversationId: ID,
  status: ConversationStatus
): Promise<OverrideConversationStatusResponse> {
  const response = await apiAxiosInstance.post<OverrideConversationStatusResponse>(
    `/v1/conversations/${conversationId}/override_status`,
    {
      status,
    }
  )
  return response.data
}
