import { formatDateTime } from 'helpers/datetimes'

import React from 'react'
import { Task } from 'types/tasks'

const OrderPlacedCol = ({ task }: { task: Task }) => {
  return <>{formatDateTime(task.created_at)}</>
}

export default OrderPlacedCol
