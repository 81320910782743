import React from 'react'
import styled from 'styled-components'

const Text = styled.div<{ lines: number }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @supports (-webkit-line-clamp: ${props => props.lines}) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.lines};
    -webkit-box-orient: vertical;
  }
`

interface EllipsisProps {
  text?: string
  lines?: number
}

const Ellipsis: React.FC<EllipsisProps> = ({ text, lines = 1 }) => {
  return (
    <Text title={text} lines={lines}>
      {text}
    </Text>
  )
}

export default Ellipsis
