import { TeamMemberList, OrdererTeamMemberList, ID } from '@supplyhound/types'
import { axiosInstance } from './axios'
import { AxiosRequestConfig } from 'axios'

export const fetchTeamMemberList = (id: ID, config: AxiosRequestConfig = {}): Promise<TeamMemberList> =>
  axiosInstance.get(`/v1/team_member_lists/${id}`, config).then(({ data: { team_member_list } }) => team_member_list)

export const fetchOrdererTeamMemberLists = (teamId: ID, jobSiteId: ID): Promise<OrdererTeamMemberList[]> =>
  axiosInstance
    .get(`v1/teams/${teamId}/team_member_lists/all`, {
      params: { job_site_id: jobSiteId },
    })
    .then(({ data: { team_member_lists } }) => team_member_lists)

export const fetchTeamMemberLists = (config: AxiosRequestConfig = {}): Promise<TeamMemberList[]> =>
  axiosInstance.get(`/v1/team_member_lists`, config).then(({ data: { team_member_lists } }) => team_member_lists)
