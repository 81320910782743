import Card from '@supplyhound/components/common/Card'
import styled from 'styled-components'

import { IonInput } from '@ionic/react'
import Button from '@supplyhound/components/buttons/SubmitButton'
import Caption from '@supplyhound/components/common/text/Caption'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const StyledCard = styled(Card)`
  display: flex;
  padding: 30px;
  justify-content: center;
  flex-direction: column;
  width: 500px;
  align-items: center;
  text-align: center;
`

export const StyledInput = styled(IonInput)`
  text-align: center;
  border: 1px solid var(--ion-border-color);
  border-radius: var(--ion-border-radius);
  background-color: var(--ion-background-color);
  margin: 4px;
`

export const StyledButton = styled(Button)`
  width: 100%;
`

export const Error = styled.div`
  color: var(--ion-color-warning);
  background-color: rgba(var(--ion-color-warning-rgb), 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  border-radius: var(--ion-border-radius);
`
export const Success = styled.div`
  color: var(--ion-color-success);
  background-color: rgba(var(--ion-color-success-rgb), 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  border-radius: var(--ion-border-radius);
`
export const BottomLink = styled(Caption)`
  color: var(--greyscale-6);
`
