import { makeAutoObservable, runInAction } from 'mobx'
import * as FullStory from '@fullstory/browser'
import jwt from 'jsonwebtoken'
import { UserProfile, Market, SelectOption, DecodedUserToken, ModalView } from '@supplyhound/types'
import { ProfileStoreProps } from './ProfileStore'
import { destroyUser, fetchProfile } from '@supplyhound/api'
import { fetchMarkets } from '@supplyhound/api'
import { fetchModalViews, createModalView } from '@supplyhound/api'
import { AuthStore } from './AuthStore'
import * as DirectUpload from '@supplyhound/utils/directUpload'
import { updateTeamReminderSchedule as updateTeamReminderScheduleApi } from '@supplyhound/api'
import { SubscriptionStore } from './SubscriptionStore'

type UserStoreProps = ProfileStoreProps<UserProfile> & { subscriptionStore: SubscriptionStore }

export class UserProfileStore {
  markets: Market[] = []
  error: string = ''
  modalViews: ModalView[] | null = null

  authStore: AuthStore<UserProfile>
  subscriptionStore: SubscriptionStore

  constructor({ authStore, subscriptionStore }: UserStoreProps) {
    this.authStore = authStore
    this.subscriptionStore = subscriptionStore

    makeAutoObservable(this, {
      authStore: false,
      subscriptionStore: false,
    })
  }

  get marketOptions(): SelectOption[] {
    return this.markets.map(market => ({
      label: market.name,
      value: market.id,
    }))
  }

  get marketTimezone(): string | undefined {
    return this.profile?.market.timezone
  }

  get profile() {
    return this.authStore.profile
  }

  get decodedToken() {
    const token = this.authStore.getToken()
    return token && (jwt.decode(token) as DecodedUserToken)
  }

  setMarkets(markets: Market[]) {
    this.markets = markets
  }

  setError(error: string) {
    this.error = error
  }

  reset() {
    this.authStore.reset()
    this.subscriptionStore.reset()
  }

  dispatchLoadMarkets = async () => {
    try {
      this.setError('')

      const { markets } = await fetchMarkets()

      this.setMarkets(markets)
    } catch (e) {
      this.setError((e as Error).toString())
    }
  }

  dispatchLoadProfile = async () => {
    if (!this.authStore.getToken()) {
      return
    }

    try {
      this.setError('')

      const response = await fetchProfile()

      this.authStore.setProfile(response.data.user)
      this.subscriptionStore.setSubscription(response.data.user.subscription)
      DirectUpload.initialize(this.authStore.getToken())
    } catch (e) {
      this.setError((e as Error).toString())
    }
  }

  dispatchLoadModalViews = async () => {
    if (!this.authStore.getToken()) {
      return
    }

    try {
      this.setError('')

      const response = await fetchModalViews()
      runInAction(() => (this.modalViews = response))
    } catch (e) {
      this.setError((e as Error).toString())
    }
  }

  dispatchCreateModalView = async (modalType: string) => {
    if (!this.authStore.getToken()) {
      return
    }

    try {
      this.setError('')

      const response = await createModalView(modalType)
      // modalViews initially set to null
      this.modalViews = this.modalViews || []
      this.modalViews.push(response)
    } catch (e) {
      this.setError((e as Error).toString())
    }
  }

  dispatchUpdateReminderSchedule(reminderTime: string, reminderDays: string[]) {
    const teamId = this.profile?.team.id

    if (!teamId) throw Error('No team id')

    return updateTeamReminderScheduleApi(teamId, reminderTime, reminderDays).then(team =>
      this.authStore.setTeamReminderSchedule(team)
    )
  }

  dispatchDeleteAccount = async () => {
    if (this.profile) {
      return await destroyUser(this.profile.id)
    }
  }

  identifyFullStoryUser = () => {
    const id = this.profile?.id.toString()
    if (id) {
      FullStory.identify(id, {
        displayName: this.profile?.name,
        email: this.profile?.email,
      })
    }
  }
}
