import React, { useCallback } from 'react'
import { Formik, FormikProps, FormikHelpers, Field, Form } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'
import Spacer from '@supplyhound/components/common/Spacer'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import TextAreaField from '@supplyhound/forms/fields/TextAreaField'

interface Values {
  note: string
}

interface Props {
  onSubmit: (values: Values) => void
}

const ValidationSchema = Yup.object().shape({
  note: Yup.string().required('Required'),
})

const Container = styled.div`
  padding: 20px;
`

const ChangeRequestModal: React.FC<Props> = ({ onSubmit, dismiss }) => {
  const handleSubmit = useCallback(
    (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      onSubmit(values)

      setSubmitting(false)
    },
    [onSubmit]
  )

  return (
    <BaseModal label="Change Request" onDidDismiss={() => dismiss()}>
      <Container>
        <Formik
          enableReinitialize
          initialValues={{ note: '' }}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, handleSubmit }: FormikProps<Values>) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Field name="note" component={TextAreaField} placeholder="Please enter change note" rows={4} />
              <Spacer height={20} />
              <SubmitButton type="submit" disabled={!isValid}>
                Submit
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </Container>
    </BaseModal>
  )
}

export default ChangeRequestModal
