import { makeAutoObservable, runInAction } from 'mobx'
import {
  JobSite,
  JobSiteStatus,
  JobSiteStatuses,
  LastOrderExtraProps,
  OrdererList,
  OrdererListItem,
  SelectableOrdererList,
  SelectableOrdererListItem,
  TaskTypes,
  UnidentifiedDeliveryTask,
  UnidentifiedGenericTask,
  UnidentifiedJobSite,
  UnidentifiedPickupTask,
  UnidentifiedTaskItem,
  ID,
  QuoteTask,
  DeliveryTaskBillingDetails,
} from '@supplyhound/types'
import {
  createJobSite,
  deleteJobSite,
  fetchJobSites,
  updateJobSite,
  getDeliveryQuotes,
  getPickupQuotes,
} from '@supplyhound/api'
import { convertJobSiteToAttributes } from '@supplyhound/utils/jobSite'
import { omit, orderBy, pick, union } from 'lodash'
import { OrdererListStore, TaskStore } from '.'
import { CreatableDeliveryTask, createDeliveryTask } from '@supplyhound/api'
import { CreatablePickupTask, createPickupTask } from '@supplyhound/api'
import { makePersistable } from 'mobx-persist-store'

type JobSitesStoreContructorProps = {
  listStore: OrdererListStore
  taskStore: TaskStore
}

type JobSiteMap = Record<ID, JobSite>

type LastOrderedTask = UnidentifiedGenericTask & LastOrderExtraProps

export const DELIVERY_ONLY_JOBSITE_ID = -1

export class JobSitesStore {
  jobSites: JobSiteMap = {}

  listStore: OrdererListStore
  taskStore: TaskStore
  lastOrderedTask?: LastOrderedTask
  deliveryOnlyJobSite?: JobSite

  constructor({ listStore, taskStore }: JobSitesStoreContructorProps) {
    this.listStore = listStore
    this.taskStore = taskStore
    this.lastOrderedTask = undefined

    makeAutoObservable(this, { listStore: false, taskStore: false })
    makePersistable(this, {
      name: 'JobSitesStore',
      properties: ['lastOrderedTask'],
    })
  }

  // Passing `-1` here as a temporary job site id because a job site id in a delivery only flow isn't known until right before the order is placed
  get deliveryOnlyJobSiteId() {
    return DELIVERY_ONLY_JOBSITE_ID
  }

  get allJobSiteArray() {
    const allUserJobSites = Object.values(this.jobSites).filter(jobSite => jobSite.id !== this.deliveryOnlyJobSiteId)
    return orderBy(allUserJobSites, ['status'], ['asc'])
  }

  get jobSiteArray() {
    return this.allJobSiteArray.filter(jobSite => jobSite.status !== JobSiteStatuses.archived)
  }

  reset() {
    this.jobSites = {}
    this.taskStore.reset()
    this.listStore.reset()
  }

  getJobSiteTask(jobSiteId: ID) {
    const jobSite = jobSiteId === this.deliveryOnlyJobSiteId ? this.deliveryOnlyJobSite : this.jobSites[jobSiteId]

    if (!jobSite) return

    let task = this.taskStore.getTask(jobSiteId)
    if (!task) {
      task = this.taskStore.upsert(jobSiteId, {
        job_site_name: jobSite.name,
        job_site_id: jobSite.id,
        delivery_address: jobSite.formatted_address,
        delivery_username: jobSite.contact_name,
        delivery_phone: jobSite.phone,
        delivery_note: jobSite.note,
      })
    }
    return task
  }

  getJobSiteTaskBillingDetails(jobSiteId: ID, waiveOrderMangementFee: boolean, useNewOrderManagmentFee: boolean) {
    return this.taskStore.getDeliveryTaskBillingDetails(jobSiteId, waiveOrderMangementFee, useNewOrderManagmentFee)
  }

  updateJobSiteTask(jobSiteId: ID, partialTask: Partial<UnidentifiedGenericTask>) {
    const jobSite = this.jobSites[jobSiteId]
    let task = this.taskStore.getTask(jobSiteId)
    if (task) {
      this.taskStore.upsert(jobSiteId, partialTask)
    } else {
      this.taskStore.upsert(jobSiteId, {
        job_site_name: jobSite.name,
        job_site_id: jobSite.id,
        delivery_address: jobSite.formatted_address,
        delivery_username: jobSite.contact_name,
        delivery_phone: jobSite.phone,
        delivery_note: jobSite.note,
        ...partialTask,
      })
    }
  }

  selectJobSiteTaskItems(jobSiteId: ID, items: SelectableOrdererListItem[]) {
    this.updateJobSiteTask(jobSiteId, {
      itemIds: items.filter(item => !!item.selected).map(item => item.id),
    })
  }

  setJobSiteTaskItemSelectState(jobSiteId: ID, targetItemId: ID, isSelected: boolean) {
    const jobSiteTask = this.getJobSiteTask(jobSiteId)
    if (isSelected) {
      this.updateJobSiteTask(jobSiteId, {
        itemIds: union(jobSiteTask!.itemIds, [targetItemId]),
      })
    } else {
      this.updateJobSiteTask(jobSiteId, {
        itemIds: jobSiteTask!.itemIds.filter(itemId => itemId !== targetItemId),
      })
    }
  }

  getJobSiteOrdererList(jobSiteId: ID): SelectableOrdererList | undefined {
    const jobSite = this.jobSites[jobSiteId]
    const list = this.listStore.lists[jobSite?.orderer_list.id]
    const task = this.getJobSiteTask(jobSiteId)

    if (!task || !list) return

    const active_items = this.getItemsWithSelection(list.active_items, task.itemIds)

    return { ...list, active_items }
  }

  private getItemsWithSelection(items: OrdererListItem[], selectedItemIds: ID[]) {
    return items.map(item => ({
      ...item,
      selected: selectedItemIds.some(id => id === item.id),
    }))
  }

  private getSelectedItemsForTask(list: OrdererList, task: UnidentifiedGenericTask) {
    const itemsWithSelection = this.getItemsWithSelection(list.active_items, task.itemIds)
    return itemsWithSelection.filter(({ selected }) => selected)
  }

  private constructCreatableDeliveryTask(
    task: UnidentifiedDeliveryTask,
    items: OrdererListItem[],
    stripe_payment_method_id: string,
    quote_id?: string,
    quote_code?: string
  ): CreatableDeliveryTask {
    let supplier_place_id = task.supplier?.place_id
    if (task.supplier?.place_ids && task.supplier?.place_ids.length > 0) {
      supplier_place_id = task.supplier?.place_ids[0]
    }
    return {
      ...omit(task, ['promotion', 'itemIds', 'supplier']),
      items: items.map(item => {
        item['orderer_list_item_id'] = item.id
        return omit(item, ['id', 'selected', 'orderer_list_id']) as UnidentifiedTaskItem
      }),
      supplier_id: task.supplier?.supplier_id,
      extra_fee: this.taskStore.taskExtraFee(task),
      stripe_payment_method_id,
      quote_id,
      quote_code,
      supplier_place_id,
    }
  }
  private constructCreatablePickupTask(
    task: UnidentifiedPickupTask,
    items: OrdererListItem[],
    stripe_payment_method_id: string
  ): CreatablePickupTask {
    return {
      ...pick(task, [
        'job_site_name',
        'pickup_address',
        'pickup_datetime',
        'pickup_note',
        'pickup_type',
        'type',
        'job_site_id',
      ]),
      items: items.map(item => {
        item['orderer_list_item_id'] = item.id
        return omit(item, ['id', 'selected', 'orderer_list_id']) as UnidentifiedTaskItem
      }),
      supplier_id: task.supplier?.supplier_id,
      stripe_payment_method_id,
    }
  }

  private updateLastOrderedTask(
    jobSiteId: ID,
    task: UnidentifiedGenericTask,
    stripe_payment_method_id: string,
    items: OrdererListItem[] = [],
    waiveOrderMangementFee: boolean,
    useNewOrderManagmentFee: boolean
  ) {
    this.lastOrderedTask = {
      ...task,
      items: items,
      billingDetails: this.getJobSiteTaskBillingDetails(jobSiteId, waiveOrderMangementFee, useNewOrderManagmentFee),
      supplier_id: task.supplier?.supplier_id,
      stripe_payment_method_id,
    }
  }

  updateLastBillingDetails(billingDetails: DeliveryTaskBillingDetails) {
    this.lastOrderedTask = {
      ...this.lastOrderedTask,
      billingDetails: { ...billingDetails },
    }
  }

  dispatchFetchJobSites = async () => {
    const jobSites = await fetchJobSites()
    runInAction(() => {
      this.jobSites = jobSites.reduce((memo, jobSite) => ({ ...memo, [jobSite.id]: jobSite }), {})
    })
    return this.jobSites
  }

  async dispatchCreateJobSite(data: UnidentifiedJobSite) {
    const jobSite = await createJobSite(convertJobSiteToAttributes(data))

    this.jobSites[jobSite.id] = jobSite

    return jobSite
  }

  async dispatchUpdateJobSite(jobSite: JobSite) {
    if (!this.jobSites[jobSite.id]) throw new Error(`Can't update a jobsite (${jobSite.id}) that doesn't exist!`)

    const updatedJobSite = await updateJobSite(jobSite.id, convertJobSiteToAttributes(jobSite))
    this.jobSites[jobSite.id] = updatedJobSite
    return updatedJobSite
  }

  async dispatchUpdateJobSiteStatus(jobSiteId: ID, status: JobSiteStatus) {
    if (!this.jobSites[jobSiteId]) throw new Error(`Can't update a jobsite (${jobSiteId}) that doesn't exist!`)

    this.jobSites[jobSiteId] = await updateJobSite(jobSiteId, { status: status })
  }

  async dispatchDeleteJobSite(jobSiteId: ID) {
    if (!this.jobSites[jobSiteId]) throw new Error(`Can't delete a jobsite (${jobSiteId}) that doesn't exist!`)

    await deleteJobSite(jobSiteId)
    delete this.jobSites[jobSiteId]
  }

  async dispatchCreateJobSiteDeliveryTask(
    jobSiteId: ID,
    stripe_payment_method_id: string,
    waiveOrderMangementFee: boolean,
    useNewOrderManagmentFee: boolean,
    quote_id?: string,
    quote_code?: string
  ) {
    let task = this.taskStore.getTask(jobSiteId) as UnidentifiedDeliveryTask
    const list = this.getJobSiteOrdererList(jobSiteId)

    if (!task) throw new Error(`Task for jobsite (${jobSiteId}) does not exist!`)
    if (task.type !== TaskTypes.Delivery) throw new Error(`Task ${task} is a ${task.type} task!`)
    if (task.ordered_directly) {
      const delivery_task = this.constructCreatableDeliveryTask(
        task,
        [],
        stripe_payment_method_id,
        quote_id!,
        quote_code
      )
      await createDeliveryTask({ delivery_task })
      this.updateLastOrderedTask(
        jobSiteId,
        task,
        stripe_payment_method_id,
        [],
        waiveOrderMangementFee,
        useNewOrderManagmentFee
      )

      this.taskStore.removeTask(this.deliveryOnlyJobSiteId)
    } else {
      if (!list) throw new Error(`List for jobsite (${jobSiteId}) does not exist!`)
      if (!list?.active_items) throw new Error(`Jobsite ${jobSiteId} has no selected items!`)

      const items = this.getSelectedItemsForTask(list, task)

      const delivery_task = this.constructCreatableDeliveryTask(
        task,
        items,
        stripe_payment_method_id,
        quote_id!,
        quote_code
      )
      await createDeliveryTask({ delivery_task })
      this.updateLastOrderedTask(
        jobSiteId,
        task,
        stripe_payment_method_id,
        items,
        waiveOrderMangementFee,
        useNewOrderManagmentFee
      )

      await Promise.all(items.map(this.listStore.dispatchArchiveItem))

      this.taskStore.removeTask(jobSiteId)
    }
  }

  async dispatchCreateJobSitePickupTask(
    jobSiteId: ID,
    stripe_payment_method_id: string,
    waiveOrderMangementFee: boolean,
    useNewOrderManagmentFee: boolean
  ) {
    let task = this.taskStore.getTask(jobSiteId)
    const list = this.getJobSiteOrdererList(jobSiteId)

    if (!task) throw new Error(`Task for jobsite (${jobSiteId}) does not exist!`)
    if (task.type !== TaskTypes.Pickup) throw new Error(`Task ${task} is a ${task.type} task!`)
    if (!list) throw new Error(`List for jobsite (${jobSiteId}) does not exist!`)
    if (!list?.active_items) throw new Error(`Jobsite ${jobSiteId} has no selected items!`)

    task = task as UnidentifiedPickupTask

    const items = this.getSelectedItemsForTask(list, task)
    const pickup_task = this.constructCreatablePickupTask(task, items, stripe_payment_method_id)

    await createPickupTask({ pickup_task })
    this.updateLastOrderedTask(
      jobSiteId,
      task,
      stripe_payment_method_id,
      items,
      waiveOrderMangementFee,
      useNewOrderManagmentFee
    )

    await Promise.all(items.map(this.listStore.dispatchArchiveItem))

    this.taskStore.removeTask(jobSiteId)
  }

  async dispatchGetDeliveryQuotes(deliveryTask: QuoteTask) {
    const { quote, code } = await getDeliveryQuotes(deliveryTask)
    const subtotal = Number(quote.subtotal)
    const extraFee = Number(quote.extra_fee)
    const handlingFee = Number(quote.order_management_fee)
    const discount = Number(quote.discount)
    return {
      quote_code: code,
      quote_id: quote.quote_id,
      discount: discount,
      extraFee: extraFee,
      handlingFee: handlingFee,
      subtotal: subtotal,
      subtotalWithFees: subtotal + extraFee + handlingFee - discount,
      total: Number(quote.total),
    }
  }

  async dispatchGetPickupQuotes(pickupTask: UnidentifiedPickupTask) {
    const { quote, code } = await getPickupQuotes(pickupTask)
    const subtotal = Number(quote.subtotal)
    const handlingFee = Number(quote.order_management_fee)
    const discount = Number(quote.discount)
    return {
      quote_code: code,
      quote_id: quote.quote_id,
      discount: discount,
      extraFee: 0,
      handlingFee: handlingFee,
      subtotal: subtotal,
      subtotalWithFees: subtotal + handlingFee - discount,
      total: Number(quote.total),
    }
  }
}
