import React from 'react'
import { observer } from 'mobx-react-lite'
import { IonButton, IonRow, IonGrid, IonCol } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import styled from 'styled-components'
import Content from '@supplyhound/components/Content'
import { timeOutline, checkmarkCircleOutline } from 'ionicons/icons'
import Spacer from '@supplyhound/components/common/Spacer'
import { REMINDER_TIME, formatTime } from '@supplyhound/utils/teamReminder'
import InlineModal from '@supplyhound/components/common/Modals/InlineModal'

interface Props {
  openModal: boolean
  setOpenModal: (arg0: boolean) => void
  handleClick: (time: string) => void
  selectedReminderTime: string
  setSelectedReminderTime: (time: string) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`

const Text = styled.h3`
  text-align: center;
  margin: 0px 55px 0px 55px;
`

const ClockIcon = styled(Icon)`
  font-size: 5rem;
  margin-top: 40px;
  margin-bottom: 55px;
  @media (min-width: 769px) {
    margin-top: 10px;
    margin-bottom: 30px;
  }
`

const CheckmarkIcon = styled(Icon)`
  font-size: 1.6rem;
  color: var(--ion-color-success);
  margin-left: 10px;
`

const ChooseButton = styled(IonButton)`
  height: 60px;
  margin-top: 20px;
  position: fixed;
  right: 20px;
  left: 20px;
  bottom: 15px;
  width: auto;
`

const Time = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid;
  border-radius: var(--ion-border-radius);
  border-color: ${props => (props.selected ? 'var(--ion-color-success)' : 'var(--ion-color-dark)')};
  height: 60px;
  cursor: pointer;
`
const Col = styled(IonCol)`
  text-align: center;
`

const Row = styled(IonRow)`
  align-items: center;
`

const StyledHeader = styled.h3`
  color: var(--ion-color-dark);
  margin: auto;
`
const ChangeReminderTimeModal: React.FC<Props> = ({
  openModal,
  setOpenModal,
  handleClick,
  selectedReminderTime,
  setSelectedReminderTime,
}) => {
  return (
    <InlineModal isOpen={openModal} onDidDismiss={() => setOpenModal(false)}>
      <Content>
        <Container>
          <ClockIcon width={80} icon={timeOutline} />
          <Text>What time of the day would you like your team to receive materials reminder?</Text>
        </Container>
        {REMINDER_TIME.map((time, index) => {
          return (
            <TimeCard
              selected={time === selectedReminderTime}
              label={formatTime(time)}
              key={index}
              onClick={() => {
                setSelectedReminderTime(time)
              }}
            />
          )
        })}
        <ChooseButton
          expand="block"
          fill="solid"
          onClick={() => {
            handleClick(selectedReminderTime)
            setOpenModal(false)
          }}
        >
          Choose this time
        </ChooseButton>
      </Content>
    </InlineModal>
  )
}

export default observer(ChangeReminderTimeModal)

interface TimeCardProps {
  selected: boolean
  label: string
  onClick: () => void
}
const TimeCard: React.FC<TimeCardProps> = ({ label, onClick, selected }) => {
  return (
    <>
      <Time selected={selected} onClick={onClick}>
        <IonGrid>
          <Row>
            <Col size="1">{selected && <CheckmarkIcon icon={checkmarkCircleOutline} width={30} />}</Col>
            <Col>
              <StyledHeader>{label}</StyledHeader>
            </Col>
          </Row>
        </IonGrid>
      </Time>
      <Spacer height={20} />
    </>
  )
}
