import React from 'react'
import { Spinner } from 'react-bootstrap'

const LoadingSpinner = () => (
  <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
)

export default LoadingSpinner
