import React from 'react'
import { generatePath } from 'react-router-dom'
import { IonLabel, IonRouterLink } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import { enterOutline } from 'ionicons/icons'
import styled from 'styled-components'
import TextInputField from '@supplyhound/forms/fields/TextInputField'
import { Field, withFormik, FormikProps, FormikBag } from 'formik'
import { SIGNUP_ROUTE } from '@supplyhound/utils/config'
import * as Yup from 'yup'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import Spacer from '@supplyhound/components/common/Spacer'
import useHandlePressEnter from '@supplyhound/hooks/useHandlePressEnter'

export interface LoginFormValues {
  email: string
  password: string
}

interface LoginFormProps {
  error: string
  triggerResetPasswordModal: () => void
  onSubmit: (values: LoginFormValues) => Promise<void>
}

const ForgotPasswordLink = styled.div`
  font-size: 14px;
  color: var(--ion-color-anchor);
  cursor: pointer;
`

const IncorrectPasswordLabel = styled(IonLabel)`
  color: #ff4236;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
`
const ButtonTier = styled.div`
  padding-top: 20px;
  padding-bottom: 26px;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
`

const StyledIcon = styled(Icon)`
  color: white;
  margin-right: 10px;
`

const EnterEmailField = styled(Field)`
  margin-top: 25px;
`

const PasswordField = styled(Field)`
  margin-top: 20px;
`

const StyledLink = styled.span`
  text-align: center;
`

const LoginForm: React.FC<LoginFormProps & FormikProps<LoginFormValues>> = ({
  error,
  submitForm,
  triggerResetPasswordModal,
}) => {
  useHandlePressEnter(submitForm, false)
  return (
    <ContentWrapper>
      {/* HACK!! - Empty input field for browser to autofocus on instead of the email field.
        Without this empty input, the first field will auto validate and display validation
        error message on load. 
        */}
      <input type="text" style={{ display: 'none' }} />
      {/* End Hack */}
      <EnterEmailField label="Enter your email to login" name="email" placeholder="email" component={TextInputField} />
      <PasswordField
        label="Password"
        name="password"
        extraLabel={
          <ForgotPasswordLink color="anchor" onClick={triggerResetPasswordModal}>
            Forgot password
          </ForgotPasswordLink>
        }
        placeholder="password"
        component={TextInputField}
        type="password"
      />
      <IncorrectPasswordLabel>{error}</IncorrectPasswordLabel>
      <ButtonTier>
        <SubmitButton
          size="large"
          color="dark"
          expand="block"
          onClick={() => {
            submitForm()
          }}
        >
          <StyledIcon width={30} icon={enterOutline}></StyledIcon>
          Login
        </SubmitButton>
      </ButtonTier>
      <StyledLink>
        <h4>
          <IonRouterLink href={generatePath(SIGNUP_ROUTE)} color="anchor">
            I'm a new user - create account
          </IonRouterLink>
        </h4>
      </StyledLink>
      <Spacer height={35} />
    </ContentWrapper>
  )
}

export default withFormik<LoginFormProps, LoginFormValues>({
  displayName: 'LoginForm',

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .trim()
      .email('Enter a valid email address in the format name@example.com')
      .required('Enter a valid email address in the format name@example.com'),
    password: Yup.string().required('Enter the password for this account'),
  }),

  handleSubmit(values: LoginFormValues, { props: { onSubmit } }: FormikBag<LoginFormProps, LoginFormValues>) {
    onSubmit(values)
  },
})(LoginForm)
