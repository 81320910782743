import { InputChangeEventDetail, IonInputCustomEvent } from '@ionic/core'
import { IonInput } from '@ionic/react'
import { FieldProps } from 'formik'
import clsx from 'clsx'
import React, { ReactNode, useRef, useState } from 'react'
import styled from 'styled-components'
import ExpandableList from '@supplyhound/components/common/ExpandableList'
import Item from '@supplyhound/components/common/Item'
import useDynamicHeight, { HeightStates } from '@supplyhound/hooks/useDynamicHeight'
import { withInputBorder } from './InputBorder'

type SearchFieldProps<ResultType> = {
  search: (input: string) => Promise<void>
  results: ResultType[]
  onResultSelect: (result: ResultType) => void
  renderResult: (result: ResultType) => React.ReactNode
  controlText?: string
  control?: ReactNode
  placeholder?: string
  isSearchFieldInvalid?: boolean
  onIonInput?: () => void
}

const Wrapper = styled.div`
  position: relative;
  min-height: ${({ theme }) => theme.fieldHeight};
  height: 47px;
`

const StyledExpandableList = styled(withInputBorder(ExpandableList))`
  &:not(.${HeightStates.Collapsed}) .control {
    border-bottom: 1px solid;
  }
  background-color: var(--greyscale-1);
`

const StyledIonInput = styled(IonInput)`
  --padding-start: calc(1.5 * var(--space-unit));
  height: ${({ theme }) => theme.fieldHeight};
`

const ResultRow = styled(Item)`
  ::part(native) {
    --inner-padding-end: calc(2.5 * var(--space-unit));
    --background: none;
    border-color: var(--ion-border-color);
  }
`

const ResultRowInnerWrapper = styled.div`
  width: 100%;
`

// IonInput's public interface for accessing its native input element
// has a bug: sometimes it returns undefined. This would be fine if it
// were not wrapped in a promise. Since it *is* wrapped in a promise, we
// can't use it as a dependency for side effects, so expose the private
// variable instead, which is not wrapped in a promise.
// see https://github.com/ionic-team/ionic-framework/blob/v6.0.5/core/src/components/input/input.tsx#L298
export interface HTMLIonInputElementWithPublicNativeInput extends HTMLIonInputElement {
  nativeInput?: HTMLInputElement
}

const SearchField = <
  ValueType,
  SearchResultType extends {
    name: string
    place_id?: string
    address: string
  }
>({
  field: { name, onBlur },
  form,
  className,
  search,
  results,
  onResultSelect,
  renderResult,
  control,
  isSearchFieldInvalid,
  onIonInput,
  ...props
}: SearchFieldProps<SearchResultType> & FieldProps<ValueType> & { className?: string }) => {
  const { hostRef, collapse, expand, heightState } = useDynamicHeight<HTMLDivElement>()
  const ionInputRef = useRef<HTMLIonInputElementWithPublicNativeInput>(null)
  const nativeInput = ionInputRef.current?.nativeInput
  const [controlText, setControlText] = useState<string>('')

  const handleFocus = () => {
    // Set as intentionally unsupported value to stop autocomplete in chrome
    if (nativeInput) nativeInput.autocomplete = 'none'

    if (results.length) {
      expand()
    }
  }

  const handleOnInput = (event: IonInputCustomEvent<InputEvent>) => {
    const text = event.target.value || ''

    form.setFieldValue(name, {})
    if (text) {
      search(text.toString()).then(expand)
      setControlText(text.toString())
    } else {
      setControlText('')
      collapse()
    }

    if (onIonInput) {
      onIonInput()
    }
  }

  const handleSearchResultSelect = (result: SearchResultType) => {
    setControlText('')
    onResultSelect(result)
    collapse()
  }

  const handleSelectAll = () => {
    if (nativeInput) nativeInput.select()
  }

  return (
    <Wrapper ref={hostRef} className={className}>
      <StyledExpandableList
        state={heightState}
        className={clsx({ 'ion-invalid': isSearchFieldInvalid })}
        control={
          <StyledIonInput
            ref={ionInputRef}
            className="control"
            value={props.controlText ?? controlText}
            onIonInput={handleOnInput}
            debounce={400}
            onIonBlur={onBlur}
            onIonFocus={handleFocus}
            placeholder={props.placeholder}
            onClick={handleSelectAll}
            name={name}
          />
        }
      >
        {results.map(result => (
          <ResultRow lines="none" button detail key={result.place_id} onClick={() => handleSearchResultSelect(result)}>
            <ResultRowInnerWrapper>{renderResult(result)}</ResultRowInnerWrapper>
          </ResultRow>
        ))}
      </StyledExpandableList>
    </Wrapper>
  )
}

export default SearchField
