import React, { ReactNode, useCallback } from 'react'
import styled from 'styled-components'
import { IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import BackButton from '@supplyhound/components/buttons/BackButton'
import { HOME_ROUTE } from '@supplyhound/utils/config'
import MenuButton from '@supplyhound/components/buttons/MenuButton'
import { useHistory } from 'react-router-dom'
import { toEnumType } from '@supplyhound/types'

const StyledIonToolbar = styled(IonToolbar)`
  --padding-top: var(--space-unit);
  --padding-bottom: var(--space-unit);
  --padding-start: var(--space-unit);
  --padding-end: var(--space-unit);
`

const Label = styled.div`
  font-size: 1rem;
  font-weight: 700;
`
const SubLabel = styled.div`
  font-size: 1rem;
  line-height: 20px;
  font-weight: 400;
  color: var(--greyscale-5);
`

export const HeaderModes = {
  Root: 'Root',
  Leaf: 'Leaf',
}

type HeaderMode = toEnumType<typeof HeaderModes>

type Props = {
  mode?: HeaderMode
  label?: String
  subLabel?: String
  end?: ReactNode
  onBackButtonClick?: () => void
}

const Header: React.FC<Props> = ({ label, subLabel, end, mode = HeaderModes.Root, onBackButtonClick }) => {
  const history = useHistory()

  const handleBackButtonClick = useCallback(
    () => (history.length ? history.goBack() : history.push(HOME_ROUTE)),
    [history.length]
  )

  const renderStart = useCallback(() => {
    switch (mode) {
      case HeaderModes.Root:
        return (
          <IonButtons>
            <MenuButton menu="menu" autoHide={false} />
          </IonButtons>
        )
      case HeaderModes.Leaf:
        return <BackButton onClick={onBackButtonClick ? onBackButtonClick : handleBackButtonClick} />
    }
  }, [mode])

  return (
    <IonHeader class="ion-no-border">
      <StyledIonToolbar>
        <div slot="start">{renderStart()}</div>
        <IonTitle>
          <Label>{label}</Label>
          <SubLabel>{subLabel}</SubLabel>
        </IonTitle>
        <div slot="end">{end}</div>
      </StyledIonToolbar>
    </IonHeader>
  )
}

export default Header
