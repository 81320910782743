import React, { JSXElementConstructor } from 'react'
import styled from 'styled-components'
import Item from '@supplyhound/components/common/Item'

const StyledItem = styled(Item)<Props>`
  --highlight-height: 0;

  ::part(native) {
    background: none;
    --padding-bottom: 0px;
    --padding-top: 0px;
    height: ${({ theme }) => theme.fieldHeight};
  }

  input {
    background: none;
  }
  background-color: var(${props => (props.disabledBackground ? '--greyscale-2' : '--greyscale-1')});
  border-radius: var(--ion-border-radius);
  --padding-start: 10px;
`

export const withInputBorder = (component: JSXElementConstructor<any>) => styled(component)<Props>`
  border: 1px solid ${props => (props.isError ? 'var(--ion-color-danger)' : 'var(--greyscale-3)')};
  &:focus-within {
    border: 1px solid ${props => (props.isError ? 'var(--ion-color-danger)' : 'var(--greyscale-10)')};
  }
  box-sizing: border-box;
  border-radius: var(--ion-border-radius);
`
type Props = {
  isError?: boolean
  disabledBackground?: boolean
}

const InputBorder: React.FC<Props> = ({ disabledBackground, isError, children, ...props }) => (
  <div {...props}>
    <StyledItem lines="none" isError={isError} disabledBackground={disabledBackground} {...props}>
      {children}
    </StyledItem>
  </div>
)

export default withInputBorder(InputBorder)
