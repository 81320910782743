import { useRef } from 'react'

export type GoogleGeoCoderHelpers = {
  getGeocode: (address: string) => Promise<google.maps.places.PlaceResult[] | void>
  getByPlaceId: (placeId: string) => Promise<google.maps.GeocoderResult | void>
}

export const useGoogleGeocoder = (): GoogleGeoCoderHelpers => {
  const serviceRef = useRef<google.maps.Geocoder | null>(null)

  const getGeocode = async (address: string) => {
    return new Promise<google.maps.GeocoderResult[] | void>((resolve, reject) => {
      if (!serviceRef.current) resolve()

      serviceRef.current!.geocode({ address }, (results, status) => {
        if (results) resolve(results)
        else if (
          [
            google.maps.GeocoderStatus.INVALID_REQUEST,
            google.maps.GeocoderStatus.REQUEST_DENIED,
            google.maps.GeocoderStatus.UNKNOWN_ERROR,
          ].includes(status)
        ) {
          reject()
        }
      })
    })
  }

  const getByPlaceId = async (placeId: string) => {
    const result = await serviceRef.current?.geocode({ placeId })
    if (result) {
      return result.results[0]
    }
  }

  serviceRef.current = new google.maps.Geocoder()

  return { getGeocode, getByPlaceId }
}

export default useGoogleGeocoder
