import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IonApp } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Switch, Route, Redirect } from 'react-router-dom'
import useStreamChat from 'hooks/useStreamChat'

import { GlobalLoading } from '@supplyhound/layout'

import Login from './Auth/Login'
import RequestPasswordReset from './Auth/RequestPasswordReset'
import PerformPasswordReset from './Auth/PerformPasswordReset'
import Header from './Layout/Header'
import BodyContainer from './Layout/BodyContainer'
import DashboardPage from '../pages/Dashboard'
import ConversationPage from '../pages/Conversation'
import TaskDetail from '../pages/TaskDetail'
import OrderPage from 'pages/Order'
import OrderHistoryTaskPage from 'pages/TaskDetail/OrderHistoryTaskPage'

import { fetchConversations as fetchConversationsApi } from 'api/conversations'
import { Conversation } from 'types/conversations'

import usePushNotification from 'hooks/usePushNotification'
import { usePushNotificationOnNewStreamChatMessage } from 'hooks/useOnNewStreamChatMessage'

import {
  LOGIN_PATH,
  RESET_PASSWORD_CREATE_PATH,
  RESET_PASSWORD_UPDATE_PATH,
  CONFIRMATION_PATH,
  TASKS_PATH,
  ORDER_PATH,
  DASHBOARD_PATH,
  CONVERSATIONS_PATH,
} from 'config/paths'

import Unauthenticated from './Auth/Unauthenticated'
import Authenticated from './Auth/Authenticated'

import useStores from 'hooks/useStores'

const App: React.FC = () => {
  const { authStore } = useStores()

  const { token, userIsConnectedToStreamChat } = authStore
  const { notifier } = usePushNotification()
  const { adapter: streamChatAdapter } = useStreamChat()

  const [conversations, setConversations] = useState<Conversation[]>([])

  useEffect(() => {
    // If the user is already logged in then refresh
    // their cached profile.
    if (token) {
      authStore.dispatchFetchProfile()
    }
  }, [])

  // Load conversations if we're authed.
  useEffect(() => {
    if (!token) return

    fetchConversationsApi().then(({ conversations }) => {
      setConversations(conversations)
    })
  }, [token])

  // If we've found conversations then hook them up to streamchat.
  useEffect(() => {
    if (!userIsConnectedToStreamChat) return
    if (!conversations.length) return

    streamChatAdapter.loadConversationChannels(conversations)
  }, [conversations, streamChatAdapter, userIsConnectedToStreamChat])

  // Check if we can/need to request push notification permission.
  useEffect(() => {
    notifier.requestPermissionToPushNotifications()
  }, [notifier])

  // Push notifications when we get new messages from streamchat.
  usePushNotificationOnNewStreamChatMessage()

  return (
    <IonApp>
      <IonReactRouter>
        <GlobalLoading />
        <Header />
        <BodyContainer>
          <Switch>
            <Route path={LOGIN_PATH}>
              <Unauthenticated>
                <Login />
              </Unauthenticated>
            </Route>
            <Route exact path={RESET_PASSWORD_UPDATE_PATH}>
              <Unauthenticated>
                <PerformPasswordReset source="passwordReset" />
              </Unauthenticated>
            </Route>
            <Route exact path={CONFIRMATION_PATH}>
              <Unauthenticated>
                <PerformPasswordReset source="confirmation" />
              </Unauthenticated>
            </Route>
            <Route exact path={RESET_PASSWORD_CREATE_PATH}>
              <Unauthenticated>
                <RequestPasswordReset />
              </Unauthenticated>
            </Route>
            <Route exact path={DASHBOARD_PATH}>
              <Authenticated>
                <DashboardPage />
              </Authenticated>
            </Route>
            <Route exact path={`${TASKS_PATH}/:taskId`}>
              <Authenticated>
                <TaskDetail />
              </Authenticated>
            </Route>
            <Route exact path={ORDER_PATH}>
              <Authenticated>
                <OrderPage />
              </Authenticated>
            </Route>
            <Route exact path={`${ORDER_PATH}/:taskId`}>
              <Authenticated>
                <OrderHistoryTaskPage />
              </Authenticated>
            </Route>
            <Route exact path={`${CONVERSATIONS_PATH}/:conversationId`}>
              <Authenticated>
                <ConversationPage />
              </Authenticated>
            </Route>
            <Route path="/">
              <Redirect to={DASHBOARD_PATH} />
            </Route>
          </Switch>
        </BodyContainer>
      </IonReactRouter>
    </IonApp>
  )
}

export default observer(App)
