import React, { useState } from 'react'
import styled from 'styled-components'
import { Task, TaskTypes } from 'types/tasks'
import { updateDeliveryNetwork } from 'api/network'
import BookCurriModal from 'components/modals/BookCurriModal'
import { IonModal, IonCol } from '@ionic/react'
import { TaskStatuses } from 'config/taskStatuses'

const Select = styled.select<{ value: string }>`
  border: none;
  padding: 5px 10px;
  border-radius: var(--ion-border-radius);
  width: 100px;
  color: grey;
`

export const Col = styled(IonCol)`
  flex: 0 0 110px;
  text-align: center;
`

const FullScreenModal = styled(IonModal)`
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
  @media (min-width: 1024px) {
    --min-width: 1024px;
    --max-width: 1024px;
  }
`

const NetworkCol = ({
  task,
  refreshTable,
  setLoading,
}: {
  task: Task
  refreshTable: () => void
  setLoading: (state: boolean) => void
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  if (task.type !== TaskTypes.Delivery || TaskStatuses[task.status] === TaskStatuses.delivered)
    return <Col>{task.delivery_network}</Col>

  return (
    <Col
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <Select
        value={task.delivery_network || 'none'}
        onChange={async e => {
          const network = e.target.value
          if (network === 'CURRI') {
            setShowModal(true)
          } else {
            setLoading(true)
            await updateDeliveryNetwork(task.id, network)
            await refreshTable()
            setLoading(false)
          }
        }}
        placeholder={'Select a network'}
      >
        <option value="none" disabled hidden>
          Select a Network
        </option>
        <option value={'CURRI'}>Curri</option>
        <option value={'TOOKAN'}>Tookan</option>
      </Select>
      <FullScreenModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <BookCurriModal task={task} refreshTable={refreshTable} dismiss={() => setShowModal(false)} />
      </FullScreenModal>
    </Col>
  )
}

export default NetworkCol
