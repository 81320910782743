import { IonButton } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import { chevronForwardOutline, chevronDownOutline } from 'ionicons/icons'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import styled from 'styled-components'
import { JobSite, ForwardPartialProps, ID } from '@supplyhound/types'
import Dropdown from './common/Dropdown'
import { HeightStates, HeightState } from '@supplyhound/hooks/useDynamicHeight'
import JobSiteItem from '@supplyhound/components/JobSiteItem'
import ListItem from '@supplyhound/components/common/ListItem'

const JOB_SITE_ITEM_HEIGHT: number = 81
const MENU_COLLAPSE_BUTTON_HEIGHT: number = 60

type Props = ForwardPartialProps<typeof Dropdown> & {
  jobSites: JobSite[]
  selectedJobSite?: JobSite
  onJobSiteSelect: (jobSiteId: ID) => any
  onDropdownStateChange?: (state: HeightState) => any
  disableAddJobSite?: boolean
} & EditableJobSite

type EditableJobSite = { editableJobSite?: boolean }

const StyledDropdown = styled(Dropdown)`
  .expandable-list {
    width: calc(100% + 2px);
    margin-left: -1px;
    background: var(--ion-color-light);
    top: 100px;
  }

  .collapse-button {
    background: var(--ion-color-light);
    margin: 0;
    padding: 4px 2px;
  }

  &.${HeightStates.Collapsed} .control {
    --border-color: transparent;
  }
`

const StyledJobSiteItem = styled(JobSiteItem)`
  height: ${JOB_SITE_ITEM_HEIGHT}px;
`

const Control = styled(ListItem)`
  border-radius: 8px;
  ::part(native) {
    background: none;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`

const ControlLabel = styled.div`
  display: flex;
  flex-direction: column;
  height: 66px;
  padding-left: calc(1.5 * var(--space-unit));
  justify-content: center;
`

const UpdateJobSiteButton = styled(IonButton)`
  height: 25px;
  margin: 0 auto 0 0;
  --padding-start: 0;
  font-size: 1rem;
  font-weight: 500;
`

const SelectedAddress = styled.div<EditableJobSite>`
  font-size: 1.5rem;
  ${props => (props.editableJobSite ? 'margin-top: calc( 0.5 * var(--space-unit));' : '')}
`

const UpdateJobSiteIcon = styled(Icon)`
  padding-top: 3px;
`

const JobSiteDropdown: React.FC<Props> = ({
  jobSites,
  selectedJobSite,
  onJobSiteSelect,
  editableJobSite = true,
  onDropdownStateChange,
  disableAddJobSite = false,
  ...props
}) => {
  const [updateJobSiteIcon, setUpdateJobSiteIcon] = useState<string>(chevronForwardOutline)
  const handleDropdownStateChange = (state: HeightState) => {
    switch (state) {
      case HeightStates.Collapsed:
      case HeightStates.Collapsing:
        setUpdateJobSiteIcon(chevronForwardOutline)
        break
      case HeightStates.Expanded:
      case HeightStates.Expanding:
        setUpdateJobSiteIcon(chevronDownOutline)
    }
  }

  // # of job sites + 1 (for add job site item) * height of job site item + height of collapse menu button
  // Calculate the height ahead of time since the list is absolute positioned
  // it isn't trivial to get the height before displaying the elemnt.
  const dropDownMenuHeight = (jobSites.length + 1) * JOB_SITE_ITEM_HEIGHT + MENU_COLLAPSE_BUTTON_HEIGHT
  return (
    <StyledDropdown
      onStateChange={(state: HeightState) => {
        if (onDropdownStateChange) {
          onDropdownStateChange(state)
        }
        handleDropdownStateChange(state)
      }}
      dropDownMenuHeight={dropDownMenuHeight}
      disabled={!editableJobSite}
      control={
        <Control lines="full" className="control">
          <ControlLabel>
            {selectedJobSite && (
              <SelectedAddress editableJobSite={editableJobSite}>
                <b>{selectedJobSite.name}</b>
              </SelectedAddress>
            )}
            {editableJobSite ? (
              <UpdateJobSiteButton fill="clear">
                {!!selectedJobSite ? 'Change job site' : jobSites.length ? 'Select a job site' : 'Add a job site'}
                <UpdateJobSiteIcon icon={updateJobSiteIcon} />
              </UpdateJobSiteButton>
            ) : null}
          </ControlLabel>
        </Control>
      }
      items={
        <>
          {jobSites.map(({ id, formatted_address, nickname }) => (
            <StyledJobSiteItem key={id} label={nickname || formatted_address} onClick={() => onJobSiteSelect(id)} />
          ))}
          {!disableAddJobSite && (
            <StyledJobSiteItem addJobSiteItem onJobSiteCreate={(jobSite: JobSite) => onJobSiteSelect(jobSite.id)} />
          )}
        </>
      }
      {...props}
    />
  )
}

export default observer(JobSiteDropdown)
