import { makeAutoObservable } from 'mobx'

type AsyncFn = () => Promise<any>
type LoadingQueue = Array<Promise<any>>

/**
 * Maintain a global loading state backed
 * by a queue of unresolved promises.
 */
export class LoadingStore {
  private areLoading: LoadingQueue = []

  constructor() {
    makeAutoObservable(this)
  }

  loadWhile = (fn: AsyncFn): Promise<any> => {
    const promise = fn().finally(this.popLoadingPromise)
    this.areLoading.unshift(promise)

    return promise
  }

  popLoadingPromise = () => {
    this.areLoading.pop()
  }

  get isLoading() {
    return !!this.areLoading.length
  }
}
