import styled from 'styled-components'
import React, { ReactNode } from 'react'
import { IonContent } from '@ionic/react'

import useStores from 'hooks/useStores'

const BodyContainer = styled(IonContent)`
  background-color: var(--ion-background-color);
  height: 100%;
  overflow-y: auto;
`
const Container = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
`
const Constraint = styled.div<{ isAdmin: boolean }>`
  width: ${({ isAdmin }) => (isAdmin ? '95%' : '1240px;')};
  justify-content: center;
  display: flex;
`

const Body = ({ children }: { children: ReactNode }) => {
  const { authStore } = useStores()
  const isAdmin = authStore.getIsAdmin

  return (
    <BodyContainer>
      <Container>
        <Constraint isAdmin={isAdmin}>{children}</Constraint>
      </Container>
    </BodyContainer>
  )
}

export default Body
