import { IonItemGroup } from '@ionic/react'
import { FieldProps, getIn } from 'formik'
import React, { useState } from 'react'
import useGoogleAutocompleteService, { Prediction } from '@supplyhound/hooks/useGoogleAutocompleteService'
import FieldHeader from './FieldHeader'
import SearchField from './SearchField'
import styled from 'styled-components'
import useGooglePlacesService from '@supplyhound/hooks/useGooglePlacesService'
import { LocationFactory } from '@supplyhound/utils/location'
import { Location } from '@supplyhound/types'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'
import { removeCountry } from '@supplyhound/utils/address'

const PlacesHost = styled.div`
  width: 1px;
  height: 1px;
  visibility: hidden;
`

const LocationSearchField: React.FC<
  FieldProps<Location> & {
    className: string
    label: string
    onSearchResultSelect: (place: google.maps.places.PlaceResult) => void
    onIonInput: () => void
  }
> = props => {
  const { onSearchResultSelect, onIonInput } = props
  const { search: googleSearch } = useGoogleAutocompleteService()
  const { getPlaceDetails, hostRef } = useGooglePlacesService()
  const [results, setResults] = useState<Prediction[]>([])
  const search = (input: string) => googleSearch(input).then(setResults)
  const { value, name } = props.field
  const { errors, touched } = props.form
  const handleSearchResultSelect = (result: Prediction) => {
    getPlaceDetails(result.place_id!).then(place => {
      if (!place) return

      if (onSearchResultSelect) {
        onSearchResultSelect(place)
      }
      props.form.setFieldValue(props.field.name, LocationFactory(place))
    })
  }

  const error = getIn(errors, `${name}.full_address`) || getIn(errors, name)
  const fieldTouched = getIn(touched, name)
  let controlText = value?.full_address ? removeCountry(value.full_address) : undefined
  return (
    <IonItemGroup className={props.className}>
      <FieldHeader label={props.label} />
      <SearchField<{ full_address?: string }, Prediction>
        {...props}
        controlText={controlText}
        onResultSelect={handleSearchResultSelect}
        renderResult={(result: Prediction) => <h4>{result.address}</h4>}
        results={results.filter(({ place_id }) => !!place_id)}
        search={search}
        isSearchFieldInvalid={error && fieldTouched}
        onIonInput={onIonInput}
      />

      <PlacesHost ref={hostRef} />
      {error && fieldTouched && <ErrorLabel>{error}</ErrorLabel>}
    </IonItemGroup>
  )
}

export default LocationSearchField
