import { Capacitor } from '@capacitor/core'

export const buildLinkToClientAppURI = (path: string) => {
  let origin = 'https://app.supplyhound.com'

  if (Capacitor.getPlatform() === 'web') {
    origin = window.location.origin
  } else {
    origin = process.env.REACT_APP_CLIENT_BASE_URL!
  }
  return `${origin}${path}`
}

export const IMAGE_HOST_URL = 'https://supplyhound.s3.us-west-1.amazonaws.com/images'
