import { makeAutoObservable, set } from 'mobx'
import { getItem, removeItem, setItem } from '@supplyhound/utils/storage'
import { AuthToken, DecodedUserToken, Team, BasicUser } from '@supplyhound/types'
import { authorizationHeader } from '@supplyhound/api'
import { fetchProfile } from '@supplyhound/api'
import jwt from 'jsonwebtoken'

const TOKEN_EXPIRED = 'expired'

export class AuthStore<ProfileType> {
  profile?: ProfileType
  token?: AuthToken

  readonly tokenKey: string

  constructor(tokenKey: string) {
    makeAutoObservable(this)

    this.tokenKey = tokenKey

    this.loadTokenFromStorage()
  }

  get axiosConfig() {
    return {
      headers: {
        Authorization: authorizationHeader(this.token || ''),
      },
    }
  }

  get isAuthenticated() {
    return !!this.getToken() && this.getToken() !== TOKEN_EXPIRED
  }

  get isTokenExpired() {
    return this.getToken() === TOKEN_EXPIRED
  }

  getToken() {
    if (this.token) {
      return this.tokenExpired(this.token) ? TOKEN_EXPIRED : this.token
    }
    return ''
  }

  setToken(token: string) {
    setItem(this.tokenKey, token)
    this.token = token
  }

  clearToken() {
    removeItem(this.tokenKey)

    this.token = undefined
  }

  reset() {
    this.clearToken()
    this.profile = undefined
  }

  setProfile(profile: ProfileType) {
    this.profile = profile
  }

  updateProfile(profile: ProfileType) {
    if (this.profile) {
      set(this.profile, profile)
    } else {
      this.setProfile(profile)
    }
  }

  updateBasicUserInfo(basicUser: BasicUser) {
    if (this.profile) {
      set(this.profile, basicUser)
    }
  }

  tokenExpired(token?: string) {
    if (token) {
      const decodedToken = jwt.decode(token) as DecodedUserToken
      return decodedToken?.exp * 1000 < Date.now()
    } else {
      return true
    }
  }

  loadTokenFromStorage() {
    const existingToken = getItem(this.tokenKey)

    if (existingToken) {
      this.setToken(existingToken)
    }
  }

  setTeamReminderSchedule(team: Team) {
    if (this.profile) {
      set(this.profile, { ...this.profile, team })
    }
  }

  dispatchLoadProfile = async () => {
    const response = await fetchProfile()
    this.setProfile(response.data.user)
  }
}
