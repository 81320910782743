import { useHistory } from 'react-router-dom'
import queryString, { ParsedQuery } from 'query-string'

interface UseQueryResult<QueryType> {
  query: QueryType
  setQuery: (query: QueryType) => void
}

export function useQuery<QueryType extends ParsedQuery>(): UseQueryResult<QueryType> {
  const history = useHistory()
  const {
    location: { search, pathname },
  } = history

  const query = queryString.parse(search) as QueryType

  const setQuery = (query: QueryType) => {
    const stringifiedQuery = queryString.stringify(query)
    history.replace(`${pathname}?${stringifiedQuery}`)
  }

  return {
    query,
    setQuery,
  }
}

export default useQuery
