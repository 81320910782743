import compact from 'lodash/compact'

import { Location } from '@supplyhound/types'

const PLACE_RESULT_FIELDS: Record<string, { tag: string; field: keyof google.maps.GeocoderAddressComponent }[]> = {
  country: [
    {
      tag: 'country',
      field: 'short_name',
    },
  ],
  state: [
    {
      tag: 'administrative_area_level_1',
      field: 'short_name',
    },
  ],
  city: [
    {
      tag: 'locality',
      field: 'long_name',
    },
  ],
  street: [
    {
      tag: 'street_number',
      field: 'short_name',
    },
    {
      tag: 'route',
      field: 'short_name',
    },
  ],
  zipcode: [
    {
      tag: 'postal_code',
      field: 'short_name',
    },
  ],
}

export const LocationFactory = (placeResult: google.maps.places.PlaceResult): Location => {
  const { address_components: addressComponents, place_id } = placeResult
  const location = Object.fromEntries(
    Object.keys(PLACE_RESULT_FIELDS).map((key: string) => {
      const fields = PLACE_RESULT_FIELDS[key]

      const values = fields.map(({ tag, field }) => {
        const addressComponent = addressComponents!.find(c => c.types.includes(tag))

        if (!addressComponent) {
          return null
        }

        return addressComponent[field]
      })

      return [key, compact(values).join(' ')]
    })
  ) as unknown as Location

  location.place_id = place_id
  location.full_address = placeResult.formatted_address!

  return location
}
