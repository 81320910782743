import React, { ReactNode } from 'react'
import { Header, HeaderModes } from '@supplyhound/layout'

import Content from '@supplyhound/components/Content'

export interface BaseModalProps {
  label?: string
  subLabel?: string
  onDidDismiss?: () => void
  back?: () => void
  children: ReactNode
  mode?: string
  end?: ReactNode
}

const BaseModal = ({
  label,
  subLabel,
  onDidDismiss,
  back,
  children,
  mode = HeaderModes.Cancel,
  end,
}: BaseModalProps) => {
  return (
    <>
      <Header
        mode={mode}
        label={label}
        subLabel={subLabel}
        onCancelButtonClick={onDidDismiss}
        onBackButtonClick={back}
        end={end}
      />
      <Content>{children}</Content>
    </>
  )
}

export default BaseModal
