import { makeAutoObservable, runInAction } from 'mobx'
import { keyBy, findIndex, map, find, matchesProperty } from 'lodash'
import { JobSite, TeamMemberList, TeamMemberListItem, UnidentifiedTeamMemberListItem, ID } from '@supplyhound/types'
import {
  createTeamMemberListItem,
  updateTeamMemberListItem,
  submitTeamMemberListItem,
  deleteTeamMemberListItem,
} from '@supplyhound/api'
import { AxiosRequestConfig } from 'axios'
import { fetchTeamMemberList, fetchTeamMemberLists } from '@supplyhound/api'

type ListStoreProps = {
  axiosConfig: AxiosRequestConfig
}

export class TeamMemberListStore {
  lists: Record<ID, List> = {}
  current_job_site_id?: ID

  axiosConfig: AxiosRequestConfig

  constructor({ axiosConfig }: ListStoreProps) {
    this.axiosConfig = axiosConfig

    makeAutoObservable(this, { axiosConfig: false }, { autoBind: true })
  }

  setAxiosConfig(axiosConfig: AxiosRequestConfig) {
    this.axiosConfig = axiosConfig
  }

  reset() {
    this.lists = {}
  }

  get jobSitesArray() {
    return map(Object.values(this.lists), 'jobSite')
  }

  findListByJobSiteId(jobSiteId: ID) {
    return find(Object.values(this.lists), matchesProperty(['jobSite', 'id'], jobSiteId))
  }

  async dispatchFetchList(listId: ID) {
    const list = await fetchTeamMemberList(listId, this.axiosConfig)
    this.lists[listId] = new List(list, {
      store: this,
      axiosConfig: this.axiosConfig,
    })
  }

  async dispatchFetchLists() {
    const teamMemberLists = await fetchTeamMemberLists(this.axiosConfig)
    const lists = teamMemberLists.map(list => new List(list, { store: this, axiosConfig: this.axiosConfig }))
    this.lists = keyBy(lists, 'id')
  }
}

export class List {
  id: ID
  items: TeamMemberListItem[]
  active_items_count: number
  jobSite: JobSite
  store: TeamMemberListStore
  axiosConfig?: AxiosRequestConfig

  constructor(
    { id, items, job_site, active_items_count }: TeamMemberList,
    { store, axiosConfig }: { store: TeamMemberListStore; axiosConfig: AxiosRequestConfig }
  ) {
    makeAutoObservable(this, {
      store: false,
      axiosConfig: false,
    })
    this.id = id
    this.items = items
    this.active_items_count = active_items_count
    this.jobSite = job_site
    this.store = store
    this.axiosConfig = axiosConfig
  }

  get asJson() {
    return {
      id: this.id,
      items: this.items,
      jobSite: this.jobSite,
    }
  }

  get activeItems() {
    return this.items.filter(item => item.status === 'active')
  }

  get submitedItems() {
    return this.items.filter(item => item.status !== 'active')
  }

  replaceItem(item: TeamMemberListItem) {
    const idxOfItem = this.idxOfItem(item)
    if (!idxOfItem) return
    this.items.splice(idxOfItem, 1, item)
  }

  idxOfItem(item: TeamMemberListItem) {
    const { id } = item
    return findIndex(this.items, { id })
  }

  async dispatchCreateItem(item: UnidentifiedTeamMemberListItem) {
    item.team_member_list_id = this.id
    const createdItem = await createTeamMemberListItem(item, this.axiosConfig)
    runInAction(() => {
      this.items.push(createdItem)
    })
    return createdItem.id
  }

  async dispatchUpdateItem(item: TeamMemberListItem) {
    const updatedItem = await updateTeamMemberListItem(item, this.axiosConfig)
    runInAction(() => {
      this.replaceItem(updatedItem)
    })
  }

  async dispatchSubmitItems(idsToSubmit: ID[]) {
    await submitTeamMemberListItem(this.id, idsToSubmit, this.axiosConfig)
    runInAction(() => {
      this.items = this.items.map(item => (idsToSubmit.includes(item.id) ? { ...item, status: 'submitted' } : item))
    })
  }

  async dispatchDeleteItem(teamMemberListItemIdToDelete: ID) {
    runInAction(() => {
      this.items = this.items.filter(item => item.id !== teamMemberListItemIdToDelete)
    })
    await deleteTeamMemberListItem(teamMemberListItemIdToDelete, this.axiosConfig)
  }
}
